import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {RedactionBase} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/RedactionBase";
import {traceClass} from "../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  TracerServiceBase
} from "../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";

/** Тип компонента. График | Табель */
type ComponentType = 'graph' | 'table';
/** Тип редакция */
type RedactionType = Pick<RedactionBase, 'id' | 'modifiedDate'> & {
  /** Является ли актуальной редакцией */
  isActualRedaction: boolean,
  /** Номер версии данной редакции */
  version: number,
  /** Является ли версия актуальной */
  isActualVersion: boolean,
};

/** Компонент кнопки Редакция */
@Component({
  selector: 'app-redaction-button',
  templateUrl: './redaction-button.component.html',
  styleUrls: ['./redaction-button.component.css']
})
@traceClass('RedactionButtonComponent')
export class RedactionButtonComponent implements OnDestroy{
  /** Отключен ли компонент */
  @Input() disabled: boolean = false;
  /** Тип. Влияет на отображения Версия| Первичный Корректирующий */
  @Input() type: ComponentType = 'graph';
  /** Редакция */
  @Input() redaction: RedactionType;
  /** Имеет ли вложенную кнопку(Очистить сравнение редакций) */
  @Input() hasClearCompareButton: boolean = false;

  /** Событие нажатия на основную кнопку */
  @Output() clickByRoot$: EventEmitter<void> = new EventEmitter<void>();
  /** Событие нажатия на кнопку очищения сравнения редакций */
  @Output() clickByClearCompare$: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly tracerService: TracerServiceBase) { }

  /** Обработка нажатия на главную кнопку */
  @traceFunc()
  public onRootClick(){
    this.clickByRoot$.emit();
  }

  /** Обработка нажатия на кнопку очищения сравнения редакций */
  @traceFunc()
  public onClearCompare($event: any){
    $event.preventDefault();
    $event.stopPropagation();
    this.clickByClearCompare$.emit();
  }

  @traceFunc()
  ngOnDestroy(): void {
  }
}
