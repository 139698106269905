import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {takeUntil} from "rxjs/operators";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {PrintFormForDateSettingsWithSubdivisions}
  from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CheckedSubdivisionsValidator} from "../report-settings-form-validators";
import {DateHelper} from "../../../helpers/dateHelper";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {DeferSelectionService} from "../../../services/defer-selection-services/defer-selection.service";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {trace} from "../../../modules/trace/operators/trace";
import {CalendarView} from "@progress/kendo-angular-dateinputs";

@Component({
  selector: 'app-print-form-for-date-settings-with-subdivision-tree',
  templateUrl: './print-form-for-date-settings-with-subdivision-tree.component.html',
  styleUrls: ['./print-form-for-date-settings-with-subdivision-tree.component.css'],
  providers: [DeferSelectionService]
})
@traceClass('PrintFormForDateSettingsWithSubdivisionTreeComponent')
export class PrintFormForDateSettingsWithSubdivisionTreeComponent implements OnInit, OnDestroy{
  public form:FormGroup;

  @Input()
  public datePickerViewParams: {
    activeView: CalendarView,
    format: string,
    deepBottomView?: CalendarView,
    deepTopView?: CalendarView
  } = {
    activeView: "month",
    format: "dd MMMM yyyy",
    deepBottomView: "year"
  };

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input()
  public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase) {
    this._subdivisionDataSourceService = value;
  }

  @Input() public selection;

  private _checkableSettings: CheckableSettings;
  // Настройки checkBox TreeView списка подразделений
  @Input()
  public get checkableSettings(): CheckableSettings {
    return this._checkableSettings;
  }
  public set checkableSettings(value: CheckableSettings){
    this._checkableSettings = value;
  }

  /** Функция формирования отображаемой в treeView строки из объекта ISubdivision */
  private _displayTextFn: (subdivision: ISubdivision) => string;
  @Input() public get displayTextFn(){ return this._displayTextFn; }
  public set displayTextFn(value){ this._displayTextFn = value; }

  /** Развернуть (отобразить) все элементы списка подразделений при отображении формы настроек */
  @Input() public expandAllSubdivisions: boolean;

  /** Изменение даты, по которой формируются данные (необходимо для выполнения перезагрузки списка подразделений, сформированного на конкретную дату)*/
  @Output() dateChange$: EventEmitter<Date> = new EventEmitter<Date>();
  /** Нажата кнопка отмены */
  @Output() cancel$: EventEmitter<void> = new EventEmitter<void>();
  /** Нажата кнопка подтверждения выбранных/изменненных настроек */
  @Output() print$: EventEmitter<PrintFormForDateSettingsWithSubdivisions> = new EventEmitter<PrintFormForDateSettingsWithSubdivisions>();

  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private readonly traceService: TracerServiceBase) {  }

  ngOnInit(): void {
    this.formInit();
  }

  /** Установить значение параметра выбранных подразделений на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  @traceFunc()
  public setCheckedSubdivisionsValue(el: number[]){
    this.form.controls.checkedSubdivisions.setValue(el);
  }

  /** Установить значение параметра "Выбрать все подразделения" на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  @traceFunc()
  public setAllSubdivisionsFlagValue(el: boolean){
    this.form.controls.allSubdivisionsFlag.setValue(el);
  }

  /** Событие нажатия на кнопку подтверждения выбранных/измененных настроек */
  @traceFunc()
  public onClickOk() {
    this.print$.next(new PrintFormForDateSettingsWithSubdivisions(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.date.value,
      this.form.controls.allSubdivisionsFlag.value,
      this.form.controls.checkedSubdivisions.value));
  }

  /** Событие нажатия на кнопку Отмена */
  @traceFunc()
  public onClickCancel() {
    this.cancel$.next();
  }

  public ngOnDestroy(): void {
    this.print$.complete();
    this.cancel$.complete();
    this.dateChange$.complete();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private formInit() {
    this.form = new FormGroup({
    date: new FormControl(DateHelper.getStartOfMounth(new Date(), true), {validators: Validators.required, updateOn: 'blur'}),
    allSubdivisionsFlag: new FormControl(false),
    checkedSubdivisions: new FormControl([], CheckedSubdivisionsValidator()),
    });

    this.form.controls.date.valueChanges.pipe(trace(this.traceService),
      takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.dateChange$.emit(value);
      });

    this.form.controls.date.updateValueAndValidity({emitEvent: true});
    this.form.controls.date.markAsTouched();
  }
}
