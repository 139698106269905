import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CalendarView} from "@progress/kendo-angular-dateinputs";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {
  PeriodTimeIntervalModel,
  ReportSettingsWithSubdivisionList
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {ReplaySubject} from "rxjs";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {DateHelper} from "../../../helpers/dateHelper";
import {trace} from "../../../modules/trace/operators/trace";
import {takeUntil} from "rxjs/operators";
import {CheckedSubdivisionsValidator} from "../report-settings-form-validators";
import * as moment from "moment";

@Component({
  selector: 'app-report-settings-with-timeintervals-and-subdivision-tree-list',
  templateUrl: './report-settings-with-timeintervals-and-subdivision-tree-list.component.html',
  styleUrl: './report-settings-with-timeintervals-and-subdivision-tree-list.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('ReportSettingsWithTimeintervalsAndSubdivisionTreeListComponent')
export class ReportSettingsWithTimeintervalsAndSubdivisionTreeListComponent implements OnInit, OnDestroy{
  public form:FormGroup;
  public selectedTimeIntervals: Array<PeriodTimeIntervalModel> = [];
  public endOfReportDayTime: Date;
  public isEndOfReportDayTime: boolean;

  @Input()
  public datePickerViewParams: {
    activeView: CalendarView,
    format: string,
    deepBottomView?: CalendarView,
    deepTopView?: CalendarView
  } = {
    activeView: "month",
    format: "dd MMMM yyyy",
    deepBottomView: "month"
  };

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input()
  public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase) {
    this._subdivisionDataSourceService = value;
  }

  @Input() public selection;

  private _checkableSettings: CheckableSettings;
  // Настройки checkBox TreeView списка подразделений
  @Input()
  public get checkableSettings(): CheckableSettings {
    return this._checkableSettings;
  }
  public set checkableSettings(value: CheckableSettings){
    this._checkableSettings = value;
  }

  /** Функция формирования отображаемой в treeView строки из объекта ISubdivision */
  private _displayTextFn: (subdivision: ISubdivision) => string;
  @Input() public get displayTextFn(){ return this._displayTextFn; }
  public set displayTextFn(value){ this._displayTextFn = value; }

  /** Развернуть (отобразить) все элементы списка подразделений при отображении формы настроек */
  @Input() public expandAllSubdivisions: boolean;

  /** Изменение даты, по которой формируются данные (необходимо для выполнения перезагрузки списка подразделений, сформированного на конкретную дату)*/
  @Output() dateChange$: EventEmitter<Date> = new EventEmitter<Date>();
  /** Нажата кнопка отмены */
  @Output() cancel$: EventEmitter<void> = new EventEmitter<void>();
  /** Нажата кнопка подтверждения выбранных/изменненных настроек */
  @Output() print$: EventEmitter<ReportSettingsWithSubdivisionList> = new EventEmitter<ReportSettingsWithSubdivisionList>();

  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);


  constructor(private readonly traceService: TracerServiceBase) {
  }

  public ngOnInit() {
    this.formInit();
  }

  /** Установить значение параметра выбранных подразделений на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  @traceFunc()
  public setCheckedSubdivisionsValue(el: number[]){
    this.form.controls.checkedSubdivisions.setValue(el);
  }

  /** Установить значение параметра "Выбрать все подразделения" на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  @traceFunc()
  public setAllSubdivisionsFlagValue(el: boolean){
    this.form.controls.allSubdivisionsFlag.setValue(el);
  }

  /** Событие нажатия на кнопку подтверждения выбранных/измененных настроек */
  @traceFunc()
  public onClickOk() {
    this.print$.next(new ReportSettingsWithSubdivisionList(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.date.value,
      this.form.controls.checkedSubdivisions.value,
      this.selectedTimeIntervals,
      this.form.controls.considerLastMinuteFlag.value));
  }

  /** Событие нажатия на кнопку Отмена */
  @traceFunc()
  public onClickCancel() {
    this.cancel$.next();
  }
  public ngOnDestroy(): void {
    this.print$.complete();
    this.cancel$.complete();
    this.dateChange$.complete();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private formInit() {
    this.form = new FormGroup({
      date: new FormControl(DateHelper.getStartOfMounth(new Date(), true),
        {validators: Validators.required, updateOn: 'blur'}),
      showTimeIntervalsBlock: new FormControl<boolean>(false),
      considerLastMinuteFlag: new FormControl<boolean>(false),
      allSubdivisionsFlag: new FormControl<boolean>(false),
      checkedSubdivisions: new FormControl([], CheckedSubdivisionsValidator()),
    });

    this.form.controls.date.valueChanges.pipe(trace(this.traceService),
      takeUntil(this.unsubscribe$))
      .subscribe((value: Date) => {
        this.selectedTimeIntervals.forEach(ti => ti.startTime.setDate(value.getDate()));
        this.selectedTimeIntervals.forEach(ti => ti.endTime.setDate(value.getDate()));

        this.endOfReportDayTime = this.getEndOfReportDay();
        this.dateChange$.emit(value);
      });

    this.form.controls.showTimeIntervalsBlock.valueChanges
      .pipe(trace(this.traceService), takeUntil(this.unsubscribe$))
      .subscribe(value => value
          ? this.selectedTimeIntervals = [this.createFirstPeriodForReportDate()]
          : this.selectedTimeIntervals.length = 0
);

    this.form.controls.date.updateValueAndValidity({emitEvent: true});
    this.form.controls.date.markAsTouched();
  }

  /** Удалить временной интервал */
  public deleteTimeInterval(timeInterval: PeriodTimeIntervalModel, index: number, isLast: boolean) {
    if(this.selectedTimeIntervals.length > 1){
      if (!isLast){//если удаляется не последний интервал, то "склеиваем" последующий после удаляемого интервал с предыдущим удаляемому
        this.selectedTimeIntervals[index + 1].startTime = this.selectedTimeIntervals[index].startTime; //чтоб не было разрывов интервалов времени
      }

      this.selectedTimeIntervals.splice(index, 1);
    }
  }

  /** Добавить временной интервал */
  public addTimeInterval() {
      this.selectedTimeIntervals.push(new PeriodTimeIntervalModel(
        moment(this.selectedTimeIntervals[this.selectedTimeIntervals.length - 1].endTime)
          .add(1, 'minute').toDate(),
        this.endOfReportDayTime));
  }

  /** Создать период продолжительностью на весь выбранный отчетный день */
  private createFirstPeriodForReportDate(): PeriodTimeIntervalModel {
    return new PeriodTimeIntervalModel(
      moment(this.form.controls.date.value).startOf('day').toDate(),
      this.getEndOfReportDay());
  }

  /** Получить дату окончания дня отчета с округлением до минут */
  private getEndOfReportDay(): Date{
    return moment(this.form.controls.date.value).endOf('day').startOf("minute").toDate();
  }
}
