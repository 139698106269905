import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, of, ReplaySubject, share} from "rxjs";
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "./services/subdivisions-treelist-component-data.service";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {filter, map, takeUntil} from "rxjs/operators";
import {
  KendoTreeListDataSourceSelection
} from "../../../classes/array-data-sources/selections/kendo-treelist-array-data-source-selection";
import {DeferSelectionService} from "../../../services/defer-selection-services/defer-selection.service";
import {SortDescriptor} from "@progress/kendo-data-query";

/** Элемент данных */
class DataItem{
  public readonly id: number;

  constructor(public readonly subdivision: ISubdivision, public displayValue: string) {
    this.id = subdivision.id;
  }

  /** Название поля .subdivision */
  public static readonly subdivisionName: keyof DataItem = 'subdivision';
}

/** По какому полю сортировать. displayValue - по выводимому тексту */
type SortByPropertyType = keyof (Pick<DataItem, 'displayValue'> & Pick<ISubdivision, 'id' | 'sortOrder'>);
/** Описание сортировки */
export type SortDescriptorType = {
  by: SortByPropertyType
} & Pick<SortDescriptor, 'dir'>

/** Интерфейс входных параметров для компонента */
export interface ISubdivisionsTreelistComponent_Inputs{
  /** Функция получения текста для отображения */
  displayTextFn: (subdivision: ISubdivision) => string;
  /** Сервис данных */
  dataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  /** Стиль колонки */
  cellStyle: any;
  /** Доступно ли выделение нескольких строк */
  multipleSelected: boolean;
  /** Описание сортировки. По умолчанию сортирует по sortOrder ask */
  set sortDescriptor(value: SortDescriptorType);
}

@Component({
  selector: 'app-subdivisions-treelist',
  templateUrl: './subdivisions-treelist.component.html',
  styleUrls: ['./subdivisions-treelist.component.css'],
  providers: [DeferSelectionService]
})
@traceClass('SubdivisionsTreelistComponent')
export class SubdivisionsTreelistComponent implements ISubdivisionsTreelistComponent_Inputs, OnInit, OnDestroy {

  private _displayTextFn: (subdivision: ISubdivision) => string = x => x.longName;
  @Input()public get displayTextFn(){
    return this._displayTextFn;
  }
  public set displayTextFn(value){
    this._displayTextFn = value;
    this.setDataSource();
  }

  private _dataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input() public get dataSourceService(){
    return this._dataSourceService;
  }
  public set dataSourceService(value){
    this._dataSourceService = value;
    this.setDataSource();
    this.setScrollToRow();
    this.selectionService.originSelection = value?.dataSource;
  }

  @Input("cellStyle") cellStyle: any;

  @Input() public multipleSelected: boolean = false;

  private _selection: KendoTreeListDataSourceSelection<ISubdivision, number>;
  /** Слежение за выделенными строками */
  @Input() get selection(){
    return this._selection;
  }
  set selection(value){
    this._selection = value;
    this.setScrollToRow();
    this.selectionService.originSelection = value;
  }

  /** Описание сортировки */
  @Input() public set sortDescriptor(value: SortDescriptorType){
    if(!value){
      this.sort = undefined;
      return;
    }

    this.sort = [ { field: this.getSortPropertyPath(value.by), dir: value.dir } ]
  }

  /** Событие двойного клика по строке */
  @Output() public doubleClick = new EventEmitter<ISubdivision>();
  /** Событие изменения набора выделенных идентификаторов строк */
  @Output() public selectionIdsChanged = new EventEmitter<number[]>();

  /** Сортировка в таблице */
  public sort: SortDescriptor[];

  /** Стрим прокрутки до выделенной строки */
  public scrollToRow$: Observable<any>;
  /** Источник данных для компонента */
  public dataSource$: Observable<DataItem[]>;

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private readonly tracerService: TracerServiceBase,
              public readonly selectionService: DeferSelectionService<SubdivisionsTreelistComponentDataSourceServiceBase['dataSource'], KendoTreeListDataSourceSelection<ISubdivision, number>>) {
    this.selectionService.tempCtorFunc = dataSource => new KendoTreeListDataSourceSelection<ISubdivision, number>(dataSource);
    this.selectionService.isDeferApply = false;

    this.selectionService.tempSelection.data$
      .pipe(map(value => !!value ? value.selectedIds.data$ : of([])))
      .subscribe(items$ => {
        items$.subscribe(items => this.selectionIdsChanged.emit(items));
      });

    this.sortDescriptor = { by: 'sortOrder', dir: 'asc' }; //Устанавливаем значение по умолчанию
  }

  @traceFunc()
  ngOnInit() {
  }


  /** Устанавливает источник данных для таблицы */
  @traceFunc()
  private setDataSource(){
    if(!this._dataSourceService){
      this.dataSource$ = undefined;
      return;
    }

    this.dataSource$ = this._dataSourceService.dataSource.data$
      .pipe(
        map(value => {
          return value.map(x => new DataItem(x, this._displayTextFn(x)))
        }),
        share(),
        takeUntil(this.streams$.unsubscribe)
      );
  }

  /** Установить стрим прокрутки до выделенной строки */
  @traceFunc()
  private setScrollToRow(){
    if(!this.selection){
      this.scrollToRow$ = undefined;
      return;
    }

    this.scrollToRow$ = this.selection.withInitiator.data$
      .pipe(
        filter(value => value.initiator === 'program'),
        takeUntil(this.streams$.unsubscribe)
      );
  }

  /** Получить путь к полю по которому необходимо производить сортировку */
  @traceFunc()
  private getSortPropertyPath(value: SortByPropertyType): string{
    switch (value){
      case "displayValue":
        return value;
      case "id":
        return value;
      case "sortOrder":
        return `${DataItem.subdivisionName}.${value}`;
      default: throw new Error('out of range');
    }
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
