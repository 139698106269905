import {
  BehaviorSubject,
  defer,concatMap, concatWith,
  Observable,
  of, pairwise,
  ReplaySubject,
  share, startWith,
  Subject,
  switchMap,
  tap, throwError,
} from "rxjs";
import {IEntityId} from "src/app/classes/domain/POCOs/interfaces/IEntityId";
import {catchError, filter, finalize, map, take, takeUntil} from "rxjs/operators";
import {DbChangedListener_Entity} from "../../services/signal-r/listeners/db-changed-listener";
import {xnameofPath} from "../../functions/nameof";
import {ArrayHelper, FindAllItemResultType} from "../../helpers/arrayHelper";
import {ObservableQueue} from "../observable-queues/observable-queue";
import { IEntityIdGuid } from '../domain/POCOs/interfaces/IEntityIdGuid';

/** Интерфейс параметров принимаемых в конструктор, содержащий функции необходимые для логики */
interface DataSourceConstructorFn<TData>{
  /**
   * Функция определения, что данные отсутствуют.<br>
   * По умолчанию x => x === undefined || x === null <br>
   */
  isNotDataFn?: (currentValue: TData) => boolean;

  /**
   * Функция получения значения, если данные отсутствуют.<br>
   * К примеру, если тип данных [] и необходимо вместо undefined устанавливать пустой []<br>
   */
  ifNotDataValueGetFn?: () => TData;
  /**
   * Функция копирования значения перед установкой.<br>
   * По умолчанию x => x. (не копируется)<br>
   * Пример: (х: []) => [...x] - для установки нового массива.<br>
   */
  copyValueFn?: (currentValue: TData) => TData;
}

/** Тип объекта события перед изменением */
type BeforeChangeType<TData> = {
  /**
   * Инициализатор события.<br>
   * change - изменение значения<br>
   * onDestroy - освобождение ресурсов<br>
   */
  initiator: 'change' | 'onDestroy',
  /** Текущее значение */
  currentData: TData
}

/** DataSource только для чтения */
export type DataSourceReadOnly<TData> = Readonly<Pick<DataSource<TData>, 'fns' | 'data' | 'data$' | 'beforeChange$' | 'change$' | 'data2' | 'data2$' | 'isDataLoading' | 'isDataLoading$' | 'wasEmitted'>>

/**
 * Класс обвертка для любого значения(кроме undefined).<br>
 * Позволяет устанавливать значения как T, так и Observable<T> <br>
 * Позволяет получать значение как T, так и Observable<T> <br>
 * <br>
 * Если ожидается значение из асинхронности, и при этом устанавливается новое значение, то прекратит слушать предыдущую асинхронность<br>
 * Тем самым значение будет последним вызвавшимся.<br>
 */
export class DataSource<TData> {

  /** стримы */
  protected readonly streams$ = {
    setData: new Subject<any>(),
    unsubscribe: new ReplaySubject<any>(1)
  }


  private _data: TData;
  /** Данные. Использовать только для чтения. */
  public get data(): TData {
    return this._data;
  }

  private readonly _data$ = new ReplaySubject<TData>(1);
  /** Данные в виде стрима. Используется ReplaySubject */
  public get data$(): Observable<TData> {
    return this._data$;
  }


  private readonly _change$ = new Subject<TData>();
  /** Событие изменения данных. Используется Subject */
  public get change$(): Observable<TData>{
    return this._change$;
  }

  private readonly _beforeChange$ = new Subject<BeforeChangeType<TData>>();
  /**
   * Событие происходит перед изменением значения или освобождением ресурса данного экземпляра.<br>
   * @example Необходимо освобождать ресурсы текущего объекта перед сменой на новый
   */
  public get beforeChange$(): Observable<BeforeChangeType<TData>>{
    return this._beforeChange$;
  }

  private readonly _data2$ = new BehaviorSubject<TData | undefined>(undefined);
  /** Аналог свойства data$. Если транслирует undefined - данных нет . Используется BehaviorSubject(undefined) */
  public get data2$(): Observable<TData | undefined> {
    return this._data2$;
  }

  /** Аналог свойства data. Может возвращать undefined если данные отсутствуют. */
  public get data2(): TData | undefined{
    return this._data2$.value;
  }


  private _isDataLoading$ = new BehaviorSubject<boolean>(false);
  /** Стрим начала окончания загрузки данных. Если транслирует true - начало загрузки, false - окончание загрузки */
  public get isDataLoading$(): Observable<boolean>{
    return this._isDataLoading$;
  }

  /** Происходит ли загрузка данных. true может быть если установили данные из Observable и в данный момент еще не-было первой трансляции */
  public get isDataLoading(){
    return this._isDataLoading$.value;
  }

  /** Установить состояние загрузки. Транслирует событие если значение изменилось */
  private set setIsDataLoading(value: boolean){
    if(this.isDataLoading == value){
      return;
    }

    this._isDataLoading$.next(value);
  }

  /** была ли хоть одна трансляция.(устанавливали хоть раз значение) */
  public get wasEmitted(){
    return this.isOnChange;
  }

  /**
   * Конструктор
   * @param fns инжекция функций необходимых для работы
   */
  constructor(public readonly fns: DataSourceConstructorFn<TData> | undefined | null = undefined) {
    if(!this.fns){
      this.fns = {};
    }

    this.fns.isNotDataFn = this.fns.isNotDataFn ?? (x => x === undefined || x === null);
    this.fns.ifNotDataValueGetFn = this.fns.ifNotDataValueGetFn ?? (() => undefined);
    this.fns.copyValueFn = this.fns.copyValueFn ?? (x => x);

    this._data = this.fns.ifNotDataValueGetFn();
  }

  /**
   * Установить значение<br>
   * Установка отменяет ожидание предыдущей установки из асинхронности<br>
   * @param data данные или стрим данных
   * @param errorHandler функция обработки ошибки
   */
  public setData(data: TData | Observable<TData>, errorHandler: (e: any) => void = () => {}) {
    this.setData$(data).pipe(takeUntil(this.streams$.unsubscribe)).subscribe(
      {
        error: errorHandler
      }
    );
    return this;
  }

  /**
   * Установить значение<br>
   * Установка отменяет ожидание предыдущей установки из асинхронности<br>
   * Установка произойдет только в момент подписки на результирующий Observable
   * <br>
   * Результирующий стрим будет транслировать события до тех пор, пока не установится новое значение<br>
   * <br>
   * Используется pipe share(), для предотвращения двойного выполнения при двух подписках<br>
   * @param data данные или стрим данных.
   */
  public setData$(data: TData | Observable<TData>): Observable<this>{
    return of(null).pipe(
      tap(x => this.streams$.setData.next(null)),
      switchMap(x => {
        if(data instanceof Observable){
          this.setIsDataLoading = true; //Устанавливаем что происходит загрузка данных
          return data.pipe(
            catchError((err, caught) => {
              this.onChange(undefined);
              this.setIsDataLoading = false;
              return throwError(err);
            }),
            takeUntil(this.streams$.setData), //Пока не установили новый стрим
            takeUntil(this.streams$.unsubscribe) //Пока не уничтожили
          );
        }

        return of(data);
      }),
      tap(value => { this.onChange(value) }),
      switchMap(x => of(this)),
      share(), //Делаем для нескольких подписчиков
    );
  }

  /** Завершает трансляцию data$ (нужно вызывать при onDestroy() ) */
  public onDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();

    this.streams$.setData.next(null);
    this.streams$.setData.complete();

    this._data$.complete();
    this._data2$.complete();
    this._change$.complete();
    this._beforeChange$.next({initiator: 'onDestroy', currentData: this.data});
    this._beforeChange$.complete();
    this._isDataLoading$.complete();

  }

  private isOnChange: boolean = false;
  /** Обработка изменения данных. Запоминает и транслирует в стрим */
  protected onChange(data: TData){
    this._beforeChange$.next({initiator: 'change', currentData: this.data}); //Сообщаем что будет смена значения
    const isTrySetNotData = this.fns.isNotDataFn(data);

    if(this.isOnChange && isTrySetNotData && this.data2 === undefined){ //защита от повторных сообщений, что данные отсутствуют
      return;
    }

    this.isOnChange = true;

    //Если устанавливают отсутствие данных, возьмет из функции
    data = isTrySetNotData ?
      this.fns.ifNotDataValueGetFn() :
      data;

    // Копируем значение перед установкой
    data = this.fns.isNotDataFn(data) ? data : this.fns.copyValueFn(data);


    this._data = data;
    this._data$.next(data);
    this._data2$.next(isTrySetNotData ? undefined : data); //Устанавливаем или undefined, если устанавливают отсутствие данных или переданные данные
    this._change$.next(data);
    this.setIsDataLoading = false;
  }
}

/** Класс элемента метода detect */
export class DetectItem<TDataItem>{
  /** Действие. Если !current добавить. Если !expected удалить. Модифицировать. */
  public readonly action: DbChangedListener_Entity<any>['state'];

  /**
   * Конструктор
   * @param current Текущее состояние
   * @param expected Ожидаемое состояние
   * @param strictly Строго. Для примера возьмем вставку, если === true и такой элемент уже есть, бросит ошибку, иначе не бросит
   */
  constructor(public readonly current: TDataItem,
              public readonly expected: TDataItem,
              public readonly strictly: boolean = true) {
    this.action = this.calculateAction();
  }

  /** Определить действие */
  private calculateAction(): DetectItem<any>['action']{
    if(!this.current && !this.expected){
      const xPath = xnameofPath(DetectItem);
      throw new Error(`Не допускается !${xPath.current} и !${xPath.expected}`);
    }

    if(!this.current){ return 'added'; }
    if(!this.expected){ return 'deleted'; }
    return 'modified';
  }
}

/** Класс элемента результата метода detect */
export class DetectItemResult<TDataItem>{
  constructor(public readonly item: DetectItem<TDataItem>,
              public readonly isExecuted: boolean) {
  }
}

const errorDataUndefinedMessage = 'В данный момент массив данных == undefined';
const errorDataLoadingMessage = 'В данный момент происходит загрузка новых данных';

/** ArrayDataSource только для чтения */
export type ArrayDataSourceReadOnly<TDataItem> = DataSourceReadOnly<TDataItem[]> & Pick<ArrayDataSource<TDataItem>, 'observe$' | 'findAll'>;

/**
 * Класс для данных в виде массива, умеет транслировать Observable при изменении данных.<br>
 * Использовать для данных KendoGrid, KendoDropdown и т.д
 */
export class ArrayDataSource<TDataItem> extends DataSource<TDataItem[]>{

  /** Конструктор */
  constructor(fns: DataSourceConstructorFn<TDataItem[]> | undefined | null = undefined,
              public readonly itemComparerFn: (tem1: TDataItem, item2: TDataItem) => boolean = (item1, item2) => item1 === item2) {
    fns = !fns ? {} : fns;

    fns.isNotDataFn = fns.isNotDataFn ?? (x => !x);
    fns.ifNotDataValueGetFn = fns.ifNotDataValueGetFn ?? (() => []);
    fns.copyValueFn = fns.copyValueFn ?? (x => [...x]);

    super(fns);
  }

  /**
   * Наблюдать за изменениями в массиве.<br>
   * @param matchingItemsFn Функция сопоставления элементов. К примеру по полю id. По умолчанию x1 === x2
   * @param itemComparerFn Функция сравнения элементов, для определения модифицирован или нет. По умолчанию x1 === x2
   * @returns Observable транслирующий событие, кода изменились элементы или изменился источник данных, со списком изменений
   */
  public observe$(matchingItemsFn: (currentItem: TDataItem, originItem: TDataItem) => boolean = undefined,
                 itemComparerFn: (currentItem: TDataItem, originItem: TDataItem) => boolean = undefined){
    matchingItemsFn = matchingItemsFn ?? ((x1, x2) => x1 === x2);
    itemComparerFn = itemComparerFn ?? ((x1, x2) => x1 === x2);

    return defer(() => this.change$.pipe(
      startWith(this.data),
      pairwise(),
      map(value => ArrayHelper.difference2(value[1], value[0], matchingItemsFn, itemComparerFn)),
      filter(value => value.length > 0),
      takeUntil(this.streams$.unsubscribe)
    ));
  }

  /**
   * Найти все элементы которые соответствуют переданному предикату или переданному элементу
   * @return [] если нет соответствующих элементов или [item1, item2, ...]
   */
  public findAll(itemOrPredicate: TDataItem | ((item: TDataItem) => boolean)): FindAllItemResultType<TDataItem>[]{
    return this._findAll(this.data2, itemOrPredicate instanceof Function ? itemOrPredicate : x => this.itemComparerFn(x, itemOrPredicate));
  }

  /**
   * Добавить один или несколько элементов
   * @param strictly Если true бросит ошибку если элемент уже присутствует, иначе повторно вставит
   * @param items Элементы на добавление
   */
  public addItems(strictly: boolean, ...items: TDataItem[]): DetectItemResult<TDataItem>[]{
    return this.detect(...items.map(item => new DetectItem(null, item, strictly)));
  }

  /**
   * Удалить один или несколько элементов
   * @param strictly Если true бросит ошибку если целевой элемент НЕ найден в источнике данных, иначе не выполнит удаление
   * @param items Элементы на удаление
   */
  public deleteItems(strictly: boolean, ...items: TDataItem[]): DetectItemResult<TDataItem>[]{
    return this.detect(...items.map(item => new DetectItem(item, null, strictly)));
  }

  /**
   * Обновить один или несколько элементов
   * @param strictly Если true бросит ошибку если целевой элемент НЕ найден в источнике данных, иначе не выполнит удаление
   * @param items Элементы на обновление
   * @exception items.some(item => !item.newValue) Не допускается !newValue так как элемент будет удален
   */
  public updateItems(strictly: boolean, ...items: {current: TDataItem, newValue: TDataItem}[]): DetectItemResult<TDataItem>[]{
    if(items.some(item => !item.newValue)){
      throw new Error('Не допускается !newValue так как элемент будет удален');
    }

    return this.detect(...items.map(item => new DetectItem(item.current, item.newValue, strictly)));
  }

  /**
   * Метод определяет необходимое действие(вставка, добавление, удаление) и выполняет его<br>
   * @param items массив действий
   * @return массив обверток над элементами переданного массива. Для определения выполнилось ли действие;
   */
  public detect(...items: DetectItem<TDataItem>[]): DetectItemResult<TDataItem>[]{
    if(!this.data2){
      throw new Error(errorDataUndefinedMessage);
    }

    const result: DetectItemResult<TDataItem>[] = [];
    let data = [...this.data2]; //Обязательно копируем, для НЕ допускания редактирования реального массива

    items.forEach(item => {
      let foundAll: FindAllItemResultType<TDataItem>[];

      switch (item.action) {
        case "deleted":
          foundAll = this._findAll(data, x => this.itemComparerFn(x, item.current));

          if(item.strictly && !foundAll.length){
            throw new Error('Элемент на удаление не найден');
          }

          result.push(new DetectItemResult<TDataItem>(item, foundAll.length > 0));

          if(foundAll.length > 0){
            data = data.filter(item => !foundAll.some(x => x.item === item));
          }
          break;
        case "added":
          foundAll = this._findAll(data, x => this.itemComparerFn(x, item.expected));
          if(item.strictly && foundAll.length > 0){
            throw new Error('Элемент уже находится в источнике данных');
          }

          result.push(new DetectItemResult<TDataItem>(item, true)); //Запоминаем результат
          data.push(item.expected);
          break;
        case "modified":
          foundAll = this._findAll(data, x => this.itemComparerFn(x, item.current));

          if(item.strictly && !foundAll.length){
            throw new Error('Целевой элемент для обновления отсутствует в источнике данных ');
          }

          result.push(new DetectItemResult<TDataItem>(item, foundAll.length > 0)); //Запоминаем результат

          foundAll.forEach(found => { //Изменяем данные
            data[found.index] = item.expected;
          })

          break;
        default: throw new Error('out of range');
      }
    })

    if(result.some(x => x.isExecuted)){ //Если данные были изменены
      this.onChange(data);
    }

    return result;
  }

  /**
   * Найти все элементы которые соответствуют переданному предикату
   * @return [] если нет соответствующих элементов или [item1, item2, ...]
   */
  protected _findAll(source: TDataItem[], predicate: (item: TDataItem) => boolean): FindAllItemResultType<TDataItem>[]{
    return ArrayHelper.findAll(source, predicate);
  }
}

/** ArrayDataSourceHasId только для чтения */
export type ArrayDataSourceHasIdReadOnly<TDataItem, TId> = ArrayDataSourceReadOnly<TDataItem> & Pick<ArrayDataSourceHasId<TDataItem, TId>, 'idGetter' | 'getItemById'>

/**
 * Класс для данных в виде массива элемент которого имеет идентификатор, умеет транслировать Observable при изменении данных.<br>
 * Использовать для данных KendoGrid, KendoDropdown и т.д
 */
export class ArrayDataSourceHasId<TDataItem, TId> extends ArrayDataSource<TDataItem>{

  /**
   * Конструктор
   * @param idGetter Функция получения ключа-идентификатора для сравнения
   */
  constructor(public readonly idGetter: (item: TDataItem) => TId,
              fns: DataSourceConstructorFn<TDataItem[]> | undefined | null = undefined,
              itemComparerFn: (tem1: TDataItem, item2: TDataItem) => boolean = (item1, item2) => idGetter(item1) === idGetter(item2)) {
    super(fns, itemComparerFn);
  }

  /**
   * Наблюдать за изменениями в массиве.<br>
   * @param matchingItemsFn Функция сопоставления элементов. По умолчанию сопоставляет элементы по идентификатору(используется функция idGetter переданная в конструктор)
   * @param itemComparerFn Функция сравнения элементов, для определения модифицирован или нет. По умолчанию x1 === x2
   * @returns Observable транслирующий событие, кода изменились элементы или изменился источник данных, со списком изменений
   */
  observe$(matchingItemsFn: (currentItem: TDataItem, originItem: TDataItem) => boolean = undefined, itemComparerFn: (currentItem: TDataItem, originItem) => boolean = undefined) {
    matchingItemsFn = matchingItemsFn ?? ((x1, x2) => this.idGetter(x1) === this.idGetter(x2))

    return super.observe$(matchingItemsFn, itemComparerFn);
  }

  /** Получить dataItem по идентификатору */
  public getItemById(id: TId): TDataItem {
    if(!this.data2){
      throw new Error(errorDataUndefinedMessage);
    }

    return this.data2.find(d => this.idGetter(d) === id);
  }

  /**
   * Обновить dataItem
   * @param strictly если true и элемент не найден будет ошибка
   * @param items объект dataItem, который нужно установить
   */
  public updateItems2(strictly: boolean, ...items: TDataItem[]) {
    return this.updateItems(strictly, ...items.map(item =>{return {current: item, newValue: item}}));
  }

  /**
   * Добавить или изменить dataItem
   * @param items новое значение dataItem
   */
  public addOrUpdateItems(...items: TDataItem[]) {
    if(!this.data2){
      throw new Error(errorDataUndefinedMessage);
    }

    const source = items.map(item => {
      const itemIndex = this.data2.findIndex(x => this.idGetter(x) === this.idGetter(item));
      return new DetectItem(itemIndex < 0 ? null : item, item, true);
    })

    return this.detect(...source);
  }


  /**
   * Удалить dataItem по его id
   * @param strictly если true и элемент не найден будет ошибка
   * @param ids список идентификаторов который нужно удалить.
   */
  public deleteItemByIds(strictly: boolean, ...ids: TId[]){
    const distinctIds = ArrayHelper.distinct(ids);
    const source = this.findAll(item => distinctIds.some(id => id === this.idGetter(item)))
      .map(x => x.item);

    if(strictly && source.length != distinctIds.length){
      throw new Error('Один или несколько элементов для удаления НЕ найдены');
    }

    return this.deleteItems(strictly, ...source);
  }

  /**
   * Перезагрузить элементы из удаленного источника по переданному списку элементов<br>
   * Каждый вызов становится в очередь и выполняется после завершения всех предыдущих вызовов данного метода<br>
   * Отменяется если установлен новый источник через метод setData или setData$<br>
   * @param remote$Fn функция получающая список идентификаторов и возвращающая Observable элементов, идентификаторы которых находятся в переданном списке индентификаторов
   * @param targets интересующие строки. Делается distinct идентификаторов под капотом.
   * @return Observable элементов затронутых строк. По которым можно определить, что именно было с каждой строкой.
   */
  public reloadFromRemoteByItems$(remote$Fn: (ids: TId[]) => Observable<TDataItem[]>, ...targets: TDataItem[]): Observable<DetectItemResult<TDataItem>[]>{
    return this.reloadFromRemoteByIds$(remote$Fn, ...targets.map(x => this.idGetter(x)))
  }

  /** Очередь для метода {@link reloadFromRemoteByIds$} */
  private reloadFromRemoteByIdsQueue = new ObservableQueue(true);
  /**
   * Перезагрузить элементы из удаленного источника по переданному списку идентификаторов<br>
   * Каждый вызов становится в очередь и выполняется после завершения всех предыдущих вызовов данного метода<br>
   * Отменяется если установлен новый источник через метод setData или setData$<br>
   * Используется {@link ObservableQueue} с параметром true<br>
   * @param remote$Fn функция получающая список идентификаторов и возвращающая Observable элементов, идентификаторы которых находятся в переданном списке индентификаторов
   * @param targets идентификаторы интересующих строк. Делается distinct под капотом.
   * @return Observable элементов затронутых строк. По которым можно определить, что именно было с каждой строкой.
   */
  public reloadFromRemoteByIds$(remote$Fn: (ids: TId[]) => Observable<TDataItem[]>, ...targets: TId[]): Observable<DetectItemResult<TDataItem>[]>{
    if(this.data2 === undefined){
      throw new Error(errorDataUndefinedMessage);
    }

    if(this.isDataLoading){
      throw new Error(errorDataLoadingMessage);
    }

    return this.reloadFromRemoteByIdsQueue.push$(
      this._reloadFromRemoteByIds$(remote$Fn, ...targets)
    );
  }

  /**
   * Перезагрузить элементы из удаленного источника по переданному списку идентификаторов
   * @param remote$Fn функция получающая список идентификаторов и возвращающая Observable элементов, идентификаторы которых находятся в переданном списке индентификаторов
   * @param targets идентификаторы интересующих строк. Делается distinct под капотом.
   * @return Observable элементов затронутых строк. По которым можно определить, что именно было с каждой строкой.
   */
  private _reloadFromRemoteByIds$(remote$Fn: (ids: TId[]) => Observable<TDataItem[]>, ...targets: TId[]): Observable<DetectItemResult<TDataItem>[]>{
    targets = targets.filter(x => x !== undefined && x !== null);

    if(targets.length === 0){
      return of([]);
    }

    targets = ArrayHelper.distinct(targets);

    return remote$Fn(targets)
      .pipe(
        take(1),
        map(value => {
          return targets.map(id => {
            return {
              inDataSource: this.getItemById(id) ?? null,
              inRemoteSource: value.find(element => this.idGetter(element) === id) ?? null
            }
          }).filter(x => x.inDataSource || x.inRemoteSource) //Отфильтровываем те которые нет в источнике данных и нет в удаленном источнике
            .map(item => {
              return new DetectItem(
                item.inDataSource,
                item.inRemoteSource,
                true
              )
            })
        }),

        map(value => this.detect(...value)), //Выполняем
        share(),
        takeUntil(this.streams$.setData),//Пока не сменили источник данных на новый
        takeUntil(this.streams$.unsubscribe),//Пока не высвободили ресурсы
      )
  }


  onDestroy() {
    this.reloadFromRemoteByIdsQueue.onDestroy();
    super.onDestroy();
  }
}

/** ArrayDataSourceHasId только для чтения */
export type ArrayDataSourceIEntityIdReadOnly<TDataItem extends IEntityId> = ArrayDataSourceHasIdReadOnly<TDataItem, TDataItem['id']>

/** Класс для данных в виде массива, элементы которого реализуют интерфейс IEntityId  */
export class ArrayDataSourceIEntityId<TDataItem extends IEntityId> extends ArrayDataSourceHasId<TDataItem, TDataItem['id']>{

  constructor(fns: DataSourceConstructorFn<TDataItem[]> | undefined | null = undefined) {
    super(x => x.id, fns);
  }
}

/** Класс для данных в виде массива, элементы которого реализуют интерфейс IEntityIdGuid  */
export class ArrayDataSourceIEntityIdGuid<TDataItem extends IEntityIdGuid> extends ArrayDataSourceHasId<TDataItem, TDataItem['id']>{

  constructor(fns: DataSourceConstructorFn<TDataItem[]> | undefined | null = undefined) {
    super(x => x.id, fns);
  }
}
