import {Injectable, OnDestroy} from '@angular/core';
import {WebApi1Service} from "../web-api1.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {shareReplay, take, takeUntil, tap} from "rxjs/operators";
import {defer, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class Api1StaffListSettingsControllerService implements OnDestroy{

  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _rateStepWithCash$: Observable<number>;

  public get rateStepWithCash$(): Observable<number>{
    if(!this._rateStepWithCash$){
      this._rateStepWithCash$ =  this.getRateStep$().pipe(shareReplay(1));
    }
    return this._rateStepWithCash$;
  }

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) { }

  public getRateStep$(): Observable<number>{
    return  this.httpClient.get<number>(this.webApi1Service.controllers.staffListSettings.actions.getRateStep.toString());
  }

  public getOccupationCodeLength$(): Observable<number>{
    return  this.httpClient.get<number>(this.webApi1Service.controllers.staffListSettings.actions.getOccupationCodeLength.toString());
  }

  private _isUsedDuty: boolean = null;
  /**
   * Обвертка над методом isUsedDuty$
   * Для кэширования результата на время работы
   */
  public isUsedDuty_cached$(): Observable<boolean>{
    return defer(() => {
      return this._isUsedDuty == null ?
        this.isUsedDuty$().pipe(takeUntil(this.unsubscribe$), tap(value => this._isUsedDuty = value)) :
        of(this._isUsedDuty);
    })
  }

  private _isUsedUvor: boolean = null;
  /**
   * Обвертка над методом isUsedUvor$
   * Для кэширования результата на время работы
   */
  public isUsedUvor_cached$(): Observable<boolean>{
    return defer(() => {
      return this._isUsedUvor == null ?
        this.isUsedUvor$().pipe(takeUntil(this.unsubscribe$), tap(value => this._isUsedUvor = value)) :
        of(this._isUsedUvor);
    })
  }

  /**
   * Получить флаг применяется ли данной клиникой функционал "Дежурство"
   */
  public isUsedDuty$(): Observable<boolean>{
    return this.httpClient.get<boolean>(this.webApi1Service.controllers.staffListSettings.actions.isUsedDuty.toString());
  }

  /**
   * Получить флаг применяется ли данной клиникой функционал "УВОР" (УВеличение Объема Работ)
   */
  public isUsedUvor$(): Observable<boolean>{
    return this.httpClient.get<boolean>(this.webApi1Service.controllers.staffListSettings.actions.isUsedUvor.toString());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
