import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SkipSelf} from "@angular/core";
import {CorrectionListDialogService} from "../../correction-list/services/correction-list-dialog.service";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {interval, Observable, of, race, ReplaySubject, Subject, switchMap, takeWhile} from "rxjs";
import {GridToolbarManagementService} from "../services/grid-toolbar-management.service";
import {
  RedactionBaseService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import {AlertButton, AlertService} from "../../../../../../../src/app/services/alert.service";
import {
  Api1PrintFormControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-form-controller.service";
import {
  Api1PrintReportControllerService,
  PrintFormForDateSettings,
  PrintFormForDateSettingsWithSubdivisions,
  PrintFormFromtillDatesSettings,
  PrintFormFromTillDatesWithStaffUnitTypesSettings,
  PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes,
  RegisterReportSettings,
  ReportSettingsWithSubdivisionList
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {LoadingIndicatorService} from "../../../../../../../src/app/services/loading-indicator.service";
import {KendoNotificationService} from "../../../../../../../src/app/services/kendo-notification.service";
import {CommentDialogService} from "../../../../../../../src/app/components/comment/comment-dialog.service";
import {TableDismissedDialogService} from "../../table-dismissed/services/table-dismissed-dialog.service";
import {
  FromTillPrintSettingsDialogService
} from "../../from-till-print-settings/services/from-till-print-settings-dialog.service";
import {
  ForDateWithSubdivisionsPrintSettingsDialogService
} from "../../for-date-with-subdivisions-print-settings/for-date-with-subdivisions-print-settings-dialog.service";
import {
  TracerServiceBase
} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {
  DisplayErrorsService
} from "../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {
  Api1SubdivisionsTreelistControlControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-subdivisions-treelist-control-controller.service";
import {DbChangedListener} from "../../../../../../../src/app/services/signal-r/listeners/db-changed-listener";
import {AuthService} from "../../../../../../../src/app/modules/auth/services/auth.service";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {delay, take, takeUntil} from "rxjs/operators";
import {trace} from "../../../../../../../src/app/modules/trace/operators/trace";
import {ResponseObjError} from "../../../../../../../src/app/classes/requestResults/responseObjError";
import {
  RedactionDetailGridComponent_PreviewEvent
} from "../../shareds/redactions/redaction-grid/redaction-grid.component";
import {PrintFormType} from "../../../../../../../src/app/classes/domain/enums/print-form-type";
import * as moment from "moment";
import {
  SubdivisionsTreelistComponentDataSourceService2
} from "../../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import * as FileSaver from "file-saver";
import { ReportPeriodSettingsComponent
} from "../../../../../../../src/app/components/print-form-settings/report-period-settings/report-period-settings.component";
import {exErrorHandler} from "../../../../../../../src/app/operators/ex-error-handler";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {StaffUnitTypeEnum} from "../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {exLoadingMessage} from "../../../../../../../src/app/operators/ex-loading-message.operator";
import {
  Api1GraphControlControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {
  HierarchiStringService
} from "../../../../../../../src/app/components/hierarchi-strings/services/hierarchi-string.service";
import {
  PrintFormForDateSettingsComponent
} from "../../../../../../../src/app/components/print-form-settings/print-form-for-date-settings/print-form-for-date-settings.component";
import {
  ReportSettingsWithTimeintervalsAndSubdivisionListDialogService
} from "../../../../../../../src/app/components/print-form-settings/report-settings-with-timeintervals-and-subdivision-tree-list/services/report-settings-with-timeintervals-and-subdivision-tree-list.service";
import {
  StaffUnitTypeListSettingsComponent
} from "../../settings-for-reports/staff-unit-type-list-settings/staff-unit-type-list-settings.component";
import {
  Api1StaffUnitTypeControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {
  PrintFormFromTillDatesWithStaffunitTypesSettingsComponent
} from "../../../../../../../src/app/components/print-form-settings/print-form-from-till-dates-with-staffunit-types-settings/print-form-from-till-dates-with-staffunit-types-settings.component";
import {
  CovidRegisterSettingsComponent
} from "../../settings-for-reports/covid-register-settings/covid-register-settings.component";
import {
  ForDateWithSubdivisionsAndStaffunitTypesSettingsComponent
} from "../../../../../../../src/app/components/print-form-settings/for-date-with-subdivisions-and-staffunit-types-settings/for-date-with-subdivisions-and-staffunit-types-settings.component";
import {CalendarView} from "@progress/kendo-angular-dateinputs";
import {
  ArrayDataSourceSelection
} from "../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";
import {ISubdivision} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision";

@Component({
  selector: 'app-grid-toolbar-management',
  templateUrl: './grid-toolbar-management.component.html',
  styleUrls: ['./grid-toolbar-management.component.css'],
  providers: [CorrectionListDialogService, HierarchiStringService]
})
@traceClass('GridToolbarManagementComponent')
export class GridToolbarManagementComponent implements OnInit, OnDestroy {
  /** Отключен ли контрол */
  @Input() disabled: boolean = false;
  /** Отключена ли кнопка сохранить */
  @Input() disableSaveButton: boolean = true;

  public readonly printData$: Observable<IPrintItem[]> = new ReplaySubject<IPrintItem[]>(1);
  private _printData: Array<IPrintItem> = new Array<IPrintItem>();
  /** Данные для списка Печать */
  public get printData(): Array<IPrintItem> {
    return this._printData;
  }
  private set printData(value: Array<IPrintItem>) {
    this._printData = value;
    (this.printData$ as Subject<IPrintItem[]>).next(value);
  }

  /** Выбранный элемент печати */
  public reportType: IPrintItem;

  /** Отображать ли компонент ReportSettings с выбором подразделений */
  public showReportWithSubdivisionTreeSettingsDialog: boolean;

  /** Отображать ли диалог выбора редакции */
  public showRedactionSelect: boolean = false;

  private dialogRef: DialogRef;


  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }
  reportCalendarLevel: 'month' | 'year' = 'month';

  constructor(@SkipSelf() public readonly service: GridToolbarManagementService,
              @SkipSelf() private readonly redactionBaseService: RedactionBaseService,
              private readonly alertService: AlertService,
              private readonly printFormService: Api1PrintFormControllerService,
              private readonly api1PrintReportControllerService: Api1PrintReportControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly commentDialogService: CommentDialogService,
              private readonly correctionListDialogService: CorrectionListDialogService,
              private readonly tableDismissedDialogService: TableDismissedDialogService,
              private readonly fromTillSettingsDialogService: FromTillPrintSettingsDialogService,
              private readonly forDateWithSubdivisionsPrintSettingsDialogService: ForDateWithSubdivisionsPrintSettingsDialogService,
              private readonly reportSettingsWithTimeintervalsAndSubdivisionListDialogService: ReportSettingsWithTimeintervalsAndSubdivisionListDialogService,
              private readonly tracerService: TracerServiceBase,
              private readonly displayErrorService: DisplayErrorsService,
              private readonly subdivisionTreelistControlControllerService: Api1SubdivisionsTreelistControlControllerService,
              private readonly dbChangedListner: DbChangedListener,
              private readonly authService: AuthService,
              private readonly chngDetector: ChangeDetectorRef,
              private readonly dialogService: DialogService,
              private readonly api1GraphControlControllerService: Api1GraphControlControllerService,
              private readonly hierarchiStringsService: HierarchiStringService,
              private readonly api1StaffUnitTypeControllerService: Api1StaffUnitTypeControllerService,
  ) {  }

  @traceFunc()
  public ngOnInit(): void {
    this.subscribeToUpdateLastActivity();
  }

  /** Обрабобтка нажатия на Отправить на согласование */
  @traceFunc()
  public onToApproving() {
    this.api1GraphControlControllerService.checkErrors$(
      {subdivisionId: this.service.data.graphTable.subdivisionOwnerId, month: this.service.data.graphTable.month})
      .pipe(
        trace(this.tracerService),
        exLoadingMessage(this.loadingIndicatorService, 'Проверка данных'),
        exErrorHandler(this.displayErrorService),
      switchMap(checkRes => {
        if (checkRes.some(x => !x.success)) {
          let component = this.hierarchiStringsService.show(
            checkRes.map(x => x.errorsDescriptor),
            'Список ошибок',
            true);
          return component.dialog.result;
        } else return of({result: true});
      }), takeUntil(this.streams$.unsubscribe)
    ).subscribe((x : {result: boolean}) => {
      if (!x.result) return;

        const dialog = this.commentDialogService.open();
        dialog.component.maxLength = 1000;

        dialog.component.ok$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(comment => {
          this.loadingIndicatorService.addToObservable(
            'Отправка на согласование',
            this.redactionBaseService.toApproving(this.service.redaction.id, comment)
          ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
            next: value => {
              if (!value.isExecuted) {
                this.kendoNotificationService.showError({ content: 'НЕ удалось отправить на согласование' });
              } else {
                this.kendoNotificationService.showSuccess({ content: 'Отправлено на согласование' })
              }
              this.service.OnInit4(value.data, null, false);
            }, error: error => {
              this.kendoNotificationService.showError({ content: 'НЕ удалось отправить на согласование' });
              if (ResponseObjError.checkTypeReturnCode(error) == '9bc4dce1-87c7-4e46-8db5-3e6dc80c0c8b') {
                const innerError = (error as ResponseObjError<string>).data
                this.alertService.defaultAlertOption.information().mod(x => {
                  x.message = innerError;
                }).showAlert();
                return;
              }
              this.alertService.defaultAlertOption.error().showAlert();
            }
          });
        });
    });
  }

  /** Обработка нажатия на Вернуть в работу */
  @traceFunc()
  public onFromApproving() {
    this.alertService.defaultAlertOption.question().mod(x => {
      x.title = 'Подтверждение действия'
      x.message = `Вернуть ${this.service.target == 'graph' ? 'график' : 'табель'} в работу?`
      x.buttons[1].text = 'Вернуть'
      x.buttons[1].callBack = () => {
        this.loadingIndicatorService.addToObservable(
          'Возврат в работу',
          this.redactionBaseService.fromApproving(this.service.redaction.id)
        ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
          next: value => {
            if (!value.isExecuted) {
              this.kendoNotificationService.showError({ content: 'НЕ удалось вернуть в работу' })
            } else {
              this.kendoNotificationService.showSuccess({ content: 'Вернут в работу' })
            }
            this.service.OnInit4(value.data, null, true);
          }, error: error => {
            this.kendoNotificationService.showError({ content: 'НЕ удалось вернуть в работу' })
            this.alertService.defaultAlertOption.error().showAlert();
          }
        });
      }
    }).showAlert();
  }

  /** Обработка события начала редактирования */
  @traceFunc()
  public onStartEdit() {
    this.service.compareRedactionId = null;
    this.loadingIndicatorService.addToObservable(
      'Режим редактирования',
      this.redactionBaseService.startEdit(this.service.redaction.id)
    ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        if (!value.isExecuted) {
          this.kendoNotificationService.showError({ content: 'НЕ удалось начать редактирование' })
        } else {
          this.service.status.status = 'edit';
          this.subscribeToUpdateLastActivity();
          this.service.isEditing$.next(true);
          this.kendoNotificationService.showSuccess({ content: 'Начато редактирование' })
        }
        this.service.OnInit4(value.data, null, false);
      }, error: error => {
        this.alertService.defaultAlertOption.error().showAlert();
      }
    })
  }

  /** Обработка события Завершения редактирования */
  @traceFunc()
  public onStopEdit() {
    const getStream = () => {
      this.loadingIndicatorService.addToObservable(
        'Завершение редактирования',
        this.redactionBaseService.stopEdit(this.service.redaction.id)
      ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
        next: value => {
          if (!value.isExecuted) {
            this.kendoNotificationService.showError({ content: 'НЕ удалось завершить редактирование' })
          } else {
            this.service.isEditing$.next(false);
            this.kendoNotificationService.showSuccess({ content: 'Редактирование завершено' })
          }
          this.service.OnInit4(value.data, null, true);
        }, error: error => {
          this.alertService.defaultAlertOption.error().showAlert();
          this.kendoNotificationService.showError({ content: 'НЕ удалось завершить редактирование' })
        }
      });
    }

    if (!this.disableSaveButton) {
      this.alertService.defaultAlertOption.information().mod(x => {
        x.titleMessage = "Есть НЕ сохраненные данные"
        x.message = 'Нажмите <strong>"Отмена"</strong>, и Вы останетесь в режиме редактирования и сможете сохранить данные<br>' +
          'Нажмите <strong>"Продолжить"</strong>, для завершения редактирования с потерей изменений';
        x.buttons = [
          AlertButton.Get('Отмена', true, false, () => { }),
          AlertButton.Get('Продолжить', false, false, getStream)
        ]
      }).showAlert();

      return;
    }

    getStream();
  }

  /** Обработка события Сохранить */
  @traceFunc()
  public onSave() {
    const dialog = this.commentDialogService.open();

    dialog.component.ok$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(comment => {
      this.service.save$.next(comment);
    })
  }

  /** Обработка события смены редакции */
  @traceFunc()
  public onRedactionChange(data: RedactionDetailGridComponent_PreviewEvent) {
    this.service.OnInit4(data.data, data.selectedRedactionId, false);
    this.showRedactionSelect = false;
  }

  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

  /** Обработка события нажатия на кнопку "Печать" */
  @traceFunc()
  public clickPrintBtn() {
    if (this.printData.length > 0){
      return;
    }

    this.printFormService.getPrintFormList$(this.service.target === 'graph' ? PrintFormType.Graph : PrintFormType.Table)
      .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe(value => {
        this.printData = value.sort((a, b) => a.order > b.order ? 1 : -1).map(x =>
          ({ text: x.name, innerKey: x.innerKey, disabled: false }));
      });
  }

  /** Обработка события выбора (нажатия) пункта меню "Печать" */
  @traceFunc()
  public onPrintMenuItemClick($event: any) {
    let response: Observable<Blob>;
    this.reportType = $event;
    switch ($event.innerKey) {
      case "graph_f1":
      case "graph_f2":
      case "graph_f3":
        response = this.api1PrintReportControllerService.printGraphNew$(
          this.service.redaction.id,
          $event.innerKey);
        break;
      case "table_f1":
      case "table_rv":
        response = this.api1PrintReportControllerService.printTable$(
          this.service.redaction.id,
          $event.innerKey,
          this.service.data.graphTable.month,
          new Date(this.service.data.graphTable.month.getFullYear(),
            this.service.data.graphTable.month.getMonth() + 1,
            0));
        break;
      case "table_halfMonth":
        response = this.api1PrintReportControllerService.printTable$(
          this.service.redaction.id,
          $event.innerKey,
          this.service.data.graphTable.month,
          moment(this.service.data.graphTable.month).add(14, 'days').toDate());
        break;
      case "table_secondHalfMonth":
        response = this.api1PrintReportControllerService.printTable$(
          this.service.redaction.id,
          $event.innerKey,
          moment(this.service.data.graphTable.month).add(15, 'days').toDate(),
          moment(this.service.data.graphTable.month).add(1, 'months').add(-1, 'days').toDate());
        break;
      case "operating_plan":
        response = this.api1PrintReportControllerService.printOperatingPlan$(this.service.data.graphTable.month);
        break;
      case "memo_proxy":
        this.showReportPeriodSettingsDialog();
        this.chngDetector.detectChanges();
        return;
      case "memo_overtime":
        response = this.api1PrintReportControllerService.printOverTimeMemo$(
          this.service.redaction.id,
          $event.innerKey,
          this.service.data.graphTable.month);
        break;
      case "memo_nightTime":
        response = this.api1PrintReportControllerService.printCodeMemo$(
          this.service.redaction.id,
          $event.innerKey,
          this.service.data.graphTable.month,
          null);
        break;
      case "memo_rv":
        response = this.api1PrintReportControllerService.printMemoRv$(
          this.service.redaction.id,
          this.service.data.graphTable.month,
          $event.innerKey);
        break;
      case "FL_Report":
        let flReportrqstObsFunc = (settings: ReportSettingsWithSubdivisionList) => this.api1PrintReportControllerService.printFLReportNew$(settings);
        setTimeout(() => {
          this.reportSettingsWithTimeintervalsAndSubdivisionListDialogService.showDialog(
            $event.innerKey,
            $event.text,
            flReportrqstObsFunc,
            new SubdivisionsTreelistComponentDataSourceService2(this.subdivisionTreelistControlControllerService,
              this.loadingIndicatorService, this.dbChangedListner, this.authService, this.alertService)
          );
        });
        return;
      case "time_interval_comparison_report":
        let rqstObsFnc = (settings: PrintFormForDateSettingsWithSubdivisions) => this.api1PrintReportControllerService.printTimeIntervalComporisonReport$(settings);
        setTimeout(() => {
          this.forDateWithSubdivisionsPrintSettingsDialogService.showDialog(
            $event.innerKey,
            $event.text,
            rqstObsFnc,
            new SubdivisionsTreelistComponentDataSourceService2(this.subdivisionTreelistControlControllerService,
              this.loadingIndicatorService, this.dbChangedListner, this.authService, this.alertService)
          );
        });
        return;
      case "table_correction":
        setTimeout(() => this.correctionListDialogService.showDialog(
          this.service.data.graphTable.subdivisionOwnerId,
          this.service.data.graphTable.month,
          $event.innerKey,
          $event.text));
        return;
      case "graph_correction":
        setTimeout(() => this.correctionListDialogService.showDialog(
          this.service.data.graphTable.subdivisionOwnerId,
          this.service.data.graphTable.month,
          $event.innerKey,
          $event.text));
        return;
      case "table_dismissed":
        setTimeout(() => this.tableDismissedDialogService.showDialog(
          this.service.data.graphTable.subdivisionOwnerId,
          this.service.data.graphTable.month,
          this.service.redaction.id));
        return;
      case "summary_report":
        const currentQuarter = Math.ceil((this.service.data.graphTable.month.getMonth() + 1) / 3);
        const currentYear = this.service.data.graphTable.month.getFullYear();

        response = this.api1PrintReportControllerService.summaryReportReport$(
          new PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes(
            $event.innerKey,
            `${currentQuarter} квартал`,
            new Date(currentYear, currentQuarter * 3 - 3, 1),
            new Date(currentYear, currentQuarter * 3, 0, 23, 59, 59),
            false,
            [this.service.data.graphTable.subdivisionOwnerId],
            [StaffUnitTypeEnum.Basic]));
        break;
      case "table_milk":
        this.showReportPeriodSettingsDialog();
        this.chngDetector.detectChanges();
        return;
      case "table_common":
        this.showDateWithSubdivisionsAndStaffUnitTypesSettingsDialog(
          'year', 'year', 'LLLL yyyy');
        this.chngDetector.detectChanges();
        return;
      case "position_hours_count":
        response = this.api1PrintReportControllerService.printPositionHoursCountReport$(
          this.service.data.actualRedactionId)
        break;
      case "memo_stimul_new_staffunits":
      case "memo_stimul_inner_moonlighters":
      case "memo_stimul_inner_moonlighters_long":
        response = this.api1PrintReportControllerService.printMemoStimul$(
          $event.innerKey,
          this.service.data.actualRedactionId)
        break;
      case "combination_free_raport":
      case "combination_busy_raport":
        response = this.api1PrintReportControllerService.printCombinationRaport$(
          $event.innerKey,
          this.service.data.actualRedactionId)
        break;
      case "uvor_free_raport":
      case "uvor_busy_raport":
        response = this.api1PrintReportControllerService.printUvorRaport$(
          $event.innerKey,
          this.service.data.actualRedactionId)
        break;
      case "memo_stimul_duty":
        response = this.api1PrintReportControllerService.printMemoStimulDuty$(
          this.service.data.actualRedactionId, $event.innerKey);
        break;
      case "worked_time_report":
        let requestObsFunc = (settings: PrintFormFromtillDatesSettings) => this.api1PrintReportControllerService.workedTimeReport$(settings);
        setTimeout(() => this.fromTillSettingsDialogService.showDialog(
          $event.innerKey,
          $event.text,
          requestObsFunc,
          new SubdivisionsTreelistComponentDataSourceService2(this.subdivisionTreelistControlControllerService,
            this.loadingIndicatorService, this.dbChangedListner, this.authService, this.alertService)
          ));
        return;
      case "daily_free_rates":
        let rqstObsFunc = (settings: PrintFormForDateSettingsWithSubdivisions) => this.api1PrintReportControllerService.dailyFreeRatesReport$(settings);
        setTimeout(() => {
          this.forDateWithSubdivisionsPrintSettingsDialogService.showDialog(
              $event.innerKey,
              $event.text,
              rqstObsFunc,
              new SubdivisionsTreelistComponentDataSourceService2(this.subdivisionTreelistControlControllerService,
                this.loadingIndicatorService, this.dbChangedListner, this.authService, this.alertService)
            );
          });
        return;
      case "subdivision_staffunits_for_date":
        this.showReportPeriodWithStaffUnitTypesDialog();
        this.chngDetector.detectChanges();
        return;
      case "total_covid_report":
        this.showReportPeriodSettingsDialog();//используется для табеля на молоко
        this.chngDetector.detectChanges();
        return;
      case "table_covid_19":
      case "table_compensation_payment_covid":
      case "table_codiv_19_new":
      case "table_covid_19_FSS":
        this.showReportPeriodWithStaffUnitTypesDialog();
        this.chngDetector.detectChanges();
        return;
      case "covid_register_report":
      case "covid_register_report_2":
        this.showRegisterSettingsDialog(true);
          this.chngDetector.detectChanges();
        return;
      case "table_VichTub":
      case "table_vich":
      case "table_tub":
        this.showStaffUnitTypeChoiceDialog(x => this.createTuberVichTableBlob(x));
        return;
      case "memo_vich":
      case "memo_tuberculosis":
      case "memo_covid_19":
        this.showStaffUnitTypeChoiceDialog(x => this.createCodeMemoBlob(x));
        return;
      case "register_vich_hospital":
      case "register_vich_paraclinic":
      case "register_vich_disinfection":
      case "register_vich_disinfection_OOMO":
      case "register_vich_cleaners":
      case "register_vich_PAO":
      case "register_vich_laboratory":
      case "register_tub_hospital":
      case "register_tub_paraclinic":
      case "register_tub_laboratory":
      case "register_tub_PAO":
        this.showRegisterSettingsDialog(false);
        this.chngDetector.detectChanges();
        return;

      default: throw new Error('out of range')
    }

    this.downloadFile(response, $event.text);
  }

  /** Сформировать бинарный файл vich или tuber табеля */
  private createTuberVichTableBlob(staffUnitTypeIds: number[]){
    return  this.api1PrintReportControllerService.printTable$(
      this.service.redaction.id,
      this.reportType.innerKey,
      this.service.data.graphTable.month,
      new Date(this.service.data.graphTable.month.getFullYear(),
        this.service.data.graphTable.month.getMonth() + 1, 0),
      null, staffUnitTypeIds);
  }

  /** Сформировать бинарный файл vich, tuber или covid служебной записки */
  private createCodeMemoBlob(staffUnitTypeIds: number[]){
    return  this.api1PrintReportControllerService.printCodeMemo$(
      this.service.redaction.id,
      this.reportType.innerKey,
      this.service.data.graphTable.month,
      staffUnitTypeIds);
  }

  /** Сформировать отчет "Журнал учета рабочего времени по коду CV" */
  @traceFunc()
  public printCovidRegisterReport(reportSettings: RegisterReportSettings) {
    let response = this.api1PrintReportControllerService
      .printCovidRegisterReportNew$(reportSettings, this.service.redaction.id, this.reportType.innerKey);

    this.downloadFile(response, this.reportType.text)
  }

  /** Сформировать отчет "Журнал учета рабочего времени по кодам vich\tub" */
  @traceFunc()
  public printTableCodeRegisterReport(reportSettings: RegisterReportSettings) {
    let response = this.api1PrintReportControllerService
      .printTableCodeRegister$(reportSettings, this.service.redaction.id, this.reportType.innerKey);

    this.downloadFile(response, this.reportType.text)
  }

  /** Открыть диалог запроса настроек отчета и подписаться на события нажатия кнопок и по результату сформировать файл или выйти */
  private showForDateSettingsDialogAndDownloadFile<T>(requestFunc: (y: T) => Observable<Blob>, convertFunc: (x: PrintFormForDateSettings) => T): void{
    this.dialogRef = this.dialogService.open({
      title: "Настройки отчета",
      content: PrintFormForDateSettingsComponent,
      width: 400,
      height: 170,
    });

    const component = this.dialogRef.content.instance as PrintFormForDateSettingsComponent;

    const unsubscribeRice = race(this.streams$.unsubscribe, component.cancel$, component.print$)
      .pipe(delay(1));

    component.cancel$.pipe(
      trace(this.tracerService),
      take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: () => this.dialogRef.close()
      });

    component.print$
      .pipe(trace(this.tracerService),
        exErrorHandler(this.displayErrorService),
        take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: date => {
          this.dialogRef.close()
          let response = requestFunc(convertFunc(date));
          this.downloadFile(response, this.reportType.text);
        }
      });
  }

  /** Отобразить диалоговое окно выбора типов исполнения должностей (staffUnitType), используемых для формирования отчета */
  private showStaffUnitTypeChoiceDialog(responseObsFunc: (staffUnitTypeIds: number[]) => Observable<Blob>){
    this.dialogRef = this.dialogService.open({
      title: "Настройки отчета",
      content: StaffUnitTypeListSettingsComponent,
      width: 400,
      minHeight: 250
    });

    const component = this.dialogRef.content.instance as StaffUnitTypeListSettingsComponent;
    this.api1StaffUnitTypeControllerService.getByIds$(
      [StaffUnitTypeEnum.Basic,StaffUnitTypeEnum.MoonlighterInner,StaffUnitTypeEnum.MoonlighterExternal])
      .pipe(take(1))
      .subscribe(x => {
        component.dataSource = x;
      });

    const unsubscribeRice = race(this.streams$.unsubscribe, component.cancel$, component.select$)
      .pipe(delay(1));

    component.select$
      .pipe(trace(this.tracerService),
        exErrorHandler(this.displayErrorService),
        take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: staffUnitTypeIds => {
          this.dialogRef.close();

          let response = responseObsFunc(staffUnitTypeIds);

          this.downloadFile(response, this.reportType.text);
        }
      });

    component.cancel$.pipe(
      trace(this.tracerService),
      take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: () => this.dialogRef.close()
      });
  }

  private showReportPeriodSettingsDialog(){
    this.dialogRef = this.dialogService.open({
      title: "Настройки отчета",
      content: ReportPeriodSettingsComponent,
      width: 410,
      height: 190,
    });

    const component = this.dialogRef.content.instance as ReportPeriodSettingsComponent;
    component.minDate = this.service.data.graphTable.month;
    component.maxDate = moment(this.service.data.graphTable.month).endOf('month').toDate();
    component.reportDate = this.service.data.graphTable.month;

    const unsubscribeRice = race(this.streams$.unsubscribe, component.cancel$, component.select$)
      .pipe(delay(1));

    component.select$
      .pipe(trace(this.tracerService),
        exErrorHandler(this.displayErrorService),
        take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: dates => {
          this.dialogRef.close();
          let response: Observable<Blob>;
          switch (this.reportType.innerKey) {
            case "total_covid_report":
              response = this.api1PrintReportControllerService.printTotalCovidReport$(dates);
              break;
            case "table_milk":
              response = this.api1PrintReportControllerService.printTableMilk$(
                this.service.redaction.id,
                this.reportType.innerKey,
                dates.startDate,
                dates.endDate
              );
              break;
            case "memo_proxy":
                response = this.api1PrintReportControllerService.printProxyMemoNew$(
                  this.service.redaction.id,
                  this.reportType.innerKey,
                  dates.startDate,
                  dates.endDate
                )
              break;
            default: throw new Error('out of range')
          }

          this.downloadFile(response, this.reportType.text);
        }
      });

    component.cancel$.pipe(
      trace(this.tracerService),
      take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: () => this.dialogRef.close()
      });
  }

  /** Показать диалоговое окно выбора диапазона дат и типов исполнения должностей (StaffUnitType) */
  public showReportPeriodWithStaffUnitTypesDialog(){
    this.dialogRef = this.dialogService.open({
      title: "Настройки отчета",
      content: PrintFormFromTillDatesWithStaffunitTypesSettingsComponent,
      width: 400,
      minHeight: 270
    });

    const component = this.dialogRef.content.instance as PrintFormFromTillDatesWithStaffunitTypesSettingsComponent;
    component.initialDate = this.service.data.graphTable.month;

    switch (this.reportType.innerKey) {
      case "subdivision_staffunits_for_date":
        break;
      default:
        component.maxDate = moment(this.service.data.graphTable.month).endOf('month').endOf('day').toDate();
        component.minDate = this.service.data.graphTable.month;
    }

    const unsubscribeRice = race(this.streams$.unsubscribe, component.cancel$, component.select$)
      .pipe(delay(1));

    component.select$
      .pipe(trace(this.tracerService),
        exErrorHandler(this.displayErrorService),
        take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: data => {
          this.dialogRef.close();

          let response: Observable<Blob>;

          switch (this.reportType.innerKey) {
            case "table_covid_19":
            case "table_compensation_payment_covid":
            case "table_covid_19_FSS":
              response = this.api1PrintReportControllerService.printTable$(
                this.service.redaction.id,
                this.reportType.innerKey,
                data.fromDate,
                data.tillDate,
                null,
                data.staffUnitTypeIds);
              break;
            case "subdivision_staffunits_for_date":
              response = this.api1PrintReportControllerService.subdivisionStaffUnitsReport$(new PrintFormFromTillDatesWithStaffUnitTypesSettings(
                this.reportType.innerKey, data.fromDate, data.tillDate, data.staffUnitTypeIds), this.service.data.graphTable.subdivisionOwnerId);
              break;

            default: throw new Error('out of range')
          }

          this.downloadFile(response, this.reportType.text);
        }
      });

    component.cancel$.pipe(
      trace(this.tracerService),
      take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: () => this.dialogRef.close()
      });
  }

  /** Отобразить диалоговое окно настроек, содержащее дату, список подразделений и типов исполнения должностей */
  public showDateWithSubdivisionsAndStaffUnitTypesSettingsDialog(deepBottomView: CalendarView, activeView: CalendarView,
                                                                 displayedDateFormat: string){
    this.dialogRef = this.dialogService.open({
      title: "Настройки отчета",
      content: ForDateWithSubdivisionsAndStaffunitTypesSettingsComponent,
      maxWidth: '90%',
      width: 700,
      maxHeight: '90%'
    });

    const component = this.dialogRef.content.instance as ForDateWithSubdivisionsAndStaffunitTypesSettingsComponent;
    const dataSourceService = new SubdivisionsTreelistComponentDataSourceService2(this.subdivisionTreelistControlControllerService,
      this.loadingIndicatorService, this.dbChangedListner, this.authService, this.alertService);
    const selection = new ArrayDataSourceSelection<ISubdivision, number>(dataSourceService.dataSource);

    component.subdivisionDataSourceService = dataSourceService;
    component.selection = selection;
    component.initialDate = this.service.data.graphTable.month;
    component.subdivisionDataSourceService = dataSourceService;
    component.selection = selection;
    component.datePickerViewParams.deepBottomView = deepBottomView;
    component.datePickerViewParams.format = displayedDateFormat;
    component.datePickerViewParams.activeView = activeView;

    const unsubscribeRice = race(this.streams$.unsubscribe, component.cancel$, component.select$)
      .pipe(delay(1));

    component.select$
      .pipe(trace(this.tracerService),
        exErrorHandler(this.displayErrorService),
        take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: data => {
          this.dialogRef.close();

          let response: Observable<Blob>;

          switch (this.reportType.innerKey) {
            case "table_common":
              response = this.api1PrintReportControllerService.printTable$(
                this.service.redaction.id,
                this.reportType.innerKey,
                data.date,
                moment(data.date).endOf('month').toDate(),
                null,
                data.staffUnitTypeIds);
              break;

            default: throw new Error('out of range')
          }

          this.downloadFile(response, this.reportType.text);
        }
      });

    component.cancel$.pipe(
      trace(this.tracerService),
      take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: () => this.dialogRef.close()
      });

    component.dateChange$.pipe(takeUntil(component.unsubscribe$))
      .subscribe({
        next: value => {
          component.subdivisionDataSourceService
            .reloadData$(value)
            .pipe(exErrorHandler(this.displayErrorService), take(1))
            .subscribe();
        }
      });
  }

  private showRegisterSettingsDialog(isCovidRegister: boolean){
    this.dialogRef = this.dialogService.open({
      title: "Настройки отчета",
      content: CovidRegisterSettingsComponent,
      width: 500,
      minHeight: 150,
    });

    const component = this.dialogRef.content.instance as CovidRegisterSettingsComponent;
    component.firstReportSectionDate = this.service.data.graphTable.month;

    const unsubscribeRice = race(this.streams$.unsubscribe, component.cancel$, component.select$)
      .pipe(delay(1));

    component.select$
      .pipe(trace(this.tracerService),
        exErrorHandler(this.displayErrorService),
        take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: registerSettings => {
          this.dialogRef.close();
          isCovidRegister
            ? this.printCovidRegisterReport(registerSettings)
            : this.printTableCodeRegisterReport(registerSettings);
        }
      });

    component.cancel$.pipe(
      trace(this.tracerService),
      take(1), takeUntil(unsubscribeRice))
      .subscribe({
        next: () => this.dialogRef.close()
      });
  }


  /** Обработка события выбора редакции для сравнения */
  public onRedactionCompare(redactionId: number) {
    this.service.compareRedactionId = redactionId;
    this.showRedactionSelect = false;
  }

  /** Обработка события нажания на кнопку "Проверить" */
  @traceFunc()
  public onCheckBtnClick() {
     this.service.onCheckErrors();
  }

  /** Загрузить файл с сервера */
  private downloadFile(request: Observable<Blob>, reportName: string) {
    if (!request) {
      return;
    }

    request.pipe(take(1)).subscribe({
      next: value => {
        FileSaver.saveAs(value, `${reportName}_${moment().format('DD_MM_yyyy')}.xlsx`);
      },
      error: err => {
        this.displayErrorService.handleError(err);
      }
    })

  }

  /** Подпись на обновление последней активности */
  private subscribeToUpdateLastActivity(period: number = 60000) {
    if (this.service.status.status != 'edit' || (this.service.status.editUser && this.service.status.editUser?.id != this.service.currentUserId)) {
      return;
    }

    interval(period).pipe(takeWhile(x => this.service.status.status == 'edit'), takeUntil(this.streams$.unsubscribe)).subscribe(count => {
      this.redactionBaseService.updateLastActivity(this.service.redaction.id)
        .pipe(takeWhile(x => this.service.status.status == 'edit'), takeUntil(this.streams$.unsubscribe))
        .subscribe(response => {
          if (response.isExecuted) {
            return;
          }

          this.alertService.defaultAlertOption.error().mod(x => {
            x.title = 'Конфликт'
            x.titleMessage = 'К сожалению, Вы не можете продолжать редактирование'
            x.message = `Причина: ${response.message}`
            x.buttons[0].callBack = () => {
              this.service.OnInit4(response.data, null, true);
            }
          }).showAlert();
        })
    })
  }
}

/** Интерфейс данных кнопки печать */
interface IPrintItem {
  text: string,
  innerKey: string,
  disabled: boolean
}
