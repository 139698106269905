<app-cell-color [color]="graphDayColor"
                class="cell cell-contains-graph-day"
>
  <ng-container *ngIf="!isEmpty">
    <div *ngIf="milk"
         class="milk-label"
         title="Компенсация за молоко"
    >
      мол
    </div>
    <div *ngIf="covidHours"
         class="covid-label"
         title="COVID-19"
    >
      c:{{covidHours}}
    </div>
    <div *ngIf="hasCovid2"
         class="covid-label"
    >
      covid
    </div>
    <div *ngIf="vichHours"
         class="vich-label"
         title="ВИЧ"
    >
      v:{{vichHours}}
    </div>
    <div *ngIf="tuberHours"
        class="tuber-label"
        title="Туберкулез"
    >
      t:{{tuberHours}}
    </div>
    <div *ngIf="dayDeviationKey"
         class="day-deviation"
    >
      {{dayDeviationKey}}
      <div *ngIf="dayDeviationCustomValue"
           class="day-deviation-value">
        {{dayDeviationCustomValue}}
      </div>
    </div>
    <span *ngIf="dayDeviationKey && !!timeInterval"
         class="space"
    >
    </span>
    <span *ngIf="!!timeInterval" class="time-interval">
      {{timeInterval.start}}<br>{{timeInterval.end}}
    </span>
    <div *ngIf="substractLunch" class="substract-lunch-cell"></div>
  </ng-container>
</app-cell-color>

<div *ngIf="isChanged"
     class="div-has-change"
     title="ячейка изменена"
>
</div>
