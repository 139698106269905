<kendo-grid [data]="gridData$ | async"
            [selectable]="{enabled: true, mode: 'single'}"

            [kendoGridSelectBy]="selectionService.tempSelection?.data?.forDirective?.selectionKey"
            [selectedKeys]="selectionService.tempSelection?.data?.forDirective?.selectedKeys"
            (selectedKeysChange)="selectionService.tempSelection?.data?.forDirective?.onSelectedKeysChange($event)"

            KendoGridExpanded
            (dbCellClick)="onDbCellClick($event)"
            style="max-height: 100%;"
>
  <kendo-grid-column title="Редакция"
                     field="redaction.modifiedDate"
                     format="HH:mm dd.MM.yyyy"
                     [width]="150"
  >

  </kendo-grid-column>
  <kendo-grid-column title="Исполнитель"
                     field="modifiedUser.fio"
                     [width]="300"
  >

  </kendo-grid-column>
  <kendo-grid-column title="Комментарий"
                     field="redaction.comment"
  >

  </kendo-grid-column>

  <kendo-grid-column title="Статус"
                     [width]="200"
                     field="displayStatus.text"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Версия"
                     hidden="hidden"
                     field="versionId"
  >
    <ng-template kendoGridGroupHeaderTemplate let-value="value" let-index="index">
      <ng-container *ngIf="(dataSourceService?.dataSource?.data$ | async) as data">
        <span *ngIf="dataSourceService.type === 'table'">{{(value > 0 ? 'Корректирующий' : 'Первичный')}} № {{value}}</span>
        <span *ngIf="dataSourceService.type === 'graph'">Версия {{value}}</span>
        <span *ngIf="dataSourceService.versions[index].routeStatus"
              style="margin-left: 50px"
        >
          {{dataSourceService.versions[index].routeStatus.name}}
        </span>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column *ngIf="actionColumnState" [width]="actionColumnState.columnWidth" title="Действие">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button kendoButton primary="true"
              *ngIf="actionColumnState.settings.preview"
              [disabled]="((currentRedactionId$ | async) === dataItem.redaction.id)"
              [icon]="'preview'"
              title="Просмотр"
              (click)="onPreview(dataItem)"
      >
      </button>
      <button kendoButton
              *ngIf="actionColumnState.settings.copy && dataItem.canCopy"
              primary="true"
              [icon]="'copy'"
              title="Использовать для работы"
              (click)="onCopy(dataItem)"
      >
      </button>
      <button kendoButton
              *ngIf="actionColumnState.settings.compare"
              [hidden]="((currentRedactionId$ | async) - 1 < dataItem.redaction.id)"
              primary="false"
              [icon]="'equal'"
              title="Сравнить"
              (click)="onCompare(dataItem)"
      >
      </button>
    </ng-template>
  </kendo-grid-command-column>

  <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="detailTemplateShowIf"
  >
    <div style="display: flex; width: 100%; justify-content: center">
      <app-redaction-detail-grid2 *ngIf="dataItem.status"
                                 [data]="dataItem.routes"
                                 style="width: 90%"
      >
      </app-redaction-detail-grid2>
    </div>
  </ng-template>
</kendo-grid>
