import {Injectable} from "@angular/core";
import {ResponseObjError} from "src/app/classes/requestResults/responseObjError";
import {IBusinessValidationResult} from "src/app/classes/requestResults/busines-validation-result";
import {AlertService} from "src/app/services/alert.service";
import {DefaultEntityName, ErrorsConvertationService} from "./errors-convertation.service";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {TraceParamEnum} from "../../../modules/trace/decorators/classes/traceSetting.interface";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {traceParam} from "../../../modules/trace/decorators/param.decorator";

@Injectable({providedIn: 'root'})
@traceClass('DisplayErrorsService')
export class DisplayErrorsService {

  constructor(private alertService: AlertService,
              private errorsConvertationService: ErrorsConvertationService,
              private readonly tracerService: TracerServiceBase) {  }

  /** Отображение простой ошибки.
   * Будет отображено всплывающее окно с простой строкой ошибки.
   * @param errorString Текст строки ошибки
   * @param titleMessage Текст заголовка
  */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public showSimpleError(@traceParam({maxLength: 1000}) errorString: string, titleMessage?: string) {
    this.alertService.defaultAlertOption.warning().mod(x => {
      x.message = errorString,
      x.titleMessage = titleMessage ? titleMessage : x.titleMessage
    }).showAlert();
  }

  /** Отображение бизнес ошибки присланной с сервера
   * @param businessValidationErrors Объект результата валидации объекта на стороне сервера.
   * @param settings Опциональные настройки для отображения ошибок
   */
  @traceFunc({traceParamType: TraceParamEnum.traceAll})
  public showBusinessValidationError(businessValidationErrors: IBusinessValidationResult[], settings: IShowErrorsSettings = null) {
    const message = this.errorsConvertationService.convertBusinessErrorsToString(businessValidationErrors, settings?.customEntityNames);
    this.showSimpleError(message, "Ошибка валидации данных!");
  }


  /** Обработка ошибок
   * Определяет, что за ошибка произошла. Если это валидационная ошибка то отображает окно информации для валидационных ошибок,
   * в противном случае получает текст ошибки из самой ошибки и отображает её, в противном случае просто отображает всплывающее окно,
   * что произошла ошибка и бросает экспешен данной ошибки
   * @param errorObj Объект ошибки
   * @param settings Опциональные настройки для отображения ошибок
   */
  public handleError(errorObj: any, settings: IShowErrorsSettings = null) {
    if(ResponseObjError.checkType(errorObj)){
      if (ResponseObjError.isBusinessValidationError(errorObj)) {
        const businessValidationError = ResponseObjError.getAsBusinessValidationError(errorObj);
        this.showBusinessValidationError(businessValidationError.data, settings);
      }
      else if (ResponseObjError.isExcelParsingException(errorObj)) {
        const simpleError = errorObj as ResponseObjError<string[]>;
        this.showSimpleError(simpleError.data.join("\n"), "Ошибка парсинга данных в Excel документе!");
      }
      else if( ResponseObjError.isDbUpdateConcurrencyExceptionError(errorObj)) {
        this.showSimpleError("Данные были изменены другим пользователем или в другом окне, рекомендуем перезагрузить страницу и повторить попытку.", "Ошибка сохранения данных!");
      }
      else {
        const simpleError = errorObj as ResponseObjError<string>;
        this.showSimpleError(simpleError.data);
      }
    } else {
      if(errorObj.status === 400){
        this.showSimpleError('Данные не прошли проверку!');
        this.tracerService.add2("Ошибка валидации данных", {obj: errorObj.error?.errors});
        throw new Error(errorObj);
      }
      else {
        this.showSimpleError('Произошла непредвиденная ошибка!<br><br>Рекомендуем перезагрузить страницу.');
        throw new Error(errorObj);
      }
    }
  }

}

/** Опциональные настройки для отображения ошибок */
export interface IShowErrorsSettings {

  /** Кастомный массив соответствий названий энтити с бекенда и названий энтити с UI.
   * При формировании строки ошибки, сначала идет поиск соответствий названий энтити с бекенда с отображаемыми в UI названиями, в первую очередь,
   *  по переданному кастомному массиву соответствий, а если он не задан или там нет текого соответствия то идет поиск по массиву сопоставлений по умолчанию,
   * а если и там не найдено сопоставление то возвращается в исходном виде (как как пришло от сервера)
   */
  customEntityNames?: DefaultEntityName[]
}
