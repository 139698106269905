/**
 * Объект, возвращаемый сервером и содержащий информацию
 * о норме и факте часов для конкретной щтатной единицы
 */
export interface ICalculateNormaFactResponse {

  /** Идентификатор штатной единицы */
  staffUnitOwnerId: number;
  /** Результат */
  result: ICalculateNormaFactResult;
}

export interface ICalculateNormaFactResult {
  /** Норма времени для данной штатной единицы */
  norma: number;
  /** Фактическое значение времени для данной штатной единицы */
  fact: number;
  /** Количество ночных часов в факте отработанного времени за рассчитываемый период */
  nightTimeHours: number;
  /** Количество праздничных часов в факте отработанного времени за рассчитываемый период */
  holidayHours: number;
  /** Подневная информация о факте отработанного времени в рамках рассчитываемого периода */
  dayFactInfos: IDayFactInfo[];
}

export interface IDayFactInfo{
  /** Дата */
  date: Date,
  /** Факт отработанного времени за текущий день */
  dayFact: number,
  /** Факт отработанного времени в ночное время за текущий день */
  nightDuration: number,
  /** Продолжительность обеда, учитываемая в данном дне */
  dinnerDuration: number,
  /** Тип дня недели */
  dayTypeId: number,
}
