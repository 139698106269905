import {GraphTableBase, IGraphTableBase} from "../timesheet/GraphTableBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';


export interface ITable extends IGraphTableBase{

}

@classBackend('Table', 'timesheet_table')
@className('Table')
/** Табель */
export class Table extends GraphTableBase implements ITable{

}
