<kendo-grid [data]="data">
  <kendo-grid-column title="Дата"
                     field="route.modifiedDate"
                     format="HH:mm dd.MM.yyyy"
                     [width]="150"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Исполнитель"
                     field="modifiedUser.fio"
                     [width]="280"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Статус"
                     [width]="200"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.routeStatus?.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Комментарий"
                     field="route.comment"
                     [style]="{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}"
  >
  </kendo-grid-column>
</kendo-grid>
