import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable, ReplaySubject} from "rxjs";
import {debounceTime, distinctUntilChanged, map, take, takeUntil} from "rxjs/operators";
import {
  Api1StaffListSettingsControllerService
} from "../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-list-settings-controller.service";
import {GraphCellEditService,} from "../graph-table-workspace/graph-grid/secvices/graph-cell-edit.service";
import {StaffUnit} from "../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {
  StaffUnitForProxyList
} from "../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service";
import {DateHelper} from "../../../../../../src/app/helpers/dateHelper";
import {
  Api1GraphControlControllerService
} from "../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {LoadingIndicatorService} from "../../../../../../src/app/services/loading-indicator.service";
import {KendoNotificationService} from "../../../../../../src/app/services/kendo-notification.service";
import {AlertService} from "../../../../../../src/app/services/alert.service";
import {DropDownItem} from "../../../../../../src/app/classes/requestResults/iDropDownItem";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {
  Api1StaffPositionsControlControllerService
} from "../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-positions-control.service";
import {traceFunc} from "../../../../../../src/app/modules/trace/decorators/func.decorator";
import * as moment from "moment";
import {traceClass} from "../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {trace} from "../../../../../../src/app/modules/trace/operators/trace";
import {exErrorHandler} from "../../../../../../src/app/operators/ex-error-handler";
import {
  DisplayErrorsService
} from "../../../../../../src/app/components/display-errors/services/display-errors.service";

@Component({
  selector: 'app-edit-duty',
  templateUrl: './edit-duty.component.html',
  styleUrls: ['./edit-duty.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('EditDutyComponent')
export class EditDutyComponent implements OnInit {

  /** Минимальная дата, отображаемая в DatePicker-е выбора периода замещения/совмещения */
  public minShownDate: Date;
  /** Максимальная дата, отображаемая в DatePicker-е выбора периода замещения/совмещения */
  public maxShownDate: Date;
  /**Первый день месяца */
  public firstDayOfCurrentMonth: Date;
  /** Последний день месяца */
  public lastDayOfCurrentMonth: Date;

  /** Настройки компонента при инициализации */
  @Input() settings: EditDutyComponent_Settings = null;
  /** Событие добавления Proxy */
  @Output() save$: EventEmitter<EditDutyComponent_SaveEvent> = new EventEmitter<EditDutyComponent_SaveEvent>();
  /** Событие отмены добавления Proxy */
  @Output() cancel$: EventEmitter<void> = new EventEmitter<void>();

  public form: UntypedFormGroup;
  public openStaffUnitDialog: boolean;
  public dutyStaffUnitOwnerId: number;
  public positionList: DropDownItem[];
  public enabledDutyPositionButton: boolean;
  public disabledDutyEmployeeFild: boolean;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  private unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private readonly settingsService: Api1StaffListSettingsControllerService,
              private readonly graphEditService: GraphCellEditService,
              private readonly graphControlService: Api1GraphControlControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly alertService: AlertService,
              private readonly api1StaffPositionsControlControllerService: Api1StaffPositionsControlControllerService,
              private readonly traceService: TracerServiceBase,
              private readonly displayErrorsService: DisplayErrorsService,
  ) { }

  @traceFunc()
  public ngOnInit(): void {
    if (!this.settings) {
      throw new Error('@Input settings НЕ передан')
    }
    this.settings.validation();

    this.dutyStaffUnitOwnerId = this.settings.dutyStaffUnit?.ownerId;
    this.enabledDutyPositionButton = this.settings.type != "add";
    this.disabledDutyEmployeeFild = this.settings.type == "add";

    this.firstDayOfCurrentMonth = this.settings.month;
    this.lastDayOfCurrentMonth = DateHelper.getEndOfMounth(this.settings.month, false);

    this.minShownDate = this.firstDayOfCurrentMonth;
    this.maxShownDate = this.lastDayOfCurrentMonth;

    const employeeFullNameControl = new UntypedFormControl(this.settings.dutyStaffUnit?.employeeFullName, [Validators.required]);
    const startDateControl = new UntypedFormControl((this.settings.dutyStaffUnit?.start ?? this.firstDayOfCurrentMonth) || this.firstDayOfCurrentMonth, [Validators.required]);
    const endDateControl = new UntypedFormControl((this.settings.dutyStaffUnit?.end ?? this.lastDayOfCurrentMonth) || this.lastDayOfCurrentMonth, [Validators.required]);
    const commentControl = new UntypedFormControl(this.settings.dutyStaffUnit?.comment ?? "");
    const positionControl = new UntypedFormControl(null, [Validators.required]);
    const proxyHoursControl = new UntypedFormControl();
    const proxyDaysControl = new UntypedFormControl();
    const milkControl = new UntypedFormControl(!!this.settings.dutyStaffUnit?.milk, [Validators.required]);

    this.form = new UntypedFormGroup({
      employeeFullName: employeeFullNameControl,
      positionId: positionControl,
      dutyStartDate: startDateControl,
      dutyEndDate: endDateControl,
      comment: commentControl,
      proxyHours: proxyHoursControl,
      proxyDays: proxyDaysControl,
      milk: milkControl,
    });

    this.form.valueChanges.pipe(trace(this.traceService), map(x => {
      return { positionId: x.positionId, start: x.dutyStartDate, end: x.dutyEndDate }
    }),
      distinctUntilChanged((prev, curr) =>
        prev.positionId == curr.positionId && prev.start == curr.start && prev.end == curr.end),
      debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.recalculatePositionNorma();
        this.setProxyDaysFormValue(value);
      });

    this.api1StaffPositionsControlControllerService
      .getPositionDropDownList$(this.settings.subdivisionId,
        this.firstDayOfCurrentMonth,
        this.firstDayOfCurrentMonth,
        this.lastDayOfCurrentMonth)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.positionList = value;
        if (this.settings.dutyStaffUnit) {
          this.form.controls.positionId.setValue(this.positionList.find(p => p.id == this.settings.dutyStaffUnit.positionId));
        }
      });
  }

  @traceFunc()
  private recalculatePositionNorma() {
    if (!this.form.controls.positionId.value) {
      return;
    }
    this.graphControlService.calculateNormaForPosition$(this.settings.redactionId, this.form.controls.positionId.value.id,
      this.settings.dutyStaffUnit?.rate ?? 1, this.form.controls.dutyStartDate.value, this.form.controls.dutyEndDate.value)
      .pipe(trace(this.traceService), take(1), takeUntil(this.unsubscribe$))
      .subscribe(value => this.form.controls.proxyHours.setValue(value));
  }

  /** Установить значение поля формы "Количесво календарных дней" */
  @traceFunc()
  private setProxyDaysFormValue(datesParams){
    if(datesParams.start && datesParams.end){
      let days = moment(datesParams.end).diff(datesParams.start, 'days') + 1;
      this.form.controls.proxyDays.setValue(days);
    }
  }

  /** методы валидации вводимых дат (начало периода не должно быть больше окончания и наоборот) */
  public startDateValidator = (date: Date) => !(date <= this.form.controls.dutyEndDate.value);
  public endDateValidator = (date: Date) => !(date >= this.form.controls.dutyStartDate.value);

  /** Открыть окно "Выбор штатной единицы" на форме добавления/редактирования дежурства */
  public openDutyStaffUnitChoiceWindow() {
    this.openStaffUnitDialog = true;
  }

  /** Событие нажатия на кнопку Отмена при выборе StaffUnit-а диалогового окна редактирования дежурства/TreeList "Выбор штатной единицы" */
  public onDutySelectingCancel() {
    this.openStaffUnitDialog = false;
  }

  /** Событие выбора StaffUnit-а диалогового окна редактирования дежурства/TreeList "Выбор штатной единицы" */
  public onDutySelected($event: StaffUnitForProxyList) {
    this.form.controls.employeeFullName.setValue($event.employeeFullName);
    this.dutyStaffUnitOwnerId = $event.ownerId;
    this.openStaffUnitDialog = false;
  }

  /** Событие нажания на кнопку Отмена диалогового окна редактирования дежурства */
  public onCancel() {
    this.cancel$.emit();
  }

  /** Событие нажания на кнопку Сохранить диалогового окна добавления/редактирования дежурства */
  public onSave() {
    switch (this.settings.type) {
      case "add":
        this.saveAdd();
        break;
      case "edit":
        this.saveEdit();
        break;
      default: new Error("Неизвестный тип операции, связанной с дежурством")
    }
  }

  /** Сохранение добавленной записи дежурства */
  private saveAdd() {
    if (this.settings.type != 'add') { throw new Error('Для добавления необходим режим "add"'); }
    //Добавляемый StaffUnit
    let addedStaffUnit$: Observable<StaffUnit>;
    addedStaffUnit$ = this.graphControlService.addDuty$(
      this.settings.redactionId,
      this.dutyStaffUnitOwnerId,
      this.form.controls.dutyStartDate.value,
      this.form.controls.dutyEndDate.value,
      this.form.controls.comment.value,
      this.form.controls.positionId.value.id,
      this.form.controls.milk.value);

    this.loadingIndicatorService.addToObservable(
      'Назначение дежурства',
      addedStaffUnit$
    ).pipe(take(1), exErrorHandler(this.displayErrorsService), takeUntil(this.unsubscribe$)).subscribe(
      {
        next: value => {
          this.save$.emit({ type: this.settings.type, staffUnit: value });
          this.kendoNotificationService.showSuccess({ content: 'Дежурство назначено' })
        }
      }
    );
  }

  /** Сохранение отредактированной записи дежурства */
  private saveEdit() {
    if (this.settings.type != 'edit') {
      throw new Error('Для редактирования необходим режим "edit"');
    }

    // ЛОГИКА СОХРАНЕНИЯ ОТРЕДАКТИРОВАННОЙ ЗАПИСИ
    let editedStaffUnit$: Observable<StaffUnit>;

    editedStaffUnit$ = this.graphControlService.editDuty$(
      this.settings.redactionId,
      this.dutyStaffUnitOwnerId,
      this.form.controls.positionId.value.id,
      this.form.controls.dutyStartDate.value,
      this.form.controls.dutyEndDate.value,
      this.form.controls.milk.value,
      this.form.controls.comment.value,
      this.settings.dutyStaffUnit.timestamp);

    this.loadingIndicatorService.addToObservable(
      'Редактирование дежурства',
      editedStaffUnit$
    ).pipe(take(1), exErrorHandler(this.displayErrorsService), takeUntil(this.unsubscribe$)).subscribe(
      {
        next: value => {
          this.save$.emit({ type: this.settings.type, staffUnit: value });
          this.kendoNotificationService.showSuccess({ content: 'Дежурство назначено' });
        }
      });

  }
}

/** Событие сохранения Дежурства */
export class EditDutyComponent_SaveEvent {
  constructor(public type: 'add' | 'edit',
    public staffUnit: StaffUnit) {
  }
}

/** Настройки компонента */
export class EditDutyComponent_Settings {
  /**
   * Конструктор
   * @param type Режим
   * @param month Месяц в котором добавляется заместитель
   * @param redactionId Идентификатор редакции графика
   * @param subdivisionId Идентификатор подразделения
   * @param dutyStaffUnit Данные о выбранной штатной единице
   */
  constructor(public type: 'add' | 'edit',
    public month: Date,
    public redactionId: number,
    public subdivisionId: number,
    public dutyStaffUnit: EditDutyComponent_StaffUnit) { }

  /** Валидировать объект */
  public validation() {
    if (!this.month) {
      throw new Error('поле month должно быть заполнено')
    }
    if (!this.redactionId) {
      throw new Error('поле redactionId должно быть заполнено')
    }
    if (this.type == 'edit') {
      this.dutyStaffUnit.validation();
    }
  }
}

/** Редактируемая запись */
export class EditDutyComponent_StaffUnit {
  /**
   * Конструктор
   * @param ownerId Идентификатор
   * @param employeeFullName ФИО сотрудника
   * @param employeeOwnerId
   * @param start Начало
   * @param end Окончание
   * @param rate Ставка
   * @param positionId Идентификатор должности (Position)
   * @param milk Выплаты компенсации за молоко
   * @param comment Комментарий к записи
   * @param timestamp
   */
  constructor(public ownerId: number,
    public employeeFullName: string,
    public employeeOwnerId: number,
    public start: Date,
    public end: Date,
    public rate: number,
    public positionId: number,
    public milk: boolean,
    public comment: string,
    public timestamp: []) {
  }

  /** Валидировать объект */
  public validation() {
    if (!this.ownerId) {
      throw new Error('ownerId должен быть заполнен')
    }
    if (!this.rate) {
      throw new Error('rate должен быть заполнен')
    }
    if (!this.employeeFullName) {
      throw new Error('employeeFullName должен быть заполнен')
    }
    if (!this.timestamp) {
      throw new Error('timestamp должен быть заполнен')
    }
  }
}
