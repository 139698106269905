import {Component, EventEmitter, Input, OnDestroy, Output,} from '@angular/core';
import {InputSize} from "@progress/kendo-angular-inputs/common/models/size";
import {Observable, ReplaySubject} from "rxjs";
import {map} from "rxjs/operators";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {exArrayJoin} from "../../../operators/ex-array-join.operator";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";
import {DeferSelectionService} from "../../../services/defer-selection-services/defer-selection.service";

/** Компонент выбора подразделения в виде textbox. Пример использования: График, Табель */
@Component({
  selector: 'app-subdivision-textbox-select',
  templateUrl: './subdivision-textbox-select.component.html',
  styleUrls: ['./subdivision-textbox-select.component.css'],
  providers: [DeferSelectionService]
})
@traceClass('SubdivisionTextboxSelectComponent')
export class SubdivisionTextboxSelectComponent implements OnDestroy {
  /** Размер textBox*/
  @Input() public size: InputSize = undefined;

  /** Отключен ли компонент */
  @Input() public disabled: boolean = false

  /** Функция получения названия подразделения для textBox */
  @Input() public textBox_displayTextFn : (subdivision: ISubdivision) => string = x => x.longName;

  /** Функция получения названия подразделения для диалога выбора подразделения */
  @Input() public selectionDialog_displayTextFn : (subdivision: ISubdivision) => string = x => x.longName;

  /** Поддерживает ли множественное выделение */
  @Input() public isSupportMultipleSelection = false;

  /** Поддерживает ли не выбор */
  @Input() public isSupportNotSelection = false;

  private _dataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input() public get dataSourceService(){
    return this._dataSourceService;
  }
  public set dataSourceService(value){
    this._dataSourceService = value;
    this.selectionService.originSelection = value?.dataSource;
  }

  private _selection: ArrayDataSourceSelection<ISubdivision, number>;
  @Input() public get selection(){
    return this._selection;
  }
  public set selection(value){
    this._selection = value;
    this.selectionService.originSelection = value;
  }

  /** Стрим для отображения текста выбранных подразделений в textBox */
  public text$: Observable<string>;

  /** Открыт ли диалог */
  public isOpenDialog = false;

  /** Событие изменения списка выбранных подразделений */
  @Output() public readonly selectionIdsChange$ = new EventEmitter<number[]>();

  private streams$ = {
    unsubscribe: new ReplaySubject<any>()
  }

  constructor(private readonly traceService: TracerServiceBase,
              public readonly selectionService: DeferSelectionService<SubdivisionsTreelistComponentDataSourceServiceBase['dataSource'], ArrayDataSourceSelection<ISubdivision, number>>) {
    this.selectionService.tempCtorFunc = dataSource => new ArrayDataSourceSelection<ISubdivision, number>(dataSource);
    this.selectionService.isDeferApply = false;

    this.subscribeToTempSelectionData();
  }

  /** Событие выбора в окне выбора подразделения */
  public onDialogSubdivisionSelect(){
    this.selectionService.apply();
    this.isOpenDialog = false;
  }

  /** Подпись на изменения temp selection */
  @traceFunc()
  private subscribeToTempSelectionData(){
    this.selectionService.tempSelection.data$.subscribe(selection => {
      if(!selection){
        this.text$ = undefined;
        this.selectionIdsChange$.emit([]);
        return;
      }

      selection.selectedIds.change$.subscribe(ids => {
        this.selectionIdsChange$.emit(ids);
      })

      this.text$ = selection.selectedItems2.data$
        .pipe(
          map(value => value.map(x => this.textBox_displayTextFn(x))),
          exArrayJoin(', '),
          map(value => !value ? undefined : value)
        );
    })
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
