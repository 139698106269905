import { IEntityId } from '../interfaces/IEntityId';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {NumberHelper} from "../../../../helpers/numberHelper";

export interface ITimeInterval extends IEntityId {
  /**
   * Идентификатор подразделения, в списке интервалов времени которого отображается данный интервал времени. FK на таблицу stafflist.Subdivisions
   * @depricate в базе отсутствует данное поле.
   */
  subdivisionId: number | null;
  /** Строка с временем начала временного интервала */
  startInterval: number;
  /** Строка с временем окончания временного интервала */
  endInterval: number;
  /**
   * Продолжительность рабочего времени
   * @depricate в базе отсутствует данное поле.
   */
  duration: number;
  /**
   * Продолжительность рабочего времени, приходящегося на ночные часы
   * @depricate в базе отсутствует данное поле.
   */
  nightDuration: number;
}

@classBackend('TimeInterval', 'timesheet_graph')
@className('TimeInterval')
/** Интервалы времени. Отображает время работы сотрудника с__по__ */
export class TimeInterval implements ITimeInterval {
  constructor(public id: number,
              public subdivisionId: number | null,
              public startInterval: number,
              public endInterval: number,
              public duration: number,
              public nightDuration: number,
  ) {
  }

  public static intervalToString(interval: number): string {
    const hours = Math.floor(interval / 60);
    const minutes = interval % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  }

  /**
   * Получить продолжительность интервала<br>
   * @param startInterval начало интервала. {@link ITimeInterval.startInterval}
   * @param endInterval окончание интервала. {@link ITimeInterval.endInterval}
   * @param [digits=2] округление результата. < 0 без округления
   */
  public static getDuration(startInterval: number, endInterval: number, digits: number = 2): number {
    return NumberHelper.round((endInterval - startInterval) / 60, digits);
  }

  /**
   * Получить продолжительность рабочего времени, приходящегося на ночные часы<br>
   * @param startInterval начало интервала. {@link ITimeInterval.startInterval}
   * @param endInterval окончание интервала. {@link ITimeInterval.endInterval}
   * @param [digits=2] округление результата. < 0 без округления
   */
  public static getNightDuration(startInterval: number, endInterval: number, digits: number = 2): number {
    const before = startInterval < 360 ? Math.min(360, endInterval) - startInterval : 0;
    const after = endInterval > 1320 ? endInterval - Math.max(startInterval, 1320) : 0;

    return NumberHelper.round((before + after) / 60, digits);
  }
}
