import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IVichLog extends IEntityId, IEntityModify, IEntityDeletedFlag {
  /** Идентификатор сотрудника */
  staffUnitId: number;
  /** Дата, за которую составлена данная запись */
  date: Date;
  /** Номер истории болезни пациента */
  diseaseNumber: string;
  /** ФИО пациента */
  patientFio: string;
  /** Вид обслуживания */
  typeServiceId: number | null;
  /** Вид исследования */
  typeResearchId: number | null;
  customTypeResearch: string; // Кастомный вид иследования
  /** Вида работ или название материала */
  typeWorkId: number | null;
  customTypeWork: string; // Кастомный вид работ или название материала
  /** Количество проведенных исследований в рамках данной записи */
  researchCount: number | null;
  /** № протокола исследований */
  researchProtocol: string;
  /** Вид обслуживания */
  subdivisionId: number | null;
  /** Время проведения */
  operatingTime: number;
}

@classBackend('VichLog', 'timesheet')
@className('VichLog')
/** Модель строки ВИЧ журнала */
export class VichLog implements IVichLog {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public deletedFlag: boolean,
              public staffUnitId: number,
              public date: Date,
              public diseaseNumber: string,
              public patientFio: string,
              public typeServiceId: number | null,
              public typeResearchId: number | null,
              public customTypeResearch: string,
              public typeWorkId: number | null,
              public customTypeWork: string,
              public researchCount: number | null,
              public researchProtocol: string,
              public subdivisionId: number | null,
              public operatingTime: number,
  ) {
  }

  /** Получить заполненный по умолчанию */
  public static GetEmpty(staffUnitId: number, date: Date): VichLog {
    return new VichLog(0, null, null, false, staffUnitId, date, '', '', 0, 0, null, 0, null, 0, '', 0, 1);
  }
}

