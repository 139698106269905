import {
  DateInStaffUnitRangeType,
  IStaffUnit,
  StaffUnit
} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {Employee} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee";
import {IGraphGridServerDataSource_ServerDataItem} from "./graph-grid-server-data-source.classes";
import {DateHelper} from "../../../../../../../../../src/app/helpers/dateHelper";
import {IconsUrlHelper} from "../../../../../../../../../src/app/helpers/icons-url.helper";
import {ArrayExpanded, ArrayHelper} from "../../../../../../../../../src/app/helpers/arrayHelper";

/** Класс идентификатора строки юи */
export class GraphGridUiDataSource_UiDataItem_IdClass {
  /** Уникальный идентификатор = {@link staffUnitId}_{@link index} */
  public readonly uid: string;

  /**
   * Конструктор
   * @param staffUnitId Идентификатор исполнения должности
   * @param index Индекс виртуальной строки
   */
  public constructor(public readonly staffUnitId: number,
                     public readonly index: number){
    this.uid = `${staffUnitId}_${index}`;
  }
}

/** Класс строки для отображения в ui */
export class GraphGridUiDataSource_UiDataItem {
  /** Строка, на которую ссылается {@link IStaffUnit.ParentId} и при этом дата окончания текущей строки находится в диапазоне подстроки(Так как их может быть несколько) */
  public parent: GraphGridUiDataSource_UiDataItem | undefined;

  /** Составной идентификатор строки */
  public readonly id: GraphGridUiDataSource_UiDataItem_IdClass;

  /** Состояние строки сравнения двух редакций. Если {@link undefined} - оригинальная строка */
  public compareState: 'added' | 'deleted' | 'modified' | undefined;

  /** Иконка */
  public readonly imageUrl: string;

  /** Является ли внешним исполнением должности */
  public readonly isExternal: boolean;

  /** ФИО */
  public readonly fio: string;

  /** Конструктор */
  constructor(indexSubItem: number,
              public readonly subItem: IGraphGridServerDataSource_ServerDataItem['subItems'][0]) {
    this.id = new GraphGridUiDataSource_UiDataItem_IdClass(subItem.staffUnit.id, indexSubItem);
    this.imageUrl = IconsUrlHelper.getstaffUnitImagePath(subItem.staffUnit.typeId, StaffUnit.isProxy(subItem.staffUnit.parentId));
    this.isExternal = StaffUnit.isExternal(subItem.staffUnit.typeId);
    this.fio = Employee.fullName(subItem.employee);
  }

  private readonly _cellTypeMap = new Map<Date, ReturnType<typeof this.getCellType>>();
  /** Получить тип ячейки. Используется кэш на основе {@link Map} */
  public getCellType(date: Date): DateInStaffUnitRangeType{
    let value = this._cellTypeMap.get(date);
    if(value){
      return value;
    }

    value = StaffUnit.checkDateInRange(date, this.subItem.staffUnit.startDate, this.subItem.staffUnit.endDate, this.subItem.data.date, this.subItem.data.endDate);

    this._cellTypeMap.set(date, value);
    return value;
  }

  /** Создать несколько на основе серверных данных */
  public static CreateManyFromServerData(sources: IGraphGridServerDataSource_ServerDataItem[]): GraphGridUiDataSource_UiDataItem[]{
    const result = Array.from(internal());
    this.FillParent(result);

    return result;

    function* internal(){
      for (let source of sources) {
        for (let i = 0; i < source.subItems.length; i++) {
          yield new GraphGridUiDataSource_UiDataItem(i, source.subItems[i]);
        }
      }
    }
  }

  /** Заполнить поле {@link GraphGridUiDataSource_UiDataItem.parent} */
  public static FillParent(sources: GraphGridUiDataSource_UiDataItem[]): void{
    const isProxyMap = new ArrayExpanded(sources)
      .groupBy(x => !!x.subItem.staffUnit.parentId)
      .toMap(x => x.key);

    new ArrayExpanded(isProxyMap.get(true)?.values ?? [])
      .leftInnerJoinGroupedRight(
        isProxyMap.get(false)?.values ?? [],
        x => x.subItem.staffUnit.parentId,
        x => x.subItem.staffUnit.id,
        (left, rights) => {
          return {
            proxy: left,
            target: ArrayHelper.single(
              rights,
              x =>
                left.subItem.data.endDate >= x.subItem.data.date && left.subItem.data.endDate <= x.subItem.data.endDate //Дата окончания должна находится внутри родителя
            )
          }
        })
      .array
      .forEach(x => x.proxy.parent = x.target)
  }
}
