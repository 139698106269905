import {Injectable} from "@angular/core";
import {EMPTY, Observable, of, ReplaySubject, Subject} from "rxjs";
import {
  ArrayDataSourceIEntityId,
  DataSource
} from "../../../../../../../../../src/app/classes/array-data-sources/data-source";
import {
  ArrayDataSourceIEntityIdServiceWithParamsBase
} from "../../../../../../../../../src/app/services/data-source-services/data-source.service";
import {
  Api1GraphControlControllerService,
  IGetGraphRowResponse
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {Day} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/Day";
import {ArrayExpanded, ArrayHelper} from "../../../../../../../../../src/app/helpers/arrayHelper";
import * as moment from "moment";
import {map, switchMap, take, tap} from "rxjs/operators";
import {CovidLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog";
import {CovidLog2} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog2";
import {VichLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/VichLog";
import {TuberLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/TuberLog";
import {ExcludeMilkLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/ExcludeMilkLog";
import {DayType} from "../../../graph-grid/classes/view-models/day-type-view-model.class";
import {StaffUnit} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {Subdivision} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision";
import {Occupation} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Occupation";
import {WorkMode} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkMode";
import {Position} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Position";
import {Employee} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee";
import {DbChangedListener} from "../../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener";
import {
  DataHasOwnerStateBuilder
} from "../../../../../../../../../src/app/classes/data-state-builders/data-has-owner-state-builder";
import {DataStateBuilder} from "../../../../../../../../../src/app/classes/data-state-builders/data-state-builder";
import {exDistinctUntilChanged} from "../../../../../../../../../src/app/operators/ex-distinct-until-changed.operator";
import {DateHelper} from "../../../../../../../../../src/app/helpers/dateHelper";
import {
  GraphGridServerDataSource_DayType,
  IGraphGridServerDataSource_ServerDataItem
} from "./graph-grid-server-data-source.classes";
import {
  GraphGrid_GraphDayCellDataSourceService
} from "../graph-grid-graph-day-cell-data-sources/graph-grid-graph-day-cell-data-source.service";
import {GraphGridUiDataSource_UiDataItem} from "./graph-grid-ui-data-source.classes";
import {
  GraphGrid_GraphDayCell
} from "../graph-grid-graph-day-cell-data-sources/graph-grid-graph-day-cell-data-source.classes";

/** Тип параметров источника данных */
type ParamType = {
  /** Идентификатор редакции графика */
  redactionId: number,
  /** Слушать ли изменения по signalR. К примеру если график согласован то слушать не надо */
  useSignalR: boolean,
}

/** Тип результата анализа signalR */
type SignalRResultType = {
  reloadAll: boolean,
  staffUnitIds: number[] | undefined
}

/** Дополнительная информация о данных сервиса данных */
type GraphGridServerDataSourceService_AdditionDataInfoType = {
  /** График */
  graph: IGetGraphRowResponse['graph'],
  /** Идентификатор редакции */
  redactionId: number,
  /** Дата аудита данных */
  auditDate: Date | undefined,
  /** Дни */
  days: GraphGridServerDataSource_DayType[],
}

/** Данные для юи */
type GraphGridServerDataSourceService_UiDataType = GraphGridServerDataSourceService_AdditionDataInfoType & {
  /** Строки */
  rows: GraphGridUiDataSource_UiDataItem[],
  /** Источник данных ячеек */
  cellsDataSource: GraphGrid_GraphDayCellDataSourceService,
}

/** Сервис серверных данных для Графика */
@Injectable()
export class GraphGridServerDataSourceService extends ArrayDataSourceIEntityIdServiceWithParamsBase<ParamType, IGraphGridServerDataSource_ServerDataItem> {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    useSignalR: new Subject<boolean>(),
  }

  /** @inheritDoc */
  public readonly paramsDataSource: DataSource<ParamType> = new DataSource<ParamType>();
  /** @inheritDoc */
  public readonly dataSource: ArrayDataSourceIEntityId<IGraphGridServerDataSource_ServerDataItem> = new ArrayDataSourceIEntityId<IGraphGridServerDataSource_ServerDataItem>();
  /** Источник дополнительных данных о данных в {@link dataSource} */
  public readonly additionDataSource: DataSource<GraphGridServerDataSourceService_AdditionDataInfoType> = new DataSource<GraphGridServerDataSourceService_AdditionDataInfoType>();
  /** Источник данных для отображения в юи */
  public readonly uiDataSource: DataSource<GraphGridServerDataSourceService_UiDataType> = new DataSource<GraphGridServerDataSourceService_UiDataType>();

  /** Источник данных для ячеек дней исполнений должностей */
  private readonly graphDayCellDataSource: GraphGrid_GraphDayCellDataSourceService = new GraphGrid_GraphDayCellDataSourceService();

  /**
   * Конструктор
   */
  constructor(private readonly api1GraphControlControllerService: Api1GraphControlControllerService,
              private readonly dbChangedListener: DbChangedListener,) {
    super();

    let dataType: 'init' | 'update' = 'init';
    this.paramsDataSource.change$
      .pipe(
        tap(x => dataType = 'init'), //Сбрасываем
        switchMap(x => this.dataSource.change$)
      )
      .subscribe(serverRows => {
        if (dataType === 'init') {
          this.graphDayCellDataSource.clear();
          this.graphDayCellDataSource.recalculateChanged();

          dataType = 'update';
        }

        this.graphDayCellDataSource.addForStaffUnits(this.getCells(serverRows));
        this.graphDayCellDataSource.recalculateChanged();

        this.uiDataSource.setData({
          ...this.additionDataSource.data,
          rows: GraphGridUiDataSource_UiDataItem.CreateManyFromServerData(serverRows),
          cellsDataSource: this.graphDayCellDataSource
        })
      });
  }

  /** Метод управляет включением-отключением signalR. */
  public enabledSignalR(value: boolean) {
    this.streams$.useSignalR.next(value);
  }

  /** Получить ячейки для всех исполнений должностей */
  private getCells(serverRows: IGraphGridServerDataSource_ServerDataItem[]){
    const additionData = this.additionDataSource.data;

    const changedCellMap = new ArrayExpanded(this.graphDayCellDataSource.changed)
      .groupBy(x => x.staffUnitId, (key, items) => ({
        key: key,
        items: items.map(x => ({
          date: x.day.date,
          ...x.graphDay.current
        }))
      }))
      .toMap(x => x.key, x => x.items);

    return new ArrayExpanded(serverRows)
      .map(serverRow => {
        return GraphGrid_GraphDayCell.CreateForStaffUnit2(
          additionData.graph,
          additionData.days,
          serverRow.id,
          serverRow.covidLogs,
          serverRow.covidLog2s,
          serverRow.vichLogs,
          serverRow.tuberLogs,
          serverRow.excludeMilkLogs,
          serverRow.graphDays.map(x => {
            return {
              date: x.graphDay.date,
              timeInterval: x.timeInterval,
              dayDeviation: x.dayDeviation,
              dayDeviationCustomValue: x.graphDay.dayDeviationCustomValue,
              flexDinner: x.graphDay.flexDinner,
              substractLunchFlag: x.graphDay.substractLunchFlag
            }
          }),
          changedCellMap.get(serverRow.id) ?? []
        )
      })
      .flatMap(x => x)
      .array;
  }

  /** @inheritDoc */
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.useSignalR.complete();
    this.uiDataSource.onDestroy();
    this.additionDataSource.onDestroy();
    this.graphDayCellDataSource.ngOnDestroy();
    super.ngOnDestroy();
  }

  /** @inheritDoc */
  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.streams$.useSignalR
      .pipe(
        exDistinctUntilChanged(undefined),
        switchMap(useSignalR => {
          if (!useSignalR) {
            return of(EMPTY);
          }

          return this.dbChangedListener.onMulti({
            subdivisions: Subdivision,
            occupations: Occupation,
            workModes: WorkMode,
            positions: Position,
            employees: Employee,
            staffUnits: StaffUnit,
            days: Day,
            covidLogs: CovidLog,
            covidLog2s: CovidLog2,
            vichLogs: VichLog,
            tuberLogs: TuberLog,
            excludeMilkLogs: ExcludeMilkLog
          }).pipe(
            map(value => value.data),
            map(value => {
              //----Анализ перезагрузки всех данных----
              //Анализ дней
              const intersectDays = new ArrayExpanded(value.days)
                .map(x => x.currentOrOrigin)
                .innerJoin(this.additionDataSource.data.days, x => x.date, x => x.date);

              if (intersectDays.left.length > 0) { //Перезагрузка всех данных
                return getResult_ReloadAll();
              }
              //Анализ подразделений
              const allSubItems = ArrayHelper.flatMapBy(this.dataSource.data, x => x.subItems);

              const subdivisions = new DataHasOwnerStateBuilder(value.subdivisions, allSubItems, x => x.subdivision.id).build_()
                .source
                .filter(x => x.state == 'modified') //Только модифицированные
                .filter(x => x.dataItem) //Только относящиеся к данным источника данных

              if (subdivisions.length > 0) {
                return getResult_ReloadAll();
              }

              //--------------------------------------------------
              //Анализ перегрузки строк
              const staffUnitIdsForReload: Array<number> = [
                ...new DataHasOwnerStateBuilder(value.occupations, allSubItems, x => x.occupation.id).build_()
                  .source
                  .filter(x => x.state === 'modified') //Только модифицированные
                  .filter(x => x.dataItem) //Только относящиеся к данным источника данных
                  .map(x => x.dataItem.staffUnit.id),

                ...new DataHasOwnerStateBuilder(value.workModes, allSubItems, x => x.workMode.id).build_()
                  .source
                  .filter(x => x.state === 'modified') //Только модифицированные
                  .filter(x => x.dataItem) //Только относящиеся к данным источника данных
                  .map(x => x.dataItem.staffUnit.id),

                ...new DataHasOwnerStateBuilder(value.positions, allSubItems, x => x.position.id).build_()
                  .source
                  .filter(x => x.state === 'modified') //Только модифицированные
                  .filter(x => x.dataItem) //Только относящиеся к данным источника данных
                  .map(x => x.dataItem.staffUnit.id),

                ...new DataHasOwnerStateBuilder(value.employees, allSubItems, x => x.employee.id).build_()
                  .source
                  .filter(x => x.state === 'modified') //Только модифицированные
                  .filter(x => x.dataItem) //Только относящиеся к данным источника данных
                  .map(x => x.dataItem.staffUnit.id),

                ...new DataHasOwnerStateBuilder(value.staffUnits, allSubItems, x => x.staffUnit.id).build_()
                  .source
                  //Для оптимизации можно добавить фильтрацию что диапазон попадает в месяц, сейчас изменения в любом подразделении и месяце будет делаться запрос
                  .map(x => x.signalR.currentOrOrigin.ownerId),

                ...new DataHasOwnerStateBuilder(value.staffUnits, allSubItems, x => x.staffUnit.parentId).build_()
                  .source
                  //Для оптимизации можно добавить фильтрацию что диапазон попадает в месяц, сейчас изменения в любом подразделении и месяце будет делаться запрос
                  .map(x => x.signalR.currentOrOrigin.ownerId),

                ...new DataStateBuilder(value.covidLogs, this.dataSource.data2, (l, r) => l.id === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),

                ...new DataStateBuilder(value.covidLog2s, this.dataSource.data2, (l, r) => l.id === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),

                ...new DataStateBuilder(value.vichLogs, this.dataSource.data2, (l, r) => l.id === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),

                ...new DataStateBuilder(value.tuberLogs, this.dataSource.data2, (l, r) => l.id === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),

                ...new DataStateBuilder(value.excludeMilkLogs, this.dataSource.data2, (l, r) => l.id === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),
              ]

              return getResult_ReloadById(staffUnitIdsForReload);
            }),
            map(value => {
              if (value.reloadAll) {
                return this.updateData$();
              }

              return this.reloadFromSignalR$(value.staffUnitIds);
            })
          )
        }),
      )

    /** Получить результат - перезагрузить все */
    function getResult_ReloadAll(): SignalRResultType {
      return {
        reloadAll: true,
        staffUnitIds: undefined
      }
    }

    /** Получить результат - перезагрузить по списку id */
    function getResult_ReloadById(ids: number[]): SignalRResultType {
      return {
        reloadAll: false,
        staffUnitIds: undefined
      }
    }
  }

  /** @inheritDoc */
  protected _reloadData$(params: ParamType): Observable<IGraphGridServerDataSource_ServerDataItem[]> {
    return this.api1GraphControlControllerService.getGraphRow2$(
      params.redactionId,
      undefined,
      true,
      true
    ).pipe(
      map(response => {
        const additionData: GraphGridServerDataSourceService_AdditionDataInfoType = {
          graph: response.graph,
          redactionId: params.redactionId,
          auditDate: response.auditDate,
          days: convertToDayType(response.days, response.dayTypes)
        }

        this.additionDataSource.setData(additionData);

        this.streams$.useSignalR.next(params.useSignalR); //Устанавливаем использовать ли signalR
        return RowHelper.convert(response, additionData.days, response.graph, params.redactionId);
      })
    );
  }

  /** @inheritDoc */
  protected _reloadFromRemoteByIds$(params: ParamType, targets: number[]): Observable<IGraphGridServerDataSource_ServerDataItem[]> {
    return this.api1GraphControlControllerService.getGraphRow2$(
      params.redactionId,
      targets,
      false,
      false
    ).pipe(
      map(response =>
        RowHelper.convert(
          response,
          this.additionDataSource.data.days,
          this.additionDataSource.data.graph,
          params.redactionId)
      )
    );
  }
}

/** Конвертирует, сортирует переданные данные в {@link DayType} */
function convertToDayType(days: IGetGraphRowResponse['days'], dayTypes: IGetGraphRowResponse['dayTypes']): GraphGridServerDataSource_DayType[] {
  return new ArrayExpanded(days)
    .leftOuterJoinGroupedRight(
      dayTypes,
      x => x.dayTypeId,
      x => x.id,
      (left, rights) => {
        const r = ArrayHelper.single(rights);
        const result: GraphGridServerDataSource_DayType = {
          id: left.id,
          dayTypeId: left.dayTypeId,
          date: left.date,
          dayType: r,
          dayInMonth: moment(left.date).date(),
        };
        return result;
      })
    .array
    .sort((a, b) => a.dayInMonth - b.dayInMonth)
}

/** Класс помощник конвертации данных сервера в тип данных источника данных */
class RowHelper {

  /** Конвертировать ответ сервера в строки источника данных */
  public static convert(source: IGetGraphRowResponse,
                        days: GraphGridServerDataSource_DayType[],
                        graph: IGetGraphRowResponse['graph'],
                        redactionId: number): IGraphGridServerDataSource_ServerDataItem[] {
    return this.buildRows(
      source.rows,
      this.getAsMap(source),
      days,
      graph,
      redactionId
    );
  }

  /** Построить строки */
  private static buildRows(source: IGetGraphRowResponse['rows'],
                           map: ReturnType<typeof this.getAsMap>,
                           days: GraphGridServerDataSource_DayType[],
                           graph: IGetGraphRowResponse['graph'],
                           redactionId: number): IGraphGridServerDataSource_ServerDataItem[] {
    return source
      .map(item => {
        const result: IGraphGridServerDataSource_ServerDataItem = {
          id: item.id,
          graph: graph,
          redactionId: redactionId,
          days: days,
          graphDays: (map.graphDay.get(item.id) ?? [])
            .map(x => ({
              graphDay: x,
              timeInterval: x.timeIntervalId ? map.timeInterval.get(x.timeIntervalId) : undefined,
              dayDeviation: x.dayDeviationId ? map.dayDeviation.get(x.dayDeviationId) : undefined
            })),
          covidLogs: map.covidLogs.get(item.id) ?? [],
          covidLog2s: map.covidLog2s.get(item.id) ?? [],
          vichLogs: map.vichLogs.get(item.id) ?? [],
          tuberLogs: map.tuberLogs.get(item.id) ?? [],
          excludeMilkLogs: map.excludeMilkLogs.get(item.id) ?? [],
          subItems: item.items
            .map(sub => {
              const staffUnit = map.staffUnit.get(item.id).getDumpOr(sub.endDate);
              const parentStaffUnit = !staffUnit.parentId
                ? undefined
                : map.staffUnit.get(staffUnit.parentId).getDumpOr(sub.endDate);

              const positionId = !parentStaffUnit
                ? staffUnit.positionId
                : parentStaffUnit.positionId;
              const position = map.position.get(positionId).getDumpOr(sub.endDate);

              const financingSourceId = !parentStaffUnit
                ? staffUnit.financingSourceId
                : parentStaffUnit.financingSourceId;

              const subResult: IGraphGridServerDataSource_ServerDataItem['subItems'][0] = {
                subdivision: map.subdivision.get(position.subdivisionId).getDumpOr(sub.endDate),
                occupation: map.occupation.get(position.occupationId).getDumpOr(sub.endDate),
                workMode: map.workMode.get(position.workModeId).getDumpOr(sub.endDate),
                position: position,
                employee: map.employee.get(staffUnit.employeeId).getDumpOr(sub.endDate),
                staffUnit: staffUnit,
                staffUnitType: map.staffUnitType.get(staffUnit.typeId),
                financingSource: map.financingSource.get(financingSourceId),
                data: sub
              }

              return subResult;
            })
        }

        return result;
      })
  }

  /** Получить данные как {@link Map} */
  private static getAsMap(
    source: Pick<
      IGetGraphRowResponse,
      'subdivisions' | 'occupations' | 'workModes' | 'positions' | 'employees' | 'staffUnits' | 'staffUnitTypes' |
      'financingSources' | 'timeIntervals' | 'dayDeviations' | 'graphDays' | 'covidLogs' | 'covidLog2s' |
      'vichLogs' | 'tuberLogs' | 'excludeMilkLogs'
    >
  ) {
    return {
      subdivision: new ArrayExpanded(source.subdivisions)
        .toMap(x => x.dumpItems[0].dump.id),
      occupation: new ArrayExpanded(source.occupations)
        .toMap(x => x.dumpItems[0].dump.id),
      workMode: new ArrayExpanded(source.workModes)
        .toMap(x => x.dumpItems[0].dump.id),
      position: new ArrayExpanded(source.positions)
        .toMap(x => x.dumpItems[0].dump.id),
      employee: new ArrayExpanded(source.employees)
        .toMap(x => x.dumpItems[0].dump.id),
      staffUnit: new ArrayExpanded(source.staffUnits)
        .toMap(x => x.dumpItems[0].dump.id),
      staffUnitType: new ArrayExpanded(source.staffUnitTypes)
        .toMap(x => x.id),
      financingSource: new ArrayExpanded(source.financingSources)
        .toMap(x => x.id),
      timeInterval: new ArrayExpanded(source.timeIntervals)
        .toMap(x => x.id),
      dayDeviation: new ArrayExpanded(source.dayDeviations)
        .toMap(x => x.id),
      graphDay: internal(source.graphDays, x => x.staffUnitId),
      covidLogs: internal(source.covidLogs, x => x.staffUnitId),
      covidLog2s: internal(source.covidLog2s, x => x.staffUnitId),
      vichLogs: internal(source.vichLogs, x => x.staffUnitId),
      tuberLogs: internal(source.tuberLogs, x => x.staffUnitId),
      excludeMilkLogs: internal(source.excludeMilkLogs, x => x.staffUnitId)
    }

    /** Метод помощник */
    function internal<T>(sources: T[], staffUnitIdGetter: (item: T) => number): Map<number, T[]> {
      return new ArrayExpanded(sources)
        .groupBy(staffUnitIdGetter)
        .toMap(x => x.key, item => item.values)
    }
  }
}
