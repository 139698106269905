import {
  ContentChildren,
  Directive, ElementRef, EventEmitter,
  OnDestroy, OnInit, Output,
  QueryList
} from "@angular/core";
import {SelectedGridCellDirective, SelectedGridCellDirectiveReadOnly} from "./selected-grid-cell.directive";
import {fromEvent, ReplaySubject, startWith, switchMap, tap} from "rxjs";
import {ArrayExpanded, ArrayHelper} from "../../helpers/arrayHelper";
import {filter, map, takeUntil} from "rxjs/operators";
import {ArrayDataSourceHasId} from "../../classes/array-data-sources/data-source";
import {ArrayDataSourceSelection} from "../../classes/array-data-sources/selections/array-data-source-selection";

/** Тип данных события изменения выделения */
type SelectedEventType = SelectedGridCellDirectiveReadOnly[];

/** Тип данных события изменения выделения с расширенными данными*/
type SelectedEventExpandedType = {
  /** Выделенные ячейки */
  selectedItems: SelectedGridCellDirectiveReadOnly[];
  /** Не выделенные ячейки */
  notSelectedItems: SelectedGridCellDirectiveReadOnly[];
  /** Все ячейки */
  all: SelectedGridCellDirectiveReadOnly[];
}

/**
 * Директива помечает html элемент как таблица в которой происходит выделение по ячейки<br>
 * Все выделяемые html элементы-ячейки должны быть помечены директивой {@link SelectedGridCellDirective}<br>
 * Директива также устанавливает css классы в html элементы директивы {@link SelectedGridCellDirective}. Смотри {@link CssClassType}<br>
 */
@Directive({
  selector: '[appSelectedGridCellTarget]'
})
export class SelectedGridCellTargetDirective implements OnInit, OnDestroy {

  /** Стримы */
  private readonly stream$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** Выделение */
  private readonly _selection: ArrayDataSourceSelection<SelectedGridCellDirective, string>;

  /** Источник данных для директив ячеек */
  private readonly _cellDirectivesDataSource = new ArrayDataSourceHasId<SelectedGridCellDirective, string>(x => x.id, );

  /** Событие изменения выделения */
  @Output() public selectedChange = new EventEmitter<SelectedEventType>();

  /**
   * Событие изменения с расширенными данными<br>
   * Происходит когда изменилось выделение или изменились ячейки
   */
  @Output() public selectedChangeExpanded = new EventEmitter<SelectedEventExpandedType>();

  /** Инжекция директив ячеек */
  @ContentChildren(SelectedGridCellDirective, {descendants: true}) public set cellDirectiveQueryList(value: QueryList<SelectedGridCellDirective>){
    const arr = value.toArray();

    const notUniqueIds = new ArrayExpanded(arr)
      .groupBy(
        x => x.id)
      .array
      .some(x => x.values.length > 1);

    if(notUniqueIds){
      throw new Error('Директивы ячеек имеют повторяющиеся идентификаторы');
    }

    const notUniqueRowColumnIndex = new ArrayExpanded(arr)
      .groupBy(
        x => `${x.rowIndex}_${x.columnIndex}`)
      .array
      .some(x => x.values.length > 1);

    if(notUniqueRowColumnIndex){
      throw new Error('Директивы ячеек имеют повторяющиеся индексы [индекс строки, индекс колонки]');
    }

    this._cellDirectivesDataSource.setData(arr);
  }

  /** Конструктор */
  constructor(public readonly elRef: ElementRef) {
    this._selection = new ArrayDataSourceSelection<SelectedGridCellDirective, string>(this._cellDirectivesDataSource);
  }

  /** @inheritdoc */
  public ngOnInit() {
    //Трансляция события изменения выделения
    this._selection.selectedItems.change$
      .subscribe(value => {
        this.selectedChange.next(value);
      })

    //Трансляция события изменения выделения с расширенными данными
    this._selection.selectedItems.change$
      .pipe(
        switchMap(selected => {
          return this._cellDirectivesDataSource.data$
            .pipe(
              map(all => {
                const event: SelectedEventExpandedType = {
                  selectedItems: selected,
                  notSelectedItems: ArrayHelper
                    .disjointElements(
                      selected,
                      all,
                        x => x.id,
                        x => x.id,
                      (lefts, rights) => rights),
                  all: all
                }

                return event;
              })
            )
        })
      ).subscribe(value => {
        this.selectedChangeExpanded.next(value);
    });

    //Обработка ячеек(установка значений в директивы)
    this.selectedChangeExpanded
      .pipe(takeUntil(this.stream$.unsubscribe))
      .subscribe(value => {
      this.processCells(value);
    })

    //Изменение выделения левой клавишей мыши
    this.getSelectionChange$()
      .subscribe(value => {

        const rowIndex = {
          min: Math.min(value.firstDirective.rowIndex, value.secondDirective.rowIndex),
          max: Math.max(value.firstDirective.rowIndex, value.secondDirective.rowIndex)
        }

        const columnIndex = {
          min: Math.min(value.firstDirective.columnIndex, value.secondDirective.columnIndex),
          max: Math.max(value.firstDirective.columnIndex, value.secondDirective.columnIndex)
        }

        const newSelected = new ArrayExpanded(this._cellDirectivesDataSource.data)
          .filter(x => x.rowIndex >= rowIndex.min && x.rowIndex <= rowIndex.max && x.columnIndex >= columnIndex.min && x.columnIndex <= columnIndex.max)
          .map(x => x.id)
          .fullGroupJoin(
            value.ids,
              x => x,
              x => x)
          .filter(x => x.rights[0] === undefined || x.lefts[0] === undefined)
          .map(x => x.key)
          .array;

        this._selection.setIds(newSelected, true, 'user');
    })
  }

  /** Очистить выделение */
  public clear(initiator: Parameters<typeof this._selection.setIds>[2] = 'program'){
    if(this._selection.selectedIds.data.length === 0 && this._selection.withInitiator.data.initiator === initiator){
      return;
    }

    this._selection.setIds([], true, initiator);
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.stream$.unsubscribe.next(null);
    this.stream$.unsubscribe.complete();
    this._cellDirectivesDataSource.onDestroy();
    this._selection.onDestroy();
  }

  /** Найти html элемент имеющий директиву {@link SelectedGridCellDirective} */
  private getHtmlElementFromMouseEvent(event: MouseEvent){
    let element = event.target as HTMLElement;

    while (true){
      if(element === this.elRef.nativeElement){
        return undefined;
      }

      if(element.hasAttribute && element.hasAttribute('appSelectedGridCell')){
        return element;
      }

      element = element.parentElement;

      if(!element){
        return undefined;
      }
    }
  }

  /**
   * Получить директиву по событию мыши
   * @return (директиву + событие) или undefined, если событие мыши не затрагивает элемент помеченный {@link CellSelectingDirective}
   */
  private findDirectiveByHtmlElement(htmlElement: HTMLElement){
    return htmlElement ? this._cellDirectivesDataSource.data.find(x => x.elRef.nativeElement == htmlElement) : undefined;
  }

  /** Получить стрим изменения выделения левой клавишей мыши */
  private getSelectionChange$(){
    const events$ = {
      /** Нажатие */
      mousedown: fromEvent(this.elRef.nativeElement, 'mousedown'),
      /** мышь переместилась на другой html элемент */
      mouseover: fromEvent(this.elRef.nativeElement, 'mouseover'),
      /** Отпускание */
      mouseup: fromEvent(this.elRef.nativeElement, 'mouseup'),
      /** Выход за предел основного html элемента */
      mouseleave: fromEvent(this.elRef.nativeElement, 'mouseleave')
    }

    return events$.mousedown
      .pipe(
        map(event => event as MouseEvent), //Типизируем
        filter(event => event.button === 0), //Только левая клавиша мыши
        map((event) => {
          const directive = this.findDirectiveByHtmlElement(this.getHtmlElementFromMouseEvent(event));

          return {
            /** Идентификаторы выделенных ячеек на момент нажатия левой клавиши */
            ids: event.ctrlKey //Если с нажатой клавишей ctrl
              ? [...this._selection.selectedIds.data] //Необходимо добавлять к текущим выбранным
              : [], //Очищаем историю
            directive: directive,
            lastDirectiveId: '',
          }
        }),
        filter(value => !!value.directive), //Интересует клик по элементу с директивой
        switchMap(value => {
          return events$.mouseover
            .pipe(
              map((mouseover: MouseEvent) => this.findDirectiveByHtmlElement(this.getHtmlElementFromMouseEvent(mouseover))),
              startWith(value.directive),
              filter(x => !!x), //Интересует только переход на элемент имеющий директиву
              filter(x => x.id !== value.lastDirectiveId), //Не интересует перемещение внутри элемента с директивой
              tap(x => {
                value.lastDirectiveId = x.id
              }), //Запоминаем как последний выделенный
              map(x => ({
                /** Идентификаторы выделенных ячеек на момент нажатия левой клавиши */
                ids: value.ids,
                /** Ячейка на которой нажали левой клавишей первый раз */
                firstDirective: value.directive,
                /** Ячейка над которой находится мышь в данный момент */
                secondDirective: x
              })),
              //
              takeUntil(this._cellDirectivesDataSource.change$), //Пока не будет изменения в директивах.
              takeUntil(events$.mouseup),
              takeUntil(events$.mouseleave),
            )
        }),
        takeUntil(this.stream$.unsubscribe)
      )
  }

  /** Обработать ячейки */
  private processCells(event: SelectedEventExpandedType){
    const selectedIds = new Set<string>();
    const selectedRows = new Set<number>();
    const selectedColumns = new Set<number>();

    for (let selectedItem of event.selectedItems) {
      selectedIds.add(selectedItem.id);
      selectedRows.add(selectedItem.rowIndex);
      selectedColumns.add(selectedItem.columnIndex);
    }

    for (let item of event.all as SelectedGridCellDirective[]) {
      item.inSelectedRow = !selectedRows.has(item.rowIndex) ? 'out' : (selectedRows.size === 1 ? 'single' : 'multi');
      item.inSelectedColumn = !selectedColumns.has(item.columnIndex) ? 'out' : (selectedColumns.size === 1 ? 'single' : 'multi');
      item.isSelected = selectedIds.has(item.id);
    }
  }
}
