import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ExtensionObj} from "../../../../../helpers/extensionObj";

/**
 * Базовый класс сервисов мониторинга
 */
export abstract class MonitoringBase{

  protected constructor(private httpClient: HttpClient,
                        private path: { get: string, getFor: string, getDetail: string, approving: string, cancelApproving: string, decline: string, approved: string, toUnderApproving: string, toUnderRevision: string, tryCancelRouting: string}
                        ) {

  }

  /** Получить список мониторинга */
  public get$(date: Date): Observable<Array<MonitoringGetResponse>>{
    return this.httpClient.post<Array<MonitoringGetResponse>>(
      this.path.get,
      {date: date}
    );
  }

  /** Получить данные по идентификатору */
  public getFor$(date: Date, graphOrTableId: number): Observable<MonitoringGetResponse>{
    return this.httpClient.post<MonitoringGetResponse>(
      this.path.getFor,
      {date: date, graphOrTableId: graphOrTableId}
    );
  }

  /**
   * Получить список деталий конкретному графику/табелю
   * @param id
   */
  public detailGet$(id: number): Observable<MonitoringDetailGetResponse>{
    return this.httpClient.post<any>(
      this.path.getDetail,
      {id: id}
    );
  }

  /** Начать согласование */
  public approving$(graphOrTableId: number): Observable<boolean>{
    return this.httpClient.post<boolean>(
      this.path.approving,
      {graphOrTableId: graphOrTableId}
    );
  }

  /** Отменить согласование */
  public cancelApproving$(graphOrTableId: number): Observable<boolean>{
    return this.httpClient.post<boolean>(
      this.path.cancelApproving,
      {graphOrTableId: graphOrTableId}
    )
  }

  /** Отклонить согласование */
  public decline$(graphOrTableId: number, comment: string): Observable<boolean>{
    return this.httpClient.post<boolean>(
      this.path.decline,
      {graphOrTableId: graphOrTableId, comment: comment}
    );
  }

  /** Согласовать */
  public approved$(graphOrTableId: number, comment: string): Observable<boolean>{
    return this.httpClient.post<boolean>(
      this.path.approved,
      {graphOrTableId: graphOrTableId, comment: comment}
    )
  }

  /** Отменить согласование */
  public toUnderApproving$(graphOrTableId: number): Observable<boolean>{
    return this.httpClient.post<boolean>(
      this.path.toUnderApproving,
      {graphOrTableId: graphOrTableId}
    )
  }

  /** Отправить на доработку */
  public toUnderRevision$(graphOrTableId: number, comment: string): Observable<boolean>{
    return this.httpClient.post<boolean>(
      this.path.toUnderRevision,
      {graphOrTableId: graphOrTableId, comment: comment}
    )
  }

  /**
   * Метод пытается вернуть график/табель в состояние в котором возможно редактирование.
   * Если график/табель уже в этом состоянии, то ничего не будет происходить.
   */
  public tryCancelRouting$(graphOrTableIds: number[]): Observable<number[]>{
    return this.httpClient.post<number[]>(
      this.path.tryCancelRouting,
      {
        graphOrTableIds: graphOrTableIds
      }
    )
  }
}

export interface MonitoringGetResponse{
  graphOrTable: MonitoringGetResponse_GraphOrTable
  status: MonitoringGetResponse_Status,
  stage: string,
  whoEdits: string,
  lastRoute: MonitoringGetResponse_LastRoute,
  managingApproval: MonitoringGetResponse_ManagingApproval
}

export interface MonitoringGetResponse_GraphOrTable{
  id: number,
  year: number,
  month: number,
  subdivisionId: number,
  subdivisionName: string
}

/** Интерфейс статуса графика/табеля */
export interface MonitoringGetResponse_Status{
  id: number,
  name: string
}

/** Интерфейс последнего маршрута */
export interface MonitoringGetResponse_LastRoute{
  dateTime: Date,
  author: string,
  comment: string
}

/** Интерфейс управления этапами согласования */
export interface MonitoringGetResponse_ManagingApproval{
  canApproving: boolean,
  /** В данный момент пользователь согласовывает ли */
  isApproving: boolean,
  /** Можно ли отменить согласование */
  canUnderApproving: boolean,
  /** Можно ли отправить на доработку */
  canToUnderRevision: boolean
}

export class MonitoringDetailGetResponse{
  /** Ответсвтенные ведения учета */
  public responsible: string;

  public information: Array<IMonitoringDetailGetResponse_Information>;
}

export interface IMonitoringDetailGetResponse_Information{
  displayStatus: string,
  stage: string,
  dateTime: Date,
  author: string,
  comment: string,
}
