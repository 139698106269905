import {ArrayDataSourceSelection} from "./array-data-source-selection";
import {ArrayDataSourceHasId} from "../data-source";
import {exElementByIndexOr} from "../../../operators/ex-element-by-index-or";

/**
 * @deprecated может не верно работать с onPush. Необходимо реализовать директиву по аналогии с {@link KendoGridSelectByArrayDataSourceSelectionDirective}
 * Класс расширяет возможности ArrayDataSourceSelection для KendoDropDownList
 */
export class KendoDropDownListArrayDataSourceSelection<TDataItem, TId> extends ArrayDataSourceSelection<TDataItem, TId>{
  /**
   * Данные для компонента KendoDropDownList<br>
   * НЕ ДОПУСКАЕТСЯ ПРОГРАММНО УСТАНАВЛИВАТЬ ЗНАЧЕНИЯ В ОБЪЕКТЕ!<br>
   */
  public forComponent: ForComponent<TDataItem, TId>;

  /**
   * Конструктор
   * @param dataSource Источник данных
   */
  constructor(dataSource: ArrayDataSourceHasId<TDataItem, TId>) {
    super(dataSource);

    this.forComponent = new ForComponent<TDataItem, TId>(
      dataSource.idGetter,
      (item) => this.onUserChangedSelectedIdsCallback(item)
    );

    this.selectedItems.data$.pipe(exElementByIndexOr(0, undefined)).subscribe(value => {
      this.update_forComponent_value(value);
    })
  }

  /** Обработка изменения пользователем выделенных строк(идентификаторов) */
  private onUserChangedSelectedIdsCallback(item: TDataItem){
    const id = this._idGetter(item);
    this.setIds(!id ? [] : [id], true, 'user');
  }

  /** Метод поддерживает в актуальности значение в this.forComponent.value */
  private update_forComponent_value(item: TDataItem){
    if(this._idGetter(item) != this._idGetter(this.forComponent.value)){
      this.forComponent.value = item;
    }
  }

  /** Безопасно получить идентификатор. Защита от !item */
  private _idGetter(item: TDataItem){
    if(!item){
      return undefined;
    }

    return this.dataSource.idGetter(item);
  }
}

/** Класс данных для компонента <kendo-dropdownlist */
class ForComponent<TDataItem, TId>{
  private _value: TDataItem;
  /**
   * Использовать для биндинга значения в html<br>
   * @example <kendo-dropdownlist [data]="dataSourceDropDownList.data$ | async"
   *                              [valueField]="'id'"
   *                              [textField]="'text'"
   *                              [(value)]="dropDownListSelection.forComponent.value"
   */
  public get value(){
    return this._value;
  }
  public set value(value){
    this._value = value;
    this.userChangedSelectedIdsCallback(value);
  }

  /**
   * Конструктор
   * @param idGetter функция получения идентификатора из элемента данных. Можно получить из dataSource
   * @param userChangedSelectedIdsCallback функция обратного вызова при изменении выделенного элемента пользователем
   */
  constructor(private readonly idGetter: (item: TDataItem) => TId,
              private readonly userChangedSelectedIdsCallback: (item: TDataItem) => void) {

  }
}
