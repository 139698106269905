/** Енам таблицы StaffUnits */
export const enum StaffUnitTypeEnum {
  /** Основная ставка */
  Basic = 1,

  /** Совместительство (внутреннее) */
  MoonlighterInner = 2,

  /** Совместительство (внешнее) */
  MoonlighterExternal = 3,

  /** Совмещение (внутреннее) */
  CombinationInner = 4,

  /** Совмещение (внешнее) */
  CombinationExternal = 5,

  /** Дежурство */
  Duty = 6,

  /** Увеличение объема работ (УВОР) */
  Uvor = 7
}

/** Помощник для {@link StaffUnitTypeEnum} */
export class StaffUnitTypeEnumHelper{
  /** Относится ли значение к {@link StaffUnitTypeEnum.MoonlighterInner} или {@link StaffUnitTypeEnum.MoonlighterExternal} */
  public static isMoonlighter(value: StaffUnitTypeEnum | number): boolean{
    return value == StaffUnitTypeEnum.MoonlighterInner || value == StaffUnitTypeEnum.MoonlighterExternal;
  }

  /** Относится ли значение к {@link StaffUnitTypeEnum.CombinationInner} или {@link StaffUnitTypeEnum.CombinationExternal} */
  public static isCombination(value: StaffUnitTypeEnum | number): boolean{
    return value == StaffUnitTypeEnum.CombinationInner || value == StaffUnitTypeEnum.CombinationExternal;
  }
}
