import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StaffUnitType} from "../../../classes/domain/POCOs/stafflist/StaffUnitType";
import {
  PrintFormForDateSettingsWithSubdivisionsAndStaffUnitTypes,
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {
  Api1StaffUnitTypeControllerService
} from "../../../services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {take, takeUntil} from "rxjs/operators";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import * as moment from "moment";
import {
  CheckedStaffUnitTypesValidator
} from "../print-form-from-till-dates-with-staffunit-types-settings/print-form-from-till-dates-with-staffunit-types-settings.component";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {CalendarView} from "@progress/kendo-angular-dateinputs";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {CheckedSubdivisionsValidator} from "../report-settings-form-validators";
import {trace} from "../../../modules/trace/operators/trace";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";

@Component({
  selector: 'app-for-date-with-subdivisions-and-staffunit-types-settings',
  templateUrl: './for-date-with-subdivisions-and-staffunit-types-settings.component.html',
  styleUrl: './for-date-with-subdivisions-and-staffunit-types-settings.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@traceClass('ForDateWithSubdivisionsAndStaffunitTypesSettingsComponent')
export class ForDateWithSubdivisionsAndStaffunitTypesSettingsComponent implements OnInit {

  /** Перечень допустимых типов занятости, по которым будут формироваться данные отчета */
  public staffUnitTypes: StaffUnitType[];

  public minDate: Date;
  public maxDate: Date;

  public form: FormGroup;

  /** Дата инициализации даты, на которую формируется отчет */
  @Input() public initialDate: Date;
  /** Параметры отображения даты в сомпоненте DatePicker */
  @Input()
  public datePickerViewParams: {
    activeView: CalendarView,
    format: string,
    deepBottomView?: CalendarView,
    deepTopView?: CalendarView
  } = {
    activeView: "year",
    format: "LLLL",
    deepBottomView: "year"
  };

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input()
  public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase) {
    this._subdivisionDataSourceService = value;
  }

  @Input() public selection: ArrayDataSourceSelection<ISubdivision, number>;

  private _checkableSettings: CheckableSettings;
  // Настройки checkBox TreeView списка подразделений
  @Input()
  public get checkableSettings(): CheckableSettings {
    return this._checkableSettings;
  }
  public set checkableSettings(value: CheckableSettings){
    this._checkableSettings = value;
  }

  /** Функция формирования отображаемой в treeView строки из объекта ISubdivision */
  private _displayTextFn: (subdivision: ISubdivision) => string;

  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  @Input() public get displayTextFn(){ return this._displayTextFn; }
  public set displayTextFn(value){ this._displayTextFn = value; }

  /** Развернуть (отобразить) все элементы списка подразделений при отображении формы настроек */
  @Input() public expandAllSubdivisions: boolean;

  /** Изменение даты, по которой формируются данные (необходимо для выполнения перезагрузки списка подразделений, сформированного на конкретную дату)*/
  @Output() dateChange$: EventEmitter<Date> = new EventEmitter<Date>();

  /** Событие выбора */
  @Output() public select$: EventEmitter<PrintFormForDateSettingsWithSubdivisionsAndStaffUnitTypes> =
    new EventEmitter<PrintFormForDateSettingsWithSubdivisionsAndStaffUnitTypes>;
  /** Событие отмены выбора */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly traceService: TracerServiceBase,
              private readonly api1StaffUnitTypeControllerService: Api1StaffUnitTypeControllerService,
              private readonly chengeDetector: ChangeDetectorRef,
  ) {  }
  public ngOnInit(): void {
    this.api1StaffUnitTypeControllerService.getAll$()
      .pipe(take(1))
      .subscribe(x => {
        this.staffUnitTypes = x.filter(y => !y.deletedFlag);
      });

    this.formInit();
  }

  /** Установить значение параметра выбранных подразделений на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  @traceFunc()
  public setCheckedSubdivisionsValue(el: number[]){
    this.form.controls.subdivisionOwnerIds.setValue(el);
  }

  /** Установить значение параметра "Выбрать все подразделения" на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  @traceFunc()
  public setAllSubdivisionsFlagValue(el: boolean){
    this.form.controls.allSubdivisionsFlag.setValue(el);
  }

  /** Обработка события нажания на кнопку "Выбрать" */
  @traceFunc()
  public onOk() {
    this.select$.emit(new PrintFormForDateSettingsWithSubdivisionsAndStaffUnitTypes(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.date.value,
      this.form.controls.allSubdivisionsFlag.value,
      this.form.controls.subdivisionOwnerIds.value,
      this.form.controls.staffUnitTypeIds.value.map(x => x.id)));
    this.select$.complete();
  }

  /** Обработка события нажания на кнопку "Отмена" */
  @traceFunc()
  onCancel() {
    this.cancel$.emit();
    this.cancel$.complete();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  /** Инициализация формы */
  @traceFunc()
  private formInit() {
    const date = this.initialDate
      ? moment(this.initialDate).startOf("day").toDate()
      : moment(new Date()).startOf("month").startOf('day').toDate();

    this.form = new FormGroup({
      date: new FormControl<Date>(date, {validators: Validators.required, updateOn: 'blur'}),
      allSubdivisionsFlag: new FormControl<boolean>(false),
      subdivisionOwnerIds: new FormControl<number[]>([], CheckedSubdivisionsValidator()),
      staffUnitTypeIds: new FormControl<number[]>([], CheckedStaffUnitTypesValidator()),
    });

    this.form.controls.date.valueChanges.pipe(trace(this.traceService),
      takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.dateChange$.emit(value);
      });

    this.form.controls.date.updateValueAndValidity({emitEvent: true});
    this.form.controls.date.markAsTouched();

    this.chengeDetector.detectChanges();
  }
}
