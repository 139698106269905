import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { IEntityPositionOwnerId } from '../interfaces/IEntityPositionOwnerId';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IPositionRate extends IEntityVersioning, IEntityPositionOwnerId {
  /** Идентификатор источника финансирования,
   * к которому относится данное количество ставок */
  financingSourceId: number | null;
  /** Количество ставок по данной штатной позиции и источнику финансирования */
  rate: number | null;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IPositionRateOptional = Partial<IPositionRate>;

@classBackend('PositionRate', 'stafflist')
@className('PositionRate')
/** Размер ставки штатной позиции */
export class PositionRate implements IPositionRate {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public positionId: number | null,
              public financingSourceId: number | null,
              public rate: number | null,
              public timestamp: [],
  ) {
  }
}
