import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';

export interface IGraphTableBase extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag {
  /** Подразделение, на работников которого составлен данный график */
  subdivisionId: number;
  /** Месяц, на который составлен график */
  month: Date;
}

/** Общая информация о графиках работы работников определенного подразделения */
export abstract class GraphTableBase implements IGraphTableBase {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public subdivisionId: number,
              public month: Date,
  ) {
  }
}
