<kendo-dialog *ngIf="isOpen"
              title="Список редакций"
              width="90%"
              maxHeight="90%"
              (keydown.escape)="onCancel()"
              (close)="onCancel()"
>
  <app-redaction-select2 [commandButtonSettings]="commandButtonSettings"
                         [dataSourceService]="dataSourceService"
                         [selection]="selectionService.tempSelection.data$ | async"
                         (compare$)="onCompare($event)"
                         (preview$)="onPreview($event)"
                         (copy$)="onCopy($event)"
  >
  </app-redaction-select2>

  <kendo-dialog-actions layout="start">
    <button kendoButton
            (click)="onCancel()"
    >
      Отмена
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
