import {Injectable} from "@angular/core";
import {WebApi1Service} from "../web-api1.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {DayDeviation} from "../../../../classes/domain/POCOs/timesheet_graph/DayDeviation";

/**
 * Сервис работы с контроллером DayDeviation
 */
@Injectable({
  providedIn: "root"
})
export class Api1DayDeviationControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  /**
   * Получить все отклонения
   */
  public getAllDayDeviations$(): Observable<Array<DayDeviation>>{
    return this.httpClient
      .get<Array<DayDeviation>>(this.webApi1Service.controllers.dayDeviation.actions.getAllDayDeviations.toString());
  }

  /**
   * Добавить отклонение
   * @param dayDeviation
   */
  public addDayDeviation$(dayDeviation: DayDeviation): Observable<DayDeviation>{
    return this.httpClient
      .post<DayDeviation>(this.webApi1Service.controllers.dayDeviation.actions.addDayDeviation.toString(),
        dayDeviation
        );
  }

  /**
   * Пометить отклонение удаленным
   * @param dayDeviation
   */
  public markDayDeviationDeleted$(dayDeviation: DayDeviation): void{
    this.httpClient
      .get<DayDeviation>(this.webApi1Service.controllers.dayDeviation.actions.markDayDeviationDeleted.toString(),
        {
          params: new HttpParams({
            fromObject: {
              dayDeviation: dayDeviation.toString()
            }})
        });
  }

  /**
   * Пометить несколько отклонений удаленными
   * @param dayDeviations
   */
  public markDayDeviationsDeleted$(dayDeviations: Array<DayDeviation>): void{
    this.httpClient
      .get<DayDeviation>(this.webApi1Service.controllers.dayDeviation.actions.markDayDeviationsDeleted.toString(),
        {
          params: new HttpParams({
            fromObject: {
              dayDeviations: dayDeviations.toString()
            }})
        });
  }
}
