import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  IRedactionGridComponent, RedactionGridComponent2,
  RedactionGridComponent_CommandButtonSettingsType
} from "../redaction-grid/redaction-grid.component";
import {RedactionGridDataSourceServiceBase} from "../redaction-grid/services/redaction-grid-data-source.service";
import {
  ArrayDataSourceSelection
} from "../../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";
import {
  RedactionServiceGetResponse_Redaction
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import {
  DeferSelectionService
} from "../../../../../../../../src/app/services/defer-selection-services/defer-selection.service";
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";

@Component({
  selector: 'app-redaction-dialog-selection',
  templateUrl: './redaction-dialog-selection.component.html',
  styleUrls: ['./redaction-dialog-selection.component.css'],
  providers: [DeferSelectionService]
})
@traceClass('RedactionDialogSelectionComponent')
export class RedactionDialogSelectionComponent implements IRedactionGridComponent, OnInit, OnDestroy {

  @Input() public commandButtonSettings: RedactionGridComponent_CommandButtonSettingsType = {...RedactionGridComponent2.defaultCommandButtonSettings};

  private _dataSourceService: RedactionGridDataSourceServiceBase;
  @Input() public get dataSourceService(){
    return this._dataSourceService;
  }
  public set dataSourceService(value){
    this._dataSourceService = value;
    this.selectionService.originSelection = value?.dataSource;
  }

  @Input() public set selection(value: ArrayDataSourceSelection<RedactionServiceGetResponse_Redaction, number>){
    this.selectionService.originSelection = value;
  }
  /** Закроет окно при любом событии {@link compare$} {@link copy$} {@link preview$} {@link cancel$}*/
  @Input() public closeAfter: boolean = true;

  /** Состояние окна Открыто/Закрыто */
  @Input() public isOpen: boolean = false;
  /** Событие изменения состояния окна компонентом */
  @Output() public isOpenChange = new EventEmitter<boolean>;

  @Output() public readonly compare$: EventEmitter<number> = new EventEmitter<number>();
  @Output() public readonly copy$: EventEmitter<number> = new EventEmitter<number>();
  @Output() public readonly preview$: EventEmitter<number> = new EventEmitter<number>();
  /** Отмена выбора */
  @Output() public readonly cancel$: EventEmitter<void> = new EventEmitter<void>();


  constructor(public readonly selectionService: DeferSelectionService<RedactionGridDataSourceServiceBase['dataSource'], ArrayDataSourceSelection<RedactionGridDataSourceServiceBase['dataSource']['data'][0], number>>,
              private readonly tracerService: TracerServiceBase) {
    selectionService.tempCtorFunc = dataSource => new ArrayDataSourceSelection<RedactionGridDataSourceServiceBase["dataSource"]["data"][0], number>(dataSource);
    selectionService.isDeferApply = true;
  }

  @traceFunc()
  public ngOnInit(): void {
  }

  /** Обработка нажатия на кнопку Отмена */
  @traceFunc()
  public onCancel(){
    this.setIsOpenState(false);
    this.cancel$.emit();
  }

  /** Обработка нажатия на кнопку Сравнить */
  @traceFunc()
  onCompare($event: number) {
    this.setIsOpenState(false);
    this.compare$.emit($event);
  }

  /** Обработка нажатия на кнопку Смотреть */
  @traceFunc()
  onPreview($event: number) {
    this.setIsOpenState(false);
    this.selectionService.apply();
    this.preview$.emit($event);
  }

  /** Обработка нажатия на кнопку Копировать */
  @traceFunc()
  onCopy($event: number) {
    this.setIsOpenState(false);
    this.copy$.emit($event);
  }

  /** Установить состояние Открыто/Закрыто */
  @traceFunc()
  private setIsOpenState(value: boolean){
    if(!this.closeAfter){
      return;
    }
    this.isOpen = value;
    this.isOpenChange.emit(value);
  }

  @traceFunc()
  ngOnDestroy(): void {
  }
}
