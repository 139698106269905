import {Component, OnDestroy, OnInit} from '@angular/core';
import {SignalRService} from "../../services/signal-r/signal-r.service";
import {ReplaySubject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-internet-connection',
  templateUrl: './internet-connection.component.html',
  styleUrls: ['./internet-connection.component.css']
})
export class InternetConnectionComponent implements OnInit, OnDestroy {
  /** Отображено ли сообщение об отсутствии подключения к интернету */
  public isShowed = false;

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(public signalRService: SignalRService) { }

  ngOnInit(): void {
    this.signalRService.hasConnection$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.isShowed = !value;
    })
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.signalRService.ngOnDestroy();
  }
}
