import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";

/**
 * css классы<br>
 * grid-cell - класс добавляется во все html элементы помеченные директивой {@link SelectedGridCellDirective}<br>
 * grid-cell-in-selected-rows - класс добавляется в html элементы которые расположены в выделенных строках<br>
 * grid-cell-in-selected-rows grid-cell-in-selected-single-row - классы добавляются в html элементы которые расположены в выделенной одной строки<br>
 * grid-cell-in-selected-columns - класс добавляется в html элементы которые расположены в выделенных колонках<br>
 * grid-cell-in-selected-columns grid-cell-in-selected-single-column - классы добавляются в html элементы которые расположены в выделенной одной колонке<br>
 * grid-cell-selected - класс добавляется в html элемент выделенной ячейки.<br>
 */
type CssClassType = 'grid-cell'
  | 'grid-cell-in-selected-rows'
  | 'grid-cell-in-selected-columns'
  | 'grid-cell-in-selected-single-row'
  | 'grid-cell-in-selected-single-column'
  | 'grid-cell-selected'

/** Главный css класс, который будет у всех ячеек */
const rootCssClass: CssClassType = 'grid-cell';

/** css класс выделенной ячейки */
const isSelectedCssClass: CssClassType = 'grid-cell-selected';

/**
 * Тип попадания ячейки в строки/колонки<br>
 * multi - выделено несколько строк/колонок, и попадает в одну из<br>
 * single - выделено в одной строке/колонки, и попадает в нее<br>
 * out - не попадает в выделенные строки/колонки<br>
 */
type SelectedValueType = 'multi' | 'single' | 'out';

/** Директива {@link SelectedGridCellDirective} только для чтения */
export type SelectedGridCellDirectiveReadOnly = Readonly<Pick<SelectedGridCellDirective, 'id' | 'rowIndex' | 'columnIndex' | 'elRef'>>;

/** Директива помечает html элемент как выделяемый */
@Directive({
  selector: '[appSelectedGridCell]',
})
export class SelectedGridCellDirective implements OnInit {
  /** Предыдущие установленные классы */
  private readonly prevClasses : { rows: string[], columns: string[]} = {
    rows: [],
    columns: []
  }

  /**
   * Индекс строки<br>
   * Необходимо для выделения областью<br>
   */
  @Input() public rowIndex: number;

  /**
   * Индекс колонки<br>
   * Необходимо для выделения областью<br>
   */
  @Input() public columnIndex: number;

  /**
   * Идентификатор колонки, должен быть уникальным<br>
   * @example
   * id = '${rowIndex}_${columnIndex}' - если простые данные
   * id = '${staffUnitId}_${columnIndex}_${month}' - сложные данные. Идентификатор строки + индекс колонки + месяц на который построены данные
   */
  @Input() public id: string;

  private _inSelectedRow: SelectedValueType = 'out';
  /** Расположение по отношению к выделенным строкам */
  @Input() public get inSelectedRow(){
    return this._inSelectedRow;
  }
  public set inSelectedRow(value: SelectedValueType){
    if(value === this._inSelectedRow){
      return;
    }

    this.removeClasses(this.prevClasses.rows);
    this.prevClasses.rows = RowClasses.get(value);
    this.addClasses(this.prevClasses.rows);

    this._inSelectedRow = value;
  }

  private _inSelectedColumn: SelectedValueType = 'out';
  /** Расположение по отношению к выделенным колонкам */
  @Input() public get inSelectedColumn() {
    return this._inSelectedColumn;
  }
  public set inSelectedColumn(value: SelectedValueType){
    if(value === this._inSelectedColumn){
      return;
    }

    this.removeClasses(this.prevClasses.columns);
    this.prevClasses.columns = ColumnClasses.get(value);
    this.addClasses(this.prevClasses.columns);

    this._inSelectedColumn = value;
  }

  private _isSelected: boolean = false;
  /** Выделена ли ячейка */
  @Input() public get isSelected(){
    return this._isSelected;
  }
  public set isSelected(value: boolean){
    if(value == this._isSelected){
      return;
    }

    if(value){
      this.render.addClass(this.elRef.nativeElement, isSelectedCssClass)
    } else {
      this.render.removeClass(this.elRef.nativeElement, isSelectedCssClass);
    }

    this._isSelected = value;
  }

  /** Конструктор */
  constructor(public readonly elRef: ElementRef,
              private readonly render: Renderer2) {
    this.render.addClass(this.elRef.nativeElement, rootCssClass);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    if(!this.definedCheck(this.rowIndex)){
      throw new Error('Индекс строки должен быть проинициализирован');
    }

    if(!this.definedCheck(this.columnIndex)){
      throw new Error('Индекс колонки должен быть проинициализирован')
    }

    if(!this.id){
      throw new Error('Идентификатор ячейки должен быть проинициализирован')
    }
  }

  /** Удалить несколько классов из {@link elRef} */
  private removeClasses(classNames: string[]){
    for (let className of classNames) {
      this.render.removeClass(this.elRef.nativeElement, className);
    }
  }

  /** Добавить несколько классов в {@link elRef} */
  private addClasses(classNames: string[]){
    for (let className of classNames) {
      this.render.addClass(this.elRef.nativeElement, className);
    }
  }

  /** Проверка */
  private definedCheck(value: number){
    return value || value === 0;
  }
}

/** Класс стилей css для строк */
class RowClasses{
  private static readonly multi: CssClassType[] = ['grid-cell-in-selected-rows'];
  private static readonly single: CssClassType[] = ['grid-cell-in-selected-rows', 'grid-cell-in-selected-single-row'];
  private static readonly out: CssClassType[] = [];

  /** Получить css классы в зависимости от переданного значения */
  public static get(value: SelectedValueType){
    switch (value) {
      case 'multi':
        return this.multi;
      case 'single':
        return this.single;
      case 'out':
        return this.out;
      default: throw new Error('out of range');
    }
  }
}

/** Класс стилей css для колонок */
class ColumnClasses{
  private static readonly multi: CssClassType[] = ['grid-cell-in-selected-columns'];
  private static readonly single: CssClassType[] = ['grid-cell-in-selected-columns', 'grid-cell-in-selected-single-column'];
  private static readonly out: CssClassType[] = [];

  /** Получить css классы в зависимости от переданного значения */
  public static get(value: SelectedValueType){
    switch (value) {
      case 'multi':
        return this.multi;
      case 'single':
        return this.single;
      case 'out':
        return this.out;
      default: throw new Error('out of range');
    }
  }
}
