import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {Observable} from "rxjs";
import {IPosition, IPositionOptional, Position} from "../../../../classes/domain/POCOs/stafflist/Position";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";
import { IPositionRateOptional } from "src/app/classes/domain/POCOs/stafflist/PositionRate";
import {EditEndDateDto, IdAndRowVersionType} from "./shared-DTOs/edit-end-date-dto";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";

@Injectable({
  providedIn: "root"
})
export class Api1PositionControllerService{
  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {
  }

  /** Получить по списку OwnerIds */
  public getByOwnerIds$(ownerIds: Array<number>, toDate: Date, startDate: Date | null, endDate: Date | null): Observable<Array<Position>>{
    return this.httpClient.post<Array<Position>>(
      this.webApi1Service.controllers.position.actions.getByOwnerIds.toString(),
      {
        ownerIds: ownerIds,
        date: toDate,
        startDate: startDate,
        endDate: endDate
      }
    )
  }

  /** Получить */
  public getForDate$(date: Date, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<Array<Position>>{
    return this.httpClient.post<Array<Position>>(
      this.webApi1Service.controllers.position.actions.getForDate.toString(),
      {
        ownerIds: ownerIds,
        date: date,
        startDate: startDate,
        endDate: endDate
      }
    )
  }

  public addPosition$(entity: AddPositionItem): Observable<Position>
  {
    return this.httpClient
    .post<Position>(this.webApi1Service.controllers.position.actions.add.toString(),
      entity)
  }

  /** Сохранить Position */
  public save$(entity:IPositionOptional): Observable<[]>{
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.position.actions.save.toString(),
        entity
      );
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<Array<VersioningItem>>
  {
    return this.httpClient
    .get<Array<VersioningItem>>(this.webApi1Service.controllers.position.actions.getVersionsByOwnerIdAndActionId.toString(),
      {
        params: HttpParamsHelper.getParams([['ownerId', ownerId], ['actionId', actionId]])
      })
  }

  public addPositionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.position.actions.addVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }

  public editPositionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.position.actions.editVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }

  public deletePositionVersion$(versionId: number) : Observable<boolean>{
    return this.httpClient.delete<boolean>(this.webApi1Service.controllers.position.actions.deleteVersion.toString(),
      {params: HttpParamsHelper.getParams([["versionId",versionId]])}
      )
  }

  /** Удалить */
  public delete$(entityId:number, timestamp:[]): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.position.actions.delete.toString(),
      {
        Id: entityId,
        timestamp: timestamp
      })
  }

  /** Изменить порядок */
  public reorder$(positionId1:number, positionId2:number, date: Date): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.position.actions.reorder.toString(),
      {
        positionId1: positionId1,
        positionId2: positionId2,
        date: date
      });
  }

  /** Редактировать окончание срока действия */
  public editEndDate$(date: Date, rows: IdAndRowVersionType[], safe: boolean): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.position.actions.editEndDate.toString(),
      new EditEndDateDto(date, rows.map(x => { return {id: x.id, timestamp: x.timestamp}}), safe));
  }

}


export class AddPositionItem implements Pick<IPosition, 'startDate' | 'endDate' | 'comment' | 'subdivisionId' | 'occupationId' | 'workModeId'
  | 'reportPeriodId' | 'leaderFlag' | 'dinnerHourDuration' | 'subtractLunchTimeFromWorkingHoursFlag' | 'includeScheduleForPaidServiceFlag'>{

  constructor(
    public startDate: Date | null,
    public endDate: Date | null,
    public comment: string,

    public subdivisionId: number | null,
    public occupationId: number | null,
    public workModeId: number | null,
    public reportPeriodId: number | null,
    public leaderFlag: boolean | null,
    public dinnerHourDuration: number | null,
    public subtractLunchTimeFromWorkingHoursFlag: boolean | null,
    public includeScheduleForPaidServiceFlag: boolean | null,

    public rates: Array<IPositionRateOptional>,
  ) {}
}


