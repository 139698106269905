import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IPosition extends IEntityVersioning {
  /** Идентификатор подразделения, к которому отностится данная штатная позиция. FK на таблицу stafflist.Subdivision */
  subdivisionId: number | null;
  /** Должность, относящаяся к данной штатной позиции. FK на таблицу stafflist.Occupation */
  occupationId: number | null;
  /** Режим работы по данной должности. FK на таблицу stafflist.WorkModes */
  workModeId: number | null;
  /** Перод контроля. FK на таблицу stafflist.ReportPeriods */
  reportPeriodId: number | null;
  /** Является ли руководителем */
  leaderFlag: boolean | null;
  /** Продолжительность обеденного перерыва */
  dinnerHourDuration: number | null;
  /** Вычитать время перерыва из рабочего времени */
  subtractLunchTimeFromWorkingHoursFlag: boolean | null;
  /** Включать в график по платным услугам */
  includeScheduleForPaidServiceFlag: boolean | null;
  /** Порядок отображения в иерархическом дереве штатных позиций UI */
  sortOrder: number | null;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IPositionOptional = Partial<IPosition>;

@classBackend('Position', 'stafflist')
@className('Position')
/** Штатная позиция */
export class Position implements IPosition {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public subdivisionId: number | null,
              public occupationId: number | null,
              public workModeId: number | null,
              public reportPeriodId: number | null,
              public leaderFlag: boolean | null,
              public dinnerHourDuration: number | null,
              public subtractLunchTimeFromWorkingHoursFlag: boolean | null,
              public includeScheduleForPaidServiceFlag: boolean | null,
              public sortOrder: number | null,
              public timestamp: [],
  ) {
  }
}
