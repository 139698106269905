import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IDay extends IEntityId, IEntityModify {
  /** Тип календарного дня (рабочий, выходной, праздничный и пр) */
  dayTypeId: number;
  /** День календаря в формате даты */
  date: Date;
}

@classBackend('Day', 'timesheet')
@className('Day')
/**  Календанрый день */
export class Day implements IDay {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public dayTypeId: number,
              public date: Date,
  ) {
  }
}
