import {Injectable, OnDestroy} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {defer, Observable, of, ReplaySubject} from "rxjs";
import {WebApi1Service} from "../web-api1.service";
import {take, takeUntil, tap} from "rxjs/operators";
import {AuthService} from "../../../../modules/auth/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class Api1TableCodeControllerService implements OnDestroy{
  private unsubscribe$ = new ReplaySubject<any>(1);

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service,
              private authService: AuthService) {
    authService.isAuth$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if(!value){
        this._isComputedCombination = null
      }
    })
  }


  private _isComputedCombination: boolean = null;
  /**
   * Обвестка над методом isComputedCombination$
   * Для кэширования результата на время работы
   */
  public isComputedCombination_cached$(): Observable<boolean>{
    return defer(() => {
      return this._isComputedCombination == null ?
        this.isComputedCombination$().pipe(takeUntil(this.unsubscribe$), tap(value => this._isComputedCombination = value)) :
        of(this._isComputedCombination);
    })
  }

  /**
   * Получить флаг является ли расчет Совмещения автоматическим или ручным
   */
  public isComputedCombination$(): Observable<boolean>{
    return this.httpClient.get<boolean>(this.webApi1Service.controllers.tableCode.actions.isComputedCombination.toString());
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
