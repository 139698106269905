<div appSelectedGridCellTarget class="container" > <!--Див для директивы, НЕ работает внутри kendo-grid-->
  <kendo-grid *ngIf="dataSource.uiDataSource.data$ | async as data"
              [data]="data.rows"
              [size]="'small'"
              sortable="true"
              class="graph-grid"

  >

    <kendo-grid-column title=""
                       [width]="5"
    >
      <ng-template kendoGridCellTemplate let-dataItem>

      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title=""
                       [width]="30"
                       class="no-padding unselectable"
                       headerClass="no-padding"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <img *ngIf="dataItem.imageUrl"
               [src]="dataItem.imageUrl"
               class="staff-unit-img"
          />
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Должность"
                       [field]="xPath.subItem.occupation.name.toString()"
                       class="unselectable no-padding"
                       headerClass="no-padding center"
                       sortable="true"
    >
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell [rowIndex]="rowIndex" [columnIndex]="columnIndex" [id]="getCellId(dataItem, columnIndex)"
          >
            <span appCutLongString
                  [lineCount]="3"
                  [title]="dataItem.subItem.occupation.name"
            >
              {{ dataItem.subItem.occupation.name }}
            </span>
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="ФИО"
                       [field]="xPath.fio.toString()"
                       class="unselectable no-padding"
                       headerClass="no-padding center"
                       sortable="true"
    >
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell [rowIndex]="rowIndex" [columnIndex]="columnIndex" [id]="getCellId(dataItem, columnIndex)">
            <span appCutLongString
                  [lineCount]="3"
                  [title]= "dataItem.fio"
            >
              {{dataItem.fio}}
            </span>
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title=""
                       [field]="xPath.subItem.staffUnit.rate.toString()"
                       [width]="50"
                       class="unselectable center no-padding"
                       headerClass="no-padding center"
                       sortable="true"
    >
      <ng-template kendoGridHeaderTemplate>
        <ng-container *ngLet="'Ставка' as title">
          <span class="ellipsis" [title]="title">{{title}}</span>
        </ng-container>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell [rowIndex]="rowIndex" [columnIndex]="columnIndex" [id]="getCellId(dataItem, columnIndex)">
            {{dataItem.subItem.staffUnit.rate}}
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title=""
                       [width]="42"
                       class="unselectable center no-padding"
                       headerClass="no-padding center"
    >
      <ng-template kendoGridHeaderTemplate>
        <ng-container *ngLet="'Норма' as title">
          <span class="ellipsis" [title]="title">{{title}}</span>
        </ng-container>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell [rowIndex]="rowIndex" [columnIndex]="columnIndex" [id]="getCellId(dataItem, columnIndex)">
            {{dataItem.subItem.data.norma}}
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title=""
                       [width]="42"
                       class="unselectable no-padding"
                       headerClass="no-padding center"
    >
      <ng-template kendoGridHeaderTemplate>
        <ng-container *ngLet="'Факт' as title">
          <span class="ellipsis" [title]="title">{{title}}</span>
        </ng-container>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell [rowIndex]="rowIndex" [columnIndex]="columnIndex" [id]="getCellId(dataItem, columnIndex)">
            {{dataItem.subItem.data.fact}}
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title=""
                       [width]="45"
                       class="unselectable no-padding"
                       headerClass="no-padding center"
    >
      <ng-template kendoGridHeaderTemplate>
        <ng-container *ngLet="'Дельта' as title">
          <span class="ellipsis" [title]="title">{{title}}</span>
        </ng-container>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell [rowIndex]="rowIndex" [columnIndex]="columnIndex" [id]="getCellId(dataItem, columnIndex)">
            Дел
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngFor="let day of data.days"
                       [title]="day.dayInMonth.toString()"
                       [width]="36"
                       class="unselectable no-padding"
                       headerClass="no-padding center"
                       [style]="{
                         background: day.dayTypeId == dayTypeEnum.BusinessDay ? null :
                          day.dayTypeId == dayTypeEnum.Weekend ? 'rgba(255, 255, 0, 0.15)' :
                            day.dayTypeId == dayTypeEnum.PreHoliday ? 'rgba(0, 255, 63, 0.15)' :
                             day.dayTypeId == dayTypeEnum.NonWorkingDay ? 'rgba(253, 233, 16, 0.25)' :
                             'rgba(255, 23, 0, 0.15)'
                       }"
    >
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell [rowIndex]="rowIndex" [columnIndex]="columnIndex" [id]="getCellId(dataItem, columnIndex)">
            <ng-container *ngLet="data.cellsDataSource.get(dataItem.id.staffUnitId, day.date) as cell">
              <app-graph-day-cell2 [type]="dataItem.getCellType(day.date)"
                                   [graphDay]="cell.graphDay.current"
                                   [timeInterval]="cell.graphDay.current.timeInterval"
                                   [dayDeviation]="cell.graphDay.current.dayDeviation"
                                   [excludeMilk]="cell.log.excludeMilkLog"
                                   [covidLogs]="cell.log.covidLogs"
                                   [covidLog2s]="cell.log.covidLog2s"
                                   [tuberLogs]="cell.log.tuberLogs"
                                   [vichLogs]="cell.log.vichLogs"
              >

              </app-graph-day-cell2>
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
