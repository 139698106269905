import {Injectable} from "@angular/core";
import {Guid} from "guid-typescript";
import {BaseExtensionObj, ExtensionObj} from "../helpers/extensionObj";
import {Subject} from "rxjs";

/** Сервис алертов */
@Injectable({
  providedIn: "root"
})
export class AlertService {
  /** Конструктор */
  constructor() {
    AlertOptionExtension.alertService = this;
  }

  /** Фабрика кнопок по умолчанию */
  public readonly defaultButtons : {
    /** Кнопка Отмена */
    cancel: (text: string, isPrimary: boolean, callBack: () => void) => ExtensionObj<AlertButton>,
    /** Кнопка Ок */
    ok: (text: string, isPrimary: boolean, callBack: () => void) => ExtensionObj<AlertButton>
  } = {
    cancel: (text = 'Отмена', isPrimary = false, callBack = null) => {
      return new ExtensionObj(AlertButton.Get(text, isPrimary, true, callBack));
    },
    ok: (text = 'Ok', isPrimary = true, callBack = null) => {
      return new ExtensionObj(AlertButton.Get(text, isPrimary, false, callBack));
    }
  }

  /** Фабрика сообщений по умолчанию */
  public readonly defaultAlertOption: {
    /**
     * Без типа.
     * Не будет отображаться иконка
     * Одна кнопка 'Закрыть'(primary)
     */
    none: () => AlertOptionExtension,
    /**
     * Уведомление
     * Одна кнопка 'Закрыть'(primary)
     */
    notification: () => AlertOptionExtension,
    /**
     * Информация
     * Одна кнопка 'Прочитано'(primary) которая закрывает окно
     */
    information: () => AlertOptionExtension,
    /**
     * Вопрос
     * Две кнопки - 'Отмена' 'Принять'(primary)
     */
    question: () => AlertOptionExtension,
    /**
     * Подтверждение
     * Две кнопки - 'Отмена' 'Подтвердить'(primary)
     */
    confirmation: () => AlertOptionExtension,
    /**
     * Подтверждение выхода из программы
     * @param callBack обратный вызов если нажали Ok
     */
    logout: (callBack: () => void) => AlertOptionExtension,
    /**
     * Предупреждение
     * Одна кнопка - 'Понятно' которая закрывает окно
     */
    warning: () => AlertOptionExtension,
    /**
     * Ошибка
     * Одна кнопка - 'Понятно' которая закрывает окно
     */
    error: () => AlertOptionExtension,
    /**
     * Ошибка при загрузке
     */
    downloadError: () => AlertOptionExtension
  } = {
    none: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.none, ' ', null, null, [this.defaultButtons.cancel('Закрыть', true, null).source]
        )
      );
    },
    notification: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.notification, 'Уведомление', null, null, [this.defaultButtons.cancel('Закрыть', true, null).source]
        )
      );
    },
    information: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.information, 'Информация', null, null, [this.defaultButtons.cancel('Прочитано', true, null).source]
        )
      );
    },
    question: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.question, 'Вопрос', null, null, [
            this.defaultButtons.cancel('Отмена', false, null).source,
            this.defaultButtons.ok('Принять', true, null).source
          ]
        )
      );
    },
    confirmation: () => {
      return new AlertOptionExtension(AlertOption.Get(
        AlertType.question, 'Подтверждение', null, 'Необходимо подтвердить действие', [
          this.defaultButtons.cancel('Отмена', false, null).source,
          this.defaultButtons.ok('Подтвердить', true, null).source
        ]
      ));
    },
    logout: (callBack: () => void) => {
      return new AlertOptionExtension(AlertOption.Get(
        AlertType.question, 'Выход из программы', null, 'Вы точно хотите выйти из программы?', [
          this.defaultButtons.cancel('Отмена', false, null).source,
          this.defaultButtons.ok('Выход', true, callBack).source
        ]
      ))
    },
    warning: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.warning, 'Предупреждение',
          'При выполнении произошла ошибка!',
          `\tОшибка не должна привести к серьезным последствиям. Попробуйте еще раз.
\t<b>Рекомендуем:</b> Все же не стоит рисковать. Полной гарантии, что все хорошо, увы нет. Если есть несохраненные данные, сохраните и перезагрузите страницу.

\t\t<b><small>Данные об ошибке собраны и переданы в службу поддержки</small></b>`,
          [this.defaultButtons.cancel('Понятно', true, null).source]
        )
      );
    },
    error: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.error, 'Ошибка',
          'При выполнении произошла непредвиденная критическая ошибка!',
          `\tК сожалению, мы не можем гарантировать стабильность Вашей дальнейшей работы.
\t<b>Рекомендуем:</b> Если есть несохраненные данные, попробуйте их сохранить, затем перезагрузите страницу.

\t\t<b><small>Данные об ошибке собраны и переданы в службу поддержки</small></b>`,
          [this.defaultButtons.cancel('Понятно', true, null).source]
        )
      );
    },
    downloadError: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.error, 'Ошибка',
          'При загрузке данных произошла ошибка!',
          `\tК сожалению, мы не можем гарантировать стабильность Вашей дальнейшей работы.
\t<b>Рекомендуем:</b> Перезагрузите страницу.

\t\t<b><small>Данные об ошибке собраны и переданы в службу поддержки</small></b>`,
          [this.defaultButtons.cancel('Понятно', true, null).source]
        )
      );
    }
  }

  /** Сообщения */
  private messages: Array<[Guid, AlertOption]> = new Array<[Guid, AlertOption]>();

  /** Стрим текущих настроек алертк */
  public readonly currentOption$ = new Subject<AlertOption>()

  private _currentOption: AlertOption = null;
  /** Текущие настройки алерта */
  public get currentOption(): AlertOption{
    return this._currentOption;
  };
  private set currentOption(value){
    this._currentOption = value;
    this.currentOption$.next(value);
  }

  /** Добавить сообщение */
  public add(option: AlertOption){
    AlertOption.Validation(option);

    if(this.messages.some(item => AlertOption.isEquals(item[1], option))){
      return;
    }

    this.messages.push([Guid.create(), option]);
    this.currentOption = option;
  }

  /**
   * Удалить последнее сообщение из массива сообщений
   * Если сообщений больше нет - то окно закроется
   * @param isLastDelay если true последний закроется с задержкой для предатврощения моргания
   */
  public remove(isLastDelay: boolean = true){
    if(this.messages.length == 0){
      return;
    }
    const guid = this.messages[this.messages.length - 1][0];
    if(this.messages.length == 1){
      setTimeout(() => {
        this._remove(guid);
      }, 10);
      return;
    }

    this._remove(guid);
  }

  /**
   * Удалить последнее сообщение из массива сообщений
   * Если последний то окно закроется
   */
  private _remove(guid: Guid){
    this.messages = this.messages.filter(item => item[0] != guid);
    if(this.messages.length == 0){
      this.currentOption = null;
      return;
    }

    this.currentOption = this.messages[this.messages.length - 1][1];
  }

  /**
   * Очищает все сообщения и скрывает Алерт
   * @param isLastDelay если true последний закроется с задержкой для предатврощения моргания
   */
  public clear(isLastDelay: boolean = true){
    for (let i = this.messages.length; i > 0; i--){
      this.remove(isLastDelay);
    }
  }
}

/** Класс опций алерта */
export class AlertOption{
  /**
   * Тип алерта
   * От него зависит иконка в алерте
   */
  public type: AlertType;
  /** Заголовок окна */
  public title: string;
  /**
   * Заколовок сообщения
   * Поддерживает \n\r \t
   */
  public titleMessage: string;
  /**
   * Сообщение
   * Поддерживает \n\r \t
   */
  public message: string;
  /** Текст кнопки */
  public buttons: Array<AlertButton>;

  /**
   * Поддерживает ли алерт отмену(нажатие на крестик либо клавиша Escape)
   * Если кнопок нет - поддерживает
   * Если хоть одна кнопка имеет флаг isCancel - поддерживает
   */
  public get supportedCancel(): boolean{
    if(!this.buttons || this.buttons.length < 1){
      return true;
    }
    return this.buttons.some(button => {
      return button.isCancel;
    });
  }

  /** Содержаться ли кнопки управления в алерте */
  public get hasButtons(): boolean{
    return this.buttons != null && this.buttons.length > 0
  }

  /**
   * Конструктор
   * @constructor
   */
  public static Get(
    type: AlertType,
    title: string,
    titleMessage: string,
    message: string,
    buttons: Array<AlertButton>) : AlertOption
  {
    const instance = new AlertOption();
    instance.type = type;
    instance.title = title;
    instance.titleMessage = titleMessage;
    instance.message = message;
    instance.buttons = buttons;

    return instance;
  }

  /** Равны ли два объекта */
  public static isEquals(instance1: AlertOption, instance2: AlertOption): boolean{
    if(instance1 == instance2){
      return true;
    }

    return instance1.type == instance2.type &&
      instance1.title == instance2.title &&
      instance1.titleMessage == instance2.titleMessage &&
      instance1.message == instance2.message &&
      AlertButton.IsEqualsArray(instance1.buttons, instance2.buttons);
  }

  /** Валидация объекта */
  public static Validation(instance: AlertOption): void{
    if(instance == null){
      throw new Error('Переданные на валидацию опции алерта == null');
    }
    if(!instance.type){
      throw new Error('Тип алерта в опциях не установлен');
    }
    if(instance.buttons != null){
      instance.buttons.forEach(button => {
        AlertButton.Validation(button);
      });
      const cancelButtonTotal = instance.buttons.filter(button => {
        return button.isCancel;
      }).length;
      if(cancelButtonTotal > 1){
        throw new Error('В алерте ктопок с флагом isCancel == true больше 1');
      }
    }
  }
}

/** Расширение */
export class AlertOptionExtension extends BaseExtensionObj<AlertOption, AlertOptionExtension>{
  public static alertService: AlertService = null;

  constructor(source: AlertOption) {
    super(source, () => {
      return this;
    });
  }

  /** Отобразить алерт с текущими опциями */
  public showAlert(): AlertOptionExtension{
    if(AlertOptionExtension.alertService == null){
      throw new Error('AlertService еще не проинициализирован. Необходима заинжектировать его в конструктор до использования метода AlertOptionExtension.show');
    }

    AlertOptionExtension.alertService.add(this.source);
    return this;
  }
}

/** Перечисления типа алерта */
export enum AlertType {
  /**
   * Без типа.
   * Не будет отображаться иконка
   */
  none = 1,
  /** Уведомление(Иконка - колокол) */
  notification = 2,
  /** Информация(Иконка - буква i) */
  information = 3,
  /** Вопрос(Иконка - вопросительный знак) */
  question = 4,
  /** Предупреждение(Иконка - восклицательный знак) */
  warning = 5,
  /** Ошибка(Иконка - крест) */
  error = 6
}

/** Класс кнопок алерта */
export class AlertButton {
  /** Текст кнопки */
  public text: string;
  /** Является ли кнопка по умолчанию(подсвечена) */
  public isPrimary: boolean;
  /** Является ли кнопка Отмена */
  public isCancel: boolean;
  /** Функция которую нужно вызвать по нажатию */
  public callBack: () => void;

  /**
   * Конструктор
   * @constructor
   */
  public static Get(text: string, isPrimary: boolean, isCancel: boolean, callBack: () => void): AlertButton{
    const instance = new AlertButton();
    instance.text = text;
    instance.isPrimary = isPrimary;
    instance.isCancel = isCancel;
    instance.callBack = callBack;

    return instance;
  }

  /** Равны ли два объекта */
  public static IsEquals(instance1: AlertButton, instance2: AlertButton): boolean{
    if(instance1 == instance2){
      return true;
    }

    return instance1.text == instance2.text &&
      instance1.isPrimary == instance2.isPrimary &&
      instance1.isCancel == instance2.isCancel &&
      instance1.callBack == instance2.callBack;
  }

  /** Равны ли два массива */
  public static IsEqualsArray(array1: Array<AlertButton>, array2: Array<AlertButton>): boolean{
    if(array1 == array2){
      return true;
    }
    if(array1 == null || array2 == null){
      return false;
    }
    if(array1.length != array2.length){
      return false;
    }

    for (let i = 0; i < array1.length; i++){
      if(!AlertButton.IsEquals(array1[i], array2[i])){
        return false;
      }
    }

    return true;
  }

  /** Валидация объекта */
  public static Validation(button: AlertButton){
    if(!button){
      throw new Error('button is null');
    }
    if(!button.text){
      throw new Error('Текст кнопки null или undefined');
    }
  }
}
