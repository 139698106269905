import {Injectable, OnDestroy} from "@angular/core";
import {HttpClient,} from "@angular/common/http";
import { Observable, ReplaySubject} from "rxjs";
import {IDateObjForRequest, WebApi2Service} from "../web-api2.service";
import { ISubdivision, Subdivision } from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import {ITreeleestItem} from "../../../../classes/requestResults/iTreeleestItem";

@Injectable({
  providedIn: "root"
})
export class Api2SubdivisionControllerService implements OnDestroy{
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private httpClient: HttpClient,
              private webApi2Service: WebApi2Service) {

  }

  /**
   * Получить
   */
  public GetAllResponsibleForCurrentUser$(forDate: Date = null, startDate: Date = null, endDate: Date = null, subdivisionIds: number[] = null) : Observable<Array<Subdivision>>{
    return this.httpClient.post<Array<ISubdivision>>(this.webApi2Service.controllers.subdivision.actions.getAllResponsibleForCurrentUser.toString(),
    {
      startDate: startDate,
      endDate: endDate,
      forDate: forDate,
      subdivisionIds: subdivisionIds
    });
  }

  public GetForDate$(dateObj: IDateObjForRequest) : Observable<Array<Subdivision>>{
    return this.httpClient.post<Array<Subdivision>>(this.webApi2Service.controllers.subdivision.actions.getForDate.toString(),
      dateObj)
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}


