import { Injectable } from "@angular/core";
import { Cfo } from "src/app/classes/domain/POCOs/pfhd/Cfo";
import { MeasureUnit } from "src/app/classes/domain/POCOs/pfhd/MeasureUnit";
import { Nomenclature } from "src/app/classes/domain/POCOs/pfhd/Nomenclature";
import { NomenclatureType } from "src/app/classes/domain/POCOs/pfhd/NomenclatureType";
import { PkgKu } from "src/app/classes/domain/POCOs/tarification/PkgKu";
import { Seniority } from "src/app/classes/domain/POCOs/tarification/Seniority";
import { TarifStaffUnit } from "src/app/classes/domain/POCOs/tarification/TarifStaffUnit";
import { PatientMovement } from "src/app/classes/domain/POCOs/timesheet/PatientMovement";
import { IBusinessValidationResult } from "src/app/classes/requestResults/busines-validation-result";
import { LimitStaffUnit } from '../../../classes/domain/POCOs/stafflist/LimitStaffUnit';
import { LimitStaffUnitRate } from '../../../classes/domain/POCOs/stafflist/LimitStaffUnitRate';
import {PaymentForRankAndDegree} from "../../../classes/domain/POCOs/tarification/PaymentForRankAndDegree";
import {TarifEmployee} from "../../../classes/domain/POCOs/tarification/TarifEmployee";
import {Graph} from "../../../classes/domain/POCOs/timesheet_graph/Graph";
import {Table} from "../../../classes/domain/POCOs/timesheet_table/Table";
import {classBackend, getClassBackendMetadata} from "../../../decorators/classBackend/classBackend.decorator";
import {Employee} from "../../../classes/domain/POCOs/stafflist/Employee";
import {StaffUnit} from "../../../classes/domain/POCOs/stafflist/StaffUnit";
import {Subdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {Occupation} from "../../../classes/domain/POCOs/stafflist/Occupation";
import {PositionRate} from "../../../classes/domain/POCOs/stafflist/PositionRate";
import {Position} from "../../../classes/domain/POCOs/stafflist/Position";
import {WorkMode} from "../../../classes/domain/POCOs/stafflist/WorkMode";
import {Kvr} from "../../../classes/domain/POCOs/pfhd/Kvr";
import {EmployeeExtended} from "../../../classes/domain/POCOs/stafflist/EmployeeExtended";
import {RequestRow} from "../../../classes/domain/POCOs/pfhd/requests/RequestRow";
import {CfoRequest} from "../../../classes/domain/POCOs/pfhd/cfo-requests/CfoRequest";
import {CfoRequestRow} from "../../../classes/domain/POCOs/pfhd/cfo-requests/CfoRequestRow";
import {Plan} from "../../../classes/domain/POCOs/pfhd/plans/plan";
import {PlanRequest} from "../../../classes/domain/POCOs/pfhd/plans/plan-request";
import {PlanRow} from "../../../classes/domain/POCOs/pfhd/plans/plan-row";
import {PlanRowValue} from "../../../classes/domain/POCOs/pfhd/plans/plan-row-value";
import {Request} from "../../../classes/domain/POCOs/pfhd/requests/Request";

@Injectable({providedIn: 'root'})
export class ErrorsConvertationService {

  private defaultEntityNames: DefaultEntityName[] = [
    DefaultEntityName.Create(Employee, "Сотрудник"),
    DefaultEntityName.Create(StaffUnit, "Штатная единица"),
    DefaultEntityName.Create(Subdivision, "Подразделение"),
    DefaultEntityName.Create(Occupation, "Должность"),
    DefaultEntityName.Create(PositionRate, "Ставка штатной единицы"),
    DefaultEntityName.Create(Position, "Штатная позиция"),
    DefaultEntityName.Create(WorkMode, "Режим работы"),
    DefaultEntityName.Create(Kvr, "Код вида расхода"),
    DefaultEntityName.Create(EmployeeExtended, "Дополнительна информация о сотруднике"),
    DefaultEntityName.Create(Request, "Заявка"),
    DefaultEntityName.Create(RequestRow, "Позиция заявки"),
    DefaultEntityName.Create(CfoRequest, "Заявка ЦФО"),
    DefaultEntityName.Create(CfoRequestRow, "Строка заявки ЦФО"),
    DefaultEntityName.Create(Plan, "План"),
    DefaultEntityName.Create(PlanRequest, "Заявка ПЭО"),
    DefaultEntityName.Create(PlanRow, "Строка заявки ПЭО"),
    DefaultEntityName.Create(PlanRowValue, "Значение строки заявки ПЭО"),
    DefaultEntityName.Create(NomenclatureType, "Тип номенклатуры"),
    DefaultEntityName.Create(Nomenclature, "Номенклатура"),
    DefaultEntityName.Create(PatientMovement, "Движение пациентов в учреждении"),
    DefaultEntityName.Create(MeasureUnit, "Ед. измерения"),
    DefaultEntityName.Create(Cfo, "ЦФО"),
    DefaultEntityName.Create(PkgKu, "ПКГ и КУ"),
    DefaultEntityName.Create(TarifStaffUnit, "Штатная единица в Тарификации"),
    DefaultEntityName.Create(Seniority, "Стаж"),
    DefaultEntityName.Create(LimitStaffUnit, "Лимиты штатных единиц"),
    DefaultEntityName.Create(LimitStaffUnitRate, "Ставки лимитов штатных единиц"),
    DefaultEntityName.Create(PaymentForRankAndDegree, "Выплаты за наличие ученой степени, почетного звания"),
    DefaultEntityName.Create(TarifEmployee, "Сотрудник в программе Тарицикация"),
    DefaultEntityName.Create(Graph, 'График'),
    DefaultEntityName.Create(Table, 'Табель'),
  ];

  /** Получить все доступные  сопоставления Названий энтити с бекенда и названий энтити в UI*/
  public getDefaultEntityNames(): DefaultEntityName[] {
    return this.defaultEntityNames;
  }

  /** Конвертация бизнес ошибок с сервера в читаемую для UI строку ошибки.
   * При получении значений, сначала идет поиск по переданному кастомному массиву соответствий,
   * а если он не задан или там нет текого соответствия то идет поиск по массиву сопоставлений по умолчанию,
   * а если и там не найдено сопоставление то возвращается в исходном виде (как как пришло от сервера)
   * @param businessValidationResults Бизнес ошибки, полученные с сервера
   * @param customEntityNames Кастомный массив соответствий названий энтити с бекенда и названий энтити с UI.
  */
  public convertBusinessErrorsToString(businessValidationResults: IBusinessValidationResult[], customEntityNames: DefaultEntityName[] = null) {

    const messages: string[] = [];

    businessValidationResults.forEach(businessValidationResult => {
      const targetUiName = this.getEntityUIName(businessValidationResult.Target.Name, customEntityNames);

      const reasons = businessValidationResult.Reasons.map(reason => {
        return {
          reasonId: reason.Id,
          reasonUiName: this.getEntityUIName(reason.Name, customEntityNames),
          reasonMessages: reason.Results.map(rr=> rr.Message)
        }
      })

      messages.push(`${targetUiName}:\n${reasons.map(r=> `\t${r.reasonUiName}: ${r.reasonMessages.join("; ")}`).join("\n")}.`);
    } );

    return messages.join("\n\n");
  }

  /** Получить название энтити в понятном для UI названии.
   * При получении значений, сначала идет поиск по переданному кастомному массиву соответствий,
   * а если он не задан или там нет текого соответствия то идет поиск по массиву сопоставлений по умолчанию,
   * а если и там не найдено сопоставление то возвращается в исходном виде (как как пришло от сервера)
   * @param entityBackendName Название энтити на стороне бекенда, так как оно пришло от сервера
   * @param customEntityNames Кастомный массив соответствий названий энтити с бекенда и названий энтити с UI.
  */
  public getEntityUIName(entityBackendName: string, customEntityNames: DefaultEntityName[] = null): string {
    if(customEntityNames) {
      const foundedUIName = customEntityNames.find(c=> c.entityBackendName === entityBackendName)?.entityUIName;
      if(foundedUIName) {
        return foundedUIName;
      }
    }
    const foundedUIName = this.getDefaultEntityNames().find(c=> c.entityBackendName === entityBackendName)?.entityUIName;
    return foundedUIName ? foundedUIName : entityBackendName;
  }
}

export class DefaultEntityName {
  constructor(
    public entityBackendName: string,
    public entityUIName: string
  ){}

  /**
   * Функция конструктор
   * @param uiType тип в юи. Должен иметь декоратор {@link classBackend}
   * @param entityUIName псевдоним для отображения
   * @constructor
   */
  public static Create(uiType: abstract new (...args: any) => any, entityUIName: string): DefaultEntityName{
    return new DefaultEntityName(
      getClassBackendMetadata(uiType)?.name,
      entityUIName
    )
  }
}
