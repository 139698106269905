import { Component, OnInit } from '@angular/core';
import {AlertButton, AlertService, AlertType} from "../../services/alert.service";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  constructor(public alertService: AlertService) { }

  ngOnInit() {
  }

  /**
   * Обработка действий окна
   */
  public onAction(button: AlertButton){
    this.alertService.remove();
    if(button.callBack){
      button.callBack();
    }
  }

  /**
   * Обработка закрытия окна не через кнопки действия
   */
  public onClose(){
    if(!this.alertService.currentOption.supportedCancel){
      this.alertService.remove();
      return;
    }
    const cancelButtons = this.alertService.currentOption.buttons == null ?
      [] :
      this.alertService.currentOption.buttons.filter(button => {
        return button.isCancel;
      })

    if(cancelButtons.length == 0){
      this.alertService.remove();
      return;
    }

    this.onAction(cancelButtons[0]);
  }
}
