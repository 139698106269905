import {ICovidLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog";
import {ICovidLog2} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog2";
import {IVichLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/VichLog";
import {ITuberLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/TuberLog";
import {IExcludeMilkLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/ExcludeMilkLog";
import {
  AsHasStateChangeObj
} from "../../../../../../../../../src/app/classes/as-has-state-change-obj/as-has-state-change-obj";
import {
  IGraphGridServerDataSource_ServerDataItem
} from "../graph-grid-server-data-sources/graph-grid-server-data-source.classes";
import {GraphDay, IGraphDay} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/GraphDay";
import {ArrayExpanded, ArrayHelper} from "../../../../../../../../../src/app/helpers/arrayHelper";
import {ITimeInterval} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";
import {IDayDeviation} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {DateMap} from "../../../../../../../../../src/app/classes/maps/date-maps/date-map";

/** Редактируемая часть ячейки дня графика */
export class GraphGrid_EditablePartGraphDayCell implements Pick<IGraphDay, 'dayDeviationCustomValue' | 'substractLunchFlag' | 'flexDinner'> {

  /**
   * Конструктор
   * @param timeInterval временной интервал
   * @param dayDeviation отклонение
   * @param dayDeviationCustomValue поле {@link IDayDeviation.dayDeviationCustomValue}
   * @param substractLunchFlag поле {@link IDayDeviation.substractLunchFlag}
   * @param flexDinner поле {@link IDayDeviation.flexDinner}
   */
  constructor(public timeInterval: Pick<ITimeInterval, 'id' | 'startInterval' | 'endInterval'> | undefined,
              public dayDeviation: Pick<IDayDeviation, 'id' | 'name' | 'shortName' | 'color'> | undefined,
              public dayDeviationCustomValue: number,
              public substractLunchFlag: boolean,
              public flexDinner: number) {

  }

  private static _graphDayDefault = GraphDay.CreateDefault(undefined, undefined, undefined);

  /** Создать по умолчанию */
  public static CreateDefault() {
    return new GraphGrid_EditablePartGraphDayCell(
      undefined,
      undefined,
      this._graphDayDefault.dayDeviationCustomValue,
      this._graphDayDefault.substractLunchFlag,
      this._graphDayDefault.flexDinner);
  }

  /** Копировать */
  public static Copy(source: GraphGrid_EditablePartGraphDayCell): GraphGrid_EditablePartGraphDayCell {
    return !source
      ? source
      : new GraphGrid_EditablePartGraphDayCell(
        source.timeInterval,
        source.dayDeviation,
        source.dayDeviationCustomValue,
        source.substractLunchFlag,
        source.flexDinner);
  }

  /** Сравнить */
  public static IsEquals(obj1: GraphGrid_EditablePartGraphDayCell, obj2: GraphGrid_EditablePartGraphDayCell): boolean {
    return obj1?.timeInterval?.id === obj2?.timeInterval?.id
      && obj1?.dayDeviation?.id === obj2?.dayDeviation?.id
      && obj1?.dayDeviationCustomValue === obj2?.dayDeviationCustomValue
      && obj1?.substractLunchFlag === obj2?.substractLunchFlag
      && obj1?.flexDinner === obj2?.flexDinner;
  }
}

/** Тип содержащий все Log относящиеся к ячейке дня графика */
type GraphGrid_LogGraphDayCellType = {
  covidLogs: ICovidLog[],
  covidLog2s: ICovidLog2[],
  vichLogs: IVichLog[],
  tuberLogs: ITuberLog[],
  excludeMilkLog: IExcludeMilkLog,
}

/** Ячейка дня исполнения должности */
export class GraphGrid_GraphDayCell {
  public readonly graphDay: AsHasStateChangeObj<GraphGrid_EditablePartGraphDayCell>;

  constructor(public readonly graph: IGraphGridServerDataSource_ServerDataItem['graph'],
              public readonly day: IGraphGridServerDataSource_ServerDataItem['days'][0],
              public readonly staffUnitId: number,
              public log: GraphGrid_LogGraphDayCellType,
              graphDayOrigin: GraphGrid_EditablePartGraphDayCell,
              graphDayCurrent: GraphGrid_EditablePartGraphDayCell) {
    this.graphDay = new AsHasStateChangeObj(graphDayOrigin, graphDayCurrent, GraphGrid_EditablePartGraphDayCell.Copy, GraphGrid_EditablePartGraphDayCell.IsEquals);
  }

  /**
   * Создать для исполнения должности
   * @param graph график
   * @param days дни месяца
   * @param staffUnitId идентификатор исполнения должности
   * @param covidLogMap словарь относящийся только к этому исполнению должности
   * @param covidLog2Map словарь относящийся только к этому исполнению должности
   * @param vichLogMap словарь относящийся только к этому исполнению должности
   * @param tuberLogMap словарь относящийся только к этому исполнению должности
   * @param excludeMilkLogMap словарь относящийся только к этому исполнению должности
   * @param graphDayMap заполненные дни. словарь относящийся только к этому исполнению должности
   * @param changeMap измененные дни. словарь относящийся только к этому исполнению должности
   * @constructor
   */
  public static CreateForStaffUnit(graph: GraphGrid_GraphDayCell['graph'],
                                   days: GraphGrid_GraphDayCell['day'][],
                                   staffUnitId: number,
                                   covidLogMap: DateMap<ICovidLog[]>,
                                   covidLog2Map: DateMap<ICovidLog2[]>,
                                   vichLogMap: DateMap<IVichLog[]>,
                                   tuberLogMap: DateMap<ITuberLog[]>,
                                   excludeMilkLogMap: DateMap<IExcludeMilkLog>,
                                   graphDayMap: DateMap<GraphGrid_EditablePartGraphDayCell>,
                                   changeMap: DateMap<GraphGrid_EditablePartGraphDayCell>) {

    return days
      .map(day => {
        const graphDayOrigin = graphDayMap.get(day.date) ?? GraphGrid_EditablePartGraphDayCell.CreateDefault();
        const graphDayCurrent = changeMap.get(day.date) ?? GraphGrid_EditablePartGraphDayCell.Copy(graphDayOrigin);

        return new GraphGrid_GraphDayCell(
          graph,
          day,
          staffUnitId,
          {
            covidLogs: covidLogMap.get(day.date) ?? [],
            covidLog2s: covidLog2Map.get(day.date) ?? [],
            vichLogs: vichLogMap.get(day.date) ?? [],
            tuberLogs: tuberLogMap.get(day.date) ?? [],
            excludeMilkLog: excludeMilkLogMap.get(day.date)
          },
          graphDayOrigin,
          graphDayCurrent,
        );
      })
  }

  /**
   * Создать для исполнения должности
   * @param graph график
   * @param days дни месяца
   * @param staffUnitId идентификатор исполнения должности
   * @param covidLogs все относящиеся к текущему исполнению должности
   * @param covidLog2s все относящиеся к текущему исполнению должности
   * @param vichLogs все относящиеся к текущему исполнению должности
   * @param tuberLogs все относящиеся к текущему исполнению должности
   * @param excludeMilkLogs все относящиеся к текущему исполнению должности
   * @param graphDays заполненные дни. все относящиеся к текущему исполнению должности
   * @param changes измененные дни. все относящиеся к текущему исполнению должности
   * @constructor
   */
  public static CreateForStaffUnit2(graph: GraphGrid_GraphDayCell['graph'],
                                    days: GraphGrid_GraphDayCell['day'][],
                                    staffUnitId: number,
                                    covidLogs: ICovidLog[],
                                    covidLog2s: ICovidLog2[],
                                    vichLogs: IVichLog[],
                                    tuberLogs: ITuberLog[],
                                    excludeMilkLogs: IExcludeMilkLog[],
                                    graphDays: Array<Pick<IGraphDay, 'date'> & GraphGrid_EditablePartGraphDayCell>,
                                    changes: Array<Pick<IGraphDay, 'date'> & GraphGrid_EditablePartGraphDayCell>) {
    return this.CreateForStaffUnit(
      graph,
      days,
      staffUnitId,
      new ArrayExpanded(covidLogs)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => x.values),
      new ArrayExpanded(covidLog2s)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => x.values),
      new ArrayExpanded(vichLogs)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => x.values),
      new ArrayExpanded(tuberLogs)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => x.values),
      new ArrayExpanded(excludeMilkLogs)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => ArrayHelper.singleOrDefault(x.values)),
      new ArrayExpanded(graphDays)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => ArrayHelper.singleOrDefault(x.values)),
      new ArrayExpanded(changes)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => ArrayHelper.singleOrDefault(x.values))
    )
  }
}
