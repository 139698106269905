import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {IPrintFormSettingsComponentBase} from "../abstractions/IPrintFormSettingsComponentBase";
import {PrintFormFromtillDatesSettings} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {mergeWith, takeUntil} from "rxjs/operators";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {CheckedSubdivisionsValidator} from "../report-settings-form-validators";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {trace} from "../../../modules/trace/operators/trace";
import * as moment from "moment";

@Component({
  selector: 'app-print-form-fromtill-dates-settings',
  templateUrl: './print-form-fromtill-dates-settings.component.html',
  styleUrls: ['./print-form-fromtill-dates-settings.component.css']
})
@traceClass('PrintFormFromtillDatesSettingsComponent')
export class PrintFormFromtillDatesSettingsComponent implements OnInit, OnDestroy, IPrintFormSettingsComponentBase {

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input()
  public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase) {
    this._subdivisionDataSourceService = value;
  }
  @Input() public selection: any;

  private _checkableSettings: CheckableSettings;
  // Настройки checkBox TreeView списка подразделений
  @Input()
  public get checkableSettings(): CheckableSettings {
    return this._checkableSettings;
  }
  public set checkableSettings(value: CheckableSettings){
    this._checkableSettings = value;
  }

  /** Функция формирования отображаемой в treeView строки из объекта ISubdivision */
  private _displayTextFn: (subdivision: ISubdivision) => string;
  @Input() public get displayTextFn(){ return this._displayTextFn; }
  public set displayTextFn(value){ this._displayTextFn = value; }

  /** Развернуть (отобразить) все элементы списка подразделений при отображении формы настроек */
  @Input() public expandAllSubdivisions: boolean;

  public form: UntypedFormGroup;

  @Output() cancel$: EventEmitter<void> = new EventEmitter<void>();
  @Output() print$: EventEmitter<PrintFormFromtillDatesSettings> = new EventEmitter<PrintFormFromtillDatesSettings>();
  @Output() datesChange$: EventEmitter<{ startDate: Date, endDate: Date }> = new EventEmitter<{startDate: Date; endDate: Date}>();
  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private readonly traceService: TracerServiceBase) { }

  @traceFunc()
  public ngOnInit(): void {
    this.formInit();
  }

  /** Установить значение параметра выбранных подразделений на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  @traceFunc()
  public setCheckedSubdivisionsValue(el: number[]){
    this.form.controls.checkedSubdivisions.setValue(el);
  }

  /** Установить значение параметра "Выбрать все подразделения" на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  @traceFunc()
  public setAllSubdivisionsFlagValue(el: boolean){
    this.form.controls.allSubdivisionsFlag.setValue(el);
  }

  /** Событие нажатия на кнопку подтверждения выбранных/измененных настроек */
  @traceFunc()
  public onClickOk() {
    this.print$.next(new PrintFormFromtillDatesSettings(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.fromDate.value,
      this.form.controls.tillDate.value,
      this.form.controls.allSubdivisionsFlag.value,
      this.form.controls.checkedSubdivisions.value));
  }

  /** Событие нажатия на кнопку Отмена */
  @traceFunc()
  public onClickCancel() {
    this.cancel$.next();
  }

  /** методы валидации вводимых дат (начало периода не должно быть больше окончания и наоборот) */
  public startDateValidator = (date: Date) => !(date <= this.form.controls.tillDate.value);
  public endDateValidator = (date: Date) => !(date >= this.form.controls.fromDate.value);

  @traceFunc()
  public ngOnDestroy(): void {
    this.print$.complete();
    this.cancel$.complete();
    this.datesChange$.complete();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  /** Инициализация формы */
  @traceFunc()
  private formInit() {
    const currentDate = moment(new Date()).startOf('day').toDate();
    const startDate = moment(currentDate).startOf('month').toDate();
    this.form = new UntypedFormGroup({
      fromDate: new FormControl<Date>(startDate, {validators: Validators.required, updateOn: 'blur'}),
      tillDate: new FormControl<Date>(currentDate, {validators: Validators.required, updateOn: 'blur'}),
      allSubdivisionsFlag: new UntypedFormControl(false),
      checkedSubdivisions: new UntypedFormControl([], CheckedSubdivisionsValidator()),
    });

    this.form.controls.fromDate.valueChanges.pipe(
      mergeWith(this.form.controls.tillDate.valueChanges),
      trace(this.traceService),
      takeUntil(this.unsubscribe$))
      .subscribe(_ => this.datesChange$.next(
        {
          startDate: this.form.controls.fromDate.value,
          endDate: this.form.controls.tillDate.value
        }));

    this.form.controls.tillDate.markAsTouched();
    this.form.controls.fromDate.markAsTouched();
    //Необходимо, чтоб без изменений даты загрузить список подразделений
    this.form.controls.fromDate.updateValueAndValidity({emitEvent: true});
  }
}
