import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import { map } from 'rxjs/operators';
import {IPosition} from "src/app/classes/domain/POCOs/stafflist/Position";
import { IPositionRate } from "src/app/classes/domain/POCOs/stafflist/PositionRate";
import {IFinancingSource} from "src/app/classes/domain/POCOs/stafflist/FinancingSource";
import {DropDownItem} from "../../../../classes/requestResults/iDropDownItem";
import { ISubdivision } from "src/app/classes/domain/POCOs/stafflist/Subdivision";

/**
 * Сервис работы с контроллером StaffPositionsControlController
 */
@Injectable({
  providedIn: "root"
})
export class Api1StaffPositionsControlControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  /**
   * Получить StaffPosition
   * @param subdivisionOwnerId
   * @param date;
   */
  public getForDate$(subdivisionOwnerId: number, date: Date = null, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<StaffPositionGridResult>{
    return this.httpClient
      .post<IStaffPositionsResponse>(this.webApi1Service.controllers.staffPositionsControl.actions.getForDate.toString(),
        {
          SubdivisionOwnerId: subdivisionOwnerId,
          ToDate: date,
          StartDate: startDate,
          EndDate: endDate,
          OwnerIds: ownerIds
        }).pipe(map(data => new StaffPositionGridResult(data.financingSources, data.staffPositions.map(staffPosition => {

          const workMode = data.workModes.find(f => f.id === staffPosition.workModeId);
          const positionTitle = data.occupations.find(f => f.id === staffPosition.occupationId)?.title;
          const reportPeriodTitle = data.reportPeriods.find(f => f.id === staffPosition.reportPeriodId)?.title;

          const subdivision = data.subdivisions.find(f=> f.id === staffPosition.subdivisionId);

          const staffPositionRates = data.financingSources.map(fs=>
            {
              const rate = staffPosition.rates.find(spr=> spr.financingSourceId === fs.id);
              return new StaffPositionRateGridItem(
                fs.id,
                fs.shortName,
                rate?.rate,
                rate?.rateId
              );
          });

          const staffPositionFreeRates = staffPosition.freeRates.map(fr=>
            {
              const financingSource =  data.financingSources.find(spr=> spr.id === fr.financingSourceId);
              return new StaffPositionFreeRateGridItem(
                financingSource.id,
                financingSource.shortName,
                fr.rate
              );
          });

            return new StaffPositionGridItem(
              staffPosition.id,
              staffPosition.startDate,
              staffPosition.endDate,
              staffPosition.comment,
              positionTitle,
              staffPosition.occupationId,
              subdivision,
              workMode?.title,
              staffPosition.workModeId,
              workMode.workModeTypeId,
              reportPeriodTitle,
              staffPosition.reportPeriodId,
              staffPositionRates,
              staffPositionFreeRates,
              staffPosition.dinnerHourDuration,
              staffPosition.subtractLunchTimeFromWorkingHoursFlag,
              staffPosition.includeScheduleForPaidServiceFlag,
              staffPosition.leaderFlag,
              staffPosition.sortOrder,
              staffPosition.timestamp,
              staffPositionRates.reduce((sum, current) => sum + (current.rate ?? 0), 0),
              staffPositionFreeRates.reduce((sum, current) => sum + (current.rate ?? 0), 0),
              staffPosition.staffUnitsIds
            );
        }))));
  }

  /** Получить список должностей (Positions) для определенного продразделения для отображения в выпадающих списках выбора должностей */
  public getPositionDropDownList$(subdivisionOwnerId: number, date: Date = null,
                                  startDate: Date = null, endDate: Date = null,
                                  ownerIds: number[] = null) : Observable<DropDownItem[]> {
    return this.httpClient.post<DropDownItem[]>(this.webApi1Service.controllers.staffPositionsControl.actions.getPositionDropDownList.toString(),
      {
        SubdivisionOwnerId: subdivisionOwnerId,
        ToDate: date,
        StartDate: startDate,
        EndDate: endDate,
        OwnerIds: ownerIds
      });
  }

}


interface IStaffPositionsResponse
{
  subdivisions: Array<Pick<ISubdivision, 'id' | 'startDate' | 'endDate'>>;
  staffPositions: Array<IStaffPositionDTO>;
  workModes: Array<IWorkModeDTO>;
  occupations: Array<IOccupationDTO>;
  reportPeriods: Array<IReportPeriodDTO>;
  financingSources: Array<IFinancingSourceDTO>;
}

interface IStaffPositionDTO extends Pick<IPosition, 'id' | 'startDate' | 'endDate' | 'comment' | 'subdivisionId'
  | 'occupationId' | 'workModeId' | 'reportPeriodId' | 'leaderFlag' | 'dinnerHourDuration' | 'subtractLunchTimeFromWorkingHoursFlag'
  | 'includeScheduleForPaidServiceFlag' | 'sortOrder' | 'timestamp'> {

  workModeTypeId: number;

  rates: Array<IStaffPositionRateDTO>;
  freeRates: Array<Pick<IPositionRate, 'rate' | 'financingSourceId'>>;
  staffUnitsIds: number[];
  timestamp:[];
}

interface IIdTitle {
  id: number;
  title: string;
}

interface IWorkModeDTO extends IIdTitle { workModeTypeId: number; }
interface IOccupationDTO extends IIdTitle { }
interface IReportPeriodDTO extends IIdTitle { }

interface IFinancingSourceDTO extends Pick<IFinancingSource,'id' | 'shortName'>{
  id: number;
  shortName: string;
}


interface IStaffPositionRateDTO extends Pick<IPositionRate, 'rate' | 'financingSourceId' > {
  rateId: number;
}




export class StaffPositionGridResult {
  constructor(
    public financingSources: Array<Pick<IFinancingSource, 'id' | 'shortName'>>,
    public items: Array<StaffPositionGridItem>
    ) {}
}

export class StaffPositionGridItem implements Pick<IPosition, 'id' | 'startDate' | 'endDate' | 'comment' | 'occupationId'
  | 'workModeId' | 'reportPeriodId' | 'dinnerHourDuration' | 'subtractLunchTimeFromWorkingHoursFlag'
  | 'includeScheduleForPaidServiceFlag' | 'leaderFlag' | 'sortOrder' | 'timestamp'>{
  constructor(
    public id: number,
    public startDate: Date,
    public endDate: Date,
    public comment: string,
    public occupationTitle: string,
    public occupationId: number,
    public subdivision: Pick<ISubdivision, 'id' | 'startDate' | 'endDate'>,
    public workModeTitle: string,
    /** {@Link WorkMode.Id} */
    public workModeId: number,
    /** {@Link WorkModeType.Id} */
    public workModeTypeId: number,
    public reportPeriodTitle: string,
    public reportPeriodId: number,
    public staffPositionRates: Array<StaffPositionRateGridItem>,
    public staffPositionFreeRates: Array<StaffPositionFreeRateGridItem>,
    public dinnerHourDuration: number,
    public subtractLunchTimeFromWorkingHoursFlag: boolean,
    public includeScheduleForPaidServiceFlag: boolean,
    public leaderFlag: boolean,
    public sortOrder: number,
    public timestamp:[],
    public ratesSumm: number,
    public freeRatesSumm: number,
    public staffUnitsIds: number[]
  ) {}
}

export type StaffPositionGridItemType =  keyof StaffPositionGridItem;



export class StaffPositionRateGridItem implements Pick<IPositionRate, "rate" | "financingSourceId"> {
  constructor(
    public financingSourceId: number,
    public financingSourceShortName: string,
    public rate: number,
    public rateId: number
     ) {  }
}

export class StaffPositionFreeRateGridItem implements Pick<IPositionRate, "rate" | "financingSourceId">{
  constructor(
    public financingSourceId: number,
    public financingSourceShortName: string,
    public rate: number,
  ) {}


}
