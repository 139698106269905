import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {
  GraphCellEditService,
  GraphEditService_SelectedEvent
} from "../graph-table-workspace/graph-grid/secvices/graph-cell-edit.service";
import {DayDeviation} from "../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {
  StaffUnitForProxyList
} from "../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service";
import {LoadingIndicatorService} from "../../../../../../src/app/services/loading-indicator.service";
import {AlertService} from "../../../../../../src/app/services/alert.service";
import {KendoNotificationService} from "../../../../../../src/app/services/kendo-notification.service";
import {debounceTime, distinctUntilChanged, map, take, takeUntil} from "rxjs/operators";
import * as moment from "moment";
import {StaffUnit} from "../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";

import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {
  Api1GraphControlControllerService
} from "../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {LableAndTitle_Settings} from "../../Classes/LableAndTitle_Settings";
import {
  Api1StaffListSettingsControllerService
} from "../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-list-settings-controller.service";
import {
  DisplayErrorsService
} from "../../../../../../src/app/components/display-errors/services/display-errors.service";
import {TracerServiceBase} from "../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../src/app/modules/trace/decorators/func.decorator";
import {trace} from "../../../../../../src/app/modules/trace/operators/trace";

@Component({
  selector: 'app-edit-proxy',
  templateUrl: './edit-proxy.component.html',
  styleUrls: ['./edit-proxy.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('EditProxyComponent')
export class EditProxyComponent implements OnInit, OnDestroy {
  selectedCells: GraphEditService_SelectedEvent;
  // /** Месяц на который добавляется Proxy */
  // @Input() month: Date;
  /** Настройки компонента при инициализации */
  @Input() settings: EditProxyComponent_Settings = null;
  /** Событие добавления Proxy */
  @Output() save$: EventEmitter<EditProxyComponent_SaveEvent> = new EventEmitter<EditProxyComponent_SaveEvent>();
  /** Событие отмены добавления Proxy */
  @Output() cancel$: EventEmitter<void> = new EventEmitter<void>();

  private unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /** Форма */
  public form: UntypedFormGroup;

  //Даты выделенного диаппазона ячеек
  public minSelectedDate: Date | null;
  public maxSelectedDate: Date | null;
  /** Минимальная дата, отображаемая в DatePicker-е выбора периода замещения/совмещения */
  public minShownDate: Date;
  /** Максимальная дата, отображаемая в DatePicker-е выбора периода замещения/совмещения */
  public maxShownDate: Date;
  /**Первый день месяца */
  public firstDayOfCurrentMonth: Date;
  /** Последний день месяца */
  public lastDayOfCurrentMonth: Date;
  /** Максимально возможный размер ставки */
  public maxRate: number;
  /** Максимально возможный размер процента */
  public maxPercent: number;
  /** Выбранная штатная единица заместителя */
  public selectedProxy: StaffUnitForProxyList;
  /** Размер выплаты в процентах для combination*/
  public percent: number;
  /** Показать диалог причины замещения (выбор DayDeviation) */
  public openDayDeviationDialog = false;
  /** Показать диалог выбора заместителя */
  public openStaffUnitDialog = false;

  public rateStep: number;

  constructor(
    private graphControlService: Api1GraphControlControllerService,
    private loadingIndicatorService: LoadingIndicatorService,
    private alertService: AlertService,
    private graphEditService: GraphCellEditService,
    private kendoNotificationService: KendoNotificationService,
    private settingsService: Api1StaffListSettingsControllerService,
    private readonly displayErrorsService: DisplayErrorsService,
    private readonly traceService: TracerServiceBase,
    public readonly changeDetectorRef: ChangeDetectorRef,
  ) { }

  public ngOnInit() {
    if (!this.settings) {
      throw new Error('@Input settings НЕ передан')
    }
    this.settings.validation();

    this.settingsService.rateStepWithCash$.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(value => {
      this.rateStep = value;
      this.changeDetectorRef.detectChanges();
    });
    const monthAsMoment = moment(this.settings.month);
    const firstAsMoment = moment({ year: monthAsMoment.year(), month: monthAsMoment.month() });
    this.firstDayOfCurrentMonth = firstAsMoment.toDate();
    this.lastDayOfCurrentMonth = firstAsMoment.add(firstAsMoment.daysInMonth() - 1, 'day').toDate();
    this.graphEditService.selected$.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(value => {
      this.selectedCells = value;
    });

    switch (this.settings.type) {
      case 'add':
        this.percent = 0;
        this.initDialogForAdd();
        break;
      case 'edit':
        this.percent = this.settings.proxyStaffUnit.percent;
        this.initDialogForEdit();
        break;
    }

    const proxyRateControl = new UntypedFormControl(this.settings.proxyStaffUnit.rate, [Validators.required]);
    const basicEmployeeFullNameControl = new UntypedFormControl(this.settings.parentStaffUnit.employeeFullName, [Validators.required]);
    const proxyEmployeeFullNameControl = new UntypedFormControl(this.settings.proxyStaffUnit?.employeeFullName ?? '', [Validators.required]);
    const proxyStartDateControl = new UntypedFormControl(this.settings.proxyStaffUnit.start, [Validators.required]);
    const proxyEndDateControl = new UntypedFormControl(this.settings.proxyStaffUnit.end, [Validators.required]);
    const selectedDayDeviationControl = new UntypedFormControl(this.settings.selectedDayDeviation?.name ?? '');
    const commentControl = new UntypedFormControl(this.settings.proxyStaffUnit.comment);
    const precentControl = new UntypedFormControl(this.settings.proxyStaffUnit.percent);
    const proxyHoursControl = new UntypedFormControl();
    const proxyDaysControl = new UntypedFormControl();
    const milkControl = new UntypedFormControl(this.settings.proxyStaffUnit.milk, [Validators.required]);

    //--Добавляем валидаторы позже, по причине инициализации второго контрла в мамент добавления валидатора
    proxyStartDateControl.addValidators((control) => this.validatorIO(control, proxyEndDateControl))
    proxyEndDateControl.addValidators((control) => this.validatorIO(proxyStartDateControl, control))
    //---

    this.form = new UntypedFormGroup({
      basicEmployeeFullName: basicEmployeeFullNameControl,
      proxyEmployeeFullName: proxyEmployeeFullNameControl,
      proxyRate: proxyRateControl,
      proxyStartDate: proxyStartDateControl,
      proxyEndDate: proxyEndDateControl,
      selectedDayDeviation: selectedDayDeviationControl,
      comment: commentControl,
      percent: precentControl,
      proxyHours: proxyHoursControl,
      proxyDays: proxyDaysControl,
      milk: milkControl,
    });

    this.form.valueChanges.pipe(
      trace(this.traceService),
      map(x => { return { rate: x.proxyRate, start: x.proxyStartDate, end: x.proxyEndDate } }),
      distinctUntilChanged((prev, curr) =>
        prev.rate == curr.rate && prev.start == curr.start && prev.end == curr.end),
      debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.recalculatePositionNorma(value);
        this.setProxyDaysFormValue(value);
      });

    if(this.settings.combinationType != "proxy"){
      this.form.controls.percent.addValidators(Validators.required);
    }

    this.form.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  /** Пересчитать норму для должности выбранного proxy */
  private recalculatePositionNorma(dateRateParams) {
    this.graphControlService.calculateNormaForPosition$(this.settings.redactionId, this.settings.parentStaffUnit.positionId,
      dateRateParams.rate, dateRateParams.start, dateRateParams.end)
      .pipe(trace(this.traceService), take(1), takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.form.controls.proxyHours.setValue(value);
        this.changeDetectorRef.detectChanges();
      });
  }

  /** Установить значение поля формы "Количество календарных дней" */
  @traceFunc()
  private setProxyDaysFormValue(datesParams){
    if(datesParams.start && datesParams.end){
      let days = moment(datesParams.end).diff(datesParams.start, 'days') + 1;
      this.form.controls.proxyDays.setValue(days);
    }
    else {
      this.form.controls.proxyDays.setValue(undefined);
    }

    this.changeDetectorRef.detectChanges();
  }

  /* Валидатор отклонения ИО. Выбранный диаппазон не должен содержать отклонение ИО */
  private validatorIO(controlStart: AbstractControl, controlEnd: AbstractControl) {
    if (!this.settings.excludeDates) {
      return null;
    }

    const excludes = this.settings.excludeDates
      .filter(x => +x >= +controlStart.value && +x <= controlEnd.value)
      .map(x => x.getDate())
      .sort((a, b) => a > b ? 1 : -1);

    return excludes.length == 0 ? null : { io: `ИО - ${excludes.join(', ')} числа` }
  }

  /*Инициализация диалогового окна для редактирования proxy*/
  private initDialogForEdit(): void {
    this.selectedProxy = new StaffUnitForProxyList(
      this.settings.proxyStaffUnit.ownerId,
      this.settings.employeeOwnerId,
      '',
      '',
      this.settings.proxyStaffUnit.employeeFullName,
      this.settings.proxyStaffUnit.start,
      this.settings.proxyStaffUnit.end,
      this.settings.proxyStaffUnit.rate,
      '',
      this.settings.proxyStaffUnit.staffUnitTypeId,
      this.settings.parentStaffUnit.ownerId,
      this.settings.proxyStaffUnit.timestamp
    );
    this.maxRate = this.getMaxRate();
    this.maxPercent = this.getMaxPercent();
    this.maxShownDate = this.GetMinDate([this.settings.parentStaffUnit.end, this.lastDayOfCurrentMonth]);
    this.minShownDate = this.GetMaxDate([this.settings.parentStaffUnit.start, this.firstDayOfCurrentMonth]);
  }

  /*Инициализация диалогового окна для добавления proxy*/
  private initDialogForAdd(): void {
    this.maxRate = this.getMaxRate();
    this.maxPercent = this.getMaxPercent();

    this.minSelectedDate = this.settings.selectedPeriodStart;
    this.maxSelectedDate = this.settings.selectedPeriodEnd;
    this.settings.proxyStaffUnit = new EditProxyComponent_ProxyStaffUnit(
      0,
      '',
      this.GetMaxDate([this.settings.parentStaffUnit.start, this.minSelectedDate]),
      this.GetMinDate([this.settings.parentStaffUnit.end, this.maxSelectedDate]),
      0.25,
      0,
      false,
      '',
      [],
      0);

    this.minShownDate = this.GetMaxDate([this.settings.parentStaffUnit.start, this.firstDayOfCurrentMonth]);
    this.maxShownDate = this.GetMinDate([this.settings.parentStaffUnit.end, this.lastDayOfCurrentMonth]);
  }

  /** Сравнить две даты */
  private dateComparer(date1: Date, date2: Date): number {
    return date1 > date2 ? 1 : date1 < date2 ? -1 : 0;
  }

  /**
   * Получить минимальную дату из массива дат
   * даты == null в расчет не берутся
   */
  public GetMinDate = (dateArray: Array<Date>) => new Date(
    dateArray.filter(d => !!d)
      .sort((d1, d2) => this.dateComparer(d1, d2))[0]);

  /**
   * Получить максимальную дату из массива
   * даты == null в расчет не берутся
   */
  public GetMaxDate = (dateArray: Array<Date>) => new Date(
    dateArray.filter(d => !!d)
      .sort((d1, d2) => this.dateComparer(d1, d2)).reverse()[0]);

  /** методы валидации вводимых дат (начало периода не должно быть больше окончания и наоборот) */
  public startDateValidator = (date: Date) => !(date <= this.form.controls.proxyEndDate.value);
  public endDateValidator = (date: Date) => !(date >= this.form.controls.proxyStartDate.value);

  /** Обработка события закрытия диалогового окна */
  public onCancel() {
    this.settings.selectedDayDeviation = null;
    this.cancel$.emit();
    this.cancel$.complete();
  }

  /** Обработка нажатия на Сохранить */
  public onSave() {
    switch (this.settings.type) {
      case "add":
        this.saveAdd();
        return;
      case "edit":
        this.saveEdit();
        return;
      default:
        throw new Error('OutOfRange');
    }
  }

  /** Событие отмены выбора причины замещения */
  public onDayDevisionSelectingCancel() {
    this.settings.selectedDayDeviation = null;
    this.openDayDeviationDialog = false;
    this.changeDetectorRef.detectChanges();
  }

  /** Событие выбора причины замещения */
  public onDayDevisionSelected($event: DayDeviation) {
    this.form.controls.selectedDayDeviation.setValue($event.name);
    this.form.controls.selectedDayDeviation.markAsDirty();
    this.settings.selectedDayDeviation = $event;
    this.openDayDeviationDialog = false;
    this.changeDetectorRef.detectChanges();
  }

  /** Открытие окна выбора заместителя */
  public openPoxyStaffUnitChoiceWindow() {
    this.openStaffUnitDialog = true;
    this.changeDetectorRef.detectChanges();
  }

  /** Событие отмены выбора StaffUnit */
  public onProxySelectingCancel() {
    this.selectedProxy = null;
    this.openStaffUnitDialog = false;
    this.changeDetectorRef.detectChanges();
  }

  /** Событие выделения строки списка StaffUnits */
  public onProxySelected($event: StaffUnitForProxyList) {
    this.selectedProxy = $event;
    this.minShownDate = this.GetMaxDate([
      this.settings.parentStaffUnit.start,
      this.firstDayOfCurrentMonth,
      this.selectedProxy.startDate ?
        this.selectedProxy.startDate :
        undefined]);
    this.maxShownDate = this.GetMinDate([
      this.settings.parentStaffUnit.end,
      this.lastDayOfCurrentMonth,
      this.selectedProxy.endDate ?
        this.selectedProxy.endDate :
        undefined]);

    this.form.controls.proxyEmployeeFullName.setValue($event.employeeFullName);
    this.form.controls.proxyEmployeeFullName.markAsDirty();
    this.openStaffUnitDialog = false;
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private saveAdd() {
    if (this.settings.type != 'add') { throw new Error('Для добавления необходим режим "add"'); }
    //Добавляемый StaffUnit (Proxy или Combination)
    let addedStaffUnit$: Observable<StaffUnit>;
    let combinationTypeName : string;

    switch (this.settings.combinationType) {
      case 'proxy':
        addedStaffUnit$ = this.graphControlService.addProxy$(
          this.settings.parentStaffUnit.ownerId,
          this.selectedProxy.employeeId,
          this.form.controls.proxyStartDate.value,
          this.form.controls.proxyEndDate.value,
          this.form.controls.proxyRate.value,
          this.form.controls.comment.value,
          'Moonlighter',
          this.form.controls.milk.value
        );
        combinationTypeName = 'замещения';
        break;
      case 'combination':
        addedStaffUnit$ = this.graphControlService.addCombinstionBusy$(
          this.settings.parentStaffUnit.ownerId,
          this.selectedProxy.employeeId,
          this.form.controls.proxyStartDate.value,
          this.form.controls.proxyEndDate.value,
          this.form.controls.proxyRate.value,
          this.form.controls.percent.value,
          this.form.controls.comment.value,
          'Combination'
        );
        combinationTypeName = 'совмещения';
        break;
      case "uvor":
        addedStaffUnit$ = this.graphControlService.addUvorBusy$(
          this.settings.parentStaffUnit.ownerId,
          this.selectedProxy.employeeId,
          this.form.controls.proxyStartDate.value,
          this.form.controls.proxyEndDate.value,
          this.form.controls.proxyRate.value,
          this.form.controls.percent.value,
          this.form.controls.comment.value,
          'Uvor'
        );
        combinationTypeName = 'УВОР';
        break;
      default: throw Error("Передан невалидный тип совмещения");
    }

    this.loadingIndicatorService.addToObservable(
      `Назначение ${combinationTypeName}`,
      addedStaffUnit$
    ).pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(
      {
        next: value => {
          this.save$.emit({ type: this.settings.type, staffUnit: value, deviation: this.settings.selectedDayDeviation });
          this.kendoNotificationService.showSuccess({ content: `${this.settings.lableAndTitle_Settings.subj} назначен` });
          this.changeDetectorRef.detectChanges();
        },
        error: error => {
          this.displayErrorsService.handleError(error);
        }
      }
    );
  }

  private saveEdit() {
    if (this.settings.type != 'edit') { throw new Error('Для редактирования необходим режим "edit"'); }

    let editedStaffUnit$: Observable<StaffUnit>;
    switch (this.settings.combinationType) {
      case 'proxy':
        editedStaffUnit$ = this.graphControlService.editProxy$(
          this.settings.redactionId,
          this.selectedProxy.ownerId,
          this.form.controls.proxyStartDate.value,
          this.form.controls.proxyEndDate.value,
          this.form.controls.proxyRate.value,
          this.form.controls.percent.value,
          this.form.controls.milk.value,
          this.form.controls.comment.value,
          this.selectedProxy.timestamp
        );
        break;
      case 'combination':
        editedStaffUnit$ = this.graphControlService.editCombinationBusy$(
          this.settings.redactionId,
          this.selectedProxy.ownerId,
          this.form.controls.proxyStartDate.value,
          this.form.controls.proxyEndDate.value,
          this.form.controls.proxyRate.value,
          this.form.controls.percent.value,
          this.form.controls.comment.value,
          this.selectedProxy.timestamp
        );
        break;
      case "uvor":
        editedStaffUnit$ = this.graphControlService.editUvorBusy$(
          this.settings.redactionId,
          this.selectedProxy.ownerId,
          this.form.controls.proxyStartDate.value,
          this.form.controls.proxyEndDate.value,
          this.form.controls.proxyRate.value,
          this.form.controls.percent.value,
          this.form.controls.comment.value,
          this.selectedProxy.timestamp
        );
        break;
      default: throw Error("Передан невалидный combinationType");
    }

    let type : string;
    switch (this.settings.combinationType) {
      case 'proxy':
        type = 'замещения';
        break;
      case 'combination':
        type = 'совмещения';
        break;
      case 'uvor':
        type = 'УВОР';
        break;
      default: throw Error('Невалидный тип совмещения');
    }
    this.loadingIndicatorService.addToObservable(
      `Редактирование ${type}`,
      editedStaffUnit$
    ).pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(
      {
        next: value => {
          switch (this.settings.combinationType) {
            case 'proxy':
              type = 'Замещение';
              break;
            case 'combination':
              type = 'Совмещение на занятую ставку';
              break;
            case 'uvor':
              type = 'УВОР на занятую ставку';
              break;
            default: throw Error('Невалидный тип совмещения');
          }
          this.save$.emit({ type: this.settings.type, staffUnit: value, deviation: this.settings.selectedDayDeviation });
          this.kendoNotificationService.showSuccess({ content: `${type} назначено` });
        }, error: error => this.displayErrorsService.handleError(error)
      });
  }

  /** Получить максимально допустимую ставку */
  private getMaxRate(){
    return this.settings.combinationType === 'proxy' ?
      this.settings.parentStaffUnit.rate :
      1;
  }

  /** Получить максимально допустимый процент */
  private getMaxPercent(){
    return this.settings.combinationType === 'proxy' ?
      100 :
      this.settings.parentStaffUnit.rate * 100;
  }
}

/** Событие сохранения Proxy */
export class EditProxyComponent_SaveEvent {
  constructor(public type: 'add' | 'edit',
    public staffUnit: StaffUnit,
    public deviation: DayDeviation) {
  }
}

/** Настройки компонента */
export class EditProxyComponent_Settings {
  /**
   * Конструктор
   * @param type Режим
   * @param combinationType Назначение на свободную ставку (free) или на занятую (busy). Устанавливается только для combination
   * @param month Месяц в котором добавляется заместитель
   * @param redactionId Идентификатор редакции графика
   * @param subdivisionOwnerId Подразделение
   * @param employeeOwnerId OwnerId сотрудника
   * @param proxyStaffUnit Данные о выделенной штатной единице
   * @param lableAndTitle_Settings Настройки подписей контролов и заголовка диалогового окна
   * @param excludeDates Даты которые не должны попадать в выбранный диаппазон
   */
  constructor(public type: 'add' | 'edit',
    public month: Date,
    public redactionId: number,
    public subdivisionOwnerId: number,
    public employeeOwnerId: number, //todo: разобраться для чего используется и чьим идентификатором является proxy или parent и убрать
    public proxyStaffUnit: EditProxyComponent_ProxyStaffUnit,
    public parentStaffUnit: EditProxyComponent_ParentStaffUnit,
    public lableAndTitle_Settings: LableAndTitle_Settings,
    public selectedDayDeviation: DayDeviation,
    public selectedPeriodStart: Date,
    public selectedPeriodEnd: Date,
    public combinationType: 'proxy' | 'combination' | 'uvor',
    public excludeDates?: Date[]) {
    if (!excludeDates) {
      excludeDates = [];
    }
  }

  /** Валидировать объект */
  public validation() {
    if (!this.month) {
      throw new Error('month должен быть заполнен')
    }
    if (!this.redactionId) {
      throw new Error('redactionId должен быть заполнен')
    }
    if (!this.subdivisionOwnerId) {
      throw new Error('subdivisionOwnerId должен быть заполнен')
    }
    if (!this.employeeOwnerId && this.proxyStaffUnit) {
      throw new Error('employeeOwnerId должен быть заполнен')
    }
    if (this.type == "add" && this.proxyStaffUnit) {
      throw new Error('staffUnit должен == null, если режим добавление заместителя')
    }
    if (this.type == "edit" && !this.proxyStaffUnit) {
      throw new Error('StaffUnit должен быть заполнен, если режим редактирования')
    }
    if (this.type == 'edit') {
      this.proxyStaffUnit.validation();
    }
  }
}

/** Редактируемая запись */
export class EditProxyComponent_ProxyStaffUnit {
  /**
   * Конструктор
   * @param ownerId Идентификатор
   * @param start Начало
   * @param end Окончание
   * @param rate Ставка
   */
  constructor(public ownerId: number,
               public employeeFullName: string,
              public start: Date,
              public end: Date,
              public rate: number,
              public staffUnitTypeId: number,
              public milk: boolean,
              public comment: string,
              public timestamp: [],
              public percent?: number) {  }

  /** Валидировать объект */
  public validation() {
    if (!this.ownerId) {
      throw new Error('ownerId должен быть заполнен')
    }
    if (!this.rate) {
      throw new Error('rate должен быть заполнен')
    }
    if (!this.staffUnitTypeId) {
      throw new Error('staffUnitTypeId должен быть заполнен')
    }
    if (!this.employeeFullName) {
      throw new Error('employeeFullName должен быть заполнен')
    }
    if (!this.employeeFullName) {
      throw new Error('employeeFullName должен быть заполнен')
    }
    if (!this.timestamp) {
      throw new Error('timestamp должен быть заполнен')
    }
  }
}

export class EditProxyComponent_ParentStaffUnit {
  constructor(public ownerId: number, public employeeFullName: string, public start: Date, public end: Date, public rate: number, public positionId: number) {
  }
}


