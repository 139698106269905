import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { GridModule } from '@progress/kendo-angular-grid';
import { MenuModule } from '@progress/kendo-angular-menu';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UploadModule } from '@progress/kendo-angular-upload';
import { HttpClientModule } from '@angular/common/http';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule } from '@progress/kendo-angular-icons';
import * as moment from 'moment/moment';

moment.locale('ru'); // Устанавливаем русский язык для MomentJS

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        DialogsModule,
        BrowserAnimationsModule,
        ButtonsModule,
        InputsModule,
        LabelModule,
        ProgressBarModule,
        LayoutModule,
        GridModule,
        MenuModule,
        TreeListModule,
        TooltipModule,
        UploadModule,
        HttpClientModule,
        TreeViewModule,
        ListViewModule,
        NotificationModule,
        DropDownsModule,
        IconsModule,
        TextBoxModule
    ],
    providers: [],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
