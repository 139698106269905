import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {Observable} from "rxjs";
import {GetTableModelResult} from "../../../../../../projects/timesheet/src/app/Classes/GetTableModelResult";
import {IRedactionServiceGetResponse} from "./redactions/redaction-base.service";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: "root"
})
export class Api1TableControlControllerService {
  /**
   * Конструктор
   */
  public constructor(
    private httpClient: HttpClient,
    private webApi1Service: WebApi1Service
  ) {
  }

  /**
   * Получение строк табеля для рендеринга
   */
  public getTableRows$(tableRedactionId: number): Observable<GetTableModelResult> {
    return this.httpClient.get<GetTableModelResult>(
      this.webApi1Service.controllers.tableConthol.actions.getTableRows.toString(),
      {
        params: new HttpParams({fromObject: {tableRedactionId: tableRedactionId.toString()}}
        )
      }
    );
  }

  public saveTable$(redactionId: number, comment: string, items: Array<ISaveTableRequestTableCodeItem>): Observable<ISaveTableResponse>{
    return this.httpClient.post<ISaveTableResponse>(
      this.webApi1Service.controllers.tableConthol.actions.saveTable.toString(),
      {
        redactionId: redactionId,
        comment: comment,
        items: items
      }
    );
  }
}

export interface ISaveTableRequestTableCodeItem{
  /**Guid - по нему будут модифицироваться id из ответа */
  guid: number,
  /**StaffUnitOwnerId */
  sUOId: number,
  /**CodeId*/
  cId: number,
  /** Value */
  tCdV: number,
  /**OwnerId */
  oId: Guid
  /** DeletedFlag */
  dF: boolean;
}

export interface ISaveTableResponse{
  ids: Array<{guid, id, ownerid}>,
  grouper: IRedactionServiceGetResponse
}
