import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IWorkMode extends IEntityVersioning {
  /** Наименование режима работы */
  name: string;
  /** Идентификатор типа режима рабочего времени. FK на таблицу WorkModeType */
  workModeTypeId: number | null;
  /** Начало периода совпадает с началом недели */
  arrangedForWeekFlag: boolean | null;
  /** Количество рабочих дней в периоде */
  workingDays: number | null;
  /** Продолжительность рабочего дня в часах */
  workdayHourDuration: number | null;
  /** Продолжительность предпраздничного дня в часах, если он выпал на рабочее время */
  preHolidayHourDuration: number | null;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IWorkModeOptional = Partial<IWorkMode>;

@classBackend('WorkMode', 'stafflist')
@className('WorkMode')
/** Режим работы */
export class WorkMode implements IWorkMode {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public name: string,
              public workModeTypeId: number | null,
              public arrangedForWeekFlag: boolean | null,
              public workingDays: number | null,
              public workdayHourDuration: number | null,
              public preHolidayHourDuration: number | null,
              public timestamp: [],
  ) {
  }
}
