import {Component, Input, OnInit} from '@angular/core';
import {
  RedactionServiceGetResponse_Route
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";

@Component({
  selector: 'app-redaction-detail-grid',
  templateUrl: './redaction-detail-grid.component.html',
  styleUrls: ['./redaction-detail-grid.component.css']
})
export class RedactionDetailGridComponent implements OnInit {
  @Input() data: Array<RedactionDetailGridComponentRowModel> = null;


  constructor() { }

  ngOnInit(): void {
    if(!this.data){
      throw new Error('@Input data - не передан')
    }
  }

}

/** Класс модели строки компонента */
export class RedactionDetailGridComponentRowModel{
  /**
   * Конструктор
   * @param id Идентификатор
   * @param date Дата статуса
   * @param fio ФИО сотрудника установивший статус
   * @param routeStatus Статус маршрута
   * @param displayStatus Дисплай статус
   * @param comment Комментарий
   */
  constructor(public id: number,
              public date: Date,
              public fio: string,
              public routeStatus: RedactionDetailGridComponentRowModel_Status,
              public displayStatus: RedactionDetailGridComponentRowModel_Status,
              public comment: string) {
  }
}

export class RedactionDetailGridComponentRowModel_Status{
  constructor(public id: number,
              public text: string,) {
  }
}


@Component({
  selector: 'app-redaction-detail-grid2',
  templateUrl: './redaction-detail-grid.component2.html',
  styleUrls: ['./redaction-detail-grid.component.css']
})
export class RedactionDetailGridComponent2 implements OnInit {
  @Input() data: RedactionServiceGetResponse_Route[] = null;


  constructor() { }

  ngOnInit(): void {
    if(!this.data){
      throw new Error('@Input data - не передан')
    }
  }

}
