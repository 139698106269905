import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { Subdivision } from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import { VichLog } from "src/app/classes/domain/POCOs/timesheet/VichLog";
import { IDropDownItem } from "src/app/classes/requestResults/iDropDownItem";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { Api1SubdivisionControllerService } from "src/app/services/webApi/webApi1/controllers/api1-subdivision-controller.service";
import { Api1VichControllerService, IAddVichLogRequest, IEditVichLogRequest } from "src/app/services/webApi/webApi1/controllers/api1-vich-controller.service";

@Injectable()
export class VichRegisterGridComponentDataService {
  constructor(private readonly api1VichControllerService: Api1VichControllerService,
              private readonly api1SubdivisionControllerService: Api1SubdivisionControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService) { }

  private getTypesResearch$(): Observable<IDropDownItem[]> {
    return this.api1VichControllerService.typesResearch_cached$
  }

  private getTypesService$(): Observable<IDropDownItem[]> {
    return this.api1VichControllerService.typesService_cached$
  }

  private getTypesWork$(): Observable<IDropDownItem[]> {
    return this.api1VichControllerService.typesWork_cached$
  }

  private getLogs$(staffUnitOwnerIds: number[], date: Date): Observable<VichLog[]> {
    return this.api1VichControllerService.getLogs$(staffUnitOwnerIds, date)
  }

  private getCanAddEditRemove$(date: Date): Observable<boolean> {
    return this.api1VichControllerService.getCanAddEditRemove$(date)
  }

  private getSubdivisionsForDate$(date: Date): Observable<Subdivision[]> {
    return this.api1SubdivisionControllerService.getForDate$(date)
  }

  public getCombineLatestData$(staffUnitOwnerIds: number[], date: Date): Observable<[IDropDownItem[], IDropDownItem[], IDropDownItem[], VichLog[], Subdivision[], boolean]> {
    return combineLatest(
      [
        this.loadingIndicatorService.addToObservable(
          'Загрузка списка ВИЧ видов исследования',
          this.getTypesResearch$()
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка списка ВИЧ видов обслуживания',
          this.getTypesService$()
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка списка ВИЧ видов работ и названия материалов',
          this.getTypesWork$()
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка ВИЧ логов учреждения',
          this.getLogs$(staffUnitOwnerIds, date)
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка перечня подразделений учреждения',
          this.getSubdivisionsForDate$(date)
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка общей информации',
          this.getCanAddEditRemove$(date)
        )]
    )
  }

  public addLog$(model: IAddVichLogRequest): Observable<VichLog> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление случая работы с ВИЧ',
      this.api1VichControllerService.addLog$(model)
    );
  }

  public editLog$(model: IEditVichLogRequest): Observable<VichLog> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение изменений случая работы с ВИЧ',
      this.api1VichControllerService.editLog$(model)
    );
  }

  public removeLog$(id: number): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable(
      'Удаление записи о случае работы с ВИЧ',
      this.api1VichControllerService.removeLog$(id)
    );
  }
}
