import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from "rxjs";
import { traceClass } from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {IGraphTableToolbarComponent} from "./i-graph-table-toolbar-component";
import {GraphTableToolbarComponentService} from "./services/graph-table-toolbar-component.service";

@Component({
  selector: 'app-graph-table-toolbar',
  templateUrl: './graph-table-toolbar.component.html',
  styleUrls: ['./graph-table-toolbar.component.css']
})
@traceClass('GraphTableToolbarComponent')
export class GraphTableToolbarComponent implements IGraphTableToolbarComponent, OnInit, OnDestroy {

  public disabled: boolean = false;

  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Конструктор */
  constructor(public readonly service: GraphTableToolbarComponentService,
              private readonly tracerService: TracerServiceBase) {
    this.service.component = this;
  }

  @traceFunc()
  ngOnInit() {
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
