import {
  GraphGrid_EditablePartGraphDayCell,
  GraphGrid_GraphDayCell
} from "./graph-grid-graph-day-cell-data-source.classes";
import {ArrayExpanded, ArrayHelper} from "../../../../../../../../../src/app/helpers/arrayHelper";
import {Injectable, OnDestroy} from "@angular/core";

/**
 * Источник данных для ячеек дней графика.
 * После всех модификаций с данными необходимо вызывать метод {@link recalculateChanged}
 */
@Injectable()
export class GraphGrid_GraphDayCellDataSourceService implements OnDestroy{
  /** Хранилище ячеек */
  private readonly _map = new Map<number, Map<Date, GraphGrid_GraphDayCell>>();

  private _changed: Readonly<GraphGrid_GraphDayCell>[] = [];
  /**
   * Получить измененные ячейки.
   * ИСПОЛЬЗОВАТЬ ТОЛЬКО ДЛЯ ЧТЕНИЯ!!!
   */
  public get changed() {
    return this._changed;
  }

  /**
   * Получить.<br>
   * РЕЗУЛЬТАТ ИСПОЛЬЗОВАТЬ ТОЛЬКО ДЛЯ ЧТЕНИЯ!!!!
   * @return ячейка или undefined если отсутствует
   */
  public get(staffUnitId: number, date: Date): GraphGrid_GraphDayCell {
    return this._map.get(staffUnitId)?.get(date);
  }

  /**
   * Получить все ячейки для исполнения должности
   * РЕЗУЛЬТАТ ИСПОЛЬЗОВАТЬ ТОЛЬКО ДЛЯ ЧТЕНИЯ!!!
   */
  public getForStaffUnit(staffUnitId: number): GraphGrid_GraphDayCell[] {
    const dayMap = this._map.get(staffUnitId);
    return !dayMap ? [] : Array.from(dayMap.values());
  }

  /** Удалить все ячейки */
  public clear() {
    this._map.clear();
  }

  /**
   * Отменить изменения
   * @param staffUnitIds список идентификаторов исполнений должностей. Если undefined, то у всех.
   */
  public resetChanges(staffUnitIds: number[] | undefined): void {
    if (!staffUnitIds) {
      staffUnitIds = Array.from(this._map.keys());
    }

    staffUnitIds.forEach(sUId => {
      const dayMap = this._map.get(sUId);
      if (!dayMap) {
        return;
      }
      dayMap.forEach(dayCell => {
        dayCell.graphDay.reset();
      })
    })
  }

  /** Установить изменения в ячейках */
  public setChanges(changes: { staffUnitId: number, date: Date, graphCell: GraphGrid_EditablePartGraphDayCell }[]) {
    const installedCells: { staffUnitId: number, date: Date }[] = [];

    for (let change of changes) {
      const dateMap = this._map.get(change.staffUnitId);

      if (!dateMap) {
        continue;
      }

      const cell = dateMap.get(change.date);
      if (!cell) {
        continue;
      }

      cell.graphDay.current = change.graphCell;
      installedCells.push({staffUnitId: change.staffUnitId, date: change.date});
    }

    return installedCells;
  }

  /**
   * Добавить ячейки для исполнений должностей
   * @param cells ячейки. Можно передавать сразу для всех исполнений должностей
   * @exception Error если уже содержится хоть одна ячейка с идентификатором исполнения должности
   */
  public addForStaffUnits(cells: GraphGrid_GraphDayCell[]) {
    const grouped = new ArrayExpanded(cells)
      .groupBy(
        x => x.staffUnitId,
        (key, items) => {
          return {
            staffUnitId: key,
            map: new ArrayExpanded(items)
              .groupBy(x => x.day.date)
              .toMap(x => x.key, x => ArrayHelper.singleOrDefault(x.values)),
          }
        })
      .array;

    for (let group of grouped) {
      if (this._map.has(group.staffUnitId)) {
        throw new Error(`В данных уже содержатся ячейки с идентификатором исполнения должности == ${group.staffUnitId}`);
      }

      this._map.set(group.staffUnitId, group.map);
    }
  }


  /**
   * Удалить ячейки относящиеся к исполнению должности
   * @param staffUnitIds идентификаторы исполнения должности. Если undefined удалит все строки.
   */
  public deleteByStaffUnitIds(staffUnitIds: number[] | undefined): void {
    if (!staffUnitIds) {
      this._map.clear();
    } else {
      staffUnitIds.forEach(staffUnitId => {
        this._map.delete(staffUnitId);
      })
    }
  }

  /**
   * Оставить ячейки относящиеся к исполнению должности.<br>
   * Противоположность метода {@link deleteByStaffUnitIds}
   * @param staffUnitIds идентификаторы исполнения должности. Если undefined оставит все.
   */
  public leaveByStaffUnitIds(staffUnitIds: number[] | undefined): void {
    if (!staffUnitIds) {
      return;
    }

    const staffUnitSet = new Set<number>(staffUnitIds);

    Array.from(this._map.keys())
      .forEach(key => {
        if (!staffUnitSet.has(key)) {
          this._map.delete(key);
        }
      });
  }

  /**
   * Метод пересчитывает состояние {@link changed}<br>
   * Необходимо выполнять в конце всех изменений ячеек
   */
  public recalculateChanged() {
    this._changed = Array.from(internal(this._map));

    function* internal(map: GraphGrid_GraphDayCellDataSourceService['_map']): IterableIterator<GraphGrid_GraphDayCell> {
      for (let row of map.values()) {
        for (let cell of row.values()) {
          if (cell.graphDay.isChanged) {
            yield cell;
          }
        }
      }
    }
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
  }
}
