import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {DeferSelectionService} from "../../../services/defer-selection-services/defer-selection.service";
import {ReplaySubject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";

@Component({
  selector: 'app-subdivision-treeview-with-checkbox2',
  templateUrl: './subdivision-treeview-with-checkbox2.component.html',
  styleUrls: ['./subdivision-treeview-with-checkbox2.component.css'],
  providers: [DeferSelectionService]
})
@traceClass('SubdivisionTreeviewWithCheckbox2Component')
export class SubdivisionTreeviewWithCheckbox2Component implements OnInit, OnDestroy {

  private _dataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input() public get dataSourceService(){
    return this._dataSourceService;
  }
  public set dataSourceService(value){
    this._dataSourceService = value;
    this.deferSelectionService.originSelection = value?.dataSource;
  }

  /** Выделенные элементы */
  @Input() public set selection(value: ArrayDataSourceSelection<ISubdivision, number>){
    this.deferSelectionService.originSelection = value;
  }

  /** Список идентификаторов выбранных подразделений */
  public set checkedSubdivisions(value){
    this.checkedSubdivisions$.next(value);
  }

  public form: UntypedFormGroup;

  @Input() public expandAll: boolean;
  @Input() public checkableSettings: CheckableSettings;
  @Input() public displayTextFn: (subdivision: ISubdivision) => string;

  // Массив выбранных подразделений
  @Output() public checkedSubdivisions$: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

  @Output() public allSubdivisions$: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _clearSelection: boolean = true;
  private readonly unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private readonly traceService: TracerServiceBase,
              public readonly deferSelectionService: DeferSelectionService<SubdivisionsTreelistComponentDataSourceServiceBase['dataSource'], ArrayDataSourceSelection<ISubdivision, number>>) {
    this.deferSelectionService.tempCtorFunc = dataSource => new ArrayDataSourceSelection<ISubdivision, number>(dataSource);
    }


  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      allSubdivisions: new UntypedFormControl(false)
    });

    this.form.controls.allSubdivisions.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: value => {
          switch (value) {
            case true:
              this.deferSelectionService.tempSelection.data?.selectAll('user');
              break;
            case false:
              if (this._clearSelection) { //необходимо для предотвращения снятия выделения со всех подразделений в случае снятия
                // выделения с одной строки, а не при помощи флага "выделить всё"
                this.deferSelectionService.tempSelection.data?.setIds([], true, 'user');
              }
              break;
            default: return;
          }

          this.allSubdivisions$.next(value);
        }
      })

    this.deferSelectionService.tempSelection.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selection => {
        selection.selectedIds.change$.subscribe({
          next: ids => {
            if (ids.length != selection.dataSource.data.length){
              this._clearSelection = false;
              this.form.controls.allSubdivisions.setValue(false);
              this._clearSelection = true;
            }
            this.checkedSubdivisions$.next(ids);
          }
        });
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
