import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ISubdivision extends IEntityVersioning {
  /** Идентификатор вышестоящего подразделения */
  parentId: number | null;
  /** Сокращенное наименование подразделения */
  shortName: string;
  /** Полное наименование подразделения */
  longName: string;
  /** Код подразделения */
  code: string;
  /** Порядок отображения подразделения в группе подразделений иерархического дерева */
  sortOrder: number | null;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type ISubdivisionOptional = Partial<ISubdivision>;

@classBackend('Subdivision', 'stafflist')
@className('Subdivision')
/** Модель БД таблицы stafflist.Subdivisions (Подразделения) */
export class Subdivision implements ISubdivision {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public parentId: number | null,
              public shortName: string,
              public longName: string,
              public code: string,
              public sortOrder: number | null,
              public timestamp: [],
  ) {
  }
}
