import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { AddEvent, CancelEvent, EditEvent, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { Subdivision } from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import { IDropDownItem } from 'src/app/classes/requestResults/iDropDownItem';
import { GetRowsResponse_Directories_Subdivision } from 'src/app/services/webApi/webApi1/controllers/api1-covid-controller.service';
import { IVichRegisterGridComponent, IVichRegisterGridComponentChangeEvent, IVichRegisterGridComponentForm, IVichRegisterGridComponentFormType, VichRegisterComponentGridDataItem, VichRegisterComponentSettings } from './i-vich-register-grid.component';
import { VichRegisterGridComponentDataService } from './services/vich-register-grid-component-data.service';
import { VichRegisterGridComponentService } from './services/vich-register-grid-component.service';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'app-vich-register-grid',
  templateUrl: './vich-register-grid.component.html',
  styleUrls: ['./vich-register-grid.component.css'],
  providers: [
    VichRegisterGridComponentService,
    VichRegisterGridComponentDataService
  ]
})
export class VichRegisterGridComponent implements IVichRegisterGridComponent, OnInit {
  @Input() public settings: VichRegisterComponentSettings

  public typesResearchSource: IDropDownItem[]
  public typesServiceSource: IDropDownItem[]
  public typesTypeWorkSource: IDropDownItem[]
  public subdivisionsSource: Subdivision[]
  public isReadOnly: boolean

  public data$: Observable<VichRegisterComponentGridDataItem[]>

  public form: FormGroup<IVichRegisterGridComponentForm>

  public valueNormalizer = this.service.valueNormalizer

  constructor(private readonly service: VichRegisterGridComponentService) {
    service.component = this;
  }

  ngOnInit(): void {
    this.service.onInit()
  }

  public addRow(e: AddEvent) {
    this.service.addRow(e)
  }

  public editRow(e: EditEvent) {
    this.service.editRow(e)
  }

  public cancelRow(e: CancelEvent) {
    this.service.closeRow(e.sender, e.rowIndex)
  }

  public saveRow(e: SaveEvent) {
    this.service.saveRow(e)
  }

  public removeRow(e: RemoveEvent) {
    this.service.removeRow(e)
  }

  public getTitleReasonUnavailable(...controls: IVichRegisterGridComponentFormType[]): string {
    return this.service.getTitleReasonUnavailable(controls)
  }

  public getChangeEvent(): IVichRegisterGridComponentChangeEvent {
    return this.service.getChangeEvent()
  }


  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
}
