import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IGraphTableBase} from "../../../../../classes/domain/POCOs/timesheet/GraphTableBase";
import {IRedactionBase} from "../../../../../classes/domain/POCOs/timesheet/RedactionBase";
import {IRouteBase, RouteBase} from "../../../../../classes/domain/POCOs/timesheet/RouteBase";
import {IRouteStatus} from "../../../../../classes/domain/POCOs/timesheet/RouteStatus";
import {map} from "rxjs/operators";

export type RedactionBaseService_Type = 'graph' | 'table'

/** Базовый класс для работы с редакциями */
export abstract class RedactionBaseService{

  /** Тип данных. Для графика или табеля */
  public abstract get type(): RedactionBaseService_Type;

  protected constructor(
    private httpClient: HttpClient,
    private paths: {
      get: string,
      get2: string,
      get3: string,
      get4: string,
      copy: string,
      toApproving: string,
      fromApproving: string,
      startEdit: string,
      updateLastActivity: string,
      stopEdit: string
    }
  ) {  }

  /**
   * Получить редакции
   * @deprecated
   */
  public get$(graphOrTableId: number): Observable<IRedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse>(
      this.paths.get,
      {
        id: graphOrTableId
      }
    )
  }

  /**
   * Получить редакции
   * @param subdivisionOwnerId идентификатор подразделения
   * @param year год
   * @param month месяц
   * @deprecated
   */
  public get2$(subdivisionOwnerId: number, year: number, month: number): Observable<IRedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse>(
      this.paths.get2,
      {
        subdivisionOwnerId: subdivisionOwnerId,
        year: year,
        month: month
      }
    )
  }

  /**
   * Получить редакции по идентификатору Графика/Табеля
   */
  public get3$(graphOrTableId: number): Observable<RedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse2>(
      this.paths.get3,
      {
        id: graphOrTableId
      }
    ).pipe(
      map(value => {
        return RedactionServiceGetResponse.Create1(value, this.type);
      })
    )
  }

  /**
   * Получить редакции
   * @param subdivisionOwnerId идентификатор подразделения
   * @param year год
   * @param month месяц
   */
  public get4$(subdivisionOwnerId: number, year: number, month: number): Observable<RedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse2>(
      this.paths.get4,
      {
        subdivisionOwnerId: subdivisionOwnerId,
        year: year,
        month: month
      }
    ).pipe(
      map(value => {
        return RedactionServiceGetResponse.Create1(value, this.type);
      })
    )
  }

  /** Копировать конкретную редакцию */
  public copy$(redactionId: number): Observable<IRedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse>(
      this.paths.copy,
      {
        redactionId: redactionId
      }
    );
  }

  /**
   * Отправить редакцию на согласование
   * @param redactionId идентификатор редакции
   * @param comment комментарий
   */
  public toApproving(redactionId: number, comment: string): Observable<IRedactionServiceToApprovingResponse>{
    return this.httpClient.post<IRedactionServiceToApprovingResponse>(
      this.paths.toApproving,
      {
        redactionId: redactionId,
        comment: comment
      }
    );
  }

  /**
   * Вернуть редакцию с согласования
   * @param redactionId идентификатор редакции
   */
  public fromApproving(redactionId: number): Observable<IRedactionServiceToApprovingResponse>{
    return this.httpClient.post<IRedactionServiceToApprovingResponse>(
      this.paths.fromApproving,
      {
        redactionId: redactionId
      }
    )
  }

  /**
   * Начать редактировать редакцию
   * @param redactionId идентификатор редакции
   */
  public startEdit(redactionId: number): Observable<IRedactionServiceStartEditResponse>{
    return this.httpClient.post<IRedactionServiceStartEditResponse>(
      this.paths.startEdit,
      {
        redactionId: redactionId
      }
    )
  }

  /**
   * Обновить время последней активности
   * @param redactionId идентификатор редакции
   */
  public updateLastActivity(redactionId: number): Observable<IRedactionServiceUpdateLastActivityResponse>{
    return this.httpClient.post<IRedactionServiceUpdateLastActivityResponse>(
      this.paths.updateLastActivity,
      {
        redactionId: redactionId
      }
    )
  }

  /**
   * Остановить редактирование
   * @param redactionId идентификатор редакции
   */
  public stopEdit(redactionId: number): Observable<IRedactionServiceToApprovingResponse>{
    return this.httpClient.post<IRedactionServiceToApprovingResponse>(
      this.paths.stopEdit,
      {
        redactionId: redactionId
      }
    )
  }
}

/** @deprecated */
export interface IRedactionServiceGetResponse{
  graphTable: {id: number, month: Date, subdivisionOwnerId: number};
  users: Array<IRedactionServiceGetResponse_User>;
  redactions: Array<IRedactionServiceGetResponse_Redaction>;
  actualRedactionId: number;
  actualVersionId: number;
  /** Находится ли график/табель в работе. Если false то либо согласуется либо согласован */
  inWork: boolean;
  /** Идентификатор пользователя кто редактирует в данный момент */
  userEditId: number;
  /** Можно ли вернуть с согласования */
  canFromApproving: boolean;
  /** Согласован ли */
  isApproved: boolean;
  versions: Array<IRedactionServiceGetResponse_Version>;
}

interface IRedactionServiceGetResponse_Status{
  id: number;
  text: string;
}

interface IRedactionServiceGetResponse_Version{
  id: number;
  routeStatus: IRedactionServiceGetResponse_Status;
  displayStatus: IRedactionServiceGetResponse_Status;
}

interface IRedactionServiceGetResponse_User{
  id: number;
  fio: string
}

interface IRedactionServiceGetResponse_Route{
  id: number;
  date: Date;
  userId: number;
  routeStatus: IRedactionServiceGetResponse_Status;
  displayStatus: IRedactionServiceGetResponse_Status;
  comment: string;
}

interface IRedactionServiceGetResponse_Redaction{
  version: number;
  id: number;
  parentId: number;
  date: Date;
  userId: number;
  comment: string;
  routes: Array<IRedactionServiceGetResponse_Route>;
}

interface IRedactionServiceToApprovingResponse{
  /** Выполнено ли действие на сервере */
  isExecuted: boolean,
  data: IRedactionServiceGetResponse
}

interface IRedactionServiceStartEditResponse extends IRedactionServiceToApprovingResponse{
  updateInterval: number
}

interface IRedactionServiceUpdateLastActivityResponse extends IRedactionServiceToApprovingResponse{
  message: string
}





interface IRedactionServiceGetResponse2{
  graphTable: IGraphTableBase;
  actualRedactionId: number;
  actualVersionId: number;
  /** Находится ли график/табель в работе. Если false - то согласуется или согласован */
  inWork: boolean;
  /** Идентификатор пользователя кто редактирует в данный момент */
  userEditId: number;
  /** Можно ли вернуть с согласования */
  canFromApproving: boolean;
  /** Согласован ли график/табель */
  isApproved: boolean;

  /** Версии графика табеля */
  versions: IRedactionServiceGetResponse2_Version[];
  /** Редакции */
  redactions: IRedactionServiceGetResponse2_Redaction[];

  /** Статусы маршрутов для отображения */
  displayStatuses: IRedactionServiceGetResponse2_DisplayStatus[];

  /** Статусы маршрутов */
  routeStatuses: IRouteStatus[];

  /** Пользователи фигурирующие в данных */
  users: IRedactionServiceGetResponse_User[];
}

interface IRedactionServiceGetResponse2_DisplayStatus{
  id: number,
  text: string,
}

interface IRedactionServiceGetResponse2_Route{
  route: IRouteBase,
  routeStatusId: number,
  displayStatusId: number,
}

interface IRedactionServiceGetResponse2_Redaction{
  redaction: IRedactionBase,
  routes: IRedactionServiceGetResponse2_Route[],
  versionId: number,
  canCopy: boolean,
  canEdit: boolean,
  canToApproving: boolean,
  canFromApproving: boolean,
  canApproving: boolean,
  canApproved: boolean,
  canToUnderRevision: boolean,
  isActual: boolean,
  canDecline: boolean,
  canCancelApproving: boolean,
}

interface IRedactionServiceGetResponse2_Version{
  id: number,
  /** Может быть нулл */
  routeStatusId: number,
  displayStatusId: number,
  isActual: boolean,
}

/** Класс ответа сервера */
export abstract class RedactionServiceGetResponse implements Omit<IRedactionServiceGetResponse2, 'versions' | 'redactions'>{
  type: RedactionBaseService_Type;
  actualRedactionId: number;
  actualVersionId: number;
  canFromApproving: boolean;
  graphTable: IGraphTableBase;
  inWork: boolean;
  isApproved: boolean;

  userEditId: number;
  userEdit: IRedactionServiceGetResponse_User;

  versions: RedactionServiceGetResponse_Version[];
  redactions: RedactionServiceGetResponse_Redaction[];

  displayStatuses: IRedactionServiceGetResponse2_DisplayStatus[];
  routeStatuses: IRouteStatus[];
  users: IRedactionServiceGetResponse_User[];

  /** Создать */
  public static Create1(source: IRedactionServiceGetResponse2, type: RedactionBaseService_Type): RedactionServiceGetResponse{
    const versions = source.versions.map(x => {
      return RedactionServiceGetResponse_Version.Create1(x, source.routeStatuses, source.displayStatuses, undefined);
    });

    const redactions = source.redactions.map(x => {
      return RedactionServiceGetResponse_Redaction.Create1(
        x,
        x.routes.map(r => {
          return RedactionServiceGetResponse_Route.Create1(r, source.displayStatuses, source.routeStatuses, source.users)
        }),
        undefined,
        source.users);
    });

    versions.forEach(version => {
      const tempRedactions = redactions.filter(x => x.versionId == version.id);
      tempRedactions.forEach(redaction => {
        redaction.version = version;
      })

      version.redactions = tempRedactions;
    })

    return {
      type: type,
      actualRedactionId: source.actualRedactionId,
      actualVersionId: source.actualVersionId,
      canFromApproving: source.canFromApproving,
      graphTable: source.graphTable,
      inWork: source.inWork,
      isApproved: source.isApproved,
      userEditId: source.userEditId,
      userEdit: source.users.find(x => x.id === source.userEditId),
      versions: versions,
      redactions: redactions,
      displayStatuses: source.displayStatuses,
      routeStatuses: source.routeStatuses,
      users: source.users
    }
  }
}

/** Обвертка для {@link IRouteBase} */
export abstract class RedactionServiceGetResponse_Route implements IRedactionServiceGetResponse2_Route{
  route: IRouteBase;

  displayStatusId: number;
  displayStatus: IRedactionServiceGetResponse2_DisplayStatus;

  routeStatusId: number;
  routeStatus: IRouteStatus;

  modifiedUser: IRedactionServiceGetResponse_User;
  approvals: IRedactionServiceGetResponse_User;

  /** Копирует объект и заполняет навигационные поля из переданных словарей */
  public static Create1(source: IRedactionServiceGetResponse2_Route, displayStatuses: IRedactionServiceGetResponse2_DisplayStatus[], routeStatuses: IRouteStatus[], users: IRedactionServiceGetResponse_User[]){
    const instance = {...source} as RedactionServiceGetResponse_Route;

    instance.displayStatus = displayStatuses.find(x => x.id === instance.displayStatusId);
    instance.routeStatus = routeStatuses.find(x => x.id === instance.routeStatusId);
    instance.modifiedUser = users.find(x => x.id === instance.route.modifiedUserId);
    instance.approvals = users.find(x => x.id === instance.route.approvalsId);

    return instance;
  }
}

/** Класс расширяет {@link IRedactionBase} */
export abstract class RedactionServiceGetResponse_Redaction implements Omit<IRedactionServiceGetResponse2_Redaction, 'routes'> {
  redaction: IRedactionBase;
  canApproved: boolean;
  canApproving: boolean;
  canCancelApproving: boolean;
  canCopy: boolean;
  canDecline: boolean;
  canEdit: boolean;
  canFromApproving: boolean;
  canToApproving: boolean;
  canToUnderRevision: boolean;
  isActual: boolean;

  versionId: number;
  version: RedactionServiceGetResponse_Version;

  routes: RedactionServiceGetResponse_Route[];

  modifiedUser: IRedactionServiceGetResponse_User;

  status: IRouteStatus;
  displayStatus: IRedactionServiceGetResponse2_DisplayStatus;

  /** Создает экземпляр из переданного источника и заполняет навигационные поля из переданных справочников */
  public static Create1(source: IRedactionServiceGetResponse2_Redaction, routes: RedactionServiceGetResponse_Route[], version: RedactionServiceGetResponse_Version, users: IRedactionServiceGetResponse_User[]){
    const instance = {...source} as RedactionServiceGetResponse_Redaction;

    instance.version = version;
    instance.routes = routes;
    instance.modifiedUser = users.find(x => x.id === instance.redaction.modifiedUserId);

    const lastRoute = !routes || routes.length === 0 ? undefined : routes[0];
    instance.status = lastRoute?.routeStatus;
    instance.displayStatus = lastRoute?.displayStatus;

    return instance;
  }

}

/** Класс версий редакций */
export abstract class RedactionServiceGetResponse_Version implements IRedactionServiceGetResponse2_Version{
  id: number;
  isActual: boolean;

  routeStatusId: number;
  routeStatus: IRouteStatus;

  displayStatusId: number;
  displayStatus: IRedactionServiceGetResponse2_DisplayStatus;

  redactions: RedactionServiceGetResponse_Redaction[];

  /** Создает экземпляр из переданного источника и заполняет навигационные поля из переданных справочников */
  public static Create1(source: IRedactionServiceGetResponse2_Version, routeStatuses: IRouteStatus[], displayStatuses: IRedactionServiceGetResponse2_DisplayStatus[], redactions: RedactionServiceGetResponse_Redaction[]){
    const instance = {...source} as RedactionServiceGetResponse_Version;

    instance.routeStatus = routeStatuses.find(x => x.id === instance.routeStatusId);
    instance.displayStatus = displayStatuses.find(x => x.id === instance.displayStatusId);
    instance.redactions = redactions;

    return instance;
  }
}
