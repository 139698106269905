import {Observable} from "rxjs";
import {map} from "rxjs/operators";

/**
 * Оператор получения из массива элемент по индексу или переданное в оператор значение по умолчанию.<br>
 * @return элемент или переданный параметр or, если !arr || индекс выходит за диапазон
 * @param index индекс элемента
 * @param or значение если !массив или индекс выходи за пределы массива
 */
export function exElementByIndexOr<O>(index: number = 0, or: O = undefined): (source$: Observable<O[]>) => Observable<O> {
  return source$ => {
    return source$.pipe(map(arr => {
      if(!arr || arr.length < index + 1){
        return or;
      }

      return arr[index];
    }));
  }
}
