import { Injectable, OnDestroy } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { ContextMenuComponent, ContextMenuSelectEvent } from "@progress/kendo-angular-menu";
import { GridToolbarManagementService } from "../../services/grid-toolbar-management.service";
import { GraphGridRowModel } from "../classes/view-models/row-and-graph-day-view-model.class";
import { GraphRowEditService } from "./graph-row-edit.service";
import { AlertService } from "../../../../../../../../src/app/services/alert.service";
import { take } from "rxjs/operators";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import { Api1PrintReportControllerService } from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import { AppSettingsService } from "../../../../../../../../src/app/services/app-settings.service";
import { traceClass } from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import { TracerServiceBase } from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { traceFunc } from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { TraceParamEnum } from "../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import { CellClickEvent } from "@progress/kendo-angular-grid";
import { ContextItemBase } from "src/app/classes/context-menu-items/context-item-base";
import {
  StaffUnitTypeEnum,
  StaffUnitTypeEnumHelper
} from "../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";

/** Сервис контекста строки */
@Injectable()
@traceClass('GraphGridRowContextService')
export class GraphGridRowContextService implements OnDestroy {
  /** Список контекстного меню */
  public items: Array<ContextItemBase> = null;

  private streams$ = {
    unsubscribe: new ReplaySubject(1)
  }

  /** Строка на которой был вызван контекст */
  private row: GraphGridRowModel = null;

  constructor(private gridToolbarManagementService: GridToolbarManagementService,
    private graphRowEditService: GraphRowEditService,
    private alertService: AlertService,
    private api1PrintReportControllerService: Api1PrintReportControllerService,
    private readonly appSettingsService: AppSettingsService,
    private readonly tracerService: TracerServiceBase) { }

  /** Обработка клика по ячейке */
  @traceFunc()
  public onCellClick(rowContextMenu: ContextMenuComponent, $event: CellClickEvent, items: ContextItemBase[]) {
    const originalEvent = $event.originalEvent;
    originalEvent.preventDefault();

    this.row = $event.dataItem;

    if ($event.columnIndex > 1 && $event.columnIndex < 4) {
      this.items = this.gridToolbarManagementService.isEditing ?
        [
          new ContextEditItem(!this.row.staffUnit.isInMonth(this.gridToolbarManagementService.data.graphTable.month), () => {
            switch (this.row.staffUnit.staffUnitType.id) {
              case StaffUnitTypeEnum.MoonlighterInner:
              case StaffUnitTypeEnum.MoonlighterExternal:
                if (this.row.staffUnit.parentId) {
                  this.graphRowEditService.openEditProxyDialog(this.row.staffUnit.ownerId, 'proxy')
                } else {
                  this.graphRowEditService.openEditMoonlighterDialog(this.row.staffUnit.ownerId, 'moonlighter');
                }
                break;
              case StaffUnitTypeEnum.CombinationInner:
              case StaffUnitTypeEnum.CombinationExternal:
                if (this.row.staffUnit.parentId) {
                  this.graphRowEditService.openEditProxyDialog(this.row.staffUnit.ownerId, 'combination')
                } else {
                  this.graphRowEditService.openEditMoonlighterDialog(this.row.staffUnit.ownerId, 'combination');
                }
                break;
              case StaffUnitTypeEnum.Uvor:
                if (this.row.staffUnit.parentId) {
                  this.graphRowEditService.openEditProxyDialog(this.row.staffUnit.ownerId, 'uvor')
                } else {
                  this.graphRowEditService.openEditMoonlighterDialog(this.row.staffUnit.ownerId, 'uvor');
                }
                break;
              case StaffUnitTypeEnum.Duty:
                this.graphRowEditService.openEditDutyDialog(this.row.staffUnit.ownerId)
                break;
              default: this.alertService.defaultAlertOption.information()
                .mod(x => x.message = 'Вы не можете редактировать данную строку')
                .showAlert();
            }
          }),
          new ContextDeletedItem(!this.row.staffUnit.isInMonth(this.gridToolbarManagementService.data.graphTable.month), () => {
            this.alertService.defaultAlertOption.confirmation().mod(x => {
              x.message = "Вы уверены, что хотите удалить выбранную запись?";
              x.buttons[1].callBack = () => {
                this.graphRowEditService.deleteRow(this.row.staffUnit.ownerId, this.row.staffUnit.timestamp);
              };
            }).showAlert()
          }),
        ] :
        [];

    /* выводим пункт печати в контекстном меню для всех клиник */
      let childrenPrintItems = [
        // Оформление внутреннего совместительства
        new ContextPrintInternalConcurrencyRegistrationChildItem(this.row.staffUnit.staffUnitType.id != StaffUnitTypeEnum.MoonlighterInner, () => {
          const response = this.api1PrintReportControllerService.printInternalConcurrencyRegistration$(
            this.row.staffUnit.ownerId,
            this.gridToolbarManagementService.redaction.id,
            "internalConcurrency_registration");
          response.pipe(take(1)).subscribe(value => {
            FileSaver.saveAs(value, `Документы_Совместительство_${moment().format('DD_MM_yyyy')}.xlsx`);
          });
        })
      ];
      if(this.appSettingsService.company.indexOf('gb15_registry') >= 0 ||
        this.appSettingsService.company.indexOf('gvv2_registry') >= 0 ||
        this.appSettingsService.company.indexOf('fmba_registry') >= 0 ||
        this.appSettingsService.company.indexOf('demo_registry') >= 0) {
        childrenPrintItems.push(new ContextPrintUvorChildItem(this.row.staffUnit.staffUnitType.id != StaffUnitTypeEnum.Uvor, () => {
          const response = this.api1PrintReportControllerService.printUvorRegistration$(
            this.row.staffUnit.ownerId,
            this.gridToolbarManagementService.redaction.id,
            "uvor_registration");

          response.pipe(take(1)).subscribe(value => {
            FileSaver.saveAs(value, `Документы_УВОР_${moment().format('DD_MM_yyyy')}.xlsx`);
          });
        }));
        childrenPrintItems.push(
          new ContextPrintCombinationChildItem(
            !(StaffUnitTypeEnumHelper.isCombination(this.row.staffUnit.staffUnitType.id)),
            () => {
              const response = this.api1PrintReportControllerService.printCombinationRegistration$(
                this.row.staffUnit.ownerId,
                this.gridToolbarManagementService.redaction.id,
                "combination_registration");
              response.pipe(take(1)).subscribe(value => {
                FileSaver.saveAs(value, `Документы_Совмещение_${moment().format('DD_MM_yyyy')}.xlsx`);
              });
            })
        );
      }

      if(this.appSettingsService.company.indexOf('buyanova_registry') >= 0) {
        childrenPrintItems.push(new ContextPrintDutyChildItem(this.row.staffUnit.staffUnitType.id != StaffUnitTypeEnum.Duty, () => {
          const response = this.api1PrintReportControllerService.printDutyRegistration$(
            this.row.staffUnit.ownerId,
            this.gridToolbarManagementService.redaction.id,
            'duty_registration');

          response.pipe(take(1)).subscribe(value => {
            FileSaver.saveAs(value, `Документы_Дежурство_${moment().format('DD_MM_yyyy')}.xlsx`);
          });
        }));
      }

      this.items.push(new ContextPrintItem([ ...childrenPrintItems ], false));
    } else if ($event.columnIndex > 7) {
      this.items = this.gridToolbarManagementService.isEditing ? items : []
    } else {
      this.items = [];
    }

    if (this.items?.length) {
      rowContextMenu.show({
        left: originalEvent.pageX,
        top: originalEvent.pageY,
      });
    }
  }

  /** Событие выбора в контекстном меню */
  @traceFunc({ traceParamType: TraceParamEnum.notTrace })
  public onSelect($event: ContextMenuSelectEvent) {
    const item = $event.item as ContextItemBase;

    this.tracerService.add2('выбран элемент', { obj: item.text });

    item?.select()
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

/** Класс элемента контекста 'Редактировать' */
class ContextEditItem extends ContextItemBase {
  constructor(disabled: boolean, select: () => void) {
    super('Редактировать', 'edit', disabled, select);
  }
}

/** Класс элемента контекста 'Удалить' */
class ContextDeletedItem extends ContextItemBase {
  constructor(disabled: boolean, select: () => void) {
    super('Удалить', 'delete', disabled, select);
  }
}

/** Класс элемента контекста 'Печать' */
class ContextPrintItem extends ContextItemBase {
  constructor(childrens: ContextItemBase[], disabled: boolean) {
    super('Печать', 'print', disabled, () => undefined, childrens);
  }
}


/** Дочерний элемент меню Печать -> 'Оформление внутреннего совместительства' */
class ContextPrintInternalConcurrencyRegistrationChildItem extends ContextItemBase {
  constructor(disabled: boolean, select: () => void) {
    super('Оформление внутреннего совместительства', 'print', disabled, select);
  }
}

/** Дочерний элемент меню Печать -> 'Оформление дужурства' */
class ContextPrintDutyChildItem extends ContextItemBase {
  constructor(disabled: boolean, select: () => void) {
    super('Оформление дежурства', 'print', disabled, select);
  }
}

/** Дочерний элемент меню Печать -> 'Оформление увеличения объема работ' */
class ContextPrintUvorChildItem extends ContextItemBase {
  constructor(disabled: boolean, select: () => void) {
    super('Оформление увеличения объема работ', 'print', disabled, select);
  }
}

/** Дочерний элемент меню Печать -> 'Оформление совмещения' */
class ContextPrintCombinationChildItem extends ContextItemBase {
  constructor(disabled: boolean, select: () => void) {
    super('Оформление совмещения', 'print', disabled, select);
  }
}

/** Класс элемента контекста 'CovidLog' */
export class ContextCovidLogItem extends ContextItemBase {
  constructor(disabled: boolean, select: () => void) {
    super('COVID-19', '', disabled, select);
  }
}

/** Класс элемента контекста 'VichLog' */
export class ContextVichLogItem extends ContextItemBase {
  constructor(disabled: boolean, select: () => void) {
    super('ВИЧ', '', disabled, select);
  }
}

/** Класс элемента контекста 'TuberLog' */
export class ContextTuberLogItem extends ContextItemBase {
  constructor(disabled: boolean, select: () => void) {
    super('Туберкулез', '', disabled, select);
  }
}

/** Класс элемента контекста 'ExcludeMilk' */
export class ContextExcludeMilk extends ContextItemBase {
  constructor(public readonly state: 'add' | 'remove', disabled: boolean, select: () => void) {
    super('Выплата за молоко', state == 'add' ? 'plus' : 'minus', disabled, select);
  }
}
