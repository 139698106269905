import {ArrayDataSourceSelection} from "./array-data-source-selection";
import {ArrayDataSourceHasId} from "../data-source";
import {ArrayHelper} from "../../../helpers/arrayHelper";

/**
 * @deprecated может не верно работать с onPush. Необходимо реализовать директиву по аналогии с {@link KendoGridSelectByArrayDataSourceSelectionDirective}
 * Класс расширяет возможности ArrayDataSourceSelection для KendoTreeListComponent, директива [kendoTreeListSelectable]
 */
export class KendoTreeListDataSourceSelection<TDataItem, TId> extends ArrayDataSourceSelection<TDataItem, TId>{
  /**
   * Данные для директивы [kendoTreeListSelectable]<br>
   * НЕ ДОПУСКАЕТСЯ ПРОГРАММНО УСТАНАВЛИВАТЬ ЗНАЧЕНИЯ В ОБЪЕКТЕ!<br>
   */
  public readonly forDirective: ForDirective<TDataItem, TId>;

  /**
   * Конструктор
   * @param dataSource Источник данных
   */
  constructor(dataSource: ArrayDataSourceHasId<TDataItem, TId>) {
    super(dataSource);

    this.forDirective = new ForDirective<TDataItem, TId>(
      dataSource.idGetter,
      (ids) => this.onUserChangedSelectedIdsCallback(ids)
    );

    this.selectedIds.data$.subscribe(value => {
      this.update_forDirective_selectedKeys(value);
    })
  }

  /** Обработка изменения пользователем выделенных строк(идентификаторов) */
  private onUserChangedSelectedIdsCallback(ids: TId[]){
    this.setIds(ids, true, 'user');
  }

  /** Метод поддерживает в актуальности список выделенных идентификаторов в this.forDirective.selectedKeys */
  private update_forDirective_selectedKeys(ids: TId[]){
    ids = [...ids]; //Обязательно копируем
    const hasDifference = ArrayHelper.difference2(
      ids,
      this.forDirective.selectedItems.map(x => x.itemKey),
      (x1, x2) => x1 === x2,
      (x1, x2) => x1 === x2 ).length > 0;

    if(hasDifference){
      this.forDirective.selectedItems = ids.map(id => {
        return {
          itemKey: id
        }
      });
    }
  }
}

type ForDirective_EventType<TId> = {itemKey: TId}

/** Класс данных для директивы kendoTreeListSelectable */
class ForDirective<TDataItem, TId>{
  /**
   * Использовать для биндинга в директиве.<br>
   * ОБЯЗАТЕЛЬНО ДЛЯ РАБОТЫ!<br>
   * @example <kendo-treelist kendoTreeListSelectable [itemKey]="путь.itemKey"
   */
  public readonly itemKey: (dataItem: TDataItem) => TId;

  /**
   * Использовать для биндинка в директиве.<br>
   * ОБЯЗАТЕЛЬНО ДЛЯ РАБОТЫ!<br>
   * @example <kendo-treelist kendoTreeListSelectable [selectedItems]="путь.selectedItems"
   */
  public selectedItems: ForDirective_EventType<TId>[] = [];

  /**
   * Конструктор
   * @param idGetter функция получения идентификатора из элемента данных. Можно получить из dataSource
   * @param userChangedSelectedIdsCallback функция обратного вызова при изменении выделенных строк пользователем
   */
  constructor(private readonly idGetter: (item: TDataItem) => TId,
              private readonly userChangedSelectedIdsCallback: (ids: TId[]) => void) {
    this.itemKey = (dataItem) => this.idGetter(dataItem);
  }

  /**
   * Использовать для биндинга в директиве.<br>
   * ОБЯЗАТЕЛЬНО ДЛЯ РАБОТЫ!<br>
   * @example <kendo-treelist kendoTreeListSelectable (selectedItemsChange)="путь.onSelectedKeysChange($event)
   * @param event данные события
   */
  public onSelectedKeysChange(event: ForDirective_EventType<TId>[]){
    this.userChangedSelectedIdsCallback(event.map(x => x.itemKey));
  }
}
