import {GraphTableBase, IGraphTableBase} from "../timesheet/GraphTableBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IGraph extends IGraphTableBase{

}

@classBackend('Graph', 'timesheet_graph')
@className('Graph')
/** График */
export class Graph extends GraphTableBase implements IGraph{

}
