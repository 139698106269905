import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import {RouterModule} from "@angular/router";
import {DialogModule} from "@progress/kendo-angular-dialog";
import {ButtonsModule} from "@progress/kendo-angular-buttons";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TextBoxModule} from "@progress/kendo-angular-inputs";
import {LabelModule} from "@progress/kendo-angular-label";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppModule} from "../../app.module";
import {ProgressBarModule} from "@progress/kendo-angular-progressbar";
import {SharedComponentModule} from "../shareds/shared-component/shared-component.module";
import {AuthService} from "./services/auth.service";
import {WorkSpaceErrorComponentService} from "../../../../projects/timesheet/src/app/services/workspace/work-space-error.component.service";

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {path: 'login', component: LoginComponent, pathMatch: 'full'},
      {path: 'login/:company', component: LoginComponent}
    ]),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DialogModule,
    ButtonsModule,
    TextBoxModule,
    LabelModule,
    HttpClientModule,
    FormsModule,
    AppModule,
    ProgressBarModule,
    SharedComponentModule
  ],
  providers: [],
  exports: [RouterModule]
})
export class AuthModule { }
