import {IEntityId} from "../interfaces/IEntityId";
import {IEntityModify} from "../interfaces/IEntityModify";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IExcludeMilkLog extends IEntityId, IEntityModify{
  /** Идентификатор сотрудника */
  staffUnitId: number;
  /** Дата, за которую составлена данная запись */
  date: Date;
}

@classBackend('ExcludeMilkLog', 'timesheet')
@className('ExcludeMilkLog')
/** Исключать молоко */
export class ExcludeMilkLog implements IExcludeMilkLog{
  constructor(public id: number,
              public modifiedUserId: number,
              public modifiedDate: Date,
              public staffUnitId: number,
              public date: Date,) {
  }
}
