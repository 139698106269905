import { Injectable, OnDestroy } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { AddEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent } from "@progress/kendo-angular-grid";
import { map, Observable, ReplaySubject, take, takeUntil, startWith } from "rxjs";
import { ArrayDataSourceIEntityId } from "src/app/classes/array-data-sources/data-source";
import { VichLog } from "src/app/classes/domain/POCOs/timesheet/VichLog";
import { IDropDownItem } from "src/app/classes/requestResults/iDropDownItem";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { AlertService } from "src/app/services/alert.service";
import { IVichRegisterGridComponent, IVichRegisterGridComponentChangeEvent, IVichRegisterGridComponentForm, IVichRegisterGridComponentFormType, IVichRegisterGridComponentFormValue, VichRegisterComponentGridDataItem } from "../i-vich-register-grid.component";
import { VichRegisterGridComponentDataService } from "./vich-register-grid-component-data.service";

@Injectable()
export class VichRegisterGridComponentService extends ComponentServiceBase<IVichRegisterGridComponent> implements OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  private hasChange = false
  private typeWorkIsRequired = true;

  private directoryData = new ArrayDataSourceIEntityId<VichRegisterComponentGridDataItem>();

  public valueNormalizer = (text: Observable<string>) => text.pipe(map((text: string) => ({ id: null, text } as IDropDownItem)));

  constructor(private readonly dataService: VichRegisterGridComponentDataService, private readonly alertService: AlertService) {
    super();
  }

  public onInit(): void {
    this.component.data$ = this.directoryData.data$

    this.dataService
      .getCombineLatestData$([this.component.settings.staffUnitOwnerId], this.component.settings.date)
      .pipe(take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe(this.getCombineLatestDataSubscribe.bind(this))
  }

  private getCombineLatestDataSubscribe([typesResearchSource, typesServiceSource, typesTypeWorkSource, logs, subdivisions, canAddEditRemove]) {
    this.component.typesResearchSource = typesResearchSource
    this.component.typesServiceSource = typesServiceSource
    this.component.typesTypeWorkSource = typesTypeWorkSource
    this.component.subdivisionsSource = subdivisions
    this.component.isReadOnly = !canAddEditRemove

    let gridData = logs.map(log => this.vichLogToRegisterComponentGridDataItem(log))

    this.directoryData.setData(gridData)
  }

  private vichLogToRegisterComponentGridDataItem(log: VichLog) {
    return new VichRegisterComponentGridDataItem(
      log.id,
      log,
      this.component.subdivisionsSource.find(s => s.ownerId === log.subdivisionId),
      this.component.typesServiceSource.find(t => t.id === log.typeServiceId),
      this.component.typesResearchSource.find(t => t.id === log.typeResearchId) ?? null,
      this.component.typesTypeWorkSource.find(t => t.id === log.typeWorkId) ?? null
    )
  }

  private createFormGroup(
    id: number,
    staffUnitId: number,
    diseaseNumber: string,
    patientFio: string,
    typeServiceId: number,
    typeResearch: IDropDownItem,
    typeWork: IDropDownItem,
    researchCount: number,
    researchProtocol: string,
    subdivisionId: number,
    operatingTime: number
  ) {
    const ifEnabledThenRequiredValidator = (control: AbstractControl<any, any>) =>
      control.enabled && !control.value ? { 'message': 'Одно из этих полей<br>должно быть заполнено' } : null;

    const typeWorkValidator = (control: AbstractControl<any, any>) =>
      control.enabled && this.typeWorkIsRequired && !control.value ? { 'message': 'Одно из этих полей<br>должно быть заполнено' } : null;

    const controls = {
      id: new FormControl<number>(id),
      staffUnitId: new FormControl<number>(staffUnitId),
      diseaseNumber: new FormControl<string>(diseaseNumber),
      patientFio: new FormControl<string>(patientFio, [ifEnabledThenRequiredValidator]),
      typeServiceId: new FormControl<number>(typeServiceId),
      typeResearch: new FormControl<IDropDownItem>(typeResearch, [ifEnabledThenRequiredValidator]),
      typeWork: new FormControl<IDropDownItem>(typeWork, [typeWorkValidator]),
      researchCount: new FormControl<number>(researchCount),
      researchProtocol: new FormControl<string>(researchProtocol),
      subdivisionId: new FormControl<number>(subdivisionId),
      operatingTime: new FormControl<number>(operatingTime),
      operatingTimeFree: new FormControl<number>(0)
    }

    controls.operatingTime.setValidators(control => {
      const busyAndIntervalData = this.getBusyAndIntervalData(controls.staffUnitId.value, controls.id.value);
      const lengthFree = busyAndIntervalData.timeIntervalLength - busyAndIntervalData.totalBusy - control.value;

      controls.operatingTimeFree.setValue(lengthFree, { onlySelf: true });

      if (control.value && lengthFree < 0) {
        return { 'message': 'Превышает рабочее время' }
      } else if (!control.value) {
        return { 'message': 'Поле<br>должно быть заполнено' }
      } else {
        return null
      }
    })

    this.component.form = new FormGroup<IVichRegisterGridComponentForm>(controls);

    this.component.form
      .valueChanges
      .pipe(startWith(this.component.form.getRawValue()), takeUntil(this.streams$.unsubscribe))
      .subscribe(this.formValueChanges.bind(this))
  }

  private formValueChanges(value: IVichRegisterGridComponentFormValue) {
    this.enableControls('diseaseNumber', 'patientFio', 'typeServiceId', 'typeResearch', 'typeWork', 'researchCount', 'researchProtocol', 'subdivisionId')

    if (value.diseaseNumber || value.patientFio || value.typeServiceId) {
      this.enableControls('diseaseNumber', 'patientFio', 'typeServiceId', 'typeResearch')
      this.disableControls('typeWork', 'researchCount', 'researchProtocol', 'subdivisionId')
    }

    if (value.typeResearch?.id && !value.diseaseNumber && !value.patientFio && !value.typeServiceId) {
      this.enableControls('diseaseNumber', 'patientFio', 'typeServiceId', 'typeResearch', 'researchCount')
      this.disableControls('typeWork', 'researchProtocol', 'subdivisionId')
    }

    if ((value.typeResearch?.id && value.researchCount) || (value.researchCount && !value.typeResearch?.id)) {
      this.enableControls('typeResearch', 'researchCount')
      this.disableControls('diseaseNumber', 'patientFio', 'typeServiceId', 'typeWork', 'researchProtocol', 'subdivisionId')
    }

    if (this.component.settings.clinicName.includes("ikb1_registry")){
      if (value.typeWork?.id) {
        this.typeWorkIsRequired = true;
        this.component.form.controls.typeWork.updateValueAndValidity( {onlySelf : true, emitEvent: false} );
        this.enableControls('typeWork', 'researchProtocol', 'subdivisionId')
        this.disableControls('diseaseNumber', 'patientFio', 'typeServiceId', 'typeResearch', 'researchCount')
      }
      if (value.researchProtocol) {
        this.typeWorkIsRequired = true;
        this.component.form.controls.typeWork.updateValueAndValidity( {onlySelf : true, emitEvent: false } );
        this.enableControls('typeWork', 'researchProtocol', 'subdivisionId')
        this.disableControls('diseaseNumber', 'patientFio', 'typeServiceId', 'typeResearch', 'researchCount')
      }
    }
    else {
      if (value.typeWork?.id) {
        this.typeWorkIsRequired = true;
        this.component.form.controls.typeWork.updateValueAndValidity( {onlySelf : true, emitEvent: false} );
        this.enableControls('typeWork', 'researchProtocol', 'subdivisionId')
        this.disableControls('diseaseNumber', 'patientFio', 'typeServiceId', 'typeResearch', 'researchCount')
      }

      if ((value.typeWork?.id && value.subdivisionId) || (value.subdivisionId && !value.researchProtocol && !value.typeWork?.id)) {
        this.typeWorkIsRequired = false;
        this.component.form.controls.typeWork.updateValueAndValidity( {onlySelf : true, emitEvent: false} );
        this.enableControls('typeWork', 'subdivisionId')
        this.disableControls('diseaseNumber', 'patientFio', 'typeServiceId', 'typeResearch', 'researchCount', 'researchProtocol')
      }

      if ((value.typeWork?.id && value.researchProtocol) || (value.researchProtocol && !value.subdivisionId && !value.typeWork?.id)) {
        this.typeWorkIsRequired = true;
        this.component.form.controls.typeWork.updateValueAndValidity( {onlySelf : true, emitEvent: false } );
        this.enableControls('typeWork', 'researchProtocol')
        this.disableControls('diseaseNumber', 'patientFio', 'typeServiceId', 'typeResearch', 'researchCount', 'subdivisionId')
      }
    }

    if(value.typeServiceId) {
      this.disableControls('typeResearch')
    }

    if(value.typeResearch) {
      this.disableControls('typeServiceId')
    }
  }

  private getControls(controlNames: string[]): AbstractControl<any, any>[] {
    return controlNames
      .map(c => this.component.form.get(c))
  }

  private enableControls(...controlNames: IVichRegisterGridComponentFormType[]) {
    this.getControls(controlNames)
      .filter(c => c.disabled)
      .forEach(c => c.enable({ emitEvent: false }))
  }

  private disableControls(...controlNames: IVichRegisterGridComponentFormType[]) {
    this.getControls(controlNames)
      .filter(c => c.enabled)
      .forEach(c => c.disable({ emitEvent: false }))
  }

  private controlTitles = {
    diseaseNumber: "Номер ИБ",
    patientFio: "ФИО пациента",
    typeServiceId: "Вид обслуживания",
    typeResearch: "Вид исследования",
    typeWork: "Название материала, вид работы",
    researchCount: "Кол-во исследований",
    researchProtocol: "№ протоколов исследований",
    subdivisionId: "Отделение"
  }

  public getTitleReasonUnavailable(controlNames: IVichRegisterGridComponentFormType[]): string {
    const names = controlNames.map((name) => ({
      control: this.component.form.get(name),
      name
    })).filter(({ control }) => control.enabled && control.value).map(({ name }) => this.controlTitles[name]);

    return names.length > 1 ? `заполнены поля ${names.join(', ')}` : `заполнено поле ${names[0]}`
  }

  public addRow(e: AddEvent): void {
    if (!this.checkCanAddRow(null)) return;

    this.createFormGroup(
      0,
      this.component.settings.staffUnitOwnerId,
      null, null, null, null, null, null, null, null, null
    )

    e.sender.addRow(this.component.form);
  }

  public editRow(e: EditEvent) {
    const item: VichRegisterComponentGridDataItem = e.dataItem;

    if (!this.checkCanAddRow(item.id)) return;

    this.createFormGroup(
      item.log.id,
      item.log.staffUnitId,
      item.log.diseaseNumber,
      item.log.patientFio,
      item.log.typeServiceId,
      {
        id: item.log.typeResearchId ?? null,
        text:
          item.log.typeResearchId
            ? this.component.typesResearchSource.find(t => t.id === item.log.typeResearchId).text
            : item.log.customTypeResearch
      },
      {
        id: item.log.typeWorkId ?? null,
        text:
          item.log.typeWorkId
            ? this.component.typesTypeWorkSource.find(t => t.id === item.log.typeWorkId).text
            : item.log.customTypeWork
      },
      item.log.researchCount,
      item.log.researchProtocol,
      item.log.subdivisionId,
      item.log.operatingTime
    );

    e.sender.editRow(e.rowIndex, this.component.form);
  }

  public saveRow(e: SaveEvent) {
    const value = this.component.form.value

    // Если новая строка
    if (e.isNew) this.saveNewRow(value, e.sender, e.rowIndex)
    // Если редактирование
    else this.saveExistRow(value, e.sender, e.rowIndex)
  }

  public removeRow(e: RemoveEvent) {
    const id = e.dataItem.id;
    this.alertService.defaultAlertOption.question().mod(x => {
      x.title = 'Подтверждение';
      x.message = 'Удалить случай работы с ВИЧ?';
      x.buttons[1].text = 'Удалить'
      x.buttons[1].callBack = () =>
        this.dataService.removeLog$(id)
          .pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
            next: () => {
              this.hasChange = true
              this.directoryData.deleteItemByIds(true, id)
            },
            error: () => this.alertService.defaultAlertOption.warning().mod(x => {
              x.message = 'При удалении записи о случае работы с ВИЧ произошла ошибка!<br>Рекомендуем перезагрузить страницу'
            }).showAlert()
          })
    }).showAlert();
  }

  /** Закрыть редактируемую строку */
  public closeRow(grid: GridComponent, rowIndex: number) {
    grid.closeRow(rowIndex);
    this.component.form = null;
  }

  private saveNewRow(
    {
      staffUnitId, diseaseNumber, patientFio, typeServiceId, typeResearch,
      typeWork, researchCount, researchProtocol, subdivisionId, operatingTime
    }: IVichRegisterGridComponentFormValue,
    sender: GridComponent,
    rowIndex: number
  ) {
    this.dataService.addLog$({
      staffUnitId,
      date: this.component.settings.date,
      diseaseNumber,
      patientFio,
      typeServiceId: typeServiceId,
      typeResearchId: typeResearch?.id ?? null,
      customTypeResearch: typeResearch?.id ? null : typeResearch?.text,
      typeWorkId: typeWork?.id ?? null,
      customTypeWork: typeWork?.id ? null : typeWork?.text,
      researchCount,
      researchProtocol,
      subdivisionId,
      operatingTime
    }).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.hasChange = true
        this.directoryData.addItems(true, this.vichLogToRegisterComponentGridDataItem(value));
        this.closeRow(sender, rowIndex)
      }, error: () => {
        this.alertService.defaultAlertOption.warning().mod(x => {
          x.message = 'При сохранении новой записи произошла ошибка!<br>Рекомендуем перезагрузить страницу'
        }).showAlert();
      }
    })
  }

  private saveExistRow(
    {
      id, staffUnitId, diseaseNumber, patientFio, typeServiceId, typeResearch,
      typeWork, researchCount, researchProtocol, subdivisionId, operatingTime
    }: IVichRegisterGridComponentFormValue,
    sender: GridComponent,
    rowIndex: number
  ) {
    this.dataService.editLog$({
      id,
      staffUnitId,
      diseaseNumber,
      patientFio,
      typeServiceId: typeServiceId,
      typeResearchId: typeResearch?.id ?? null,
      customTypeResearch: typeResearch?.id ? null : typeResearch?.text,
      typeWorkId: typeWork?.id ?? null,
      customTypeWork: typeWork?.id ? null : typeWork?.text,
      researchCount,
      researchProtocol,
      subdivisionId,
      operatingTime
    }).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.hasChange = true
        this.directoryData.updateItems2(true, this.vichLogToRegisterComponentGridDataItem(value))
        this.closeRow(sender, rowIndex)
      }, error: () => {
        this.alertService.defaultAlertOption.warning().mod(x => {
          x.message = 'При сохранении изменений произошла ошибка!<br>Рекомендуем перезагрузить страницу'
        }).showAlert();
      }
    })
  }

  /** Получить данные о занятом времени и длительности временного интервала **/
  private getBusyAndIntervalData(staffUnitOwnerId: number, notIncludeVichLogId: number): { totalBusy: number, timeIntervalLength: number } {
    const timeInterval = this.component.settings.timeInterval;

    return {
      totalBusy: this.directoryData.data
        .filter(x => x.log.staffUnitId == staffUnitOwnerId)
        .filter(x => x.log.id != notIncludeVichLogId)
        .reduce((previousValue, currentValue) => previousValue + currentValue.log.operatingTime, 0),
      timeIntervalLength: timeInterval.duration * 60
    }
  }

  /** Проверка возможности добавления нового случая работы с ВИЧ */
  private checkCanAddRow(notIncludeCovidLogId: number): boolean {
    const data = this.directoryData.data

    const can = data.length == 0 ?
      true :
      data
        .map(x => this.getBusyAndIntervalData(x.log.staffUnitId, notIncludeCovidLogId))
        .some(x => x.timeIntervalLength - x.totalBusy > 0)

    if (!can) {
      this.alertService.defaultAlertOption.information().mod(x => {
        x.message = 'Вы не можете добавить новую запись<br>Все рабочее время уже занято'
      }).showAlert();
    }

    return can;
  }

  public getChangeEvent(): IVichRegisterGridComponentChangeEvent {
    return {
      changed: this.hasChange,
      busyTime: this.directoryData.data.reduce((previousValue, currentValue) =>
        previousValue + currentValue.log.operatingTime, 0)
    }
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
