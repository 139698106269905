import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {TableCodePanelService} from "../../services/table-code-panel.service";
import {take, takeUntil} from "rxjs/operators";
import {
  Api1CodeControllerService,
  CodePanelItem
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-code-controller.service";
import {traceFunc} from "../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {traceClass} from "../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {trace} from "../../../../../../../../../src/app/modules/trace/operators/trace";

@Component({
  selector: 'app-table-code-panel',
  templateUrl: './table-code-panel.component.html',
  styleUrls: ['./table-code-panel.component.css']
})
@traceClass('TableCodePanelComponent')
export class TableCodePanelComponent implements OnInit, OnDestroy {

  public source: Array<CodePanelItem>;

  //Доступна ли для нажатия панель кодов табельного учета
  @Input() public disabled: boolean = true;

  // Стрим для контроля жизненного цикла компонента
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private tableCodePanelService: TableCodePanelService,
              private codeControllerService: Api1CodeControllerService,
              private readonly tracerService: TracerServiceBase) {

  }

  @traceFunc()
  ngOnInit(): void {
    this.codeControllerService.getCodesForCodePanel$().pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.source = value.sort((one, two) => one.key > two.key ? 1 : -1);
    });

    this.tableCodePanelService.selectedRow$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.disabled = !value || this.tableCodePanelService.disable;
    });
  }

  @traceFunc()
  onButtonClick(code: CodePanelItem) {
    this.tableCodePanelService.insertedCode$.next(code);
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
