@if (type === 'outOfStaffUnitRange') {
  <div class="root root-out-of-staff-unit-range">

  </div>
} @else if (type === 'outOfSubStaffUnitRange') {
  <div class="root root-out-of-sub-staff-unit-range">

  </div>
} @else if (type === 'inRange') {
  <div class="root root-in-range">

    @if (excludeMilk){
      <div class="milk-label"
           title="Компенсация за молоко"
      >
        мол
      </div>
    }

    @if (covidLogs?.length) {
      <div class="covid-label"
           title="COVID-19"
      >
        c:{{covidLogs | arraySumBy:operationTimeGetter | minutesToHours | kendoNumber:'#.##':'en'}}
      </div>
    }

    @if (covidLog2s?.length) {
      <div class="covid-label"
           title="COVID-19"
      >
        covid
      </div>
    }

    @if (vichLogs?.length){
      <div class="vich-label"
           title="ВИЧ"
      >
        v:{{vichLogs | arraySumBy:operationTimeGetter | minutesToHours | kendoNumber:'#.##':'en'}}
      </div>
    }

    @if (tuberLogs?.length){
      <div class="tuber-label"
           title="Туберкулез"
      >
        t:{{tuberLogs | arraySumBy:operationTimeGetter | minutesToHours | kendoNumber:'#.##':'en'}}
      </div>
    }

    <div class="data">

      <!--Отклонения-->
      @if (!!dayDeviation) {
        <div class="day-deviation"
             [title]="dayDeviation.name"
             [style]="{
           backgroundColor: dayDeviation.color
           }"
        >
          {{ dayDeviation.shortName }}

          @if (!!graphDay.dayDeviationCustomValue) {
            <div class="day-deviation-value">{{ graphDay.dayDeviationCustomValue }}</div>
          }
        </div>
      }

      <!--Временной интервал-->
      @if (timeInterval) {
        <div class="time-interval"
             [title]="'Продолжительность: ' + (timeInterval | timeIntervalDuration | kendoNumber:'#.##':'en') + 'ч. Ночные: ' + (timeInterval | timeIntervalNightDuration | kendoNumber:'#.##':'en') + 'ч.'"
        >
          {{ timeInterval.startInterval | timeIntervalToString }} <br> {{ timeInterval.endInterval | timeIntervalToString }}
          <!--Вычитать ли обед-->
          @if (graphDay.substractLunchFlag){
            <div title="Вычитать обед" class="substract-lunch-cell"></div>
          }
        </div>
      }

    </div>
  </div>
} @else {
  <div class="root root-error"
       title="Данные в ячейке не валидны"
  >

  </div>
}
