import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ICovidLog extends IEntityId, IEntityModify, IEntityDeletedFlag {
  /** Идентификатор сотрудника */
  staffUnitId: number;
  /** Дата, за которую составлена данная запись */
  date: Date;
  /** Номер истории болезни пациента */
  diseaseNumber: string;
  /** ФИО пациента */
  patientFio: string;
  /** Режим работы с Covid. */
  mode: number;
  /** Проведенные манипуляции */
  covidManipulationId: number | null;
  /** Количество проведенных исследований в рамках данной записи */
  researchCount: number | null;
  /** Время проведения */
  operatingTime: number;
}

@classBackend('CovidLog', 'timesheet')
@className('CovidLog')
/** Модель строки Covid журнала */
export class CovidLog implements ICovidLog {
  constructor(public id: number,
              public modifiedUserId: number,
              public modifiedDate: Date,
              public deletedFlag: boolean,
              public staffUnitId: number,
              public date: Date,
              public diseaseNumber: string,
              public patientFio: string,
              public mode: number,
              public covidManipulationId: number | null,
              public researchCount: number | null,
              public operatingTime: number,
  ) {
  }

  /** Получить заполненный по умолчанию */
  public static GetEmpty(staffUnitId: number, date: Date): CovidLog {
    return new CovidLog(0, null, null, false, staffUnitId, date, '',
      '', CovidLogModeEnum.ContactPatient, null, 0, 1);
  }
}

/** Перечеслитель режимов работы в "Красной зоне" */
export enum CovidLogModeEnum {
  /** Работа с контактными пациентами */
  ContactPatient = 1,
  /** Работа с пациентами, у которых подтвержден диагноз Covid */
  CovidIsConfirmed = 2
}
