import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RedactionDetailGridComponentRowModel } from "./redaction-detail-grid/redaction-detail-grid.component";
import { ExtensionObj } from "../../../../../../../../src/app/helpers/extensionObj";
import { DataResult, process, State } from "@progress/kendo-data-query";
import {
  IRedactionServiceGetResponse,
  RedactionBaseService, RedactionServiceGetResponse_Redaction
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import { Api1RedactionGraphService } from "../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/api1-redaction-graph.service";
import { Api1RedactionTableService } from "../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/api1-redaction-table.service";
import { LoadingIndicatorService } from "../../../../../../../../src/app/services/loading-indicator.service";
import {Observable, of, ReplaySubject, switchMap} from "rxjs";
import {filter, map, take, takeUntil} from "rxjs/operators";
import { AlertService } from "../../../../../../../../src/app/services/alert.service";
import { CellClickEvent } from "@progress/kendo-angular-grid";
import { ResponseObjError } from "../../../../../../../../src/app/classes/requestResults/responseObjError";
import { KendoNotificationService } from "../../../../../../../../src/app/services/kendo-notification.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  DeferSelectionService
} from "../../../../../../../../src/app/services/defer-selection-services/defer-selection.service";
import {
  KendoGridArrayDataSourceSelection
} from "../../../../../../../../src/app/classes/array-data-sources/selections/kendo-grid-array-data-source-selection";
import {RedactionGridDataSourceServiceBase} from "./services/redaction-grid-data-source.service";
import {
  ArrayDataSourceSelection
} from "../../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";
import {exElementByIndexOr} from "../../../../../../../../src/app/operators/ex-element-by-index-or";
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';

@Component({
  selector: 'app-redaction-select',
  templateUrl: './redaction-grid.component.html',
  styleUrls: ['./redaction-grid.component.css']
})
@traceClass('RedactionGridComponent')
export class RedactionGridComponent implements OnInit, OnDestroy {
  /** Для чего нужно отобразить редакции */
  @Input() redactionFor: 'graph' | 'table' | null = null;
  /** Идентификатор графика/табеля */
  @Input() graphOrTableId: number = null;

  /** Идентификатор выделенной ячеки(Использовать только для инициализации) */
  @Input() initSelectedRowId: number = null;

  /** Имеет ли возможность сравнивать редакции */
  @Input() hasComparerButton: boolean = false;

  /** Событие простмотра редакции */
  @Output() preview$: EventEmitter<RedactionDetailGridComponent_PreviewEvent> = new EventEmitter<RedactionDetailGridComponent_PreviewEvent>();

  /** Событие сравнения редакции */
  @Output() compare$: EventEmitter<number> = new EventEmitter<number>();

  /** Состояние таблицы */
  public state: State = {
    sort: [{ field: 'id', dir: "desc" }],
    group: [{ field: 'version', dir: "desc" }]
  }

  /** DataSource для таблицы */
  public gridView: DataResult;

  /** Данные ответа сервера со всеми редакциями */
  public data: IRedactionServiceGetResponse = null;

  /** Список выделенных строк */
  public rowSelected: Array<number>;

  /** Сервис для работы с сервером */
  private redactionBaseService: RedactionBaseService = null;

  /** Состояние колонки действий таблицы */
  public actionColumnState: ActionColumnState = new ActionColumnState(this.hasComparerButton);

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  };

  constructor(private readonly rGService: Api1RedactionGraphService,
              private readonly rTService: Api1RedactionTableService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly alertService: AlertService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly tracer: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit(): void {
    if (!this.redactionFor) {
      throw new Error('@Input redactionFor - не передан');
    }

    if (!this.graphOrTableId) {
      throw new Error('@Input graphOrTableId - не передан');
    }

    switch (this.redactionFor) {
      case "graph":
        this.redactionBaseService = this.rGService;
        this.actionColumnState = new ActionColumnState(true);
        break;
      case "table":
        this.redactionBaseService = this.rTService;
        break;
      default: throw new Error('Out of range')
    }



    this.loadingIndicatorService.addToObservable(
      'Получение данных о редакциях',
      this.redactionBaseService.get$(this.graphOrTableId)
    ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.convertResponseAndFillGridView(value);
        this.rowSelected = this.initSelectedRowId ? [this.initSelectedRowId] : []
      }, error: error => {
        this.alertService.defaultAlertOption.downloadError().showAlert();
        return;
      }
    });
  }

  /** Обработка двойного клика по ячейке */
  @traceFunc()
  onDbCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    this.onPreview($event.originalEvent.dataItem);
  }

  /** Определять отображать/неотображать кнопку деталий */
  public detailTemplateShowIf(dataItem: RedactionGridComponentRedactionRowModel, index: number): boolean {
    return !!dataItem.status;
  }

  /** Обработка события нажатия на кнопку Копировать */
  @traceFunc()
  public onCopy(dataItem: RedactionGridComponentRedactionRowModel) {
    this.alertService.defaultAlertOption.confirmation().mod(x => {
      x.buttons[1].callBack = () => {
        this.redactionBaseService.copy$(dataItem.id)
          .pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
            next: value => {
              this.convertResponseAndFillGridView(value);
              this.onPreview(this.gridView.data[0].items.find(x => x.parentId == dataItem.id));
              this.kendoNotificationService.showSuccess({ content: 'Редакция скопирована' })
            }, error: error => {
              this.kendoNotificationService.showError({ content: 'Не удалось скопировать редакцию' })
              if (ResponseObjError.checkTypeReturnCode(error) == 'e4e9ea20-344b-44ed-a830-4da824ec7eff') {
                const innerError = (error as ResponseObjError<Array<string>>).data;
                this.alertService.defaultAlertOption.warning()
                  .mod(x => x.message = `<strong>${innerError}</strong><br><br>${x.message}`)
                  .showAlert();
                return;
              }
              this.alertService.defaultAlertOption.downloadError().showAlert();
              return;
            }
          })
      }
    }).showAlert();
  }

  /** Обработка события нажатия на кнопку Просмотр */
  @traceFunc()
  public onPreview(dataItem: RedactionGridComponentRedactionRowModel) {
    this.preview$.emit(new ExtensionObj(new RedactionDetailGridComponent_PreviewEvent()).modResult(x => {
      x.data = this.data;
      x.selectedRedactionId = dataItem.id;
    }))
  }

  /** Обработка события нажатия на кнопку Сравнить */
  @traceFunc()
  public onCompare(dataItem: RedactionGridComponentRedactionRowModel){
    this.compare$.emit(dataItem.id);
  }

  /** Конвертирует ответ сервера и заполняет источник данных для таблицы */
  private convertResponseAndFillGridView(response: IRedactionServiceGetResponse): void {
    this.data = response;

    const getterUserFio: (id: number) => string = id => {
      const user = !id ? null : response.users.find(x => x.id == id);
      return user?.fio ?? 'НЕИЗВЕСТНО';
    }

    const gridDataSource: RedactionGridComponentRedactionRowModel[] = response.redactions
      .map(item => new RedactionGridComponentRedactionRowModel(
        item.version,
        item.id,
        item.parentId,
        item.date,
        getterUserFio(item.userId),
        item.comment,
        item.routes.map(route => new RedactionDetailGridComponentRowModel(
          route.id,
          route.date,
          getterUserFio(route.userId),
          route.routeStatus,
          route.displayStatus,
          route.comment,
        ))
      ));

    this.gridView = process(gridDataSource, this.state);
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}


/** Тип настроек кнопок команд */
export type RedactionGridComponent_CommandButtonSettingsType = {
  preview: boolean,
  copy: boolean,
  compare: boolean,
}

/** Интерфейс компонента */
export interface IRedactionGridComponent{
  /**
   * Настройки для кнопок команд<br>
   * Если передан !value то колонка не будет отображаться<br>
   * Значение по умолчанию {@link RedactionGridComponent2.defaultCommandButtonSettings}
   */
  set commandButtonSettings(value: RedactionGridComponent_CommandButtonSettingsType);

  /** Сервис данных */
  dataSourceService: RedactionGridDataSourceServiceBase;

  /** Установить selection */
  set selection(value: ArrayDataSourceSelection<RedactionServiceGetResponse_Redaction, number>);

  /** Событие просмотра редакции */
  readonly preview$: EventEmitter<number>;

  /** Событие сравнения редакции */
  readonly compare$: EventEmitter<number>;

  /** Событие копирования редакции */
  readonly copy$: EventEmitter<number>;
}

@Component({
  selector: 'app-redaction-select2',
  templateUrl: './redaction-grid.component2.html',
  styleUrls: ['./redaction-grid.component.css'],
  providers: [DeferSelectionService],
})
@traceClass('RedactionGridComponent2')
export class RedactionGridComponent2 implements IRedactionGridComponent, OnInit, OnDestroy {

  /** Значение по умолчанию настроек кнопок команд */
  public static readonly defaultCommandButtonSettings: RedactionGridComponent_CommandButtonSettingsType = {
    preview: true,
    copy: true,
    compare: false
  };

  /** Состояние кнопок управления */
  public actionColumnState: ActionColumnState2 = new ActionColumnState2({...RedactionGridComponent2.defaultCommandButtonSettings});

  @Input() set commandButtonSettings(value: RedactionGridComponent_CommandButtonSettingsType){
    this.actionColumnState = !value ? undefined : new ActionColumnState2(value);
  }

  private _dataSourceService: RedactionGridDataSourceServiceBase;
  @Input() get dataSourceService(){
    return this._dataSourceService;
  }
  set dataSourceService(service){
    this._dataSourceService = service;
    this.gridData$ = !service ?
      undefined :
      service.dataSource.data$.pipe(map(value => process(value, this.state).data));

    this.selectionService.originSelection = service?.dataSource;
  }

  @Input() set selection(value: ArrayDataSourceSelection<RedactionServiceGetResponse_Redaction, number>){
    this.selectionService.originSelection = value;
  }

  @Output() public readonly preview$ = new EventEmitter<number>();

  @Output() public readonly compare$ = new EventEmitter<number>();

  @Output() public readonly copy$ = new EventEmitter<number>();

  /** Стрим данных для графика */
  public gridData$: Observable<RedactionServiceGetResponse_Redaction[]>;

  /** Стрим прокрутки до выделенной строки */
  public scrollToRow$: Observable<any>;

  /** Идентификатор текущей редакции */
  public currentRedactionId$: Observable<number>;

  /** Состояние таблицы */
  private state: State = {
    sort: [{ field: 'redaction.id', dir: "desc" }],
    group: [{ field: 'versionId', dir: "desc" }],
  }

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  };

  constructor(public readonly selectionService: DeferSelectionService<RedactionGridDataSourceServiceBase['dataSource'], KendoGridArrayDataSourceSelection<RedactionServiceGetResponse_Redaction, number>>,
              private readonly tracer: TracerServiceBase) {
    this.selectionService.isDeferApply = true;
    this.selectionService.tempCtorFunc = dataSource => new KendoGridArrayDataSourceSelection<RedactionServiceGetResponse_Redaction, number>(dataSource);

    //-- Устанавливаем стрим прокрутки
    this.scrollToRow$ = this.selectionService.tempSelection.data$.pipe(
      switchMap(tempSelection => !tempSelection ? of(undefined) : tempSelection.withInitiator.data$),
      filter(withInitiator => !!withInitiator && withInitiator.initiator === 'program')
    );

    //-- Устанавливаем стрим идентификатора выбранной редакции
    this.currentRedactionId$ = this.selectionService.originSelectionDataSource.data$
      .pipe(
        switchMap(value => !value ? [] : value.selectedIds.data$),
        exElementByIndexOr(0, -1000000)
      );

    //-- Вызов события изменения
    this.selectionService.tempSelection.data$.pipe(
      switchMap(tempSelection => !!tempSelection ? tempSelection.selectedIds.data$ : of([])),
      takeUntil(this.streams$.unsubscribe)
    ).subscribe(ids => {
      return ids.length === 0 ? undefined : ids[0];
    })
  }

  @traceFunc()
  ngOnInit(): void {
  }

  /** Определять отображать/не отображать кнопку деталий */
  public detailTemplateShowIf(dataItem: RedactionServiceGetResponse_Redaction, index: number): boolean {
    return !!dataItem.status;
  }

  /** Обработка события нажатия на кнопку Копировать */
  @traceFunc()
  public onCopy(dataItem: RedactionServiceGetResponse_Redaction) {
    this.copy$.emit(dataItem.redaction.id)
  }

  /** Обработка двойного клика по ячейке */
  @traceFunc()
  public onDbCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    const dataItem = $event.originalEvent.dataItem as RedactionServiceGetResponse_Redaction;
    this.onPreview(dataItem);
  }

  /** Обработка события нажатия на кнопку Просмотр */
  @traceFunc()
  public onPreview(dataItem: RedactionServiceGetResponse_Redaction) {
    this.selectionService.tempSelection.data?.setItems([dataItem]);
    this.selectionService.apply();
    this.preview$.emit(dataItem.redaction.id);
  }

  /** Обработка события нажатия на кнопку Сравнить */
  @traceFunc()
  public onCompare(dataItem: RedactionServiceGetResponse_Redaction){
    this.compare$.emit(dataItem.redaction.id);
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}


/** Модель строки компонента */
class RedactionGridComponentRedactionRowModel {
  /**
   * Конструктор
   * @param version Версия к которой относится редакция
   * @param id Идентификатор
   * @param parentId Родитель
   * @param date Дата редакции
   * @param modifiedFio Пользователь создавший редакцию
   * @param comment Комментарий пользователя сосдавший редакцию
   * @param routes Макшруты согласования
   */
  constructor(public version: number,
    public id: number,
    public parentId: number,
    public date: Date,
    public modifiedFio: string,
    public comment: string,
    public routes: RedactionDetailGridComponentRowModel[],) {
  }

  /** Статус */
  get status(): string {
    return !this.routes || this.routes.length == 0 ? null : this.routes[0].displayStatus.text;
  }
}

/** Класс события выделения */
export class RedactionDetailGridComponent_PreviewEvent {
  data: IRedactionServiceGetResponse;
  selectedRedactionId: number;
}

/** Класс управления колонкой команд в таблице редакций */
class ActionColumnState{
  /** Ширина колонки */
  public readonly columnWidth: number;

  constructor(public readonly hasCompareButton: boolean) {
    const tdPadding = 12; //Отступ у td
    const buttonWidth = 32; //Ширина кнопки
    const buttonMargin = 8; //Отступ между кнопками
    const reserve = 5; //Запас на не точность

    const buttonTotal = 2 + (hasCompareButton ? 1 : 0);

    this.columnWidth = (tdPadding * 2) + (reserve) + (buttonWidth * buttonTotal) + (buttonMargin * (buttonTotal - 1));
  }
}

/** Класс управления колонкой команд в таблице редакций */
class ActionColumnState2{
  /** Ширина колонки */
  public readonly columnWidth: number;

  constructor(public readonly settings: RedactionGridComponent_CommandButtonSettingsType) {
    const tdPadding = 12; //Отступ у td
    const buttonWidth = 32; //Ширина кнопки
    const buttonMargin = 8; //Отступ между кнопками
    const reserve = 5; //Запас на не точность

    const buttonTotal = Object.values(settings).filter(x => !!x).length;

    this.columnWidth = (tdPadding * 2) + (reserve) + (buttonWidth * buttonTotal) + (buttonMargin * (buttonTotal - 1));
  }
}
