import { Injectable } from '@angular/core';
import { RootPathItem } from '../../../classes/uiRootPath/root-path-item';
import { AppSettingsService } from '../../app-settings.service';

/**
 * Сервис получения путей запроса для WebApi1
 */
@Injectable({
  providedIn: 'root',
})
export class WebApi1Service {
  /**
   * Базовый путь
   */
  private readonly baseRoot: RootPathItem = null;

  /**
   * Контроллеры WebApi1
   */
  public controllers = {

    log: {
      controller: new RootPathItem('Log', () => this.baseRoot),
      actions: {
        error: new RootPathItem('Error', () => this.controllers.log.controller),
        warn: new RootPathItem('Warn', () => this.controllers.log.controller),
      },
    },

    graph: {
      controller: new RootPathItem('Graph', () => this.baseRoot),
      actions: {
        getVersionGraph: new RootPathItem('GetVersionGraph', () => this.controllers.graph.controller),
      },
    },

    graphRedaction: {
      controller: new RootPathItem('GraphRedaction', () => this.baseRoot),
      actions: {},
    },

    graphTableApprovalsControl: {
      controller: new RootPathItem('GraphTableApprovalsControl', () => this.baseRoot),
      actions: {
        getAllApprovalUsers: new RootPathItem('GetAllApprovalUsers', () => this.controllers.graphTableApprovalsControl.controller),
        getGraphApprovals: new RootPathItem('GetGraphApprovals', () => this.controllers.graphTableApprovalsControl.controller),
        getTableApprovals: new RootPathItem('GetTableApprovals', () => this.controllers.graphTableApprovalsControl.controller),
        addTableApprovals: new RootPathItem('AddTableApprovals', () => this.controllers.graphTableApprovalsControl.controller),
        addGraphApprovals: new RootPathItem('AddGraphApprovals', () => this.controllers.graphTableApprovalsControl.controller),
        deleteTableApprovals: new RootPathItem('DeleteTableApprovals', () => this.controllers.graphTableApprovalsControl.controller),
        deleteGraphApprovals: new RootPathItem('DeleteGraphApprovals', () => this.controllers.graphTableApprovalsControl.controller),
        reorderGraphApprovals: new RootPathItem('ReorderGraphApprovals', () => this.controllers.graphTableApprovalsControl.controller),
        reorderTableApprovals: new RootPathItem('ReorderTableApprovals', () => this.controllers.graphTableApprovalsControl.controller),
      },
    },

    date: {
      controller: new RootPathItem('Date', () => this.baseRoot),
      actions: {
        getYears: new RootPathItem('GetYears', () => this.controllers.date.controller),
        getPosibleMonth: new RootPathItem('GetPosibleMonth', () => this.controllers.date.controller),
        getCalendarDaysForYear: new RootPathItem('GetCalendarDaysForYear', () => this.controllers.date.controller),
        setDayType: new RootPathItem('SetDayType', () => this.controllers.date.controller),
      },
    },

    dayType: {
      controller: new RootPathItem('DayType', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('GetAll', () => this.controllers.dayType.controller),
      }
    },

    subdivision: {
      controller: new RootPathItem('Subdivision', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.subdivision.controller),
        add: new RootPathItem('Add', () => this.controllers.subdivision.controller),
        save: new RootPathItem('Save', () => this.controllers.subdivision.controller),
        addVersion: new RootPathItem('AddVersion', () => this.controllers.subdivision.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.subdivision.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.subdivision.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.subdivision.controller),
        getSubdivisionByDateAndId: new RootPathItem('GetSubdivisionByDateAndId', () => this.controllers.subdivision.controller),
        getSubdivisionByYearMonthAndId: new RootPathItem('GetSubdivisionByYearMonthAndId', () => this.controllers.subdivision.controller),
        reorder: new RootPathItem('Reorder', () => this.controllers.subdivision.controller),
        editEndDate: new RootPathItem('EditEndDate', () => this.controllers.subdivision.controller),
      },
    },

    workMode: {
      controller: new RootPathItem('WorkMode', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.workMode.controller),
        add: new RootPathItem('Add', () => this.controllers.workMode.controller),
        save: new RootPathItem('Save', () => this.controllers.workMode.controller),
        addVersion: new RootPathItem('AddVersion', () => this.controllers.workMode.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.workMode.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.workMode.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.workMode.controller),
      },
    },

    dayDeviation: {
      controller: new RootPathItem('DayDeviation', () => this.baseRoot),
      actions: {
        getAllDayDeviations: new RootPathItem('GetAllDayDeviations', () => this.controllers.dayDeviation.controller),
        addDayDeviation: new RootPathItem('AddDayDeviation', () => this.controllers.dayDeviation.controller),
        markDayDeviationDeleted: new RootPathItem('MarkDayDeviationDeleted', () => this.controllers.dayDeviation.controller),
        markDayDeviationsDeleted: new RootPathItem('MarkDayDeviationsDeleted', () => this.controllers.dayDeviation.controller),
      },
    },

    timeInterval: {
      controller: new RootPathItem('TimeInterval', () => this.baseRoot),
      actions: {
        getAllTimeIntervalsBySubdivisionId: new RootPathItem('GetAllTimeIntervalsBySubdivisionId', () => this.controllers.timeInterval.controller),
        addTimeIntervalToSubdivision: new RootPathItem('AddTimeIntervalToSubdivision', () => this.controllers.timeInterval.controller),
        deleteTimeIntervalLinks: new RootPathItem('DeleteTimeIntervalLinks', () => this.controllers.timeInterval.controller),
      },
    },

    //Controls
    graphControl: {
      controller: new RootPathItem('GraphControl', () => this.baseRoot),
      actions: {
        getDataForGraph: new RootPathItem('GetDataForGraph', () => this.controllers.graphControl.controller),
        getGraphRow: new RootPathItem('GetGraphRow', () => this.controllers.graphControl.controller),
        calculateRowNormaFact: new RootPathItem('CalculateNormaAndFact', () => this.controllers.graphControl.controller),
        calculateNormaForPosition: new RootPathItem('CalculateNormaForPosition', () => this.controllers.graphControl.controller),
        saveGraph: new RootPathItem('SaveGraph', () => this.controllers.graphControl.controller),
        deleteStaffUnit: new RootPathItem('DeleteStaffUnit', () => this.controllers.graphControl.controller),
        addMoonlighter: new RootPathItem('AddMoonlighter', () => this.controllers.graphControl.controller),
        editMoonlighter: new RootPathItem('EditMoonlighter', () => this.controllers.graphControl.controller),
        addProxy: new RootPathItem('AddProxy', () => this.controllers.graphControl.controller),
        editProxy: new RootPathItem('EditProxy', () => this.controllers.graphControl.controller),
        addCombinationBusy: new RootPathItem('AddCombinstionBusy', () => this.controllers.graphControl.controller),
        addCombinationFree: new RootPathItem('AddCombinstionFree', () => this.controllers.graphControl.controller),
        editCombinationBusy: new RootPathItem('EditCombinationBusy', () => this.controllers.graphControl.controller),
        editCombinationFree: new RootPathItem('EditCombinationFree', () => this.controllers.graphControl.controller),
        addDuty: new RootPathItem('AddDuty', () => this.controllers.graphControl.controller),
        editDuty: new RootPathItem('EditDuty', () => this.controllers.graphControl.controller),
        addUvorFree: new RootPathItem('AddUvorFree', () => this.controllers.graphControl.controller),
        addUvorBusy: new RootPathItem('AddUvorBusy', () => this.controllers.graphControl.controller),
        editUvorFree: new RootPathItem('EditUvorFree', () => this.controllers.graphControl.controller),
        editUvorBusy: new RootPathItem('EditUvorBusy', () => this.controllers.graphControl.controller),
        addExcludeMilkLog: new RootPathItem('AddExcludeMilkLog', () => this.controllers.graphControl.controller),
        deleteExcludeMilkLog: new RootPathItem('DeleteExcludeMilkLog', () => this.controllers.graphControl.controller),
        checkErrors: new RootPathItem('CheckErrors', () => this.controllers.graphControl.controller),
      },
    },

    tableConthol: {
      controller: new RootPathItem('TableControl', () => this.baseRoot),
      actions: {
        getTableRows: new RootPathItem('GetTableRows', () => this.controllers.tableConthol.controller),
        saveTable: new RootPathItem('SaveTable', () => this.controllers.tableConthol.controller),
      },
    },

    subdivisionsTreeListControl: {
      controller: new RootPathItem('SubdivisionsTreeListControl', () => this.baseRoot),
      actions: {
        getForTimeSheet: new RootPathItem('GetForTimeSheet', () => this.controllers.subdivisionsTreeListControl.controller),
        getForTimeSheet2: new RootPathItem('GetForTimeSheet2', () => this.controllers.subdivisionsTreeListControl.controller),
        getForDate: new RootPathItem('GetForDate', () => this.controllers.subdivisionsTreeListControl.controller),
      },
    },

    selectWorkingEmployeesControl: {
      controller: new RootPathItem('SelectWorkingEmployeesControl', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.selectWorkingEmployeesControl.controller),
      },
    },

    staffUnit: {
      controller: new RootPathItem('StaffUnit', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.staffUnit.controller),
        save: new RootPathItem('Save', () => this.controllers.staffUnit.controller),
        addVersion: new RootPathItem('AddVersion', () => this.controllers.staffUnit.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.staffUnit.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.staffUnit.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.staffUnit.controller),
        delete: new RootPathItem('Delete', () => this.controllers.staffUnit.controller),
        editEndDate: new RootPathItem('EditEndDate', () => this.controllers.staffUnit.controller),
        editStartDate: new RootPathItem('EditStartDate', () => this.controllers.staffUnit.controller),
        transfer: new RootPathItem('Transfer', () => this.controllers.staffUnit.controller),
      },
    },

    tableCode: {
      controller: new RootPathItem('TableCode', () => this.baseRoot),
      actions: {
        isComputedCombination: new RootPathItem('IsComputedCombination', () => this.controllers.tableCode.controller),
      },
    },

    code: {
      controller: new RootPathItem('Code', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('GetAll', () => this.controllers.code.controller),
        getCodesForCodePanel: new RootPathItem('GetTableCodeItemForUICodePanel', () => this.controllers.code.controller),
      },
    },

    financingSource: {
      controller: new RootPathItem('FinancingSource', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('GetAll', () => this.controllers.financingSource.controller),
      },
    },

    occupationType: {
      controller: new RootPathItem('OccupationType', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.occupationType.controller),
        get: new RootPathItem('Get', () => this.controllers.occupationType.controller),
        save: new RootPathItem('Save', () => this.controllers.occupationType.controller),
        delete: new RootPathItem('Delete', () => this.controllers.occupationType.controller),
      },
    },

    workModeType: {
      controller: new RootPathItem('WorkModeType', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('GetAll', () => this.controllers.workModeType.controller),
      },
    },

    reportPeriod: {
      controller: new RootPathItem('ReportPeriod', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.reportPeriod.controller),
        get: new RootPathItem('Get', () => this.controllers.reportPeriod.controller),
        save: new RootPathItem('Save', () => this.controllers.reportPeriod.controller),
        delete: new RootPathItem('Delete', () => this.controllers.reportPeriod.controller),
      },
    },


    employee: {
      controller: new RootPathItem('Employee', () => this.baseRoot),
      actions: {
        getByOwnerId: new RootPathItem('GetByOwnerId', () => this.controllers.employee.controller),
        deleteEmployee: new RootPathItem('Delete', () => this.controllers.employee.controller),
        add: new RootPathItem('Add', () => this.controllers.employee.controller),
        save: new RootPathItem('Save', () => this.controllers.employee.controller),
        getWorkingEmployees: new RootPathItem('GetWorkingEmployees', () => this.controllers.employee.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.employee.controller),
        addEmployeeVersion: new RootPathItem('AddVersion', () => this.controllers.employee.controller),
        editEmployeeVersion: new RootPathItem('EditVersion', () => this.controllers.employee.controller),
        getForDate: new RootPathItem('GetForDate', () => this.controllers.employee.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.employee.controller),
        editEndDate: new RootPathItem('EditEndDate', () => this.controllers.employee.controller),
      },
    },

    employeeNamesDeclension: {
      controller: new RootPathItem('EmployeeNamesDeclension', () => this.baseRoot),
      actions: {
        getEmployeeNamesDeclensionsForEmployee: new RootPathItem('GetEmployeeNamesDeclensionsForEmployee', () => this.controllers.employeeNamesDeclension.controller),
        getOrCreateEmployeeNamesDeclensionsForEmployee: new RootPathItem('GetOrCreateEmployeeNamesDeclensionsForEmployee', () => this.controllers.employeeNamesDeclension.controller),
        generateEmployeeNamesDeclensionsForEmployeeFullName: new RootPathItem('GenerateEmployeeNamesDeclensionsForEmployeeFullName', () => this.controllers.employeeNamesDeclension.controller),
        save: new RootPathItem('Save', () => this.controllers.employeeNamesDeclension.controller),
      },
    },

    occupation: {
      controller: new RootPathItem('Occupation', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.occupation.controller),
        save: new RootPathItem('Save', () => this.controllers.occupation.controller),
        addVersion: new RootPathItem('AddVersion', () => this.controllers.occupation.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.occupation.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.occupation.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.occupation.controller),
        updateOccupationCodes: new RootPathItem('UpdateOccupationCodes', () => this.controllers.occupation.controller),
        getForDate: new RootPathItem('GetForDate', () => this.controllers.occupation.controller),
      },
    },

    printReport: {
      controller: new RootPathItem('PrintReport', () => this.baseRoot),
      actions: {
        getPrintFormList: new RootPathItem('GetPrintFormList', () => this.controllers.printReport.controller),
        printGraphReport: new RootPathItem('PrintGraphReport', () => this.controllers.printReport.controller),
        printProxyMemoReport: new RootPathItem('PrintProxyMemoReport', () => this.controllers.printReport.controller),
        printOverTimeMemoReport: new RootPathItem('PrintOverTimeMemoReport', () => this.controllers.printReport.controller),
        printFL_Report: new RootPathItem('PrintFL_Report', () => this.controllers.printReport.controller),
        printTimeIntervalComporisonReport: new RootPathItem('PrintTimeIntervalComporisonReport', () => this.controllers.printReport.controller),
        printTotalCovidReport: new RootPathItem('PrintTotalCovidReport', () => this.controllers.printReport.controller),
        printTableReport: new RootPathItem('PrintTableReport', () => this.controllers.printReport.controller),
        printTableMilkReport: new RootPathItem('PrintTableMilkReport', () => this.controllers.printReport.controller),
        printCodeMemoReport: new RootPathItem('PrintCodeMemoReport', () => this.controllers.printReport.controller),
        printMemoRv: new RootPathItem('PrintMemoRv', () => this.controllers.printReport.controller),
        printSubdivisionListReport: new RootPathItem('PrintSubdivisionListReport', () => this.controllers.printReport.controller),
        printDutiesExecutionReport: new RootPathItem('PrintDutiesExecutionReport', () => this.controllers.printReport.controller),
        printBusyRatesReport: new RootPathItem('PrintBusyRatesReport', () => this.controllers.printReport.controller),
        printStaffBusyRatesReport: new RootPathItem('PrintStaffBusyRatesReport', () => this.controllers.printReport.controller),
        printInternalConcurrencyRegistration: new RootPathItem('PrintInternalConcurrencyRegistration', () => this.controllers.printReport.controller),
        printUvorRegistration: new RootPathItem('PrintUvorRegistration', () => this.controllers.printReport.controller),
        printCombinationRegistration: new RootPathItem('PrintCombinationRegistration', () => this.controllers.printReport.controller),
        printDutyRegistration: new RootPathItem('PrintDutyRegistration', () => this.controllers.printReport.controller),
        getCorrectionTableRedactions: new RootPathItem('GetCorrectionTableRedactions', () => this.controllers.printReport.controller),
        getCorrectionGraphRedactions: new RootPathItem('GetCorrectionGraphRedactions', () => this.controllers.printReport.controller),
        printSummaryReport: new RootPathItem('PrintSummaryReport', () => this.controllers.printReport.controller),
        printStaffListReport: new RootPathItem('PrintStaffListReport', () => this.controllers.printReport.controller),
        printPositionHoursCountReport: new RootPathItem('PrintPositionHoursCountReport', () => this.controllers.printReport.controller),
        printMemoStimul: new RootPathItem('PrintMemoStimul', () => this.controllers.printReport.controller),
        printCombinationRaport: new RootPathItem('PrintCombinationRaport', () => this.controllers.printReport.controller),
        printUvorRaport: new RootPathItem('PrintUvorRaport', () => this.controllers.printReport.controller),
        printTableCodeRegisterReport: new RootPathItem('PrintTableCodeRegisterReportNew', () => this.controllers.printReport.controller),
        printCovidRegisterReport: new RootPathItem('PrintCovidRegisterReport', () => this.controllers.printReport.controller),
        printMemoStimulDuty: new RootPathItem('PrintMemoStimulDuty', () => this.controllers.printReport.controller),
        printworkedTimeReport: new RootPathItem('PrintworkedTimeReport', () => this.controllers.printReport.controller),
        printLimitStaffUnitRatesReport: new RootPathItem('PrintLimitStaffUnitRatesReport', () => this.controllers.printReport.controller),
        printMilkOrderReport: new RootPathItem('PrintMilkOrderReport', () => this.controllers.printReport.controller),
        printMilkOrderFirstList: new RootPathItem('PrintMilkOrderFirstList', () => this.controllers.printReport.controller),
        printPatientMovement: new RootPathItem('PrintPatientMovement', () => this.controllers.printReport.controller),
        dailyFreeRatesReport: new RootPathItem('PrintDailyFreeRatesReport', () => this.controllers.printReport.controller),
        printShortProxyOrder: new RootPathItem('PrintShortProxyOrder', () => this.controllers.printReport.controller),
        printCheckReport: new RootPathItem('PrintCheckReport', () => this.controllers.printReport.controller),
        printHierarchiStrings: new RootPathItem('PrintHierarchiStrings', () => this.controllers.printReport.controller),
        printSubdivisionStaffUnitsReport: new RootPathItem('PrintSubdivisionStaffUnitsForDateReport', () => this.controllers.printReport.controller),
        printEmploymentContract: new RootPathItem('PrintEmploymentContract', () => this.controllers.printReport.controller),
        printOperatingPlan: new RootPathItem('PrintOperatingPlan', () => this.controllers.printReport.controller),
      },
    },

    staffUnitType: {
      controller: new RootPathItem('StaffUnitType', () => this.baseRoot),
      actions: {
        getByIds: new RootPathItem('GetByIds', () => this.controllers.staffUnitType.controller),
        getAll: new RootPathItem('GetAll', () => this.controllers.staffUnitType.controller),
      },
    },

    staffPositionsControl: {
      controller: new RootPathItem('StaffPositionsControl', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.staffPositionsControl.controller),
        getPositionDropDownList: new RootPathItem('GetPositionDropDownList', () => this.controllers.staffPositionsControl.controller),
      },
    },
    staffUnitsControl: {
      controller: new RootPathItem('StaffUnitsControl', () => this.baseRoot),
      actions: {
        getFreeRates: new RootPathItem('GetFreeRates', () => this.controllers.staffUnitsControl.controller),
        getForDate: new RootPathItem('GetForDate', () => this.controllers.staffUnitsControl.controller),
        saveStaffUnitWithShortRate: new RootPathItem('SaveStaffUnitWithShortRate', () => this.controllers.staffUnitsControl.controller),
        getStaffUnitsForProxy: new RootPathItem('GetStaffUnitListForProxyList', () => this.controllers.staffUnitsControl.controller),
        getBasicOrExternalStaffUnitByEmployeeOwnerId: new RootPathItem('GetBasicOrExternalStaffUnitByEmployeeOwnerId', () => this.controllers.staffUnitsControl.controller),
        getDismissedStaffUnitsBySubdivisionId: new RootPathItem('GetDismissedStaffUnitsBySubdivisionId', () => this.controllers.staffUnitsControl.controller),
        getPositionsForDate: new RootPathItem('GetPositionsForDate', () => this.controllers.staffUnitsControl.controller),
      },
    },

    covid: {
      controller: new RootPathItem('Covid', () => this.baseRoot),
      actions: {
        getManipulations: new RootPathItem('GetManipulations', () => this.controllers.covid.controller),
        addCovidLog: new RootPathItem('AddCovidLog', () => this.controllers.covid.controller),
        editCovidLog: new RootPathItem('EditCovidLog', () => this.controllers.covid.controller),
        removeCovidLog: new RootPathItem('RemoveCovidLog', () => this.controllers.covid.controller),
        getCovidLogs: new RootPathItem('GetCovidLogs', () => this.controllers.covid.controller),
        canAddEditRemove: new RootPathItem('CanAddEditRemove', () => this.controllers.covid.controller),
        getCovidRegisterData: new RootPathItem('GetCovidRegisterData', () => this.controllers.covid.controller),
      },
    },

    covid2: {
      controller: new RootPathItem('Covid2', () => this.baseRoot),
      actions: {
        addCovidLog: new RootPathItem('AddCovidLog', () => this.controllers.covid2.controller),
        editCovidLog: new RootPathItem('EditCovidLog', () => this.controllers.covid2.controller),
        removeCovidLog: new RootPathItem('RemoveCovidLog', () => this.controllers.covid2.controller),
        getCovidLogs: new RootPathItem('GetCovidLogs', () => this.controllers.covid2.controller),
        canAddEditRemove: new RootPathItem('CanAddEditRemove', () => this.controllers.covid2.controller),
      },
    },

    vich: {
      controller: new RootPathItem('Vich', () => this.baseRoot),
      actions: {
        getTypesService: new RootPathItem('GetTypesService', () => this.controllers.vich.controller),
        getTypesResearch: new RootPathItem('GetTypesResearch', () => this.controllers.vich.controller),
        getTypesWorkAndMaterialNames: new RootPathItem('GetTypesWorkAndMaterialNames', () => this.controllers.vich.controller),
        addLog: new RootPathItem('AddLog', () => this.controllers.vich.controller),
        editLog: new RootPathItem('EditLog', () => this.controllers.vich.controller),
        removeLog: new RootPathItem('RemoveLog', () => this.controllers.vich.controller),
        getLogs: new RootPathItem('GetLogs', () => this.controllers.vich.controller),
        canAddEditRemove: new RootPathItem('CanAddEditRemove', () => this.controllers.vich.controller),
      },

    },

    tuber: {
      controller: new RootPathItem('Tuber', () => this.baseRoot),
      actions: {
        getTypesService: new RootPathItem('GetTypesService', () => this.controllers.tuber.controller),
        getTypesResearch: new RootPathItem('GetTypesResearch', () => this.controllers.tuber.controller),
        addLog: new RootPathItem('AddLog', () => this.controllers.tuber.controller),
        editLog: new RootPathItem('EditLog', () => this.controllers.tuber.controller),
        removeLog: new RootPathItem('RemoveLog', () => this.controllers.tuber.controller),
        getLogs: new RootPathItem('GetLogs', () => this.controllers.tuber.controller),
        canAddEditRemove: new RootPathItem('CanAddEditRemove', () => this.controllers.tuber.controller),
      },
    },

    XlsxToXml: {
      controller: new RootPathItem('XlsxToXml', () => this.baseRoot),
      actions: {
        convert: new RootPathItem('convert', () => this.controllers.XlsxToXml.controller),
        downloadTemplate: new RootPathItem('downloadTemplate', () => this.controllers.XlsxToXml.controller),
      },
    },

    verificationFederalPayments: {
      controller: new RootPathItem('VerificationFederalPayments', () => this.baseRoot),
      actions: {
        getData: new RootPathItem('GetData', () => this.controllers.verificationFederalPayments.controller),
        saveOrUpdate: new RootPathItem('SaveOrUpdate', () => this.controllers.verificationFederalPayments.controller),
      },
    },

    toXmlComponent: {
      controller: new RootPathItem('ToXmlComponent', () => this.baseRoot),
      actions: {
        getCommonData: new RootPathItem('GetCommonData', () => this.controllers.toXmlComponent.controller),
        saveCommonData: new RootPathItem('SaveCommonData', () => this.controllers.toXmlComponent.controller),
        downloadTemplate: new RootPathItem('DownloadTemplate', () => this.controllers.toXmlComponent.controller),
        downloadXml: new RootPathItem('DownloadXml', () => this.controllers.toXmlComponent.controller),
      },
    },

    employersGridControl: {
      controller: new RootPathItem('EmployersGridControl', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.employersGridControl.controller),
      },
    },

    occupationsDirectoryGridControl: {
      controller: new RootPathItem('OccupationsDirectoryGridControl', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.occupationsDirectoryGridControl.controller),
      },
    },

    workModesDirectoryGridControl: {
      controller: new RootPathItem('WorkModesDirectoryGridControl', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.workModesDirectoryGridControl.controller),
      },
    },

    subdivisionsDirectoryTreeListControl: {
      controller: new RootPathItem('SubdivisionsDirectoryTreeListControl', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.subdivisionsDirectoryTreeListControl.controller),
        getEmployeesForResponsibility: new RootPathItem('GetEmployeesForResponsibility', () => this.controllers.subdivisionsDirectoryTreeListControl.controller),
      },
    },


    // Ответственные (Responsible)
    responsible: {
      controller: new RootPathItem('Responsible', () => this.baseRoot),
      actions: {
        getResponsibleEmployees: new RootPathItem('GetResponsibleEmployees', () => this.controllers.responsible.controller),
        getResponsibleSubdivisions: new RootPathItem('GetResponsibleSubdivisions', () => this.controllers.responsible.controller),
        add: new RootPathItem('Add', () => this.controllers.responsible.controller),
        delete: new RootPathItem('Delete', () => this.controllers.responsible.controller),

      },
    },

    monitoringGraph: {
      controller: new RootPathItem('GraphMonitoring', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.monitoringGraph.controller),
        getFor: new RootPathItem('GetFor', () => this.controllers.monitoringGraph.controller),
        detailGet: new RootPathItem('DetailGet', () => this.controllers.monitoringGraph.controller),
        approving: new RootPathItem('Approving', () => this.controllers.monitoringGraph.controller),
        cancelApproving: new RootPathItem('CancelApproving', () => this.controllers.monitoringGraph.controller),
        decline: new RootPathItem('Decline', () => this.controllers.monitoringGraph.controller),
        approved: new RootPathItem('Approved', () => this.controllers.monitoringGraph.controller),
        toUnderApproving: new RootPathItem('ToUnderApproving', () => this.controllers.monitoringGraph.controller),
        toUnderRevision: new RootPathItem('ToUnderRevision', () => this.controllers.monitoringGraph.controller),
        tryCancelRouting: new RootPathItem('TryCancelRouting', () => this.controllers.monitoringGraph.controller),
      },
    },

    monitoringTable: {
      controller: new RootPathItem('TableMonitoring', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.monitoringTable.controller),
        getFor: new RootPathItem('GetFor', () => this.controllers.monitoringTable.controller),
        detailGet: new RootPathItem('DetailGet', () => this.controllers.monitoringTable.controller),
        approving: new RootPathItem('Approving', () => this.controllers.monitoringTable.controller),
        cancelApproving: new RootPathItem('CancelApproving', () => this.controllers.monitoringTable.controller),
        decline: new RootPathItem('Decline', () => this.controllers.monitoringTable.controller),
        approved: new RootPathItem('Approved', () => this.controllers.monitoringTable.controller),
        toUnderApproving: new RootPathItem('ToUnderApproving', () => this.controllers.monitoringTable.controller),
        toUnderRevision: new RootPathItem('ToUnderRevision', () => this.controllers.monitoringTable.controller),
        tryCancelRouting: new RootPathItem('TryCancelRouting', () => this.controllers.monitoringTable.controller),
      },
    },

    redactionGraph: {
      controller: new RootPathItem('GraphRedaction', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.redactionGraph.controller),
        get2: new RootPathItem('Get2', () => this.controllers.redactionGraph.controller),
        get3: new RootPathItem('Get3', () => this.controllers.redactionGraph.controller),
        get4: new RootPathItem('Get4', () => this.controllers.redactionGraph.controller),
        copy: new RootPathItem('Copy', () => this.controllers.redactionGraph.controller),
        toApproving: new RootPathItem('ToApproving', () => this.controllers.redactionGraph.controller),
        fromApproving: new RootPathItem('FromApproving', () => this.controllers.redactionGraph.controller),
        startEdit: new RootPathItem('StartEdit', () => this.controllers.redactionGraph.controller),
        updateLastActivity: new RootPathItem('UpdateLastActivity', () => this.controllers.redactionGraph.controller),
        stopEdit: new RootPathItem('StopEdit', () => this.controllers.redactionGraph.controller),
      },
    },

    redactionTable: {
      controller: new RootPathItem('TableRedaction', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.redactionTable.controller),
        get2: new RootPathItem('Get2', () => this.controllers.redactionTable.controller),
        get3: new RootPathItem('Get3', () => this.controllers.redactionTable.controller),
        get4: new RootPathItem('Get4', () => this.controllers.redactionTable.controller),
        copy: new RootPathItem('Copy', () => this.controllers.redactionTable.controller),
        toApproving: new RootPathItem('ToApproving', () => this.controllers.redactionTable.controller),
        fromApproving: new RootPathItem('FromApproving', () => this.controllers.redactionTable.controller),
        startEdit: new RootPathItem('StartEdit', () => this.controllers.redactionTable.controller),
        updateLastActivity: new RootPathItem('UpdateLastActivity', () => this.controllers.redactionTable.controller),
        stopEdit: new RootPathItem('StopEdit', () => this.controllers.redactionTable.controller),
      },
    },

    rate: {
      controller: new RootPathItem('Rate', () => this.baseRoot),
      actions: {
        freeRateToAddEditMoonlighter: new RootPathItem('FreeRateToAddEditMoonlighter', () => this.controllers.rate.controller),
      },
    },

    position: {
      controller: new RootPathItem('Position', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.position.controller),
        getByOwnerIds: new RootPathItem('GetByOwnerIds', () => this.controllers.position.controller),
        add: new RootPathItem('Add', () => this.controllers.position.controller),
        save: new RootPathItem('Save', () => this.controllers.position.controller),
        addVersion: new RootPathItem('AddVersion', () => this.controllers.position.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.position.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.position.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.position.controller),
        delete: new RootPathItem('Delete', () => this.controllers.position.controller),
        reorder: new RootPathItem('Reorder', () => this.controllers.position.controller),
        editEndDate: new RootPathItem('EditEndDate', () => this.controllers.position.controller),
      },
    },

    positionRate: {
      controller: new RootPathItem('PositionRate', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.positionRate.controller),
        addVersion: new RootPathItem('AddVersion', () => this.controllers.positionRate.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.positionRate.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.positionRate.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.positionRate.controller),
      },
    },

    staffListSettings: {
      controller: new RootPathItem('StaffListSettings', () => this.baseRoot),
      actions: {
        getOccupationCodeLength: new RootPathItem('GetOccupationCodeLength', () => this.controllers.staffListSettings.controller),
        getRateStep: new RootPathItem('GetRateStep', () => this.controllers.staffListSettings.controller),
        isUsedDuty: new RootPathItem('GetIsUsedDutyFlag', () => this.controllers.staffListSettings.controller),
        isUsedUvor: new RootPathItem('GetIsUsedUvorFlag', () => this.controllers.staffListSettings.controller),
      },
    },

    timeSheetSettings: {
      controller: new RootPathItem('TimeSheetSettings', () => this.baseRoot),
      actions: {
        getOpenCovidDialogSubdivisionIds: new RootPathItem('GetOpenCovidDialogSubdivisionIds', () => this.controllers.timeSheetSettings.controller),
        getCovidLogic: new RootPathItem('GetCovidLogic', () => this.controllers.timeSheetSettings.controller),
      },
    },

    importEmployeeExtendedData: {
      controller: new RootPathItem('ImportEmployeeExtendedData', () => this.baseRoot),
      actions: {
        downloadImportTemplate: new RootPathItem('DownloadImportTemplate', () => this.controllers.importEmployeeExtendedData.controller),
        importData: new RootPathItem('ImportData', () => this.controllers.importEmployeeExtendedData.controller),
      },
    },

    employeeExtended: {
      controller: new RootPathItem('EmployeeExtended', () => this.baseRoot),
      actions: {
        savePassportData: new RootPathItem('SavePassportData', () => this.controllers.employeeExtended.controller),
        addPassportData: new RootPathItem('AddPassportData', () => this.controllers.employeeExtended.controller),
        saveAddressData: new RootPathItem('SaveAddressData', () => this.controllers.employeeExtended.controller),
        addAddressData: new RootPathItem('AddAddressData', () => this.controllers.employeeExtended.controller),
        saveInnData: new RootPathItem('SaveInnData', () => this.controllers.employeeExtended.controller),
        addInnData: new RootPathItem('AddInnData', () => this.controllers.employeeExtended.controller),
        savePhoneData: new RootPathItem('SavePhoneData', () => this.controllers.employeeExtended.controller),
        addPhoneData: new RootPathItem('AddPhoneData', () => this.controllers.employeeExtended.controller),
        saveSnilsData: new RootPathItem('SaveSnilsData', () => this.controllers.employeeExtended.controller),
        addSnilsData: new RootPathItem('AddSnilsData', () => this.controllers.employeeExtended.controller),
      },
    },

    patientMovement: {
      controller: new RootPathItem('PatientMovement', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.patientMovement.controller),
        save: new RootPathItem('Save', () => this.controllers.patientMovement.controller),
        send: new RootPathItem('Send', () => this.controllers.patientMovement.controller),
        delete: new RootPathItem('Delete', () => this.controllers.patientMovement.controller),
      },
    },

    patientMovementAdditionallyInfo: {
      controller: new RootPathItem('PatientMovementAdditionallyInfo', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.patientMovementAdditionallyInfo.controller),
        save: new RootPathItem('Save', () => this.controllers.patientMovementAdditionallyInfo.controller),
        delete: new RootPathItem('Delete', () => this.controllers.patientMovementAdditionallyInfo.controller),
      },
    },

    patientMovementAdditionallyInfoType: {
      controller: new RootPathItem('PatientMovementAdditionallyInfoType', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('GetAll', () => this.controllers.patientMovementAdditionallyInfoType.controller),
      },
    },

    patientMovementGridControl: {
      controller: new RootPathItem('PatientMovementGridControl', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.patientMovementGridControl.controller),
      },
    },

    patientMovementAdditionallyInfoGridControl: {
      controller: new RootPathItem('PatientMovementAdditionallyInfoGridControl', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.patientMovementAdditionallyInfoGridControl.controller),
      },
    },

    limitStaffUnit: {
      controller: new RootPathItem('LimitStaffUnit', () => this.baseRoot),
      actions: {
        save: new RootPathItem('Save', () => this.controllers.limitStaffUnit.controller),
        editEndDate: new RootPathItem('EditEndDate', () => this.controllers.limitStaffUnit.controller),
      },
    },

    limitStaffUnitRate: {
      controller: new RootPathItem('LimitStaffUnitRate', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.limitStaffUnitRate.controller),
        //#region Versioning
        addVersion: new RootPathItem('AddVersion', () => this.controllers.limitStaffUnitRate.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.limitStaffUnitRate.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.limitStaffUnitRate.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.limitStaffUnitRate.controller),
        //#endregion
        editEndDate: new RootPathItem('EditEndDate', () => this.controllers.limitStaffUnitRate.controller),
      },
    },

    limitStaffUnitsControl: {
      controller: new RootPathItem('LimitStaffUnitsControl', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.limitStaffUnitsControl.controller),
        add: new RootPathItem('Add', () => this.controllers.limitStaffUnitsControl.controller),
        delete: new RootPathItem('Delete', () => this.controllers.limitStaffUnitsControl.controller),
      },
    },

  };

  /**
   * Конструктор
   * @param appSettingsService
   */
  constructor(appSettingsService: AppSettingsService) {
    this.baseRoot = new RootPathItem(appSettingsService.webApiBaseUrl, null);
  }
}
