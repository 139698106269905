import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {IWorkMode} from "../../../../classes/domain/POCOs/stafflist/WorkMode";
import {IFinancingSource} from "../../../../classes/domain/POCOs/stafflist/FinancingSource";
import {IOccupation} from "../../../../classes/domain/POCOs/stafflist/Occupation";

@Injectable({
  providedIn: "root"
})
export class Api1RateControllerService{

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {
  }

  /**
   * Получить диаппазоны свободных ставок для добавления Moonlighter
   * @param month Месяц в который нужно вставить
   * @param subdivisionOwnerId
   * @param editStaffUnitOwnerId OwnerId редактируемой записи. Необходимо для передавать для исключения его в расчете
   */
  public freeRateToAddEditMoonlighter(month: Date, subdivisionOwnerId: number, editStaffUnitOwnerId: number | null){
    return this.httpClient.post<Api1RateControllerServiceResponse>(
      this.webApi1Service.controllers.rate.actions.freeRateToAddEditMoonlighter.toString(),
      {
        month: month,
        subdivisionOwnerId: subdivisionOwnerId,
        editStaffUnitOwnerId: editStaffUnitOwnerId
      }
    )
  }
}

export interface Api1RateControllerServiceResponse{
  financingSources: Array<Pick<IFinancingSource, 'id' | 'shortName'>>,
  workModes: Array<Pick<IWorkMode, 'id' | 'name'>>,
  occupations: Array<Pick<IOccupation, 'id' | 'name'>>,
  items: Array<Api1RateControllerServiceResponse_Item>
}

export interface Api1RateControllerServiceResponse_Item{
  start: Date,
  end: Date,
  rate: number,
  positionOwnerId: number,
  occupationId: number,
  financingSourceId: number,
  workModeId: number
}
