import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {
  GraphGridServerDataSourceService
} from "./services/graph-grid-server-data-sources/graph-grid-server-data-source.service";
import {
  Api1GraphControlControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {DbChangedListener} from "../../../../../../../src/app/services/signal-r/listeners/db-changed-listener";
import {
  TracerServiceBase
} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {xnameofPath} from "../../../../../../../src/app/functions/nameof";
import {DayTypeEnumObj} from "../../../../../../../src/app/classes/domain/enums/day-type-enum";

/** Тип строки источника данных для юи */
type UiDataSourceItemType = GraphGridServerDataSourceService['uiDataSource']['data']['rows'][0];

@Component({
  selector: 'app-graph-grid2',
  templateUrl: './graph-grid2.component.html',
  styleUrls: [
    './graph-grid2.component.css',
    '../../../../../../../src/app/css/appSelectedGridCell1.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@traceClass('GraphGrid2Component')
export class GraphGrid2Component implements OnInit, OnDestroy {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** для html */
  public readonly xPath = xnameofPath(this.asRow(undefined),'.', false);

  private _dataSource: GraphGridServerDataSourceService;
  /** Источник данных */
  public get dataSource(){
    return this._dataSource;
  }
  @Input() public set dataSource(value){
    if(this._dataSource){
      throw new Error('Повторная установка источника данных не допускается')
    }
    this._dataSource = value;
  }

  /** Конструктор */
  constructor(private readonly api1GraphControlControllerService: Api1GraphControlControllerService,
              private readonly dbChangedListener: DbChangedListener,

              private readonly tracerService: TracerServiceBase,) {
  }


  @traceFunc()
  /** @inheritdoc */
  public ngOnInit(): void {
    this._dataSource = new GraphGridServerDataSourceService(this.api1GraphControlControllerService, this.dbChangedListener); //TODO: временно инжектить в поле

    this.dataSource.reloadData({ //TODO: временно
      redactionId: 156048, //Женская консультация №4 2024,июль
      useSignalR: true,
    })

  }

  /** Типизировать объект в тип строки */
  public asRow(row: any): UiDataSourceItemType{
    return row;
  }

  /**
   * Получить идентификатор для ячейки
   * @param row строка
   * @param columnIndex индекс колонки
   * @return [идентификатор строки]/[индекс колонки]/[идентификатор редакции]
   */
  public getCellId(row: UiDataSourceItemType, columnIndex: number): string{
    return `${row.id.uid}/${columnIndex}/${this.dataSource.uiDataSource.data.redactionId}`;
  }

  @traceFunc()
  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

  protected readonly dayTypeEnum = DayTypeEnumObj;
}
