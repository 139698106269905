import {Injectable, OnDestroy} from "@angular/core";
import {ArrayDataSourceHasId, DataSource, DataSourceReadOnly} from "../../classes/array-data-sources/data-source";
import {ArrayDataSourceSelection} from "../../classes/array-data-sources/selections/array-data-source-selection";
import {xnameofPath} from "../../functions/nameof";
import {filter, map} from "rxjs/operators";
import {ArrayHelper} from "../../helpers/arrayHelper";


/** Тип для selection */
type SelectionType<TDataSource extends ArrayDataSourceHasId<any, any>> = ArrayDataSourceSelection<TDataSource['data'][0], ReturnType<TDataSource['idGetter']>>;

/**
 * Сервис отложенного применения изменения выделенных элементов.<br>
 * Создает новый экземпляр хранения выделенных элементов по переданной функции конструктор.<br>
 * Приводит новый экземпляр к состоянию оригинального, если он установлен(используется метод copyStateTo).<br>
 * Из оригинального хранилища транслируются все изменения в новый экземпляр, если он установлен(используется метод retransmitTo)<br>
 * @example Диалоговые окна выбора элементов
 */
@Injectable()
export class DeferSelectionService<
  TDataSource extends ArrayDataSourceHasId<any, any>,
  TTempSelection extends SelectionType<TDataSource>> implements OnDestroy{

  private _tempCtorFunc: (dataSource: TDataSource) => TTempSelection;
  /** Функция конструктор для создания временного селектора выделенных элементов */
  public set tempCtorFunc(value: (dataSource: TDataSource) => TTempSelection){
    this._tempCtorFunc = value;
  }

  /**
   * Применять изменения отложено?
   * 1. true(по умолчанию) - применит изменения только после вызова метода .apply()
   * 2. false - при изменении во временном, сразу применит и в оригинальном(вызовет метод .apply())
   */
  public isDeferApply: boolean = true;

  private _originSelectionDataSource = new DataSource<{isTemp: boolean, selection: SelectionType<TDataSource>}>()
  private _originSelectionDataSource2 = new DataSource<SelectionType<TDataSource>>();
  /** Источник данных для оригинальных selection<br> */
  public get originSelectionDataSource(): DataSourceReadOnly<SelectionType<TDataSource>>{
    return this._originSelectionDataSource2;
  }

  /**
   * Установить оригинальный selection.<br>
   * Поддерживает передачу DataSource, в этом случае создаст на основе него временный оригинальный selection
   */
  public set originSelection(value: TDataSource | SelectionType<TDataSource>){
    if(!value){ //Если сброс значения
      if(!!this._originSelectionDataSource.data){ //Предотвращаем повторную установку !value
        this._originSelectionDataSource.setData(undefined);
      }

      return;
    }

    if(value instanceof ArrayDataSourceHasId){
      this.setAsDataSource(value);
      return;
    }

    if(value instanceof ArrayDataSourceSelection){
      this.setAsSelection(value);
      return;
    }

    throw new Error('out of range');
  }

  private readonly _tempSelection = new DataSource<TTempSelection>();
  /** Источник данных для временного хранения выделенных элементов */
  public get tempSelection(): DataSourceReadOnly<TTempSelection>{
    return this._tempSelection;
  }

  private _hasSelection = new DataSource<boolean>();
  /** DataSource для состояния - имеются ли выделенные элементы. Будет транслировать только если значение изменилось */
  public get hasSelection(): DataSourceReadOnly<boolean>{
    return this._hasSelection;
  }

  private _hasSelectionChange = new DataSource<boolean>();
  /**
   * DataSource для состояния - имеются ли измененные выделенные элементы по сравнению с оригинальными<br>
   * Будет транслировать только если значение изменилось<br>
   * Если оригинальные не установлены, то будет сравнивать с []<br>
   */
  public get hasSelectionChange(): DataSourceReadOnly<boolean>{
    return this._hasSelectionChange;
  }

  constructor() {
    //-- Следим за освобождением ресурсов предыдущего оригинального selection
    this._originSelectionDataSource.beforeChange$
      .pipe(
        filter(x => !!x.currentData), //Если есть предыдущее значение
        filter(x => x.currentData.isTemp) //Если создан временный оригинальный selection
      ).subscribe(value => {
      value.currentData.selection.onDestroy();
    })

    //-- Следим за освобождением ресурсов предыдущего временного selection
    this._originSelectionDataSource.beforeChange$.subscribe(value => {
      if(!this._tempSelection.data){
        return;
      }

      this._tempSelection.data.onDestroy();
    })

    //-- Ретранслируем значения из расширенного источника данных оригинальных selection
    this._originSelectionDataSource2.setData(
      this._originSelectionDataSource.data$.pipe(map(value => value?.selection))
    );

    //-- Обработка изменения оригинального selection
    this._originSelectionDataSource.data$.subscribe(value => {
      this.setTempSelection(value);
    })

    //-- Обработка смены данных временного selection
    this._tempSelection.data$.subscribe(value => {
      if(!value){
        this.setDataToHasSelection(false);
        this.setDataToHasSelectionChange(false);
        return;
      }

      value.selectedIds.data$
        .pipe(
          map(value => value.length > 0)
        ).subscribe(hasSelection => {
          this.setDataToHasSelection(hasSelection);
      })

      value.selectedIds.data$
        .pipe(
          map(ids => {
            const originIds = this._originSelectionDataSource.data.selection.selectedIds.wasEmitted ?
              this._originSelectionDataSource.data.selection.selectedIds.data :
              [];

            return ArrayHelper.difference2(ids, originIds, (x1, x2) => x1 === x2).length > 0
          })
        ).subscribe(hasSelectionChange => {
          this.setDataToHasSelectionChange(hasSelectionChange);
      })
    })
    //--
  }

  /** Применить изменения на оригинальном если он существует */
  public apply(){
    if(this._tempSelection.data){ //Если временный выборщик установлен
      if(this._hasSelectionChange.data){ //Если установлен оригинальный выборщик и во временном имеются изменения
        this._tempSelection.data.copyStateTo(this._originSelectionDataSource.data.selection); //Применяем состояние
      }

      this.setDataToHasSelectionChange(false); //Запоминаем состояние выбранных элементов как стартовое
    }
  }

  /** Установить значение в источник данных временных выделенных элементов */
  protected setTempSelection(originSelection: {isTemp: boolean, selection: SelectionType<TDataSource>}){
    if(!this._tempCtorFunc){
      throw new Error(`${xPath.tempCtorFunc} НЕ установлена`);
    }

    if(!originSelection){
      if(!!this._tempSelection.data){
        this._tempSelection.setData(undefined);
      }
      return;
    }

    const instance = this._tempCtorFunc(originSelection.selection.dataSource as TDataSource);

    if(!originSelection.selection.selectedIds.wasEmitted){//Если оригинальный еще не транслировался
      if(this._tempSelection.data?.selectedIds?.wasEmitted){//Если есть предыдущий временный selection и при этом он транслировал
        instance.setIds([]);
      }
    } else {
      originSelection.selection.copyStateTo(instance);
    }
    originSelection.selection.retransmitTo(instance);

    if(!this.isDeferApply){ //Если нужно сразу транслировать на оригинальный из временного
      instance.retransmitTo(originSelection.selection);
    }

    this._tempSelection.setData(instance);
  }

  /** Установить оригинальный selection из dataSource */
  private setAsDataSource(dataSource: TDataSource){
    if(!dataSource){
      throw new Error('!dataSource not supported')
    }

    if(!!this._originSelectionDataSource.data){//Если имеется оригинальный selection
      if(this._originSelectionDataSource.data.selection.dataSource === dataSource){ //Если dataSource НЕ изменился
        return;
      }
    }

    const selection: SelectionType<TDataSource> = new ArrayDataSourceSelection<TDataSource["data"], ReturnType<TDataSource["idGetter"]>>(dataSource);

    if(this._originSelectionDataSource.data){
      this._originSelectionDataSource.data.selection.copyStateTo(selection);
    }

    this._originSelectionDataSource.setData({isTemp: true, selection: selection});
  }

  /** Установить оригинальный selection из selection */
  private setAsSelection(selection: SelectionType<TDataSource>){
    if(!selection){
      throw new Error('!selection not supported');
    }

    if(this._originSelectionDataSource.data?.selection === selection){ //Если selection не изменилась
      return;
    }

    this._originSelectionDataSource.setData({isTemp: false, selection: selection});
  }

  /** Установка данных в this.hasSelection */
  private setDataToHasSelection(value: boolean){
    if(this._hasSelection.data2 === value){
      return;
    }

    this._hasSelection.setData(value);
  }


  /** Установить имеются ли измененные выделенные элементы */
  private setDataToHasSelectionChange(value: boolean){
    if(this._hasSelectionChange.data2 === value){
      return;
    }

    this._hasSelectionChange.setData(value);
  }


  ngOnDestroy(){
    this._tempSelection.onDestroy();
    this._hasSelection.onDestroy();
    this._hasSelectionChange.onDestroy();
    this._originSelectionDataSource.onDestroy();
    this._originSelectionDataSource2.onDestroy();
  }
}

/** Для рендеринга ошибок */
const xPath = xnameofPath(DeferSelectionService<any, any>);
