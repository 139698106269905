import {Injectable, OnDestroy} from "@angular/core";
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";
import {AuthService} from "../../modules/auth/services/auth.service";
import {lastValueFrom, Observable, ReplaySubject, Subject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {AppSettingsService} from "../app-settings.service";
import {AlertService} from "../alert.service";

@Injectable({
  providedIn: "root"
})
export class SignalRService implements OnDestroy {
  /** SignalR хаб */
  private _hubConnection: HubConnection;
  /** SignalR хаб */
  public hubConnection(): HubConnection {
    return this._hubConnection;
  }

  /** Время переподключения */
  private _timeoutReconnect = 200;

  /** Стрим информирование присутствия/отсутствия подключения signalR */
  public hasConnection$ = new ReplaySubject<boolean>(1);

  /** Управление временем отключения */
  public disconnectionTimeManager: DisconnectionTimeManager = null;

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private appSettingsService: AppSettingsService,
              private authService: AuthService,
              private alertService: AlertService) {
    this.authService.isAuth$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(isAuth => {
      if (isAuth) {
        if (!this.disconnectionTimeManager) {
          this.disconnectionTimeManager = new DisconnectionTimeManager(true);
          this.disconnectionTimeManager.disconnectedTime$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
            if (value > this.appSettingsService.disconnectedSignalRMaxTime) {
              this.alertService.defaultAlertOption.warning().mod(x => {
                x.titleMessage = '';
                x.message = 'За время отсутствия подключения к сети,<br>данные могли устареть<br><br><strong>Рекомендуем сохранить и перезагрузить страницу</strong>'
              }).showAlert();
            }
          });
        }

        if (!this._hubConnection) {
          this.buildConnection();
          this._hubConnection.onclose(error => this.onClose(error));
          this.startConnection();
          return;
        }

        return;
      } else if (this._hubConnection && this._hubConnection.state == HubConnectionState.Connected) {
        this._hubConnection.stop().then();
        this.disconnectionTimeManager.onDestroy();
        this.disconnectionTimeManager = null;
        return;
      }
    })
  }

  /** Обработка события закрытия подключения signalR */
  private onClose(error?: Error) {
    console.log('SignalR соединение остановлено');

    this.authService.isAuth$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(isAuth => {
      if (!isAuth) { // Выходим если пользователь НЕ авторизован
        return;
      }

      this.hasConnection$.next(false);
      this.disconnectionTimeManager?.disconnected();

      setTimeout(() => this.startConnection(), this._timeoutReconnect);
    })
  }

  /** Построить подключение SignalR */
  private buildConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(this.appSettingsService.signalRPath,
        {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          logger: LogLevel.None,
          accessTokenFactory: () => {
            return lastValueFrom(this.authService.signalToken$)
          }
        })
      .build();
  }

  /** Подключится по SignalR */
  private startConnection() {
    this._hubConnection
      .start()
      .then(() => {
        console.log("SignalR соединение установлено");
        this.disconnectionTimeManager.connected();
        this.hasConnection$.next(true);
      })
      .catch(err => {
        setTimeout(() => { this.startConnection(); }, this._timeoutReconnect);
      })
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this._hubConnection?.stop().then();
    this.disconnectionTimeManager?.onDestroy();
  }
}

/** Управление временем простоя */
class DisconnectionTimeManager {
  /** Начало отключения */
  private startDisconnection: Date = null;
  /** Стрим сообщает о времени отсутствия подключения */
  public disconnectedTime$: Observable<number> = new Subject<number>();

  /**
   * Конструктор
   * @param disconnectedNow флаг - в данный момент подключение отсутствует
   */
  constructor(disconnectedNow: boolean = true) {
    if (disconnectedNow) {
      this.disconnected();
    }
  }

  /** Сообщить о подключении */
  public connected() {
    const disconnectedTime = this.startDisconnection ? Math.abs((+new Date() - +this.startDisconnection) / 1000) : 0;
    this.startDisconnection = null;
    (this.disconnectedTime$ as Subject<number>).next(disconnectedTime)
  }

  /** Сообщить о отключении */
  public disconnected() {
    this.startDisconnection = new Date();
  }

  /** Сообщить об уничтожении */
  onDestroy(): void {
    (this.disconnectedTime$ as Subject<number>).complete();
  }
}

