<div class='wrap' [formGroup]='form'>
  <span *ngIf="periodFieldVisible" class='label'>Период:</span>
  <kendo-dropdownlist  *ngIf="periodFieldVisible"
                        [data]="periods"
                        formControlName='period'
                        valueField='id'
                        textField='text'
                        style='width: 100%;'>
  </kendo-dropdownlist>
  <span *ngIf="!!subPeriods.length" class='label'>{{subPeriodLableText}}</span>
  <kendo-dropdownlist   *ngIf="!!subPeriods.length"
                        [data]='subPeriods'
                        formControlName="subPeriod"
                        valueField='id'
                        textField='text'
                        style='width: 100%;'>
  </kendo-dropdownlist>

  <span class='label'>Год:</span>
  <kendo-numerictextbox formControlName='year'
                        style='width: 100%;'
                        [min]='maxYear - 5'
                        [max]='maxYear'
                        [step]='1'
                        [format]='"#"'
                        [decimals]='0'
                        [autoCorrect]='true'>
  </kendo-numerictextbox>

  <ng-content select="[row1-label]"></ng-content>
  <ng-content select="[row1-control]"></ng-content>

</div>

<app-subdivision-treeview-with-checkbox2 [dataSourceService]="subdivisionDataSourceService"
                                         [selection]='selection'
                                         [expandAll]='expandAllSubdivisions'
                                         [checkableSettings]="checkableSettings"
                                         [displayTextFn]="displayTextFn"
                                         (checkedSubdivisions$)='setCheckedSubdivisionsValue($event)'
                                         (allSubdivisions$)='setAllSubdivisionsFlagValue($event)'>
</app-subdivision-treeview-with-checkbox2>

<div class='k-form-buttons' style='justify-content: flex-end;'>

  <button kendoButton
          type='button'
          primary='true'
          [disabled] = 'form.invalid'
          (click)='onClickOk()'
  >
    Сформировать
  </button>

  <button kendoButton
          type='button'
          (click)='onClickCancel()'
  >
    Отменить
  </button>

</div>
