/** Интерфейс сравнения двух объектов */
interface IComparer{
  /** Равны ли объекты. Перебирает все зарегистрированные поля до ПЕРВОГО НЕ РАВНОГО */
  get isEquals(): boolean;
}

/** Интерфейс сравнения двух объектов */
interface IComparer2{
  /**
   * Равны ли объекты.
   * Перебирает все зарегистрированные поля и только после сообщает равны ли или нет.
   * Использовать если в функциях сравнения используется логика и необходимо запуск всех валидаторов.
   * Данный метод по нагрузке больше чем isEquals
   */
  get isEquals2(): boolean;
}

/**
 * Сравнивает два объекта(одного типа) на равентство по зарегистрированным полям для сравнения
 * Использовать для частичного сравнивания объектов.
 */
export class ObjectComparer<T> implements IComparer, IComparer2{
  /** Композиция */
  private readonly _objectComparer: ObjectComparer2<T, T>;

  /**
   * Конструктор
   * @param item1 Первый объект
   * @param item2 Второй объект
   */
  constructor(private readonly item1: T, private readonly item2: T) {
    this._objectComparer = new ObjectComparer2<T, T>(item1, item2);
  }

  /**
   * Зарегистрировать поле для сравнения
   * @param keyItem ключ поля объектов
   * @param comparer функция сравнения
   */
  public registryProp<TProp extends keyof T>(keyItem: TProp, comparer: (item1: PropertyType<T, TProp>, item2: PropertyType<T, TProp>) => boolean = null){
    this._objectComparer.registryProp(keyItem, keyItem, comparer);
    return this;
  }

  public get isEquals(): boolean {
    return this._objectComparer.isEquals;
  }

  get isEquals2(): boolean {
    return this._objectComparer.isEquals2;
  }
}

/**
 * Сравнивает два объекта(РАЗНОГО типа) на равентство по зарегистрированным полям для сравнения
 * Использовать для частичного сравнивания объектов
 */
export class ObjectComparer2<T1, T2> implements IComparer, IComparer2{
  /** Массив всех зарегистрированных полей */
  private _propertyComparers: IComparer[] = [];

  /**
   * Конструктор
   * @param item1 Первый объект
   * @param item2 Второй объект
   */
  constructor(private readonly item1: T1,
              private readonly item2: T2) {
  }

  /**
   * Зарегистрировать поле для сравнения
   * @param keyItem1 ключ поля первого объекта
   * @param keyItem2 ключ поля второго объекта
   * @param comparer функция сравнения. По умолчанию ===
   */
  public registryProp<T1Prop extends keyof T1, T2Prop extends keyof T2>(keyItem1: T1Prop,
                                                                        keyItem2: T2Prop,
                                                                        comparer: (item1: PropertyType<T1, T1Prop>, item2: PropertyType<T2, T2Prop>) => boolean = null){

    this._propertyComparers.push(new PropertyComparer(this.item1, keyItem1, this.item2, keyItem2, comparer))
    return this;
  }

  public get isEquals(): boolean {
    return !this._propertyComparers.some(x => x.isEquals == false)
  }

  get isEquals2(): boolean {
    let isEquals = true;

    for (let i = 0; i < this._propertyComparers.length; i++) {
      if(!this._propertyComparers[i].isEquals){
        isEquals = false;
      }
    }

    return isEquals;
  }
}

/** Класс сравнения поля объектов */
class PropertyComparer<T1, T2, T1Prop extends keyof T1, T2Prop extends keyof T2> implements IComparer{

  /** Функция сравнивает два поля переданных объектов */
  private readonly _comparer : (item1: PropertyType<T1, T1Prop>, item2: PropertyType<T2, T2Prop>) => boolean;

  constructor(public readonly item1: T1,
              public readonly item1Prop: T1Prop,
              public readonly item2: T2,
              public readonly item2Prop: T2Prop,
              public readonly comparer: (item1: PropertyType<T1, T1Prop>, item2: PropertyType<T2, T2Prop>) => boolean = null) {
    this._comparer = !!comparer ? comparer : this.defaultComparer;
  }

  public get isEquals(){
    return this._comparer(this.item1[this.item1Prop], this.item2[this.item2Prop])
  }

  /** Функция по умолчанию для сравнения полей переданных объектов. Проверяет на === */
  private defaultComparer(item1: PropertyType<T1, T1Prop>, item2: PropertyType<T2, T2Prop>){
    return (item1 as any) === (item2 as any);
  }
}

/** Тип поля объекта */
type PropertyType<T, D extends keyof T> = T[D];
