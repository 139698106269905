/**
 * Сервис работы с контроллером TimeInterval
 */
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {Observable} from "rxjs";
import {TimeInterval} from "../../../../classes/domain/POCOs/timesheet_graph/TimeInterval";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class Api1TimeIntervalControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  /**
   * Получить все временные интервалы по Id подразделения
   */
  public getAllTimeIntervalsBySubdivisionId$(subdivisionId: number): Observable<Array<TimeInterval>>{
    return this.httpClient
      .get<Array<TimeInterval>>(this.webApi1Service.controllers.timeInterval.actions.getAllTimeIntervalsBySubdivisionId.toString(),
        {
          params: new HttpParams({
            fromObject: {
              subdivisionId: subdivisionId.toString()
            }})
        });
  }

  /**
   * Добавить временной интервал к подразделению
   * @param timeInterval
   */
  public addTimeIntervalToSubdivision$(subdivisionOwnerId: number, startInterval: number, endInterval: number): Observable<TimeInterval>{
    return this.httpClient
      .post<TimeInterval>(this.webApi1Service.controllers.timeInterval.actions.addTimeIntervalToSubdivision.toString(), {
        subdivisionOwnerId: subdivisionOwnerId,
        startInterval: startInterval,
        endInterval: endInterval
      });
  }

  /**
   * Удалить несколько связей TimeIntervalSubdivision по списку timeInterval_id для конкретного подразделения. Сам TimeInterval не удаляется
   * @param timeIntervalIds - список идентификаторов временных интервалов
   * @param subdivisionId - идентификатор подразделения
   */
  public deleteTimeIntervalLinks$(timeIntervalIds: Array<number>, subdivisionId: number): Observable<boolean>{
    return this.httpClient
      .post<boolean>(this.webApi1Service.controllers.timeInterval.actions.deleteTimeIntervalLinks.toString(),
        {timeIntervalIds, subdivisionId});
  }
}
