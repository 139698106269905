import { Injectable } from "@angular/core";
import { RootPathItem } from "../../../classes/uiRootPath/root-path-item";
import { AppSettingsService } from "../../app-settings.service";

/**
 * Сервис получения путей запроса для WebApi2
 */
@Injectable({
  providedIn: "root"
})
export class WebApi2Service {
  /**
   * Базовый путь
   */
  private readonly baseRoot: RootPathItem = null;

  /**
   * Контроллеры WebApi2
   */
  public controllers = {

    /** Файлы номенклатур */
    nomenclatureFile: {
      controller: new RootPathItem('NomenclatureFile', () => this.baseRoot),
      actions: {
        getWithFile: new RootPathItem('GetWithFile', () => this.controllers.nomenclatureFile.controller)
      }
    },

    /** Файлы */
    file: {
      controller: new RootPathItem('file', () => this.baseRoot),
      actions: {
        download: new RootPathItem('download', () => this.controllers.file.controller)
      }
    },

    /** Единицы измерения */
    measureUnit: {
      controller: new RootPathItem('measureunit', () => this.baseRoot),
      actions: {
        add: new RootPathItem('add', () => this.controllers.measureUnit.controller),
        save: new RootPathItem('save', () => this.controllers.measureUnit.controller),
        delete: new RootPathItem('delete', () => this.controllers.measureUnit.controller),
        get: new RootPathItem('get', () => this.controllers.measureUnit.controller),
      }
    },

    /**
    * Типы бюджета
    */
    budgetType: {
      controller: new RootPathItem('budgettype', () => this.baseRoot),
      actions: {
        add: new RootPathItem('add', () => this.controllers.budgetType.controller),
        save: new RootPathItem('save', () => this.controllers.budgetType.controller),
        delete: new RootPathItem('delete', () => this.controllers.budgetType.controller),
        get: new RootPathItem('get', () => this.controllers.budgetType.controller),
      }
    },

    /**
    * Связь заявки ЦФО со строками заявок подразделений
    */
    cfoRequestRowRequestRowController: {
      controller: new RootPathItem('CfoRequestRowRequestRow', () => this.baseRoot),
      actions: {
        add: new RootPathItem('add', () => this.controllers.cfoRequestRowRequestRowController.controller),
        delete: new RootPathItem('delete', () => this.controllers.cfoRequestRowRequestRowController.controller),
      }
    },

    /**
    * Связь заявки плана со строками ЦФО
    */
    planRequestCfoRequestRowController: {
      controller: new RootPathItem('PlanRequestCfoRequestRow', () => this.baseRoot),
      actions: {
        add: new RootPathItem('add', () => this.controllers.planRequestCfoRequestRowController.controller),
        delete: new RootPathItem('delete', () => this.controllers.planRequestCfoRequestRowController.controller),
      }
    },

    /**
    * КВР - КОСГУ
    */
    kvrCosgu: {
      controller: new RootPathItem('KvrCosgu', () => this.baseRoot),
      actions: {
        setKvrs: new RootPathItem('SetKvrs', () => this.controllers.kvrCosgu.controller),
        get: new RootPathItem('Get', () => this.controllers.kvrCosgu.controller)
      }
    },

    /**
    * Типы номенклатуры
    */
    nomenlatureType: {
      controller: new RootPathItem('nomenclatureType', () => this.baseRoot),
      actions: {
        get: new RootPathItem('get', () => this.controllers.nomenlatureType.controller),
        add: new RootPathItem('add', () => this.controllers.nomenlatureType.controller),
        save: new RootPathItem('save', () => this.controllers.nomenlatureType.controller),
        delete: new RootPathItem('delete', () => this.controllers.nomenlatureType.controller)
      }
    },

    /**
    * Пресеты
    */
    cfoSummaryValuesPreset: {
      controller: new RootPathItem('CfoSummaryValuesPreset', () => this.baseRoot),
      actions: {
        add: new RootPathItem('add', () => this.controllers.cfoSummaryValuesPreset.controller),
        save: new RootPathItem('save', () => this.controllers.cfoSummaryValuesPreset.controller),
        deleteWithValues: new RootPathItem('deleteWithValues', () => this.controllers.cfoSummaryValuesPreset.controller),
        getWithValues: new RootPathItem('getWithValues', () => this.controllers.cfoSummaryValuesPreset.controller),
      }
    },

    /**
    * Категория Типа номенклатуры
    */
    nomenlatureTypeCategory: {
      controller: new RootPathItem('nomenclatureTypeCategory', () => this.baseRoot),
      actions: {
        get: new RootPathItem('get', () => this.controllers.nomenlatureTypeCategory.controller),
      }
    },
    /**
    * ЦФО
    */
    cfo: {
      controller: new RootPathItem('Cfo', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.cfo.controller),
        save: new RootPathItem('Save', () => this.controllers.cfo.controller),
        delete: new RootPathItem('Delete', () => this.controllers.cfo.controller)
      }
    },

    cfoEmployees: {
      controller: new RootPathItem('CfoEmployees', () => this.baseRoot),
      actions: {
        add: new RootPathItem('Add', () => this.controllers.cfoEmployees.controller),
        save: new RootPathItem('Save', () => this.controllers.cfoEmployees.controller),
        delete: new RootPathItem('Delete', () => this.controllers.cfoEmployees.controller),
      }
    },

    cfoGridControl: {
      controller: new RootPathItem('CfoGridControl', () => this.baseRoot),
      actions: {
        getCfosForDate: new RootPathItem('GetCfosForDate', () => this.controllers.cfoGridControl.controller),
        getEmployeesForDate: new RootPathItem('GetEmployeesForDate', () => this.controllers.cfoGridControl.controller),
        getCfoEmployees: new RootPathItem('GetCfoEmployees', () => this.controllers.cfoGridControl.controller),
      }
    },

    /**
     * Типы заявок
     */
    requestType: {
      controller: new RootPathItem('requesttype', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('getAll', () => this.controllers.requestType.controller),
      }
    },

    /**
     * Типы номенклатур для справочника
     */
    nomenclatureTypeDirectoryTreelistControl: {
      controller: new RootPathItem('nomenclatureTypeDirectoryTreelistControl', () => this.baseRoot),
      actions: {
        get: new RootPathItem('get', () => this.controllers.nomenclatureTypeDirectoryTreelistControl.controller),
      }
    },

    /**
     * Значение строки плана ПЭО
     */
    planRowValue: {
      controller: new RootPathItem('planRowValue', () => this.baseRoot),
      actions: {
        saveOrAdd: new RootPathItem('SaveOrAdd', () => this.controllers.planRowValue.controller),
      }
    },


     /**
     * Заявки ЦФО
     */
     cfoRequestControl: {
      controller: new RootPathItem('CfoRequestControl', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.cfoRequestControl.controller),
      }
    },

    /**
    * Подразделения
    */
    subdivision: {
      controller: new RootPathItem('subdivision', () => this.baseRoot),
      actions: {
        getAllResponsibleForCurrentUser: new RootPathItem('getAllResponsibleForCurrentUser', () => this.controllers.subdivision.controller),
        getForDate: new RootPathItem('GetForDate', () => this.controllers.subdivision.controller),
      }
    },

    /**
     * Периоды контроля для заявок
     */
    requestControlPeriod: {
      controller: new RootPathItem('requestcontrolperiod', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('getAll', () => this.controllers.requestControlPeriod.controller),
      }
    },

    /**
    * Источники финансирования
    */
    financingSource: {
      controller: new RootPathItem('financingsource', () => this.baseRoot),
      actions: {
        add: new RootPathItem('add', () => this.controllers.financingSource.controller),
        save: new RootPathItem('save', () => this.controllers.financingSource.controller),
        delete: new RootPathItem('delete', () => this.controllers.financingSource.controller),
        get: new RootPathItem('get', () => this.controllers.financingSource.controller),
      }
    },

    /**
    * Виды мед. помощи
    */
    medicalCareType: {
      controller: new RootPathItem('medicalCareType', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.medicalCareType.controller),
        add: new RootPathItem('Add', () => this.controllers.medicalCareType.controller),
        save: new RootPathItem('Save', () => this.controllers.medicalCareType.controller),
      }
    },

    /**
    * КВР
    */
    kvrsDirectoryTreeListControl: {
      controller: new RootPathItem('kvrsdirectorytreelistcontrol', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('getForDate', () => this.controllers.kvrsDirectoryTreeListControl.controller),
      }
    },

    /**
    * КОСГУ
    */
    cosgusDirectoryTreeListControl: {
      controller: new RootPathItem('cosgusdirectorytreelistcontrol', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('getForDate', () => this.controllers.cosgusDirectoryTreeListControl.controller),
      }
    },

    /** Справочник пресетов */
    cfoSummaryValuePresetsGridControl: {
      controller: new RootPathItem('cfoSummaryValuePresetsGridControl', () => this.baseRoot),
      actions: {
        get: new RootPathItem('get', () => this.controllers.cfoSummaryValuePresetsGridControl.controller),
        getNomenclaturesForCurrentEmployee: new RootPathItem('getNomenclaturesForCurrentEmployee', () => this.controllers.cfoSummaryValuePresetsGridControl.controller),
        getCfosForCurrentEmployee: new RootPathItem('getCfosForCurrentEmployee', () => this.controllers.cfoSummaryValuePresetsGridControl.controller),
      }
    },

    /**
    * Номенклатура
    */
    nomenclaturesDirectoryGridControl: {
      controller: new RootPathItem('nomenclaturesDirectoryGridControl', () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem('GetForDate', () => this.controllers.nomenclaturesDirectoryGridControl.controller),
        saveMultiple: new RootPathItem('SaveMultiple', () => this.controllers.nomenclaturesDirectoryGridControl.controller),
        addFiles: new RootPathItem('AddFiles', () => this.controllers.nomenclaturesDirectoryGridControl.controller),
        deleteFile: new RootPathItem('DeleteFile', () => this.controllers.nomenclaturesDirectoryGridControl.controller),
      }
    },

    /**
     * Контрол грида заявок
     */
    requestsGridControl: {
      controller: new RootPathItem('RequestsGridControl', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('GetAll', () => this.controllers.requestsGridControl.controller),
        getNomenclaturesWithExtendedData: new RootPathItem('GetNomenclaturesWithExtendedData', () => this.controllers.requestsGridControl.controller)
      }
    },

    /**
     * Контрол получения значений для формы редактирования свода ЦФО
     */
    editCfoSummaryValuesControl: {
      controller: new RootPathItem('EditCfoSummaryValuesControl', () => this.baseRoot),
      actions: {
        getValuesData: new RootPathItem('GetValuesData', () => this.controllers.editCfoSummaryValuesControl.controller),
        saveValues: new RootPathItem('SaveValues', () => this.controllers.editCfoSummaryValuesControl.controller),
      }
    },

    сfoSummaryControl: {
      controller: new RootPathItem('CfoSummaryControl', () =>  this.baseRoot),
      actions: {
        getCfoSummary: new RootPathItem('GetCfoSummary', () => this.controllers.сfoSummaryControl.controller),
        saveOrAddCfoSummaryValue: new RootPathItem('SaveOrAddCfoSummaryValue', () => this.controllers.сfoSummaryControl.controller),
        saveOrAddCfoSummaryReserve: new RootPathItem('SaveOrAddCfoSummaryReserve', () => this.controllers.сfoSummaryControl.controller),
        saveOrAddCfoSummaryCost: new RootPathItem('SaveOrAddCfoSummaryCost', () => this.controllers.сfoSummaryControl.controller),
        createCfoRequest: new RootPathItem('CreateCfoRequest', () => this.controllers.сfoSummaryControl.controller),
        addToCfoRequest: new RootPathItem('AddToCfoRequest', () => this.controllers.сfoSummaryControl.controller),
        addToCfoRequestRowNomenclature: new RootPathItem('AddToCfoRequestRowNomenclature', () => this.controllers.сfoSummaryControl.controller),
        getRequestRowsForTakeToWork: new RootPathItem('GetRequestRowsForTakeToWork', () => this.controllers.сfoSummaryControl.controller),
        getRequestRowForCfoRequestRows: new RootPathItem('GetRequestRowForCfoRequestRows', () => this.controllers.сfoSummaryControl.controller),
        getNomenclaturesForUserWithExtendedData: new RootPathItem('GetNomenclaturesForUserWithExtendedData', () => this.controllers.сfoSummaryControl.controller),

      }
    },

    peoSummaryControl: {
      controller: new RootPathItem('PeoSummaryControl', () =>  this.baseRoot),
      actions: {
        getData: new RootPathItem('GetData', () => this.controllers.peoSummaryControl.controller),
        getTakeToWorkData: new RootPathItem('GetTakeToWorkData', () => this.controllers.peoSummaryControl.controller),
        takeToWorkData: new RootPathItem('TakeToWorkData', () => this.controllers.peoSummaryControl.controller),
        createByNomenclature: new RootPathItem('CreateByNomenclature', () => this.controllers.peoSummaryControl.controller),
        getTakeToWorkFlowingData: new RootPathItem('GetTakeToWorkFlowingData', () => this.controllers.peoSummaryControl.controller),
        takeToWorkFlowingData: new RootPathItem('TakeToWorkFlowingData', () => this.controllers.peoSummaryControl.controller),
        getTakeToWorkForecastData: new RootPathItem('GetTakeToWorkForecastData', () => this.controllers.peoSummaryControl.controller),
        takeToWorkForecastData: new RootPathItem('TakeToWorkForecastData', () => this.controllers.peoSummaryControl.controller),
        getCfoRequestRowForPlanRequests: new RootPathItem('GetCfoRequestRowForPlanRequests', () => this.controllers.peoSummaryControl.controller),
        getPlanRequestInfo: new RootPathItem('GetPlanRequestInfo', () => this.controllers.peoSummaryControl.controller),
        getPeoRequests: new RootPathItem('GetPeoRequests', () => this.controllers.peoSummaryControl.controller),
        addPlanRow: new RootPathItem('AddPlanRow', () => this.controllers.peoSummaryControl.controller),
      }
    },

    kvr: {
      controller: new RootPathItem("Kvr", () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem("GetForDate", () => this.controllers.kvr.controller),
        add: new RootPathItem("Add", () => this.controllers.kvr.controller),
        save: new RootPathItem("Save", () => this.controllers.kvr.controller),
        addVersion: new RootPathItem('AddVersion', () => this.controllers.kvr.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.kvr.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.kvr.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.kvr.controller),
        reorder: new RootPathItem("Reorder", () => this.controllers.kvr.controller),
      }
    },

    cosgu: {
      controller: new RootPathItem("Cosgu", () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem("GetForDate", () => this.controllers.cosgu.controller),
        add: new RootPathItem("Add", () => this.controllers.cosgu.controller),
        save: new RootPathItem("Save", () => this.controllers.cosgu.controller),
        addVersion: new RootPathItem('AddVersion', () => this.controllers.cosgu.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.cosgu.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.cosgu.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.cosgu.controller),
      }
    },

    nomenclature: {
      controller: new RootPathItem("Nomenclature", () => this.baseRoot),
      actions: {
        getForDate: new RootPathItem("GetForDate", () => this.controllers.nomenclature.controller),
        add: new RootPathItem("Add", () => this.controllers.nomenclature.controller),
        save: new RootPathItem("Save", () => this.controllers.nomenclature.controller),
        addVersion: new RootPathItem('AddVersion', () => this.controllers.nomenclature.controller),
        editVersion: new RootPathItem('EditVersion', () => this.controllers.nomenclature.controller),
        deleteVersion: new RootPathItem('DeleteVersion', () => this.controllers.nomenclature.controller),
        getVersionsByOwnerIdAndActionId: new RootPathItem('GetVersionsByOwnerIdAndActionId', () => this.controllers.nomenclature.controller),
      }
    },

    // раздел администрирования
    administration: {
      controller: new RootPathItem("Administration", () => this.baseRoot),
      actions: {
        // получить данные для отображения в компоненте ответственных
        getAdministrationResponsiblesData: new RootPathItem("getAdministrationResponsiblesData", () => this.controllers.administration.controller),
      }
    },

    request: {
      controller: new RootPathItem("Request", () => this.baseRoot),
      actions: {
        add: new RootPathItem("Add", () => this.controllers.request.controller),
        save: new RootPathItem("Save", () => this.controllers.request.controller),
        delete: new RootPathItem("Delete", () => this.controllers.request.controller),
        deleteRequestWithAllRowsAndDatas: new RootPathItem("DeleteRequestWithAllRowsAndDatas", () => this.controllers.request.controller),
      }
    },

    cfoRequest: {
      controller: new RootPathItem("CfoRequest", () => this.baseRoot),
      actions: {
        save: new RootPathItem("Save", () => this.controllers.cfoRequest.controller),
        add: new RootPathItem("Add", () => this.controllers.cfoRequest.controller),
        delete: new RootPathItem("Delete", () => this.controllers.cfoRequest.controller),
      }
    },

    requestRow: {
      controller: new RootPathItem("RequestRow", () => this.baseRoot),
      actions: {
        get: new RootPathItem("Get", () => this.controllers.requestRow.controller),
        addRange: new RootPathItem("AddRange", () => this.controllers.requestRow.controller),
        save: new RootPathItem("Save", () => this.controllers.requestRow.controller),
        delete: new RootPathItem("Delete", () => this.controllers.requestRow.controller),
        copy: new RootPathItem("Copy", () => this.controllers.requestRow.controller),
      }
    },

    cfoRequestRow: {
      controller: new RootPathItem("CfoRequestRow", () => this.baseRoot),
      actions: {
        get: new RootPathItem("Get", () => this.controllers.cfoRequestRow.controller),
        delete: new RootPathItem("Delete", () => this.controllers.cfoRequestRow.controller),
        save: new RootPathItem("Save", () => this.controllers.cfoRequestRow.controller),
      }
    },

    // Ответственные (RequestResponsible)
    responsible: {
      controller: new RootPathItem("Responsible", () => this.baseRoot),
      actions: {
        // получить данные для отображения в компоненте ответственных (userIdEmployeeName)
        getAllResponsibles: new RootPathItem("GetAllResponsibles", () => this.controllers.responsible.controller),
        add: new RootPathItem("Add", () => this.controllers.responsible.controller),
        delete: new RootPathItem("Delete", () => this.controllers.responsible.controller),

      }
    },

    routeStep: {
      controller: new RootPathItem('RouteStep', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('GetAll', () => this.controllers.routeStep.controller),
        getAll2: new RootPathItem('GetAll2', () => this.controllers.routeStep.controller),
        add: new RootPathItem('Add', () => this.controllers.routeStep.controller),
        update: new RootPathItem('Update', () => this.controllers.routeStep.controller),
        delete: new RootPathItem('Delete', () => this.controllers.routeStep.controller),
        getAllApprovingUsers: new RootPathItem('GetAllApprovingUsers', () => this.controllers.routeStep.controller),
      }
    },

    route: {
      controller: new RootPathItem('Route', () => this.baseRoot),
      actions: {
        getAll: new RootPathItem('GetAll', () => this.controllers.route.controller),
        getAll2: new RootPathItem('GetAll2', () => this.controllers.route.controller),
        add: new RootPathItem('Add', () => this.controllers.route.controller),

      }
    },

    pfhdSettings: {
      controller: new RootPathItem('PFHDSettings', () => this.baseRoot),
      actions: {
        getPeoValueDigitsCount: new RootPathItem('GetPeoValueDigitsCount', () => this.controllers.pfhdSettings.controller),
      }
    },

    plan: {
      controller: new RootPathItem('Plan', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.plan.controller),
        createBase: new RootPathItem('CreateBase', () => this.controllers.plan.controller),
        createSecondary: new RootPathItem('CreateSecondary', () => this.controllers.plan.controller),
      }
    },

    planRedaction: {
      controller: new RootPathItem('PlanRedaction', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.planRedaction.controller),
        create: new RootPathItem('Create', () => this.controllers.planRedaction.controller),
      }
    },

    planRequest: {
      controller: new RootPathItem('PlanRequest', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.planRequest.controller),
        save: new RootPathItem('Save', () => this.controllers.planRequest.controller),
        delete: new RootPathItem('Delete', () => this.controllers.planRequest.controller),
      }
    },

    planRow: {
      controller: new RootPathItem('PlanRow', () => this.baseRoot),
      actions: {
        save: new RootPathItem('Save', () => this.controllers.planRow.controller),
        add: new RootPathItem('Add', () => this.controllers.planRow.controller),
        delete: new RootPathItem('Delete', () => this.controllers.planRow.controller),
        saveWithValues: new RootPathItem('SaveWithValues', () => this.controllers.planRow.controller),
      }
    },

    printReport: {
      controller: new RootPathItem('PrintReport', () => this.baseRoot),
      actions: {
        printRequestsReport: new RootPathItem('PrintRequestsReport', () => this.controllers.printReport.controller),
        printCfoReport: new RootPathItem('PrintCfoReport', () => this.controllers.printReport.controller),
        printCfoRequestsUpload: new RootPathItem('PrintCfoRequestsUpload', () => this.controllers.printReport.controller),
      },
    },

    costType: {
      controller: new RootPathItem('CostType', () => this.baseRoot),
      actions: {
        get: new RootPathItem('Get', () => this.controllers.costType.controller)
      }
    }
  }

  /**
   * Конструктор
   * @param appSettingsService
   */
  constructor(appSettingsService: AppSettingsService) {
    this.baseRoot = new RootPathItem(appSettingsService.webApiBaseUrl, null)
  }
}

/* Модель даты, используемая для запроса данных версионных данных */
export interface IDateObjForRequest {
  date: Date,
  startDate?: Date,
  endDate?: Date,
  auditDate?: Date,
  ownerIds?: number[],
  includeDeleted: boolean
}
