import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {DialogService} from "@progress/kendo-angular-dialog";
import {
  HierarchiStringSmall
} from "../../../classes/requestResults/EditEntityWithDependenciesErrorData";
import {HierarchiStringsComponent} from "../hierarchi-strings.component";
import {
  Api1PrintReportControllerService
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {DisplayErrorsService} from "../../display-errors/services/display-errors.service";
import {
  GridToolbarManagementService
} from "../../../../../projects/timesheet/src/app/components/graph-table-workspace/services/grid-toolbar-management.service";

@Injectable()
export class HierarchiStringService implements OnDestroy{

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  constructor(private readonly dialogService: DialogService,
              private readonly gridToolbarManagementService: GridToolbarManagementService)  {  }

  /** Показать окно с конфликтами */
  public show(hierarchiStrings: HierarchiStringSmall[], exportExcelFileName: string, withContinueAction = false) : HierarchiStringsComponent{
    let actions = [{text: 'Закрыть', themeColor: 'primary', result: false}];
    if (withContinueAction) actions.push({text: 'Продолжить', themeColor: 'base', result: true});

    const dialogRef = this.dialogService.open({
      title: "Проверка на наличие ошибок",
      width: "96%",
      height: "90%",
      content: HierarchiStringsComponent,
      actions: actions,
      actionsLayout: "end"
    });

    const component = dialogRef.content.instance as HierarchiStringsComponent;
    component.hierarchiStrings = hierarchiStrings;
    component.params = {
      subdivisionId: this.gridToolbarManagementService.data.graphTable.subdivisionOwnerId,
      month: this.gridToolbarManagementService.data.graphTable.month };
    component.exportExcelFileName = exportExcelFileName;

    return component;
 }

  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}
