import {Injectable} from "@angular/core";
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {HttpHeadersHelper} from "../helpers/HttpHeadersHelper";

/** Сервис настроек приложения */
@Injectable({
  providedIn:"root"
})
export class AppSettingsService implements IAppSettings{
  /** Настройки полученные с сервера */
  protected settings: IAppSettings;

  /** Компания */
  public company: string;

  get program(){
    return this.settings?.program;
  }

  get webApiBaseUrl(){
    return this.settings?.webApiBaseUrl;
  }

  get webApiAuthBaseUrl(){
    return this.settings?.webApiAuthBaseUrl;
  }

  get webApiLoggingPath(){
    return this.settings?.webApiLoggingPath;
  }

  get webApiFormatSettings(){
    return this.settings?.webApiFormatSettings;
  }

  get workspaceRoute(){
    return this.settings?.workspaceRoute;
  }

  get signalRPath(){
    return this.settings?.signalRPath;
  }

  get storageVersion(){
    return this.settings?.storageVersion;
  }

  get disconnectedSignalRMaxTime(){
    return this.settings?.disconnectedSignalRMaxTime;
  }

  constructor(private httpBackend: HttpBackend) {
  }

  /** Инициализировать сервис из json файла */
  public initFromJson(jsonPath: string = '/assets/appconfig.json'){
    return this.get$(jsonPath)
      .pipe(tap(value => {
        this.settings = {
          program: value.program,
          webApiBaseUrl: value.webApiBaseUrl,
          webApiAuthBaseUrl: value.webApiAuthBaseUrl,
          webApiLoggingPath: IAppSettings_WebApiLoggingPath.Copy(value.webApiLoggingPath),
          webApiFormatSettings: IAppSettings_WebApiFormatSettings.Copy(value.webApiFormatSettings),
          workspaceRoute: value.workspaceRoute,
          signalRPath: value.signalRPath,
          storageVersion: value.storageVersion,
          disconnectedSignalRMaxTime: value.disconnectedSignalRMaxTime
        }
      }))
  }

  /** Запрос к серверу */
  private get$(jsonPath: string){
    let headers = new HttpHeaders();
    headers = HttpHeadersHelper.skipToken(headers);
    headers = HttpHeadersHelper.noCache(headers);

    return new HttpClient(this.httpBackend)
      .get<IAppSettings>(jsonPath, {headers: headers});
  }
}

/** Интерфейс настроек приложения */
export interface IAppSettings{
  /** Программа */
  program: string;

  /** Базовый url webApi */
  webApiBaseUrl: string;

  /** Базовый url webApiAuth */
  webApiAuthBaseUrl: string;

  /** Пути к логированию ошибок ui */
  webApiLoggingPath: IAppSettings_WebApiLoggingPath

  /**
   * Настройки серверного формата данных.
   * Необходим для серелизации/десерелизации даты для отправки/получения
   */
  webApiFormatSettings: IAppSettings_WebApiFormatSettings

  /**
   * Рабочая область.
   * Роут куда редиректится после авторизации
   */
  workspaceRoute: string;

  /** Путь к подключению signalR */
  signalRPath: string;

  /**
   * Текущая версия хранилища(localStorage/sessionStorage).
   * Необходимо для очистки хранилища при изменении структуры данных
   */
  storageVersion: number;

  /** Маскимальное время в секундах отсутсвия подключения singalR */
  disconnectedSignalRMaxTime: number;
}

/** Класс хранения путей сервера к логированию ошибок ui */
export class IAppSettings_WebApiLoggingPath{
  /**
   * Конструктор
   * @param error Путь логирования ошибки если пользователь НЕ авторизован
   * @param errorAuth Путь логирования ошибки если пользователь авторизован
   * @param warn Путь логирования предупреждения если пользователь НЕ авторизован
   * @param warnAuth Путь логирования предупреждения если пользователь авторизован
   */
  constructor(public readonly error: string,
              public readonly errorAuth: string,
              public readonly warn: string,
              public readonly warnAuth: string) {
  }

  /** Копирование объекта */
  public static Copy(source: IAppSettings_WebApiLoggingPath){
    return new IAppSettings_WebApiLoggingPath(source.error, source.errorAuth, source.warn, source.warnAuth)
  }
}

/**
 * Класс настройки серверного формата даты.
 * Необходим для серелизации/десерелизации даты для отправки/получения на сервер
 */
export class IAppSettings_WebApiFormatSettings {
  /**
   * Конструктор
   * @param date Формат даты сервера
   * @param dateOnly Формат только даты
   * @param timeOnly Формат только времени
   */
  constructor(public readonly date: string,
              public readonly dateOnly: string,
              public readonly timeOnly: string) {
  }

  /** Метод копирует объект с преобразованием string в Regex */
  public static Copy(source: IAppSettings_WebApiFormatSettings): IAppSettings_WebApiFormatSettings{
    return new IAppSettings_WebApiFormatSettings(source.date, source.dateOnly, source.timeOnly);
  }
}
