import {Component, Input, OnInit} from '@angular/core';
import {
  DateInStaffUnitRangeType,
} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {ITimeInterval} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";
import {IDayDeviation} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {IGraphDay} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/GraphDay";
import {ICovidLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog";
import {ICovidLog2} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog2";
import {IVichLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/VichLog";
import {ITuberLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/TuberLog";
import {IExcludeMilkLog} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/ExcludeMilkLog";


@Component({
  selector: 'app-graph-day-cell2',
  templateUrl: './graph-day-cell2.component.html',
  styleUrl: './graph-day-cell2.component.css'
})
export class GraphDayCell2Component implements OnInit {

  @Input() public type: DateInStaffUnitRangeType;
  @Input() public graphDay: Pick<IGraphDay, 'dayDeviationCustomValue' | 'substractLunchFlag'>
  @Input() public timeInterval: Pick<ITimeInterval, 'startInterval' | 'endInterval'>;
  @Input() public dayDeviation: Pick<IDayDeviation, 'name' | 'shortName' | 'color'>;

  //Логи в ячейке
  @Input() public excludeMilk: Partial<IExcludeMilkLog>;
  @Input() public covidLogs: Pick<ICovidLog, 'operatingTime'>[];
  @Input() public covidLog2s: Partial<ICovidLog2>[];
  @Input() public vichLogs: Pick<IVichLog, 'operatingTime'>[];
  @Input() public tuberLogs: Pick<ITuberLog, 'operatingTime'>[];


  /** Функция получает время из лога */
  public operationTimeGetter(item: Pick<ICovidLog, 'operatingTime'>): number{
    return item.operatingTime;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
  }
}
