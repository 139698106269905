import {Injectable, OnDestroy} from '@angular/core';
import {delay, Observable, race, ReplaySubject} from "rxjs";
import {DialogService} from "@progress/kendo-angular-dialog";
import {
  PrintFormFromtillDatesSettingsComponent
} from "../../../../../../../src/app/components/print-form-settings/print-form-fromtill-dates-settings/print-form-fromtill-dates-settings.component";
import {take, takeUntil} from "rxjs/operators";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import {
  Api1PrintReportControllerService,
  PrintFormFromtillDatesSettings
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {
  DisplayErrorsService
} from "../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {
  TracerServiceBase
} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {trace} from "../../../../../../../src/app/modules/trace/operators/trace";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {
  ArrayDataSourceSelection
} from "../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";
import {ISubdivision} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision";
import {exErrorHandler} from "../../../../../../../src/app/operators/ex-error-handler";


@Injectable({
  providedIn: 'root'
})
@traceClass('FromTillPrintSettingsDialogService')
export class FromTillPrintSettingsDialogService implements OnDestroy{

  private unsubscribe$ = new ReplaySubject(1);

  constructor(private readonly dialogService: DialogService,
              private readonly api1PrintReportControllerService: Api1PrintReportControllerService,
              private readonly traceService: TracerServiceBase,
              private readonly displayErrorService: DisplayErrorsService) { }

  @traceFunc()
  showDialog(innerKey: string, text: string, requestObsFunc: (PrintFormFromtillDatesSettings) => Observable<Blob>,
             dataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase){
    const dialogRef = this.dialogService.open({
      title: `Настройки печати "${text}"`,
      content: PrintFormFromtillDatesSettingsComponent,
      maxWidth: '90%',
      width: 700,
      maxHeight: '90%'
    });

    const component = dialogRef.content.instance as PrintFormFromtillDatesSettingsComponent;

    let selection = new ArrayDataSourceSelection<ISubdivision, number>(dataSourceService.dataSource);

    component.subdivisionDataSourceService = dataSourceService;
    component.selection = selection;

    component.datesChange$.pipe(takeUntil(component.unsubscribe$))
      .subscribe({
        next: value => {
          component.subdivisionDataSourceService
            .reloadData$(value.startDate)
            .pipe(exErrorHandler(this.displayErrorService), take(1))
            .subscribe();
        }
      });

    const unsubscribeRace = race(this.unsubscribe$, component.cancel$, component.print$)
      .pipe(delay(1));

    component.cancel$.pipe(take(1), takeUntil(unsubscribeRace))
      .subscribe(value => {
        component.subdivisionDataSourceService.ngOnDestroy();
        component.selection.onDestroy();
        dialogRef.close();
        this.traceService.add('Нажата кнопка отмены');
      });

    component.print$.pipe(take(1), takeUntil(unsubscribeRace)).subscribe(value => {
      component.subdivisionDataSourceService.ngOnDestroy();
      component.selection.onDestroy();
      dialogRef.close();
      this.traceService.add2('Нажата кнопка сохранения отчёта', {obj: value});
      value.printFormInnerKey = innerKey;

      requestObsFunc(new PrintFormFromtillDatesSettings(
        innerKey, value.fromDate, value.tillDate,
        value.allSubdivisionsFlag, value.subdivisionOwnerIds))
        .pipe(trace(this.traceService))
        .subscribe({
          next: resp => {
            FileSaver.saveAs(resp, `${text}_${moment().format('DD_MM_yyyy')}.xlsx`);
            },
          error: err => {
            this.displayErrorService.handleError(err);
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
