import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {WebApi1Service} from "../web-api1.service";
import { IGraphApprovalsOptional } from "src/app/classes/domain/POCOs/timesheet_graph/GraphApprovals";
import {Subdivision} from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import {Employee} from "src/app/classes/domain/POCOs/stafflist/Employee";
import {ApprovalsBase, IApprovalsBaseOptional} from "src/app/classes/domain/POCOs/abstract/IApprovalsBase";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class Api1GraphTableApprovalsControlControllerService {

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service,
            ) {
  }

  /**
   * Получить AprovalUsers
   */
 public getAllApprovalUsers$( date: Date = null): Observable<Array<IApprovalEmployeeDTO>>{
  return this.httpClient
    .post<Array<IApprovalEmployeeDTO>>(this.webApi1Service.controllers.graphTableApprovalsControl.actions.getAllApprovalUsers.toString(),
    {
      Date: date
    });
  }

  /**
   * Получить ответственных для графика
   */
   public getGraphApprovals$( date: Date = null, subdivisionIds: number[]): Observable<Array<ApprovalDTO>>{
    return this.httpClient
      .post<IGetApprovalsResponse>(this.webApi1Service.controllers.graphTableApprovalsControl.actions.getGraphApprovals.toString(),
      {
        Date: date,
        subdivisionIds: subdivisionIds
      }).pipe(map(m=>IGetApprovalsResponse.ToApprovalDTOs(m)));
    }

   /**
   * Получить ответственных для табеля
   */
   public getTableApprovals$( date: Date = null, subdivisionIds: number[]): Observable<Array<ApprovalDTO>>{
    return this.httpClient
      .post<IGetApprovalsResponse>(this.webApi1Service.controllers.graphTableApprovalsControl.actions.getTableApprovals.toString(),
      {
        Date: date,
        subdivisionIds: subdivisionIds
      }).pipe(map(m=> IGetApprovalsResponse.ToApprovalDTOs(m)));
    }

  /**
   * Добавить ответственных для графика
   */
   public addGraphApprovals$(subdivisionIds: number[], requestData: IGraphApprovalsOptional, isDeleteExisted: boolean): Observable<IGraphApprovalsOptional>{
    return this.httpClient
      .post<IGraphApprovalsOptional>(this.webApi1Service.controllers.graphTableApprovalsControl.actions.addGraphApprovals.toString(), {
        subdivisionIds: subdivisionIds,
        data: requestData,
        deleteExisted : isDeleteExisted
      });
    }

   /**
   * Добавить ответственных для табеля
   */
    public addTableApprovals$(subdivisionIds: number[], requestData: IGraphApprovalsOptional, isDeleteExisted: boolean): Observable<IGraphApprovalsOptional>{
      return this.httpClient
        .post<IGraphApprovalsOptional>(this.webApi1Service.controllers.graphTableApprovalsControl.actions.addTableApprovals.toString(), {
          subdivisionIds: subdivisionIds,
          data: requestData,
          deleteExisted : isDeleteExisted
        });
      }

  /**
   * Удалить ответственных для графика
   */
   public deleteGraphApprovals$(subdivisionIds: number[], approval: IApprovalsBaseOptional): Observable<any>{
    return this.httpClient
      .post(this.webApi1Service.controllers.graphTableApprovalsControl.actions.deleteGraphApprovals.toString(), {
        subdivisionIds: subdivisionIds,
        data: approval
      });
    }

  /**
   * Удалить ответственных для табеля
   */
   public deleteTableApprovals$(subdivisionIds: number[], approval: IApprovalsBaseOptional): Observable<any>{
    return this.httpClient
      .post(this.webApi1Service.controllers.graphTableApprovalsControl.actions.deleteTableApprovals.toString(), {
        subdivisionIds: subdivisionIds,
        data: approval
      });
    }

   public reorderGraphApproval$(subdivisionIds: number[], approval1: IApprovalsBaseOptional, approval2: IApprovalsBaseOptional): Observable<IReorderResult>{
    return this.httpClient
      .post<IReorderResult>(this.webApi1Service.controllers.graphTableApprovalsControl.actions.reorderGraphApprovals.toString(), {
        subdivisionIds: subdivisionIds,
        approval1: approval1,
        approval2: approval2
      });
    }

    public reorderTableApproval$(subdivisionIds: number[], approval1: IApprovalsBaseOptional, approval2: IApprovalsBaseOptional): Observable<IReorderResult>{
      return this.httpClient
        .post<IReorderResult>(this.webApi1Service.controllers.graphTableApprovalsControl.actions.reorderTableApprovals.toString(), {
          subdivisionIds: subdivisionIds,
          approval1: approval1,
          approval2: approval2
        });
      }
}

class IGetApprovalsResponse {
  approvals: Pick<ApprovalsBase, 'id' | 'subdivisionId' | 'approvalSubdivisionId' | 'approvalUserId' | 'sortOrder'>[];
  approvalSubdivisions: IApprovalSubdivisionDTO[];
  approvalEmployees: IApprovalEmployeeDTO[];

  public static ToApprovalDTOs(resp: IGetApprovalsResponse) : ApprovalDTO[]{
    return resp.approvals.map(approval => {
    return new ApprovalDTO(
      approval.id,
      approval.subdivisionId,
      resp.approvalSubdivisions?.find(s=> s.id == approval.approvalSubdivisionId),
      resp.approvalEmployees?.find(e=> e.userId == approval.approvalUserId),
      approval.sortOrder
      );
    })
  }
}

export interface IReorderResult {
  current: IApprovalsBaseOptional,
  affected: IApprovalsBaseOptional,
}

export interface IApprovalEmployeeDTO extends Pick<Employee, 'id'> {
  userId: number;
  fio: string;
}

export class ApprovalDTO {
  constructor(
    public id: number,
    public subdivisionId: number,
    public approvalSubdivision: IApprovalSubdivisionDTO,
    public approvalEmployee: IApprovalEmployeeDTO,
    public sortOrder: number
  ){}
}

export interface IApprovalSubdivisionDTO extends Pick<Subdivision, 'id' | 'longName'> {
}

