import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {Observable} from "rxjs";
import {StaffUnitType} from "../../../../classes/domain/POCOs/stafflist/StaffUnitType";

@Injectable({
  providedIn: "root"
})
export class Api1StaffUnitTypeControllerService{
  constructor(
    private httpClient: HttpClient,
    private webApi1Service: WebApi1Service
  ) {
  }

   /**
   * Получить всех
   */
    public getAll$(): Observable<Array<StaffUnitType>>{
      return this.httpClient.get<Array<StaffUnitType>>(
        this.webApi1Service.controllers.staffUnitType.actions.getAll.toString()
        );
    }

  /**
   * Получить по списку id
   */
  public getByIds$(ids: Array<number>): Observable<Array<StaffUnitType>>{
    return this.httpClient.post<Array<StaffUnitType>>(
      this.webApi1Service.controllers.staffUnitType.actions.getByIds.toString(),
      ids
      );
  }
}
