import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { IWorkModeOptional, WorkMode } from "src/app/classes/domain/POCOs/stafflist/WorkMode";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";

/**
 * Сервис работы с контроллером Occupations
 */
@Injectable({
  providedIn: "root"
})
export class Api1WorkModeControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }


  public getForDate$( date: Date, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<Array<WorkMode>>{
    return this.httpClient
      .post<Array<WorkMode>>(this.webApi1Service.controllers.workMode.actions.getForDate.toString(),
      {
        OwnerIds: ownerIds,
        StartDate: startDate,
        EndDate: endDate,
        Date: date,
      });
    }


  public addWorkMode$(entity: IWorkModeOptional): Observable<WorkMode>
  {
    return this.httpClient
    .post<WorkMode>(this.webApi1Service.controllers.workMode.actions.add.toString(),
      entity)
  }

  /**
   * Сохранить Occupation
   */
  public save$(entity:WorkMode): Observable<[]>{
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.workMode.actions.save.toString(),
        entity
      );
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<Array<VersioningItem>>
  {
    return this.httpClient
    .get<Array<VersioningItem>>(this.webApi1Service.controllers.workMode.actions.getVersionsByOwnerIdAndActionId.toString(),
      {
        params: HttpParamsHelper.getParams([['ownerId', ownerId], ['actionId', actionId]])
      })
  }

  public addWorkModeVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.workMode.actions.addVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }

  public editWorkModeVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.workMode.actions.editVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }


    public deleteWorkModeVersion$(versionId: number) : Observable<boolean>{
      return this.httpClient.delete<boolean>(this.webApi1Service.controllers.workMode.actions.deleteVersion.toString(),
        {params: HttpParamsHelper.getParams([["versionId",versionId]])}
        )
    }


}
