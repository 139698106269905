import {Injectable} from "@angular/core";
import {Api1DateControllerService} from "../webApi/webApi1/controllers/api1-date-controller.service";
import {combineLatest, Observable} from "rxjs";
import {IDropDownItem} from "../../classes/requestResults/iDropDownItem";
import {debounceTime, filter, map} from "rxjs/operators";
import * as moment from "moment";
import {ArrayDataSourceHasId, DataSource, DataSourceReadOnly} from "../../classes/array-data-sources/data-source";
import {
  KendoDropDownListArrayDataSourceSelection
} from "../../classes/array-data-sources/selections/kendo-drop-down-list-array-data-source-selection";
import {DataSourceWithParamsBase} from "../data-source-services/data-source.service";
import {exElementByIndexOr} from "../../operators/ex-element-by-index-or";
import {exDistinctUntilChanged} from "../../operators/ex-distinct-until-changed.operator";

/** Интерфейс элемента */
export interface IYearMonthService_DropDown_Item extends IDropDownItem{
  disabled: boolean
}

/** VM для выпадающего списка */
class YearMonthService_DropDownVM{
  /** Источник данных */
  public dataSource = new ArrayDataSourceHasId<IYearMonthService_DropDown_Item, number>(x => x.id);

  /** Выбранные элементы */
  public selection = new KendoDropDownListArrayDataSourceSelection(this.dataSource);

  onDestroy(){
    this.dataSource.onDestroy();
    this.selection.onDestroy();
  }
}

/** Тип параметров */
type ParamsType = {years$: Observable<IDropDownItem[]>, months$: Observable<IDropDownItem[]>};
/** Класс параметров использующий для данных сервис Api1DateControllerService*/
export class YearMonthService_Api1DateControllerService implements ParamsType{
  public readonly months$: Observable<IDropDownItem[]>;
  public readonly years$: Observable<IDropDownItem[]>;

  constructor(dateControllerService: Api1DateControllerService) {
    this.years$ = dateControllerService.getYears$;
    this.months$ = dateControllerService.getPosibleMonth$;
  }
}
/** Тип данных */
type DataType = {years: IYearMonthService_DropDown_Item[], months: IYearMonthService_DropDown_Item[]};

/** Тип выделенных идентификаторов */
type SelectionIdType = {yearId: number, monthId: number};
/** Тип выделенных элементов */
type SelectionItemType = {year: IDropDownItem, month: IDropDownItem};
/** Тип данных для выделенных элементов */
type SelectionType = {date: Date, id: SelectionIdType, item: SelectionItemType};

/**
 * Сервис для работы с Год/Месяц как отдельные компоненты KendoDropDownList
 * Используется в Графике, Табель и т.д
 */
@Injectable()
export class YearMonthService extends DataSourceWithParamsBase<ParamsType, DataType>{
  public readonly paramsDataSource = new DataSource<ParamsType>();
  public readonly dataSource = new DataSource<DataType>({copyValueFn: currentValue => { return {years: [...currentValue.years], months: [...currentValue.months]}}});

  /** VM для Год */
  public readonly year = new YearMonthService_DropDownVM();
  /** VM для Месяц */
  public readonly month = new YearMonthService_DropDownVM();

  private readonly _selection = new DataSource<SelectionType>();
  /**
   * Выделенные элементы в двух компонентах<br>
   * Если хоть один не заполнен вернет undefined<br>
   */
  public get selection(): DataSourceReadOnly<SelectionType>{
    return this._selection;
  }

  /** Установить выделенные */
  public set setSelection(value: Date | SelectionIdType){
    if(!value){
      this.year.selection.setIds([]);
      this.month.selection.setIds([]);
      return;
    }

    const id = value instanceof Date ? this.getIdsByDate(value) : value;
    this.year.selection.setIds([id.yearId]);
    this.month.selection.setIds([id.monthId]);
  }

  constructor() {
    super();

    this.setDataToSelection();
  }

  protected _reloadData$(params: ParamsType): Observable<DataType> {
    return combineLatest([
      this.year.dataSource.setData$(params?.years$?.pipe(map(value => this.convert1(value))) ?? undefined),
      this.month.dataSource.setData$(params?.months$?.pipe(map(value => this.convert1(value))) ?? undefined)
    ]).pipe(map(value => {
      return {
        years: value[0].data,
        months: value[1].data
      }
    }));
  }

  /** Устанавливает данные для выбранных элементов в двух компонентах */
  private setDataToSelection(){
    this._selection.setData(
      combineLatest([
        this.year.selection.selectedItems.data$.pipe(exElementByIndexOr(0, null)),
        this.month.selection.selectedItems.data$.pipe(exElementByIndexOr(0, null))
      ]).pipe(
        debounceTime(10),
        map(value => {
          if(!value[0] || !value[1]){
            return undefined;
          }

          const id: SelectionIdType = {
            yearId: this.year.dataSource.idGetter(value[0]),
            monthId: this.month.dataSource.idGetter(value[1])
          }

          const result: SelectionType = {
            date: this.getDateByIds(id.yearId, id.monthId),
            item: {
              year: value[0],
              month: value[1]
            },
            id: id
          };

          return result;
        }),
        exDistinctUntilChanged(undefined),
      )
    )
  }

  /** Получить дату по идентификатору года и месяца */
  private getDateByIds(yearId: number, monthId: number){
    return moment({year: yearId, month: monthId - 1}).toDate();
  }

  /** Получить идентификаторы Год/Месяц из даты */
  private getIdsByDate(month: Date): SelectionIdType{
    return {
      yearId: moment(month).year(),
      monthId: moment(month).month() + 1
    }
  }

  /** Конвертировать */
  private convert1(sources: IDropDownItem[]): IYearMonthService_DropDown_Item[]{
    return sources.map(x => {
      return {
        id: x.id,
        text: x.text,
        disabled: false
      }
    })
  }

  ngOnDestroy() {
    this.year.onDestroy();
    this.month.onDestroy();
    this._selection.onDestroy();
    super.ngOnDestroy();
  }
}
