import {Injectable, OnDestroy} from "@angular/core";
import {SignalRService} from "./signal-r.service";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {filter, take, takeUntil} from "rxjs/operators";
import {AlertService} from "../alert.service";
import {ISendObj} from "./i-send-obj";
import {
  DeepSerializerForServerService
} from "../object-serializers/deep-serializer-for-server-services/deep-serializer-for-server.service";

/**
 * Hub для подписи/отписи прослушки методов signalR сервера
 * Данный hub сообщает серверу, что данный пользователю необходимо прослушивать методы signalR
 * На стороне сервера сообщения методов отправляются только тем пользователям, которые подписаны на него(для оптимизации трафика)
 */
@Injectable({providedIn: "root"})
export class SignalRHub implements OnDestroy{
  private readonly subscribeMethodName = 'SubscribeToMethods';
  private readonly unsubscribeMethodName = 'UnsubscribeFromMethods';

  private readonly storage = new SubscribersStorage();

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  public constructor(public readonly signalRService: SignalRService,
                     public readonly deepSerializerForServerService: DeepSerializerForServerService,
                     public readonly alertService: AlertService) {
    let prevValue: boolean = null;
    signalRService.hasConnection$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      if(!value){ //Выходим если нет signalR подключения
        prevValue = value;
        return;
      }

      if(prevValue === value){ //Выходим если значение не изменилось, значит уже обрабатывалось
        return;
      }

      prevValue = value;

      const methods = this.storage.getAllMethods();
      if(methods.length == 0){
        return;
      }

      this.sendSubscriptions(this.subscribeMethodName, methods);
    })
  }

  /**
   * Подписаться на прослушку метода signalR
   * Если в данный метод ни кто не прослушивает, то отправит запрос серверу на прослушку
   */
  public subscribe(method: string): Observable<ISendObj<any>>{
    if(this.storage.isContains(method)){
      this.storage.manage(method, 'subscribe');
      return this.storage.get(method);
    }

    const stream$ = new Subject<ISendObj<any>>();
    const item = new SubscribersStorage_Subscription(method, stream$);
    this.storage.add(item);

    this.sendSubscriptions(this.subscribeMethodName, [method]);

    this.signalRService.hubConnection().on(method, (value: ISendObj<any>) => {
      stream$.next(this.deepSerializerForServerService.deserialize(value));
    })

    return stream$;
  }

  /**
   * Отписаться от прослушки метода signalR
   * Если больше подписантов к данному методу signalR нет, то отпишется от прослушки сервера
   * @return true если отписался от прослушки сервера
   */
  public unsubscribe(method: string): boolean{
    const isSubscriptionLeft = this.storage.manage(method, 'unsubscribe');
    if(isSubscriptionLeft){
      return false;
    }

    this.signalRService.hubConnection().off(method);
    this.sendSubscriptions(this.unsubscribeMethodName, [method]);
    return true;
  }

  public ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

  /** Отправить подписи */
  private sendSubscriptions(method: string, methods: Array<string>, tryCount = 10){
    if(tryCount < 1){
      this.alertService.defaultAlertOption.error().mod(x => {
        x.message = 'Что-то пошло не так!\n\rРекомендуем перезагрузить страницу'
      }).showAlert()
      return;
    }

    this.signalRService.hasConnection$.pipe(filter(value => value), take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe(value => {
        this.signalRService.hubConnection().send(method, methods)
          .then()
          .catch(reason => {
            setTimeout(() => {
              this.sendSubscriptions(method, methods, tryCount - 1)
            }, 1000)
          })
      })
  }
}

/** Класс хранения подписок */
class SubscribersStorage{
  private _array: Array<SubscribersStorageItem> = new Array<SubscribersStorageItem>();

  /** Содержится ли метод в хранилище */
  public isContains(method: string){
    return !!this._get(method);
  }

  /** Получить Subject по методу signalR */
  public get(method: string): Subject<any>{
    return this._get(method)?.item?.subject;
  }

  /**
   * Добавление в хранилище
   * Если такой метод уже добавлен, бросит ошибку
   */
  public add(item: SubscribersStorage_Subscription){
    if(!item.method){
      throw new Error('Не допускается добавление с пустым методом signalR');
    }

    if(!item.subject){
      throw new Error('Не допускается добавление с observable == null')
    }

    if(this.isContains(item.method)){
      throw new Error(`В массиве уже содержится элемент с методом ${item.method}`)
    }

    const subscribersStorageItem = new SubscribersStorageItem(item);
    subscribersStorageItem.increment();
    this._array.push(subscribersStorageItem);
  }

  /**
   * Управлять подписками
   * Вернет true если подписки на данный метод еще есть, иначе false
   */
  public manage(method: string, value: 'subscribe' | 'unsubscribe'): boolean{
    if(!value){
      throw new Error('Значение может быть subscribe или unsubscribe')
    }

    const item = this._get(method);
    if(!item){
      throw new Error('Элемент отсутствует в массиве')
    }

    switch (value){
      case 'subscribe':
        item.increment();
        return true;
      case 'unsubscribe':
        item.decrement();
        if(item.isEmpty){
          this.remove(item.item.method)
          return false;
        }
        return true;
      default: throw new Error('out of range');
    }
  }

  /** Получить все прослушиваемые методы signalR */
  public getAllMethods(): Array<string>{
    return this._array.map(x => x.item.method);
  }

  /**
   * Удаление из хранилища
   * Если элемент отсутствует будет ошибка
   * Если у элемента есть подписчики будет ошибка
   */
  private remove(method: string){
    const subscribersStorageItem = this._get(method);

    if(!subscribersStorageItem){
      throw new Error(`В массиве отсутствует элемент с методом == '${method}'`)
    }

    if(!subscribersStorageItem.isEmpty){
      throw new Error('Не допускается удаление из хранилища метод signalR если у него есть подписчики')
    }

    subscribersStorageItem.item.subject.complete();
    this._array = this._array.filter(x => x.item.method !== method);
  }

  /** Получение по методу signalR. Вернет null если он отсутствует */
  private _get(method: string){
    return this._array.find(x => x.item.method === method);
  }
}

/** Подписка */
class SubscribersStorage_Subscription{
  public constructor(public readonly method: string,
                     public readonly subject: Subject<ISendObj<any>>) {
  }
}

/** Элемент в хранилище подписок */
class SubscribersStorageItem{
  private _count = 0;

  public constructor(public readonly item: SubscribersStorage_Subscription) {
  }

  public increment(){
    this._count += 1;
  }

  public decrement(){
    this._count -= 1;
  }

  /** Количество подписок */
  public get count(){
    return this._count;
  }

  /** Отсутствуют ли подписки */
  public get isEmpty(){
    return this._count < 1;
  }
}
