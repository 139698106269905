<button *ngIf="redaction"
        kendoButton
        icon="folder-open"
        title="Сменить редакцию"
        [disabled]="disabled"
        (click)="onRootClick()"
        class="spec-root-button"
>
  <span class="spec-version-span">
      {{type == 'table'
      ? (redaction.version == 0 ? 'Первичный' : 'Корректирующий')
      : 'Версия'}}: <strong [ngClass]="{actual: redaction.isActualVersion, 'not-actual': !redaction.isActualVersion}">{{redaction.version}}</strong>
  </span>
  <span class="spec-redaction-span margin-left-10">
      Редакция:
      <strong [ngClass]="{actual: redaction.isActualRedaction, 'not-actual': !redaction.isActualRedaction}">{{redaction.modifiedDate | kendoDate: 'HH:mm dd.MM.yyyy'}}</strong>
    </span>
  <button *ngIf="hasClearCompareButton"
          kendoButton
          [size]="'small'"
          [icon]="'not-equal'"
          [themeColor]="'error'"
          class="spec-clear-compare-button margin-left-10"
          title="Отключить сравнение"
          (click)="onClearCompare($event)"
          style="margin-top: -3px; margin-bottom: -3px"
  >
  </button>
</button>
