import { ChangeDetectorRef, Directive, Input, OnDestroy, SimpleChange, SimpleChanges } from '@angular/core';
import { GridComponent, SelectionDirective } from '@progress/kendo-angular-grid';
import { ArrayDataSourceSelection } from '../../classes/array-data-sources/selections/array-data-source-selection';
import { ReplaySubject, Subject } from 'rxjs';
import { RowArgs } from '@progress/kendo-angular-grid/rendering/common/row-args';
import { delay, takeUntil } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';

/** Тип управляющего выделением */
type SelectionType = ArrayDataSourceSelection<any, any>;

/**
 * Директива управляет выделенными строками в {@link GridComponent}.<br>
 * Инициализирует поля стандартной директивы kendo {@link SelectionDirective}, при помощи переданного {@link ArrayDataSourceSelection}.<br>
 * @example
 * <kendo-grid
 *    kendoGridSelectBy (!ОБЯЗАТЕЛЬНО ИСПОЛЬЗОВАНИЕ СТАНДАРТНОЙ ДИРЕКТИВЫ!)
 *    [KendoGridSelectByArrayDataSourceSelection]="selection"
 */
@Directive({
  selector: '[KendoGridSelectByArrayDataSourceSelection]',
})
export class KendoGridSelectByArrayDataSourceSelectionDirective implements OnDestroy {

  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(),
    selectionChange: new Subject<SelectionType>(),
  };

  /** Текущее состояния выделенных идентификаторов */
  private _currentIds: any[];

  private _selection: SelectionType;
  /** Управляющий выделением {@link ArrayDataSourceSelection} */
  @Input('KendoGridSelectByArrayDataSourceSelection') set selection(value: SelectionType) {
    this._selection = value;
    this.streams$.selectionChange.next(value);
    this._currentIds = [];
    this.init();
  }

  constructor(private readonly kendoSelectByDirective: SelectionDirective,
              private readonly cdr: ChangeDetectorRef) {
  }

  /** Метод инициализирует директиву */
  private init() {
    //Устанавливаем функцию получения ключа строки в директиву
    this.kendoSelectByDirective.selectionKey = this._selection
      ? (rowArgs: RowArgs) => {
        return this._selection.dataSource.idGetter(rowArgs.dataItem);
      }
      : undefined;

    //Подпись на изменения в селекторе
    if (this._selection) {
      this._selection.selectedIds.data$
        .pipe(
          takeUntil(this.streams$.selectionChange),
          takeUntil(this.streams$.unsubscribe),
        )
        .subscribe(ids => {
          if (!this.isArraysHasDifference(ids, this._currentIds)) {
            return;
          }

          this._currentIds = ids;
          this.setSelectedKeysToDirective(ids, false);
          this.cdr.markForCheck();
        });
    }

    //Подпись на изменения выделенными строками пользователем
    this.kendoSelectByDirective.selectedKeysChange
      .pipe(
        delay(1),
        takeUntil(this.streams$.selectionChange),
        takeUntil(this.streams$.unsubscribe),
      ).subscribe(ids => {

      if (!this._selection) {
        return;
      }

      this._currentIds = ids;

      if (this.isArraysHasDifference(ids, this._selection.selectedIds.data)) {
        this._selection.setIds(ids, false, 'user');
      }
    });
  }

  /** Метод устанавливает значение в директиву {@link SelectionDirective} */
  private setSelectedKeysToDirective(ids: any[], isFirstChange: boolean) {
    const prevIds = this.kendoSelectByDirective.selectedKeys;
    this.kendoSelectByDirective.selectedKeys = ids;

    const changeName: keyof SelectionDirective = 'selectedKeys';

    const changes: SimpleChanges = {};
    changes[changeName] = new SimpleChangeObj(ids, prevIds, isFirstChange);

    this.kendoSelectByDirective.ngOnChanges(changes);
  }

  /** Метод возвращает true если набор в массивах разный */
  private isArraysHasDifference(arr1: any[], arr2: any[]) {
    return ArrayHelper.difference2(
      arr1 ?? [],
      arr2 ?? [],
      (x1, x2) => x1 === x2,
      (x1, x2) => x1 === x2,
    ).length > 0;
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.selectionChange.complete();
  }
}


class SimpleChangeObj implements SimpleChange {
  constructor(public currentValue: any,
              public previousValue: any,
              public firstChange: boolean) {
  }

  isFirstChange(): boolean {
    return this.firstChange;
  }
}
