import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ITreeleestItem} from "../../../../classes/requestResults/iTreeleestItem";
import {Observable} from "rxjs";
import {WebApi1Service} from "../web-api1.service";
import * as moment from 'moment';
import { DateHelper } from 'src/app/helpers/dateHelper';
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";
import {ISubdivision} from "../../../../classes/domain/POCOs/stafflist/Subdivision";

/** Сервис работы с контроллером SubdivisionsTreeListControlController */
@Injectable({
  providedIn: "root"
})
export class Api1SubdivisionsTreelistControlControllerService {

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service
  ) {
  }

  /**
   * Получить подразделения<br>
   * @deprecated после удаления данного метода удалить и методы контроллера
   */
  public getForTimeSheet$(month: Date): Observable<Array<ITreeleestItem>>{
    return this.httpClient.post<Array<ITreeleestItem>>(
      this.webApi1Service.controllers.subdivisionsTreeListControl.actions.getForTimeSheet.toString(),
      {month: month}
    );
  }

  /** Получить подразделения */
  public getForTimeSheet2$(month: Date, subdivisionOwnerIds: number[] = undefined): Observable<Array<ISubdivision>>{
    return this.httpClient.post<Array<ISubdivision>>(
      this.webApi1Service.controllers.subdivisionsTreeListControl.actions.getForTimeSheet2.toString(),
      {
        month: month,
        subdivisionOwnerIds: subdivisionOwnerIds
      }
    );
  }

 /** Получить подразделения на указанную дату и диапазон отчёта. Если date == null вернет на текущую дату */
  public getForDate$(date:Date = null, startDate:Date = null, endDate:Date = null): Observable<Array<ITreeleestItem>>{
    return this.httpClient.get<Array<ITreeleestItem>>(
      this.webApi1Service.controllers.subdivisionsTreeListControl.actions.getForDate.toString(),
      {params: HttpParamsHelper.getParams(
        [
          ['date',date],
          ['startDate', startDate],
          ['endDate', endDate]
        ]
      )}
    );
  }

}
