import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ITuberLog extends IEntityId, IEntityModify, IEntityDeletedFlag {
  /** Идентификатор сотрудника */
  staffUnitId: number;
  /** Дата, за которую составлена данная запись */
  date: Date;
  /** Номер истории болезни пациента */
  diseaseNumber: string;
  /** ФИО пациента */
  patientFio: string;
  /** Эпидемиологический № */
  epidemNumber: string;
  /** Вид обслуживания */
  typeServiceId: number | null;
  /** Вид исследования */
  typeResearchId: number | null;
  customTypeResearch: string; // Кастомный вид иследования
  typeWorkId: number | null;//Вида работ или название материала
  customTypeWork: string; // Кастомный вид работ или название материала
  researchProtocol: string;// № протокола исследований
  /** Количество проведенных исследований в рамках данной записи */
  researchCount: number | null;
  operatingTime: number;
}

@classBackend('TuberLog', 'timesheet')
@className('TuberLog')
/** Модель строки туберкулез журнала */
export class TuberLog implements ITuberLog {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public deletedFlag: boolean,
              public staffUnitId: number,
              public date: Date,
              public diseaseNumber: string,
              public patientFio: string,
              public epidemNumber: string,
              public typeServiceId: number | null,
              public typeResearchId: number | null,
              public customTypeResearch: string,
              public typeWorkId: number | null,
              public customTypeWork: string,
              public researchProtocol: string,
              public researchCount: number | null,
              public operatingTime: number,
  ) {
  }

  /** Получить заполненный по умолчанию */
  public static GetEmpty(staffUnitId: number, date: Date): TuberLog {
    return new TuberLog(0, null, null, false,
      staffUnitId, date, '', '', '', 0,
      0, null,0, null,
      '',0, 1);
  }
}

