import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { GenderEnum } from '../../enums/gender-enum';
import { DateOnly } from '../../../Dates/date-onlys/date-only';
import {className} from "../../../../decorators/className/className.decorator";
import {classBackend} from "../../../../decorators/classBackend/classBackend.decorator";

export interface IEmployee extends IEntityVersioning {
  /** Фамилия */
  lastName: string;
  /** Имя */
  firstName: string;
  /** Отчество */
  patronymic: string;
  /** Табельный номер */
  code: string;
  /** Пол */
  gender: GenderEnum;
  /** Дата рождения */
  birthday: DateOnly;
}

@classBackend('Employee', 'stafflist')
@className('Employee')
/** Модель БД таблицы stafflist.Employees (Сотрудник) */
export class Employee implements IEmployee {
  constructor(public id: number,
              public ownerId: number | null,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public comment: string,
              public actionId: number,
              public orderId: number | null,
              public deletedFlag: boolean,
              public code: string,
              public firstName: string,
              public lastName: string,
              public patronymic: string,
              public gender: GenderEnum,
              public birthday: DateOnly,
              public timestamp: [],
  ) {
  }

  /** Получить ФИО */
  public static fullName(source: Pick<IEmployee, 'lastName' | 'firstName' | 'patronymic'>) {
    return `${source.lastName} ${source.firstName}${source.patronymic ? ` ${source.patronymic}` : ''}`;
  }
}
