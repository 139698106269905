import {Injectable} from "@angular/core";
import {WebApi1Service} from "../web-api1.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {IEmployee} from "src/app/classes/domain/POCOs/stafflist/Employee";
import {IOccupation} from "src/app/classes/domain/POCOs/stafflist/Occupation";
import {ISubdivision} from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import { StaffUnitTypeEnum } from "src/app/classes/domain/enums/StaffUnitTypeEnum";

@Injectable({
  providedIn: "root"
})
export class Api1SelectWorkingEmployeesControlControllerService{

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  /**
   * Получить работающих сотрудников
   */
  public getForDate$(date: Date = null, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null, workingStaffUnitTypes: StaffUnitTypeEnum[] = null, includeOccupation = true, includeSubdivision = true): Observable<Array<EmployeeResponseItem>>{
    return this.httpClient
      .post<IGetForDateResponse>(this.webApi1Service.controllers.selectWorkingEmployeesControl.actions.getForDate.toString(),
        {
          date,
          startDate,
          endDate,
          ownerIds,
          workingStaffUnitTypes,
          includeOccupation,
          includeSubdivision
        }).pipe(map(data =>
          data.employees.map(employee => new EmployeeResponseItem(
          employee.id,
          employee.startDate,
          employee.endDate,
          employee.lastName,
          employee.firstName,
          employee.patronymic,
          employee.code,
          data.subdivisions.filter(subdivision=>employee.subdivisionsIds?.some(es=> es === subdivision.id)),
          data.occupations.filter(occupation=>employee.occupationsIds?.some(eo=> eo === occupation.id)),
        ))));
  }
}

interface IGetForDateResponse
{
  employees: Array<IEmployeeDTO>;
  subdivisions: Array<Pick<ISubdivision, 'id' | 'longName'>>;
  occupations: Array<Pick<IOccupation, 'id' | 'name'>>;
}

interface IEmployeeDTO extends Pick<IEmployee, 'id' | 'startDate' | 'endDate' | 'lastName' | 'firstName' | 'patronymic' | 'code'> {
  subdivisionsIds: number[],
  occupationsIds: number[]
}

export class EmployeeResponseItem implements Pick<IEmployee, 'id' | 'startDate' | 'endDate' | 'lastName' | 'firstName' | 'patronymic' | 'code'> {
  constructor(
    public id: number,
    public startDate: Date,
    public endDate: Date,
    public lastName: string,
    public firstName: string,
    public patronymic: string,
    public code: string,
    public subdivisions: Pick<ISubdivision, 'id' | 'longName'>[],
    public occupations: Pick<IOccupation, 'id' | 'name'>[],
  ){}
}
