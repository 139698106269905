import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IOccupation extends IEntityVersioning {
  /** Наименование должности */
  name: string;
  /** Код должности */
  code: string;
  /** Тип должности (врач, средний медПерсонал и пр) */
  occupationTypeId: number | null;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IOccupationOptional = Partial<IOccupation>;

@classBackend('Occupation', 'stafflist')
@className('Occupation')
/** Модель БД таблицы stafflist.Occupations (Должности) */
export class Occupation implements IOccupation {
  constructor(public id: number,
              public ownerId: number | null,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public actionId: number,
              public orderId: number | null,
              public deletedFlag: boolean,
              public name: string,
              public code: string,
              public occupationTypeId: number | null,
              public timestamp: [],
  ) {
  }
}
