import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { IEntityPositionOwnerId } from '../interfaces/IEntityPositionOwnerId';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {StaffUnitTypeEnum} from "../../enums/StaffUnitTypeEnum";
import {DateHelper} from "../../../../helpers/dateHelper";

/**
 * Результат анализа даты в диапазоне исполнения должности и ее виртуальной строки<br>
 * outOfStaffUnitRange - дата не попадает в диапазон действия исполнения должности<br>
 * outOfSubStaffUnitRange - дата попадает в диапазон действия исполнения должности, но при этом не попадает в диапазон действия текущей виртуальной строки<br>
 * inRange - дата попадает в диапазон действия исполнения должности и в диапазон действия текущей виртуальной строки<br>
 */
export type DateInStaffUnitRangeType = 'outOfStaffUnitRange' | 'outOfSubStaffUnitRange' | 'inRange';

export interface IStaffUnit extends IEntityVersioning, IEntityPositionOwnerId {
  /** Идентификатор сотрудника. FK на таблицу stafflist.Employees */
  employeeId: number | null;
  /** Идентификатор штатной позиции, к которой относится данная штатная единица. FK на таблицу Positions */
  financingSourceId: number | null;
  /** Тип штатной единицы (основная ставка, совмещение, замещение). FK на таблицу stafflist.StaffUnitTypes */
  typeId: number | null;
  /** Идентификатор штатной единицы, которую замещает данная штатная единица. FK на эту же таблицу */
  parentId: number | null;
  /** Исполнение обязанностей */
  executionDutiesFlag: boolean | null;
  /** Количество занятых ставок этой штатной единицей */
  rate: number | null;
  /** Процент выплаты */
  percent: number | null;
  /** Выплата компенсации молока за вредность */
  milk: boolean;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IStaffUnitOptional = Partial<IStaffUnit>;

@classBackend('StaffUnit', 'stafflist')
@className('StaffUnit')
/** Штатная единица */
export class StaffUnit implements IStaffUnit {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public positionId: number | null,
              public employeeId: number | null,
              public financingSourceId: number | null,
              public typeId: number | null,
              public parentId: number | null,
              public executionDutiesFlag: boolean | null,
              public rate: number | null,
              public percent: number | null,
              public timestamp: [],
              public milk: boolean,
  ) {
  }

  /** Является ли исполнение должности внешним */
  public static isExternal(staffUnitType: number | StaffUnitTypeEnum): boolean {
    switch (staffUnitType) {
      case StaffUnitTypeEnum.CombinationExternal:
      case StaffUnitTypeEnum.MoonlighterExternal:
        return true;
      default:
        return false;
    }
  }

  /** Является ли исполнение должности работой за кого то */
  public static isProxy(parentId: number): boolean {
    return !!parentId;
  }

  /**
   *  Метод анализирует переданную {@link date} на вхождение в диапазон действия исполнения должности и виртуальной строки<br>
   */
  public static checkDateInRange(date: Date, staffUnitStartDate: Date, staffUnitEndDate: Date, subStaffUnitStartDate: Date, subStaffUnitEndDate: Date): DateInStaffUnitRangeType{
    if(date < DateHelper.valueOrMin(staffUnitStartDate) || date >  DateHelper.valueOrMax(staffUnitEndDate)){
      return 'outOfStaffUnitRange';
    }

    if(date < DateHelper.valueOrMin(subStaffUnitStartDate) || date > DateHelper.valueOrMax(subStaffUnitEndDate)){
      return 'outOfSubStaffUnitRange';
    }

    return 'inRange';
  }
}
