import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {
  DeepSerializerForServerService
} from "../../../services/object-serializers/deep-serializer-for-server-services/deep-serializer-for-server.service";

/**
 * Интерцептор для глубокой конвертации запроса/ответа в формат сервера/из формата сервера.
 * Смотри {@link DeepSerializerForServerService}
 */
@Injectable({
  providedIn: "root"
})
export class DeepServerFormatInterceptor implements HttpInterceptor{
  constructor(private deepSerializerForServerService: DeepSerializerForServerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqCloned = req.clone({
      body: this.deepSerializerForServerService.serialize(req.body)}
    );

    return next.handle(reqCloned).pipe(map(value => {
      if(value.type === HttpEventType.Response) {
        return value.clone({body: this.deepSerializerForServerService.deserialize(value.body)});
      }

      return value;
    }));
  }
}
