import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { WebApi1Service } from "../web-api1.service";
import { defer, Observable, of, ReplaySubject } from "rxjs";
import { IDropDownItem } from "../../../../classes/requestResults/iDropDownItem";
import { takeUntil, tap } from "rxjs/operators";
import { AuthService } from "../../../../modules/auth/services/auth.service";
import { TuberLog } from "src/app/classes/domain/POCOs/timesheet/TuberLog";

@Injectable({
  providedIn: "root"
})
export class Api1TuberControllerService implements OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private readonly httpClient: HttpClient,
              private readonly webApi1Service: WebApi1Service,
              private readonly authService: AuthService
  ) {
    this.authService.isAuth$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      if (!value) {
        this._typesService = null;
      }
    })
  }

  private _typesService: Array<IDropDownItem> = null;
  /** Получить список видов исследования (КЭШ) */
  public get typesService_cached$(): Observable<Array<IDropDownItem>> {
    return defer(() => {
      return this._typesService == null ?
        this.typesService$.pipe(tap(value => this._typesService = value)) :
        of(this._typesService);
    });
  }

  private _typesWork: Array<IDropDownItem> = null;
  /** Получить список ВИЧ видов обслуживания (КЭШ) */
  public get typesWork_cached$(): Observable<Array<IDropDownItem>> {
    return defer(() => {
      return this._typesWork == null ?
        this.typesWork$.pipe(tap(value => this._typesWork = value)) :
        of(this._typesWork);
    });
  }

  /** Получить список ВИЧ видов обслуживания */
  public get typesWork$(): Observable<Array<IDropDownItem>> {
    return this.httpClient
      .get<Array<IDropDownItem>>(this.webApi1Service.controllers.vich.actions.getTypesWorkAndMaterialNames.toString());
  }

  /** Получить список видов исследования */
  public get typesService$(): Observable<Array<IDropDownItem>> {
    return this.httpClient
      .get<Array<IDropDownItem>>(this.webApi1Service.controllers.tuber.actions.getTypesService.toString());
  }

  private _typesResearch: Array<IDropDownItem> = null;
  /** Получить список видов обслуживания (КЭШ) */
  public get typesResearch_cached$(): Observable<Array<IDropDownItem>> {
    return defer(() => {
      return this._typesResearch == null ?
        this.typesResearch$.pipe(tap(value => this._typesResearch = value)) :
        of(this._typesResearch);
    });
  }
  /** Получить список видов обслуживания */
  public get typesResearch$(): Observable<Array<IDropDownItem>> {
    return this.httpClient
      .get<Array<IDropDownItem>>(this.webApi1Service.controllers.tuber.actions.getTypesResearch.toString());
  }

  /** Добавить запись о случае работы с туберкулезом */
  public addLog$(model: IAddTuberLogRequest): Observable<TuberLog> {
    return this.httpClient.post<TuberLog>(
      this.webApi1Service.controllers.tuber.actions.addLog.toString(),
      model
    );
  }

  /** Редактирование записи о случае работы с туберкулезом */
  public editLog$(model: IEditTuberLogRequest): Observable<TuberLog> {
    return this.httpClient.post<TuberLog>(
      this.webApi1Service.controllers.tuber.actions.editLog.toString(),
      model
    );
  }

  /** Удалить запись о случае работы с туберкулезом */
  public removeLog$(id: number): Observable<boolean> {
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.tuber.actions.removeLog.toString(), { id }
    );
  }

  /** Получить случаи работы сотрудника с туберкулезом на дату */
  public getLogs$(staffUnitOwnerIds: Array<number>, date: Date): Observable<Array<TuberLog>> {
    return this.httpClient.post<Array<TuberLog>>(
      this.webApi1Service.controllers.tuber.actions.getLogs.toString(), { staffUnitOwnerIds, date }
    );
  }

  /** Может ли пользователь редактировать добавлять удалять данные на дату */
  public getCanAddEditRemove$(date: Date): Observable<boolean> {
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.tuber.actions.canAddEditRemove.toString(), { date }
    );
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

export interface IAddTuberLogRequest extends ITuberLogEditingProperty {
  date: Date
}

export interface IEditTuberLogRequest extends ITuberLogEditingProperty {
  id: number
}

export interface ITuberLogEditingProperty {
  staffUnitId: number,
  diseaseNumber: string,
  patientFio: string,
  epidemNumber: string,
  typeServiceId: number,
  typeResearchId: number,
  customTypeResearch: string,
  typeWorkId: number,
  customTypeWork: string,
  researchProtocol: string,
  researchCount: number,
  operatingTime: number
}
