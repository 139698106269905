import { ComponentServiceBase } from "../../../../../../../../../../src/app/services/abstracts/component-service-base";
import { IGraphDayCellComponent, IGraphDayCellComponent_SettingsType } from "../i-graph-day-cell-component";
import { Injectable, OnDestroy } from "@angular/core";
import { GraphDayCellService } from "./graph-day-cell.service";

/** Сервис для компонента GraphDayCellService */
@Injectable()
export class GraphDayCellComponentService extends ComponentServiceBase<IGraphDayCellComponent> implements OnDestroy {
  private _settings: IGraphDayCellComponent_SettingsType;

  /** Настройки компонента */
  public get settings() {
    return this._settings;
  }
  public set settings(value) {
    this._settings = value;
    this.OnInit(value);
  }

  constructor(private graphDayService: GraphDayCellService) {
    super();
  }

  private OnInit(settings: IGraphDayCellComponent_SettingsType) {
    const isEmpty = this.graphDayService.isEmpty(settings.staffUnit.startDate, settings.staffUnit.endDate, settings.graphDay.day.date);
    this.component.isEmpty = isEmpty;
    this.component.graphDayColor = isEmpty ? 'rgba(0, 0, 0, 0.15)' : settings.graphDay.dayDeviation ? settings.graphDay.dayDeviation.color : null;
    this.component.dayDeviationKey = settings.graphDay.dayDeviation?.shortName ?? null;
    this.component.dayDeviationCustomValue = settings.graphDay.dayDeviation?.hasCustomValue ? settings.graphDay.customValue : null;
    this.component.timeInterval = settings.graphDay.timeInterval ? {
      start: settings.graphDay.timeInterval.start,
      end: settings.graphDay.timeInterval.end
    } : null;
    this.component.substractLunch = settings.graphDay.subtractLunch ? settings.graphDay.subtractLunch : false;
    this.component.covidHours = settings.graphDay.covidHours && settings.graphDay.timeInterval ? settings.graphDay.covidHours : null;
    this.component.hasCovid2 = settings.graphDay.hasCovid2 && settings.graphDay.timeInterval ? settings.graphDay.hasCovid2 : false;
    this.component.vichHours = settings.graphDay.vichHours && settings.graphDay.timeInterval ? settings.graphDay.vichHours : null;
    this.component.tuberHours = settings.graphDay.tuberHours && settings.graphDay.timeInterval ? settings.graphDay.tuberHours : null;
    this.setMilk(isEmpty);
    this.component.isChanged = settings.graphDay.isChanged;
  }

  /** Установить Молоко */
  private setMilk(isEmpty: boolean){
    if(isEmpty){
      this.component.milk = false;
      return;
    }

    if(this.settings.graphDay.hasExcludeMilk){
      this.component.milk = false;
      return;
    }

    this.component.milk = this.settings.staffUnit.milk && !!this.settings.graphDay.timeInterval;
  }

  ngOnDestroy(): void {
  }
}
