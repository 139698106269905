import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {IGraphDayCellComponent, IGraphDayCellComponent_SettingsType} from "./i-graph-day-cell-component";
import {GraphDayCellComponentService} from "./services/graph-day-cell-component.service";

/** Компонент ячейки содержащий GraphDay */
@Component({
  selector: 'app-graph-day-cell',
  templateUrl: './graph-day-cell.component.html',
  styleUrls: ['./graph-day-cell.component.css'],
  providers: [GraphDayCellComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphDayCellComponent implements IGraphDayCellComponent, OnDestroy {
  /** Настройки для инициализации */
  @Input() set settings(value: IGraphDayCellComponent_SettingsType){
    this.service.settings = value;
  }

  public dayDeviationCustomValue: number;
  public dayDeviationKey: string;
  public graphDayColor: string;
  public isEmpty: boolean;
  public substractLunch: boolean;
  public timeInterval: { start: string; end: string };
  public covidHours: number;
  public hasCovid2: boolean;
  public vichHours: number;
  public tuberHours: number;
  public isChanged: boolean;
  public milk: boolean;

  constructor(private service: GraphDayCellComponentService) {
    this.service.component = this;
  }

  ngOnDestroy(): void {
    this.service.ngOnDestroy();
  }
}


