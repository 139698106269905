import { Component, Input, OnInit, Self, ViewChild } from '@angular/core';
import { TableGridComponentService } from "./classes/services/table-grid-component.service";
import { Day, TableRowModel } from "./classes/view-models/row-and-table-cell-view-model.class";
import { CellClickEvent, GridComponent, SelectableSettings } from "@progress/kendo-angular-grid";
import { AuthService } from "../../../../../../../src/app/modules/auth/services/auth.service";
import { process, SortDescriptor, State } from "@progress/kendo-data-query";
import {
  GraphTableComponentSettings,
  IBySubdivisionOwnerId
} from "../graph-grid/classes/graphTableComponentSettings";
import { RedactionBaseService } from "../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import { GridToolbarManagementService } from "../services/grid-toolbar-management.service";
import { Api1RedactionTableService } from "../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/api1-redaction-table.service";
import { Observable, ReplaySubject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ResponseObjError } from "../../../../../../../src/app/classes/requestResults/responseObjError";
import { AlertService } from "../../../../../../../src/app/services/alert.service";
import { LoadingIndicatorService } from "../../../../../../../src/app/services/loading-indicator.service";
import { Api1TableControlControllerService } from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-table-control-controller.service";
import { TableCodePanelService } from "./services/table-code-panel.service";
import { CodePanelItem } from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-code-controller.service";
import { ContextMenuComponent, ContextMenuSelectEvent } from "@progress/kendo-angular-menu";
import { traceClass } from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import { TracerServiceBase } from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { traceFunc } from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { trace } from "../../../../../../../src/app/modules/trace/operators/trace";
import {StaffUnitTypeEnum} from "../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";

@Component({
  selector: 'app-table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.css'],
  providers: [
    { provide: 'target', useValue: 'table' },
    { provide: RedactionBaseService, useExisting: Api1RedactionTableService },
    { provide: GridToolbarManagementService },
    { provide: TableCodePanelService },
  ],
})
@traceClass('TableGridComponent')
export class TableGridComponent implements OnInit {
  @Input() subdivisionOwnerId: number = null;
  @Input() yearId: number = null;
  @Input() monthId: number = null;
  @Input() settings: GraphTableComponentSettings = null;
  redaction: Date = null;

  /** {@link StaffUnitTypeEnum} для работы в html */
  public readonly staffUnitTypeEnum = {
    Duty: StaffUnitTypeEnum.Duty
  };

  /** стримы */
  public streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  public view: {
    sortEntity: Array<TableSortModel>,
    source: Array<TableRowModel>,
    days: Array<Day>
  }

  public mySelection: number[] = new Array<number>();
  public selectedRow: TableRowModel;
  @ViewChild('tableGrid') tableGrid: GridComponent;
  private contextItem: any;

  //переменные для реализации контекстного меню на строках grid-а
  @ViewChild('gridMenu')
  /** элемент контекстного меню */
  public gridContextMenu: ContextMenuComponent;
  tableGridContextMenu: Array<any> = [{ text: 'Удалить выделенную строку с информацией по коду', key: 'deleteRow' }];

  /** Массив для хранения удаленных строк табеля для дальнейшей их передачи серверу для удаления*/
  private deletedRows: Array<TableRowModel> = new Array<TableRowModel>();

  /** Изменен ли график */
  public get viewHasChange(): boolean {
    if (this.view == null) {
      return false;
    }
    return this.deletedRows.length > 0 || this.view.source.some(item => item.RowHasChange());
  };

  /**
   * Объект, используемый в методах сортировки и фильтрации данных Grid
   * для фильтрации строк добавить поле filter:
   */
  public state: State = new class implements State {
    sort: Array<SortDescriptor>;
  };
  //используется для активации возможности выделять строчки табеля
  // (доступно только в режиме редактирования)
  selectableSettings: SelectableSettings = {
    enabled: false,
    mode: "single"
  };

  /** Данные для tooltip при наведении на иконку сотрудника */
  public dataForTooltip: TableRowModel;

  constructor(private tableGridComponentService: TableGridComponentService,
    public authService: AuthService,
    public gridToolbarManagementService: GridToolbarManagementService,
    private alertService: AlertService,
    private loadingIndicatorService: LoadingIndicatorService,
    private api1TableControlControllerService: Api1TableControlControllerService,
    @Self() public tableCodePanelComponentService: TableCodePanelService,
    public readonly tracerService: TracerServiceBase,
  ) { }

  @traceFunc()
  ngOnInit() {
    if (!this.settings) {
      throw new Error('Не переданы настройки табеля')
    }

    this.initGridToolbarManagementService().pipe(takeUntil(this.streams$.unsubscribe)).subscribe();

    this.gridToolbarManagementService.redactionChange$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      if (value.afterSave) {
        return;
      }
      this.tableGridComponentService.viewModel$(value.redactionId)
        .pipe(trace(this.tracerService), takeUntil(this.streams$.unsubscribe))
        .subscribe({
          next: value => {
            this.view = {
              sortEntity: new Array<TableSortModel>(),
              source: new Array<TableRowModel>(),
              days: new Array<Day>()
            }
            this.state.sort = this.tableGridComponentService.getTableDefaultSortSettings();  //Получаем первоначальную сортировку
            this.view.source = TableRowModel.CreateArray(value);

            this.view.sortEntity = this.view.source // Создаем список masterStaffUnit для дальнейшей сортировки блоков строк, относящихся к нему
              .filter(x => x.isFirstRow)
              .map(x => {
                return {
                  masterStaffUnitId: x.masterStaffUnit.ownerId,
                  employeeName: x.masterStaffUnit.employee.fullName,
                  occupationName: x.masterStaffUnit.occupation.name,
                  rate: x.masterStaffUnit.rate,
                  isProxy: x.masterStaffUnit.isProxy,
                  isEmployment: x.masterStaffUnit.isEmployment
                };
              });

            this.sortSource();

            this.view.days = Day.CreateArray(value.directories.days, value.directories.dayTypes);
          }, error: error => {
            if (ResponseObjError.checkTypeReturnCode(error) == '4400b854-8c15-49f5-8c66-7a52df686f2e') {
              const errorMessage = (error as ResponseObjError<string>).data;
              this.alertService.defaultAlertOption.information().mod(x => {
                x.message = errorMessage;
                x.buttons[0].callBack = () => {
                }
              }).showAlert();
              return;
            }
          }
        });
    });

    this.gridToolbarManagementService.save$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(comment => {
      this.saveTable(comment);
    });

    this.tableCodePanelComponentService.insertedCode$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.addRow(value);
    });
    this.gridToolbarManagementService.isEditing$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.settings.isEdit$.emit(value);
      this.selectableSettings.enabled = value;
      if (!value) {
        this.mySelection.length = 0;
        this.tableCodePanelComponentService.selectedRow$.next(null);
      }
    });
  }

  /** Сохранить график */
  @traceFunc()
  private saveTable(comment: string) {
    let changedRows = this.view.source.filter(x => x.RowHasChange()).concat(this.deletedRows);
    const saveTableData = this.tableGridComponentService
      .getDataForSaveTable(changedRows);

    this.loadingIndicatorService.addToObservable(
      'Сохранение табеля',
      this.api1TableControlControllerService.saveTable$(
        this.gridToolbarManagementService.redaction.id,
        comment,
        saveTableData.tableCodes)
    ).pipe(trace(this.tracerService)).subscribe({
      next: value => {
        value.ids.forEach(idTuple => {
          const tableRow = saveTableData.sourceData.find(x => x.guid == idTuple.guid).tableRow as TableRowModel;
          tableRow.tableCodeId = idTuple.id;
          tableRow.ownerId = idTuple.ownerid;
          tableRow.applyChange();
        });

        this.deletedRows.length = 0;

        this.gridToolbarManagementService.OnInit4(value.grouper, null, false, true);
      }, error: error => {
        if (ResponseObjError.checkTypeReturnCode(error) == 'd09bb974-f9eb-45d5-a8e6-392b034aa2a3') {
          const errorMessage = (error as ResponseObjError<string>).data;
          this.alertService.defaultAlertOption.information().mod(x => {
            x.message = `К сожалению мы не сможем сохранить Ваши данные<br>${errorMessage}<br><strong>Страница будет перезагружена</strong>`
            x.buttons[0].callBack = () => {
              this.loadingIndicatorService.add('Перезагрузка страницы');
              window.location.reload();
            }
          }).showAlert();
          return;
        }

        this.alertService.defaultAlertOption.warning().showAlert();
      }
    });
  }

  @traceFunc()
  onCellClick($event: CellClickEvent): void {
    this.gridToolbarManagementService.isEditing$.pipe(take(1)).subscribe(value => {
      // Отменяем вызов контекстного меню браузера
      const originalEvent = $event.originalEvent;
      originalEvent.preventDefault();

      if (value) {
        //обработка события вызова контекстного меню на строке
        if ($event.type === "contextmenu") {
          if (!$event.dataItem.code.isComputedFlag) {
            this.contextItem = $event.dataItem;
            // чтоб визуализировать строку, на которой вызвали контекстное меню для удаления, выделяем ее
            this.mySelection = [$event.rowIndex];
            this.selectedRow = $event.dataItem;

            // показываем контекстное меню
            this.gridContextMenu.show({
              left: originalEvent.pageX,
              top: originalEvent.pageY,
            });
          }
        }

        if ($event.columnIndex == 6 && !$event.dataItem.code.isComputedFlag) {
          $event.isEdited = true;
          $event.sender.editCell($event.rowIndex, $event.columnIndex);
        }
      }
    });
  }

  /** Событие выбора пункта контекстного меню на строке табеля*/
  @traceFunc()
  onSelectContextMenuOnRow($event: ContextMenuSelectEvent) {
    const key = $event.item.key;
    this.tracerService.add2('выбранно', { obj: key })

    switch (key) {
      case 'deleteRow':
        // логика удаления строки табеля
        const deletedRowIndex = this.mySelection[0];
        const deletedRow = this.view.source.splice(deletedRowIndex, 1)[0];
        // Добавляем в массив удаляемых строк только строки, имеющиеся в БД
        if (deletedRow.ownerId) {
          deletedRow.deletedFlag = true;
          this.deletedRows.push(deletedRow);
        }
        break;
    }
  }



  /** Сортировка */
  public sortChange(sort: Array<SortDescriptor>): void {
    this.state.sort = this.tableGridComponentService.getTableDefaultSortSettings();
    //тк. сортировка только по одному полю, то в массиве сортировок будет только один элемент sort[0]
    this.state.sort = this.state.sort.filter(x => x.field != sort[0].field);
    this.state.sort.unshift(sort[0]);

    this.sortSource();
  }

  private sortSource() {
    this.view.sortEntity = process(this.view.sortEntity, this.state).data;
    let sortedSource = new Array<TableRowModel>();
    this.view.sortEntity.map(x => {
      let staffUnitRows = this.view.source.filter(el => el.masterStaffUnit.ownerId == x.masterStaffUnitId);
      //строку с основным кодом отображаем первой
      let firstRow = staffUnitRows.find(el => el.isFirstRow);
      if (firstRow) {
        sortedSource.push(firstRow);
      } else {
        throw new Error(`У сотрудника с id == ${staffUnitRows[0].masterStaffUnit} отсутствует строка с основным кодом`);
      }
      //остальные строки сортируем по возрастанию кода и значения
      staffUnitRows.filter(el => !el.isFirstRow).sort((one, two) =>
        one.code.key > two.code.key ?
          1 :
          one.code.key == two.code.key ?
            one.tableRowValue > two.tableRowValue ?
              1 :
              -1 :
            -1
      )
        .forEach(el => sortedSource.push(el));
    });
    this.view.source = sortedSource;
  }

  /** Проинициализировать сервис */
  private initGridToolbarManagementService(): Observable<void> {
    let initObservable: Observable<void>;

    const initType = this.settings.openFor.getValue();
    switch (initType.type) {
      case "subdivisionOwnerId":
        const value = initType.value as IBySubdivisionOwnerId
        initObservable = this.gridToolbarManagementService.onInit3(value.subdivisionOwnerId, value.year, value.month);
        break;
      case "graphOrTableId":
        initObservable = this.gridToolbarManagementService.onInit1(initType.value as number);
        break;
      case "redactionId":
        initObservable = this.gridToolbarManagementService.onInit2(initType.value as number);
        break;
      default:
        throw new Error('Out of range')
    }

    return initObservable;
  }


  selectRow($event) {
    this.selectedRow = $event.selectedRows[0].dataItem;
    this.tableCodePanelComponentService.selectedRow$.next(this.selectedRow);
  }

  @traceFunc()
  private addRow(code: CodePanelItem) {
    let newRow = TableRowModel.CreateEmpty(this.selectedRow.masterStaffUnit, code, this.view.days);
    this.view.source.push(newRow);
    this.sortSource();
    const newRowIndex = this.view.source.findIndex(row =>
      row.initialTableRowValue < 0 && // это вновь добавленная строка
      row.masterStaffUnit.ownerId == newRow.masterStaffUnit.ownerId && // строка относится к редактируемому сотруднику
      row.code.id == newRow.code.id &&
      !row.tableRowValue);
    this.mySelection = newRowIndex > 0 ? [newRowIndex] : []; // Выделяем вновь добавленную строку
    if (code.isEqualBaseRowValue){
      newRow.tableRowValue = this.view.source
        .find(x => x.masterStaffUnit.ownerId === newRow.masterStaffUnit.ownerId && x.isFirstRow)
        .tableRowValue;
    } else{
      this.tableGrid.editCell(newRowIndex, 6); //входим в режим редактирования добавленного кода
    }
  }


  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.gridToolbarManagementService.ngOnDestroy();
    this.tableCodePanelComponentService.ngOnDestroy();
  }
}

export class TableSortModel {
  masterStaffUnitId: number;
  employeeName: string;
  occupationName: string;
  rate: number;
  isProxy: boolean;
  isEmployment: boolean;
}
