import { NumberHelper } from "../../../../../../../../../src/app/helpers/numberHelper";
import {
  GetGraphModelResult,
  GetGraphModelResult_Data_CovidLogs as GetGraphModelResult_Data_CovidLogs,
  GetGraphModelResult_Data_GraphDay,
  GetGraphModelResult_Data_TuberLogs,
  GetGraphModelResult_Data_VichLogs
} from "../../../../../Classes/GetGraphModelResult";
import { WorkModeVM } from "./work-mode-view-model.class";
import { TimeIntervalVM } from "./time-interval-view-model.class";
import { Day } from "./day-view-model.class";
import { StaffUnitVM } from "./staff-unit-view-model.class";
import { PositionVM } from "./position-view-model.class";
import { EmployeeVm } from "./employee-view-model.class";
import { OccupationVM } from "./occupation-view-model.class";
import { StaffUnitTypeVM } from "./staff-unit-type-view-model.class";
import { DayDeviationVM } from "./day-deviation-view-model.class";
import { GraphNormFactCalculatorService } from "../../secvices/graph-norm-fact-calculator.service";
import { FinancingSourceVM } from "./financing-source-view-model";
import { IconsUrlHelper } from "src/app/helpers/icons-url.helper";
import {
  AsHasStateChangeObj,
  IAsHasStateChange
} from "../../../../../../../../../src/app/classes/as-has-state-change-obj/as-has-state-change-obj";
import {
  AsObservableObj,
  IAsObservable
} from "../../../../../../../../../src/app/classes/as-observable/as-observable-obj";
import {StaffUnitTypeEnum} from "../../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";

/** Интерфейс содержащий результат сравнения */
interface ICompareState {
  /** Результат сравнения */
  compareState: CompareState;
}

/** ViewModel строки графика */
export class GraphGridRowModel implements ICompareState, IAsObservable<GraphGridRowModel> {

  public compareState: CompareState = null;
  public readonly asObservable: AsObservableObj<GraphGridRowModel>;

  constructor(
    public normFactCalculatorService: GraphNormFactCalculatorService,
    public staffUnit: StaffUnitVM,
    public parent: GraphGridRowModel,
    public graphDays: Array<GraphDayViewModel>
  ) {
    this.asObservable = new AsObservableObj<GraphGridRowModel>(() => this, true);
  }

  /** img строки */
  public get imageUrl(): string {
    return IconsUrlHelper.getstaffUnitImagePath(this.staffUnit.staffUnitType.id, this.staffUnit.isProxy);
  }

  private _norm: number = null;
  /** Норма часов в месяце */
  public get norm(): number {
    return this.staffUnit.staffUnitType.id !== StaffUnitTypeEnum.Duty ? this._norm : 0;
  }

  private _fact: number = null;
  /** Фактическое отработанное время в месяце */
  public get fact(): number {
    return this._fact;
  }

  /** Установить норму и факт + пересчет дельты */
  public setNormaAndFact(norm: number, fact: number) {
    this._norm = norm;
    this._fact = fact;
    this._delta = this.norm == null || this.fact == null ? null : NumberHelper.round(this.fact - this.norm, 2);
    this.asObservable.onChange();
  }

  private _delta: number = null;
  /** Разница между нормой и фактом */
  public get delta(): number {
    return this.staffUnit.staffUnitType.id !== StaffUnitTypeEnum.Duty ? this._delta : 0;
  }

  /** Массив измененных дней графика */
  private _changedGraphDays: Array<GraphDayViewModel> = new Array<GraphDayViewModel>();
  public get changedGraphDays() { return this._changedGraphDays; }

  /** Обработка изменения ячейки. Поддерживает передачу null */
  public onChangedGraphDay(graphDayViewModel: GraphDayViewModel, recalculateNormAndFact: boolean = true) {
    if (graphDayViewModel?.asHasStateChange?.isChanged) {
      if (!!graphDayViewModel && !this._changedGraphDays.some(x => x === graphDayViewModel)) {
        this._changedGraphDays.push(graphDayViewModel);
      }
    } else {
      this._changedGraphDays = this._changedGraphDays.filter(x => x !== graphDayViewModel);
    }

    if (recalculateNormAndFact) {
      this.setNormaAndFact(null, null);
      this.normFactCalculatorService.recalculate$.next();
    }
  }

  /**
   * Сформировать источник для графика из ответа сервера
   * @constructor
   */
  public static CreateArray(
    source: GetGraphModelResult,
    days: Array<Day>,
    normFactCalculatorService: GraphNormFactCalculatorService): Array<GraphGridRowModel> {
    const positions = PositionVM.CreateArray(source.directories.positions);
    const workModes = WorkModeVM.CreateArray(source.directories.workModes);
    const employees = EmployeeVm.CreateArray(source.directories.employees);
    const occupations = OccupationVM.CreateArray(source.directories.occupations);
    const staffUnitTypes = StaffUnitTypeVM.CreateArray(source.directories.staffUnitTypes);
    const timeIntervals = TimeIntervalVM.CreateArray(source.directories.timeIntervals);
    const dayDeviations = DayDeviationVM.CreateArray(source.directories.dayDeviations);
    const financingSources = FinancingSourceVM.CreateArray(source.directories.financingSources);

    const allStaffUnits = source.datas.map(data => data.staffUnit);
    const resultArray = source.datas.map(data => {
      return (() => {
        const rowModel = new GraphGridRowModel(
          normFactCalculatorService,
          null, //заполняться будет после
          null, //заполняться будет после
          null, //заполняться будет после
        )

        rowModel.staffUnit = StaffUnitVM.Create(rowModel, data.staffUnit, allStaffUnits, staffUnitTypes, positions, workModes, occupations, employees, financingSources)

        rowModel.graphDays = days.map(day => {
          const graphDaySource = data.graphDays.find(x => +x.date == +day.date);
          const covidHours = data.covidLogs.find(x => +x.date == +day.date);
          const hasCovid2 = data.covid2Logs.some(x => +x.date == +day.date);
          const vichHours = data.vichLogs.find(x => +x.date == +day.date);
          const tuberHours = data.tuberLogs.find(x => +x.date == +day.date);

          return !graphDaySource ?
              GraphDayViewModel.CreateEmpty(rowModel, day, covidHours, hasCovid2, vichHours, tuberHours) :
              GraphDayViewModel.Create(rowModel, graphDaySource, days, timeIntervals, dayDeviations, covidHours, hasCovid2, vichHours, tuberHours, data.excludeMilkLogs.some(x => +x.date == +day.date));
        });

        return rowModel;
      })()
    });
    //Заполнение parent
    resultArray.filter(x => x.staffUnit.isProxy)
      .forEach(x => {
        x.parent = resultArray.find(item => item.staffUnit.ownerId == x.staffUnit.parentId)
      });

    return resultArray;
  }
}

/** Базовый класс для ячейки */
export abstract class GraphDayViewModelBase implements ICompareState {

  public compareState: CompareState = null;

  /** Идентификатор */
  abstract id: number;
  /** Интервал */
  abstract timeInterval: TimeIntervalVM;
  /** Отклонение */
  abstract dayDeviation: DayDeviationVM;
  /** Значение отклонения */
  abstract customValue: number;
  /** Вычитать ли обед */
  abstract subtractLunch: boolean;
  /** Размер обеда, устанавливаемый вручную */
  abstract flexDinner: number | null;

  /** Значения по умолчанию */
  public static get defaultValues(): GraphDayViewModelBase{
    return new class extends GraphDayViewModelBase {
      id = 0;
      timeInterval = null;
      dayDeviation = null;
      customValue = 0;
      subtractLunch = false;
      flexDinner = null;
    }
  }

  /** Скопировать */
  public static Copy(source: GraphDayViewModelBase): GraphDayViewModelBase {
    return new class extends GraphDayViewModelBase {
      id = source.id;
      timeInterval = source.timeInterval;
      dayDeviation = source.dayDeviation;
      customValue = source.customValue;
      subtractLunch = source.subtractLunch;
      flexDinner = source.flexDinner;
    }
  }

  /** Сравнить */
  public static IsEquals(obj1: GraphDayViewModelBase, obj2: GraphDayViewModelBase) {
    if (obj1 == obj2) {
      return true;
    }

    return obj1.id == obj2.id &&
      TimeIntervalVM.IsEquals(obj1.timeInterval, obj2.timeInterval) &&
      DayDeviationVM.IsEquals(obj1.dayDeviation, obj2.dayDeviation) &&
      obj1.customValue == obj2.customValue &&
      obj1.subtractLunch == obj2.subtractLunch &&
      obj1.flexDinner === obj2.flexDinner;
  }
}

/** Модель ячейки */
export class GraphDayViewModel extends GraphDayViewModelBase implements IAsHasStateChange<GraphDayViewModelBase>, IAsObservable<GraphDayViewModel>{
  public readonly asHasStateChange: AsHasStateChangeObj<GraphDayViewModelBase>;
  public readonly asObservable: AsObservableObj<GraphDayViewModel>;

  /** Ссылка на строку к которой относится ячейка */
  public row: GraphGridRowModel;
  /** День к которой относится ячейка */
  public day: Day;
  public title: string;
  public covidHours: number;
  public hasCovid2: boolean;
  public vichHours: number;
  public tuberHours: number;

  /** Изменена ли ячейка */
  public get isChanged() {
    if (this.compareState == 'modified') { //Если в режиме сравнения
      return true;
    }

    return this.asHasStateChange.isChanged;
  }

  /** Конструктор */
  constructor(
    row: GraphGridRowModel,
    day: Day,
    values: GraphDayViewModelBase,
    covidLogs: GetGraphModelResult_Data_CovidLogs,
    hasCovid2: boolean,
    vichLogs: GetGraphModelResult_Data_VichLogs,
    tuberLogs: GetGraphModelResult_Data_TuberLogs,
    public hasExcludeMilk: boolean
  ) {
    super();

    if (!row || !day) {
      throw new Error('row or day is null')
    }

    this.row = row;
    this.day = day;

    this.getTitle(values?.timeInterval, values?.flexDinner);

    if (covidLogs) {
      this.covidHours = covidLogs.covidHours;
    }

    if (vichLogs) {
      this.vichHours = vichLogs.vichHours;
    }

    if (tuberLogs) {
      this.tuberHours = tuberLogs.tuberHours;
    }

    this.hasCovid2 = hasCovid2;

    if (!values) {
      values = GraphDayViewModelBase.defaultValues;
    }

    this.asHasStateChange = AsHasStateChangeObj.Create(values, GraphDayViewModelBase.Copy, GraphDayViewModelBase.IsEquals)
    this.asObservable = new AsObservableObj<GraphDayViewModel>(() => this, true);
  }

  private getTitle(timeInterval: TimeIntervalVM, dinner: number | null){
    if (timeInterval){
      const dinnerStr = !dinner ? '' : `, обед: ${dinner} мин`;
      this.title = '';
      this.title += `${this.day.type.name} (дневные часы: ${timeInterval.duration}, ночные часы: ${timeInterval.nightDuration}${dinnerStr})`;
    }
  }

  get id(): number { return this.asHasStateChange.current.id }
  set id(value: number) {
    this.asHasStateChange.init.id = value;
    this.asHasStateChange.current.id = value;
  }

  public get timeInterval(): TimeIntervalVM { return this.asHasStateChange.current.timeInterval };
  public set timeInterval(value: TimeIntervalVM) {
    this.asHasStateChange.current.timeInterval = value;
    this.getTitle(value, null);
    if (!value && this.subtractLunch) {
      this.subtractLunch = false;
    }
  }

  public get dayDeviation(): DayDeviationVM { return this.asHasStateChange.current.dayDeviation; };
  public set dayDeviation(value: DayDeviationVM) {
    this.asHasStateChange.current.dayDeviation = value;
    if (!value) {
      this.customValue = null;
    }
  }

  public get customValue(): number { return this.asHasStateChange.current.customValue; }
  public set customValue(value: number) {
    if (!value) {
      value = 0;
    }
    this.asHasStateChange.current.customValue = value;
  }

  public get subtractLunch(): boolean { return this.asHasStateChange.current.subtractLunch; }
  public set subtractLunch(value: boolean) { this.asHasStateChange.current.subtractLunch = value; }

  public get flexDinner(): number | null { return this.asHasStateChange.current.flexDinner; }
  public set flexDinner(value: number | null) {
    this.asHasStateChange.current.flexDinner = value;
    this.getTitle(this.timeInterval, value);
  }

  /** Выполнение после всех изменений */
  public onChange() {
    this.row.onChangedGraphDay(this); //Сообщаем строке, что данная ячейка изменилась
    this.asObservable.onChange();
  }

  /**
   * Применить изменения
   * Текущее состояние станет стартовым
   * ИСПОЛЬЗОВАНИЕ: при сохранении графика
   */
  public applyChange() {
    this.asHasStateChange.save();
    this.asObservable.onChange();
    this.row.onChangedGraphDay(this, false);
  }

  /**
   * @constructor
   */
  public static Create(
    row: GraphGridRowModel,
    source: GetGraphModelResult_Data_GraphDay,
    days: Array<Day>,
    timeIntervals: Array<TimeIntervalVM>,
    dayDeviations: Array<DayDeviationVM>,
    covidLogs: GetGraphModelResult_Data_CovidLogs,
    hasCovid2: boolean,
    vichLogs: GetGraphModelResult_Data_VichLogs,
    tuberLogs: GetGraphModelResult_Data_TuberLogs,
    hasExcludeMilk: boolean
  ): GraphDayViewModel {
    return new GraphDayViewModel(
      row,
      days.find(x => +x.date == +source.date),
      new class extends GraphDayViewModelBase {
        customValue = source.dayDeviationCustomValue;
        dayDeviation = dayDeviations.find(x => x.id == source.dayDeviationId);
        id = source.id;
        subtractLunch = source.substractLunchFlag;
        timeInterval = timeIntervals.find(x => x.id == source.timeIntervalId);
        flexDinner = source.flexDinner;
      },
      covidLogs,
      hasCovid2,
      vichLogs,
      tuberLogs,
      hasExcludeMilk,
    )
  }

  /**
   * Создать пустой
   * @constructor
   */
    public static CreateEmpty(rowModel: GraphGridRowModel, day: Day, covidHours: GetGraphModelResult_Data_CovidLogs, hasCovid2: boolean, vichHours: GetGraphModelResult_Data_VichLogs, tuberHours: GetGraphModelResult_Data_TuberLogs): GraphDayViewModel{
    return new GraphDayViewModel(
      rowModel,
      day,
      null,
      covidHours,
      hasCovid2,
      vichHours,
      tuberHours,
      !rowModel.staffUnit.milk,
    )
  }
}

/** Тип результата сравнения */
/** Тип результата сравнения */
type CompareState = 'deleted' | 'added' | 'modified' | null;
